// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
  aws_project_region: 'ap-south-1',
  aws_content_delivery_bucket: 'test.mahaayudaan.in-master',
  aws_content_delivery_bucket_region: 'ap-south-1',
  aws_content_delivery_url: 'https://d3p1lrn7fshtot.cloudfront.net'
};

export default awsmobile;
