import moment from 'moment';
import diagram from './assets/diagram.bpmn';
import {
  USERTYPE_BSD_STAFF,
  OPTIONS_SPECIALIST_DOCTYPE,
  STATUS_BSDPATIENT,
  OPTIONS_BSD_OPDFORM_VISITTYPE,
  OPTIONS_BSD_OPDFORM_MOA,
  OPTIONS_BSD_OPDFORM_TRANSFERREDFROM,
  OPTIONS_BSD_OPDFORM_SEX,
  OPTIONS_BSD_OPDFORM_SOURCEOFHISTORY,
  OPTIONS_BSD_OPDFORM_PASTHX,
  OPTIONS_BSD_OPDFORM_NUTRSCREENING,
  USERTYPE_SPECIALIST,
  USERTYPE_MEDICALINCHARGE,
  OPTIONS_SPECIALIST_ATTESTEDBY,
  OPTIONS_MEDICALINCHARGE_ATTESTEDBY,
  ORGAN,
  OPTIONS_TRANSPLANTCOORDDOCTYPE,
  OPTIONS_FORM12STATUS,
  OPTIONS_FORM13STATUS,
  FORMAT_DATE_UNIXTS,
  OPTIONS_TRANSPLANTSTATUS,
  OPTIONS_AUTHORISATIONCOMMITTEE,
  USERTYPE_INSPECTOR,
  USERTYPE_DMERINCHARGE,
  OPTIONS_SPECIALISATION,
  USERTYPE_DHS,
  USERTYPE_HSPTLIC,
  OPTIONS_GVTPVT,
  OPTIONS_TEACHING,
  OPTIONS_HOSPITALSTATUS,
  OPTIONS_SPECIALISTFORMSTATUS,
  OPTIONS_MEDICALINCHARGEFORMSTATUS,
  OPTIONS_APPROVALSTATUS,
  OPTIONS_TXFORMTYPE,
  OPTIONS_MONTHLYNOTIFICATIONSTATUS,
  OPTIONS_DONORFORMTYPE,
  URGENCY,
  RECIPIENT_FORMSTATUS,
  DONOR_FORMSTATUS,
  DISCHARGESTATUS,
  USERTYPE_ROTTOUSER,
  USERTYPE_DMER,
  OPTIONS_ALLOCATIONSTATUS,
  OPTIONS_DONOR_RELATIONSHIP_FOR_REALTED,
} from './constants';
import { OPTIONS_NATIONALITY } from './nationalities';
import { DISTRICTS_MH } from './districts/districtsMh';
import { STATE_IN } from './statesIndia';
import initialData from './components/LivingOrganTransplant/initialData';

const lastYear = `${moment().subtract(1, 'years').format('YYYY')}-${moment()
  .subtract(0, 'years')
  .format('YY')}`;
const lastYearM1 = `${moment().subtract(2, 'years').format('YYYY')}-${moment()
  .subtract(1, 'years')
  .format('YY')}`;
const lastYearM2 = `${moment().subtract(3, 'years').format('YYYY')}-${moment()
  .subtract(2, 'years')
  .format('YY')}`;

export const REGULATOR = {
  name: '',
  email: '',
  userType: USERTYPE_DHS.SUPERINTENDENT.value,
  contact: '',
  seedWords: '',
  ethAddress: '',
};

export const DMERINCHARGE = {
  name: '',
  email: '',
  userType: USERTYPE_DMERINCHARGE.INCHARGE.value,
  signingRole: '',
  designation: '',
  contact: '',
  seedWords: '',
  ethAddress: '',
};

// export const ROTTOUSER = {
//   name: '',
//   email: '',
//   userType: USERTYPE_ROTTOUSER.ROTTOUSER.value,
//   signingRole: '',
//   designation: '',
//   contact: '',
//   seedWords: '',
//   ethAddress: '',
// };

export const MONTHLYNOTIFICATION = {
  reportStatus: OPTIONS_MONTHLYNOTIFICATIONSTATUS.SCHEDULED,
  recordId: '',
};

export const ALLOCATOR = {
  name: '',
  email: '',
  userType: '',
  signingRole: '',
  designation: '',
  contact: '',
  seedWords: '',
  ethAddress: '',
};

export const HOSPITALINCHARGE = {
  name: '',
  email: '',
  userType: USERTYPE_HSPTLIC.PRGINCHARGE.value,
  contact: '',
  seedWords: '',
  ethAddress: '',
};

export const HOSPITALCHAIRMAN = {
  name: '',
  email: '',
  userType: USERTYPE_HSPTLIC.CHAIRMAN.value,
  contact: '',
  seedWords: '',
  ethAddress: '',
};

export const ROTTOSOTTODIRECTOR = {
  name: '',
  email: '',
  userType: USERTYPE_ROTTOUSER.DIRECTOR.value,
  contact: '',
  institute: '',
};

export const ROTTOSOTTOJOINTDIRECTOR = {
  name: '',
  email: '',
  userType: USERTYPE_ROTTOUSER.JOINTDIRECTOR.value,
  contact: '',
  institute: '',
};

export const ROTTOSOTTOSTATECOORDINATOR = {
  name: '',
  email: '',
  userType: USERTYPE_ROTTOUSER.STATETXCOORDINATOR.value,
  contact: '',
  institute: '',
};

export const ROTTOSOTTOCONSULTANT = {
  name: '',
  email: '',
  userType: USERTYPE_ROTTOUSER.CONSULTANT.value,
  contact: '',
  institute: '',
};

export const DMERDIRECTOR = {
  name: '',
  email: '',
  userType: USERTYPE_DMER.DIRECTOR.value,
  contact: '',
  institute: '',
};

export const DMERJOINTDIRECTOR = {
  name: '',
  email: '',
  userType: USERTYPE_DMER.JOINTDIRECTOR.value,
  contact: '',
  institute: '',
};

export const DMERSTATECOORDINATOR = {
  name: '',
  email: '',
  userType: USERTYPE_DMER.STATETXCOORDINATOR.value,
  contact: '',
  institute: '',
};

export const DMERCONSULTANT = {
  name: '',
  email: '',
  userType: USERTYPE_DMER.CONSULTANT.value,
  contact: '',
  institute: '',
};

export const HOSPITALNEWINCHARGE = {
  name: '',
  email: '',
  userType: USERTYPE_HSPTLIC.PRGINCHARGE.value,
  contact: '',
  seedWords: '',
  ethAddress: '',
  isNew: true,
};

export const HOSPITAL = {
  name: '',
  nursingHomeRegnNo: '',
  address: {
    line1: '',
    pinCode: '',
    district: DISTRICTS_MH[16].value,
  },
  email: '',
  contact: '',
  status: OPTIONS_HOSPITALSTATUS.NEW,
  incharges: [HOSPITALINCHARGE, HOSPITALCHAIRMAN],
  transplantCoordinators: [HOSPITALINCHARGE],
  hospitalTypes: [],
};

export const ZTCC = {
  name: '',
  users: [
    {
      name: '',
      email: '',
      designation: '',
      contact: '',
    },
  ],
  status: OPTIONS_HOSPITALSTATUS.NEW,
};

export const ROTTOSOTTO = {
  name: '',
  nursingHomeRegnNo: '',
  address: {
    line1: '',
    pinCode: '',
    district: DISTRICTS_MH[16].value,
  },
  email: '',
  contact: '',
  users: [
    ROTTOSOTTODIRECTOR,
    ROTTOSOTTOJOINTDIRECTOR,
    ROTTOSOTTOCONSULTANT,
    ROTTOSOTTOSTATECOORDINATOR,
  ],
  status: OPTIONS_HOSPITALSTATUS.NEW,
  hospitalTypes: [],
};

export const DMER = {
  name: '',
  nursingHomeRegnNo: '',
  address: {
    line1: '',
    pinCode: '',
    district: DISTRICTS_MH[16].value,
  },
  email: '',
  contact: '',
  users: [
    DMERDIRECTOR,
    DMERJOINTDIRECTOR,
    DMERCONSULTANT,
    DMERSTATECOORDINATOR,
  ],
  status: OPTIONS_HOSPITALSTATUS.NEW,
  hospitalTypes: [],
};

export const INSPECTOR = {
  name: '',
  email: '',
  userType: USERTYPE_INSPECTOR.MEMBER.value,
  specialisation: OPTIONS_SPECIALISATION[0].value,
  contact: '',
  seedWords: '',
  ethAddress: '',
};

export const BSD_STAFF = {
  name: '',
  email: '',
  userType: USERTYPE_BSD_STAFF.SOCIALWORKER.value,
  signingRole: USERTYPE_BSD_STAFF.SOCIALWORKER.value,
  designation: '',
  contact: '',
  seedWords: '',
  ethAddress: '',
};

export const FORM12 = {
  organ: '',
  hospital: {
    name: '',
    location: '',
    telephone: '',
    fax: '',
    website: '',
    gvtPvt: OPTIONS_GVTPVT[1].value,
    teachingNonTeaching: OPTIONS_TEACHING[0].value,
    approachedBy: {
      road: true,
      rail: false,
      air: false,
    },
    totalBedStrength: 0,
    disciplines: {
      name: '',
      url: '',
    },
    annualBudget: '',
    patientTurnover: [
      {
        year: lastYearM2,
        ipd: 0,
        opd: 0,
      },
      {
        year: lastYearM1,
        ipd: 0,
        opd: 0,
      },
      {
        year: lastYear,
        ipd: 0,
        opd: 0,
      },
    ],
    nursingHomeRegnCert: {
      documentNo: '',
      issuedDate: null,
      validTill: null,
      attachment: {
        name: '',
        url: '',
      },
    },

    bioMedicalRegnCert: {
      documentNo: '',
      issuedDate: null,
      validTill: null,
      attachment: {
        name: '',
        url: '',
      },
    },
    shopsActRegnCert: {
      documentNo: '',
      issuedDate: null,
      validTill: null,
      attachment: {
        name: '',
        url: '',
      },
    },
    fireRegnCert: {
      documentNo: '',
      issuedDate: null,
      validTill: null,
      attachment: {
        name: '',
        url: '',
      },
    },
    approvedHospitalPlan: {
      name: '',
      url: '',
    },
  },
  surgicalFacilities: {
    noOfBeds: 0,
    noOfPermanentStaff: {
      name: '',
      url: '',
    },
    noOfTemporaryStaff: {
      name: '',
      url: '',
    },
    noOfOperationsPerYr: [
      {
        year: lastYearM2,
        noOfSurgeries: 0,
      },
      {
        year: lastYearM1,
        noOfSurgeries: 0,
      },
      {
        year: lastYear,
        noOfSurgeries: 0,
      },
    ],
    trainedPersons: [],
  },
  medicalFacilities: {
    noOfBeds: 0,
    noOfPermanentStaff: {
      name: '',
      url: '',
    },
    noOfTemporaryStaff: {
      name: '',
      url: '',
    },
    trainedPersons: [],
    patientTurnover: [
      {
        year: lastYearM2,
        ipd: 0,
        opd: 0,
      },
      {
        year: lastYearM1,
        ipd: 0,
        opd: 0,
      },
      {
        year: lastYear,
        ipd: 0,
        opd: 0,
      },
    ],
    noOfTrnsplntCandidatesPerYr: 0,
  },
  anaesthesiology: {
    noOfPermanentStaff: {
      name: '',
      url: '',
    },
    noOfTemporaryStaff: {
      name: '',
      url: '',
    },
    nameNoOfOperations: {
      name: '',
      url: '',
    },
    nameNoOfEquipments: {
      name: '',
      url: '',
    },
    trainedPersons: [],
    totalOpTheaters: 0,
    noOfEmergencyOpTheaters: 0,
    noOfSeparateTransplantOpTheaters: 0,
  },
  icuHdu: {
    icuHduFacilities: false,
    noOfIcuHduBeds: 0,
    trainedNurses: {
      name: '',
      url: '',
    },
    trainedTechnicians: {
      name: '',
      url: '',
    },
    nameOfIcuEquipments: {
      name: '',
      url: '',
    },
  },
  otherSupportiveFacilities: {
    labFacilities: {
      noOfPermanentStaff: {
        name: '',
        url: '',
      },
      noOfTemporaryStaff: {
        name: '',
        url: '',
      },
      nameOfInvestigations: {
        name: '',
        url: '',
      },
      nameNoOfEquipments: {
        name: '',
        url: '',
      },
    },
    imagingFacilities: {
      noOfPermanentStaff: {
        name: '',
        url: '',
      },
      noOfTemporaryStaff: {
        name: '',
        url: '',
      },
      nameOfInvestigations: {
        name: '',
        url: '',
      },
      nameNoOfEquipments: {
        name: '',
        url: '',
      },
    },
    haematologyFacilities: {
      noOfPermanentStaff: {
        name: '',
        url: '',
      },
      noOfTemporaryStaff: {
        name: '',
        url: '',
      },
      nameOfInvestigations: {
        name: '',
        url: '',
      },
      nameNoOfEquipments: {
        name: '',
        url: '',
      },
    },
    bloodBankFacilities: {
      available: true,
      mou: {
        name: '',
        url: '',
      },
      thirdPartyName: '',
      issuedDate: parseInt(moment().format(FORMAT_DATE_UNIXTS), 10),
      validTill: parseInt(moment().format(FORMAT_DATE_UNIXTS), 10),
    },
    dialysisFacilities: {
      available: true,
      mou: {
        name: '',
        url: '',
      },
      thirdPartyName: '',
      issuedDate: parseInt(moment().format(FORMAT_DATE_UNIXTS), 10),
      validTill: parseInt(moment().format(FORMAT_DATE_UNIXTS), 10),
    },
    otherFacilities: [
      {
        name: '',
        attachment: {
          name: '',
          url: '',
        },
      },
    ],
    transplantCoordinators: [],
    otherSupportiveExperts: {
      nephrologist: false,
      neurologist: false,
      neuroSurgeon: false,
      urologist: false,
      gISurgeon: false,
      paediatrician: false,
      physiotherapist: false,
      socialWorker: false,
      immunologists: false,
      cardiologist: false,
      respiratoryPhysician: false,
      nephrologistTeam: [],
      neurologistTeam: [],
      neuroSurgeonTeam: [],
      urologistTeam: [],
      gISurgeonTeam: [],
      paediatricianTeam: [],
      physiotherapistTeam: [],
      socialWorkerTeam: [],
      immunologistsTeam: [],
      cardiologistTeam: [],
      respiratoryPhysicianTeam: [],
    },
  },
  bsdTeam: {
    medicalIncharges: [],
    indMedicalPractitioners: [],
    specialists: [],
  },
  transplantCoordinator: [],
  verification: {},
  inspection: {
    report: {
      url: '',
      name: '',
      key: '',
    },
  },
  approval: {
    certificate: {
      hash: '',
      attachment: {
        name: '',
        url: '',
        key: '',
      },
    },
  },
  formDocuments: {
    txCommittee: [],
  },
  status: OPTIONS_FORM12STATUS.NEW,
};

export const FORM_DOCUMENTS = {
  uploadDate: parseInt(moment().format(FORMAT_DATE_UNIXTS), 10),
  uploaderId: '',
  attachment: {
    name: '',
    url: '',
    key: '',
  },
  isNew: true,
};

export const FORM12_TRNSPLTCOORD_DOCUMENT = {
  documentType: OPTIONS_TRANSPLANTCOORDDOCTYPE[0].value,
  attachment: {
    name: '',
    url: '',
  },
};

export const LEGACYFORM12 = {
  organ: '',
  form12: {
    hash: '',
    name: '',
    url: '',
    key: '',
  },
  dhsLetter: {
    hash: '',
    name: '',
    url: '',
    key: '',
  },
  issuedOn: null,
  surgicalFacilities: {
    trainedPersons: [],
  },
  medicalFacilities: {
    trainedPersons: [],
  },
  anaesthesiology: {
    trainedPersons: [],
  },
  bsdTeam: {
    medicalIncharges: [],
    indMedicalPractitioners: [],
    specialists: [],
  },
  verification: {},
  approval: {
    certificate: {
      hash: '',
      attachment: {
        name: '',
        url: '',
        key: '',
      },
    },
  },
  status: OPTIONS_FORM12STATUS.NEW,
};

export const LEGACYFORM13 = {
  organ: ORGAN.HEART.value,
  form13: {
    hash: '',
    name: '',
    url: '',
    key: '',
  },
  issuedOn: null,
  surgicalFacilities: {
    trainedPersons: [],
  },
  medicalFacilities: {
    trainedPersons: [],
  },
  anaesthesiology: {
    trainedPersons: [],
  },
  bsdTeam: {
    medicalIncharges: [],
    indMedicalPractitioners: [],
    specialists: [],
  },
  verification: {},
  approval: {
    certificate: {
      hash: '',
      attachment: {
        name: '',
        url: '',
        key: '',
      },
    },
  },
  status: OPTIONS_FORM13STATUS.NEW,
};

export const FORM13 = {
  organ: '',
  hospital: {
    name: '',
    location: '',
    telephone: '',
    fax: '',
    website: '',
    gvtPvt: OPTIONS_GVTPVT[1].value,
    teachingNonTeaching: OPTIONS_TEACHING[0].value,
    approachedBy: {
      road: true,
      rail: false,
      air: false,
    },
    totalBedStrength: 0,
    disciplines: {
      name: '',
      url: '',
    },
    annualBudget: '',
    patientTurnover: [
      {
        year: lastYearM2,
        ipd: 0,
        opd: 0,
      },
      {
        year: lastYearM1,
        ipd: 0,
        opd: 0,
      },
      {
        year: lastYear,
        ipd: 0,
        opd: 0,
      },
    ],
    nursingHomeRegnCert: {
      documentNo: '',
      issuedDate: null,
      validTill: null,
      attachment: {
        name: '',
        url: '',
      },
    },
    bioMedicalRegnCert: {
      documentNo: '',
      issuedDate: null,
      validTill: null,
      attachment: {
        name: '',
        url: '',
      },
    },
    shopsActRegnCert: {
      documentNo: '',
      issuedDate: null,
      validTill: null,
      attachment: {
        name: '',
        url: '',
      },
    },
    fireRegnCert: {
      documentNo: '',
      issuedDate: null,
      validTill: null,
      attachment: {
        name: '',
        url: '',
      },
    },
    approvedHospitalPlan: {
      name: '',
      url: '',
    },
  },

  surgicalFacilities: {
    noOfBeds: 0,
    noOfPermanentStaff: {
      name: '',
      url: '',
    },
    noOfTemporaryStaff: {
      name: '',
      url: '',
    },
    noOfOperationsPerYr: [
      {
        year: lastYearM2,
        noOfSurgeries: 0,
      },
      {
        year: lastYearM1,
        noOfSurgeries: 0,
      },
      {
        year: lastYear,
        noOfSurgeries: 0,
      },
    ],
    trainedPersons: [],
  },
  medicalFacilities: {
    noOfBeds: 0,
    noOfPermanentStaff: {
      name: '',
      url: '',
    },
    noOfTemporaryStaff: {
      name: '',
      url: '',
    },
    trainedPersons: [],
    patientTurnover: [
      {
        year: lastYearM2,
        ipd: 0,
        opd: 0,
      },
      {
        year: lastYearM1,
        ipd: 0,
        opd: 0,
      },
      {
        year: lastYear,
        ipd: 0,
        opd: 0,
      },
    ],
    noOfBsdDeclaredPerYr: 0,
    noOfCriticalTraumaCasesPerYr: 0,
  },
  anaesthesiology: {
    noOfPermanentStaff: {
      name: '',
      url: '',
    },
    noOfTemporaryStaff: {
      name: '',
      url: '',
    },
    nameNoOfOperations: {
      name: '',
      url: '',
    },
    nameNoOfEquipments: {
      name: '',
      url: '',
    },
    trainedPersons: [],
    totalOpTheaters: 0,
    noOfEmergencyOpTheaters: 0,
    noOfSeparateRetrievalOpTheaters: 0,
  },
  icuHdu: {
    icuHduFacilities: false,
    noOfIcuHduBeds: 0,
    trainedNurses: {
      name: '',
      url: '',
    },
    trainedTechnicians: {
      name: '',
      url: '',
    },
    nameOfIcuEquipments: {
      name: '',
      url: '',
    },
  },
  otherSupportiveFacilities: {
    labFacilities: {
      noOfPermanentStaff: {
        name: '',
        url: '',
      },
      noOfTemporaryStaff: {
        name: '',
        url: '',
      },
      nameOfInvestigations: {
        name: '',
        url: '',
      },
      nameNoOfEquipments: {
        name: '',
        url: '',
      },
    },
    imagingFacilities: {
      noOfPermanentStaff: {
        name: '',
        url: '',
      },
      noOfTemporaryStaff: {
        name: '',
        url: '',
      },
      nameOfInvestigations: {
        name: '',
        url: '',
      },
      nameNoOfEquipments: {
        name: '',
        url: '',
      },
    },
    haematologyFacilities: {
      noOfPermanentStaff: {
        name: '',
        url: '',
      },
      noOfTemporaryStaff: {
        name: '',
        url: '',
      },
      nameOfInvestigations: {
        name: '',
        url: '',
      },
      nameNoOfEquipments: {
        name: '',
        url: '',
      },
    },
    bloodBankFacilities: {
      available: true,
      mou: {
        name: '',
        url: '',
      },
    },
    otherFacilities: [
      {
        name: '',
        attachment: {
          name: '',
          url: '',
        },
      },
    ],
    transplantCoordinators: [],
    otherSupportiveExperts: {
      nephrologist: false,
      neurologist: false,
      neuroSurgeon: false,
      urologist: false,
      gISurgeon: false,
      paediatrician: false,
      physiotherapist: false,
      socialWorker: false,
      immunologists: false,
      cardiologist: false,
      respiratoryPhysician: false,
      nephrologistTeam: [],
      neurologistTeam: [],
      neuroSurgeonTeam: [],
      urologistTeam: [],
      gISurgeonTeam: [],
      paediatricianTeam: [],
      physiotherapistTeam: [],
      socialWorkerTeam: [],
      immunologistsTeam: [],
      cardiologistTeam: [],
      respiratoryPhysicianTeam: [],
    },
  },
  bsdTeam: {
    medicalIncharges: [],
    indMedicalPractitioners: [],
    specialists: [],
  },
  transplantCoordinator: [],
  verification: {},
  inspection: {},
  approval: {
    certificate: {
      hash: '',
      attachment: {
        name: '',
        url: '',
        key: '',
      },
    },
  },
  status: OPTIONS_FORM13STATUS.NEW,
};

export const FORM13_TRNSPLTCOORD_DOCUMENT = {
  documentType: OPTIONS_TRANSPLANTCOORDDOCTYPE[0].value,
  attachment: {
    name: '',
    url: '',
  },
};

export const BSD_PATIENT = {
  mrdNo: 0,
  mlcNo: '',
  address: '',
  pinCode: '',
  visitType: OPTIONS_BSD_OPDFORM_VISITTYPE[0].value,
  visitId: '',
  visitDate: 0,
  contact: '',
  moa: OPTIONS_BSD_OPDFORM_MOA[0].value,
  transferredFrom: OPTIONS_BSD_OPDFORM_TRANSFERREDFROM[0].value,
  dept: '',
  unit: '',
  treatingDoctorId: '',
  name: '',
  sex: OPTIONS_BSD_OPDFORM_SEX[0].value,
  age: 0,
  height: 0,
  weight: 0,
  presentingComplaints: '',
  sourceOfHistory: OPTIONS_BSD_OPDFORM_SOURCEOFHISTORY[0].value,
  surgicalHistory: '',
  medications: '',
  pastHx: OPTIONS_BSD_OPDFORM_PASTHX[0].value,
  allergies: '',
  nutritionalScreening: OPTIONS_BSD_OPDFORM_NUTRSCREENING[0].value,
  examinationDetails: '',
  diagnosis: '',
  investigations: '',
  medicationPrescribed: '',
  status: STATUS_BSDPATIENT.STABLE,
  monitoring: {
    bdc: {
      assigned: false,
      team: {},
    },
    gcs: [
      {
        time: 0,
        recordingDoctor: {},
        reading: {
          eyes: '',
          verbal: '',
          motor: '',
        },
      },
    ],
    primaryAssessment: {
      causesOfComaExcluded: {
        intoxication: false,
        depressantDrugs: false,
        relaxants: false,
        primaryHypothermia: false,
        hypovolaemicShock: false,
        metabolicEndocrineDisorders: false,
        brainStemFunctions: false,
      },
      coma: false,
      cessationOfSpontaneousBreathing: false,
      pupillarySize: false,
      pupillaryLightReflexes: false,
      dollsHeadEyeMovements: false,
      cornealReflexes: false,
      motorResponse: false,
      gagReflex: false,
      cough: false,
      eyeMovements: false,
      respiratoryMovement: false,
    },
  },
};

export const SPECIALIST = {
  name: '',
  email: '',
  userType: USERTYPE_SPECIALIST,
  specialisation: [],
  contact: '',
  bsd: {
    isBsdMember: false,
    memberType: '',
  },
  surgeon: {
    isTranslplantSurgeon: false,
    organ: '',
  },
  documents: [
    {
      documentType: 'MBBS',
      documentNo: 'NA',
      issuedDate: '',
      validTill: '',
      attachment: {
        name: '',
        url: '',
      },
      attestation: {
        attestedBy: OPTIONS_SPECIALIST_ATTESTEDBY[0].value,
        attestorName: '',
        attestorDesignation: '',
      },
    },
    {
      documentType: 'PG',
      documentNo: '',
      issuedDate: '',
      validTill: '',
      academicMedicalDegree: [],
      otherMedicalDegreeDetails: '',
      academicSpecialisation: [],
      otherAcademicSpecialisationDetails: '',
      attachment: {
        name: '',
        url: '',
      },
      attestation: {
        attestedBy: OPTIONS_SPECIALIST_ATTESTEDBY[0].value,
        attestorName: '',
        attestorDesignation: '',
      },
    },
    {
      documentType: 'MMCREGN',
      documentNo: 'NA',
      issuedDate: '',
      validTill: '',
      attachment: {
        name: '',
        url: '',
      },
      attestation: {
        attestedBy: OPTIONS_SPECIALIST_ATTESTEDBY[0].value,
        attestorName: '',
        attestorDesignation: '',
      },
    },
    {
      documentType: 'ADDNLQUALIFICATION',
      documentNo: '',
      issuedDate: '',
      validTill: '',
      attachment: {
        name: '',
        url: '',
      },
      attestation: {
        attestedBy: OPTIONS_SPECIALIST_ATTESTEDBY[0].value,
        attestorName: '',
        attestorDesignation: '',
      },
    },
    {
      documentType: 'MMC',
      documentNo: 'NA',
      issuedDate: '',
      validTill: '',
      attachment: {
        name: '',
        url: '',
      },
      attestation: {
        attestedBy: OPTIONS_SPECIALIST_ATTESTEDBY[0].value,
        attestorName: '',
        attestorDesignation: '',
      },
    },
    {
      documentType: 'EXPERIENCECERT',
      documentNo: '',
      issuedDate: '',
      validTill: '',
      attachment: {
        name: '',
        url: '',
      },
      attestation: {
        attestedBy: OPTIONS_SPECIALIST_ATTESTEDBY[0].value,
        attestorName: '',
        attestorDesignation: '',
      },
    },
  ],
  consentAttachment: {
    name: '',
    url: '',
  },
  appointmentLetter: {
    name: '',
    url: '',
  },
  approval: {
    status: OPTIONS_APPROVALSTATUS.PENDING,
    comment: '',
  },
  formStatus: OPTIONS_SPECIALISTFORMSTATUS.NEW,
};

export const HOSPITAL_MEDICALINCHARGE = {
  name: '',
  email: '',
  userType: USERTYPE_MEDICALINCHARGE,
  designation: '',
  contact: '',
  isBsdMember: false,
  documents: [
    {
      documentType: 'MBBS',
      documentNo: 'NA',
      issuedDate: '',
      validTill: '',
      attachment: {
        name: '',
        url: '',
      },
      attestation: {
        attestedBy: '',
        attestorName: '',
        attestorDesignation: '',
      },
    },
    {
      documentType: 'PG',
      documentNo: '',
      issuedDate: '',
      validTill: '',
      attachment: {
        name: '',
        url: '',
      },
      attestation: {
        attestedBy: '',
        attestorName: '',
        attestorDesignation: '',
      },
    },
    {
      documentType: 'MMCREGN',
      documentNo: 'NA',
      issuedDate: '',
      validTill: '',
      attachment: {
        name: '',
        url: '',
      },
      attestation: {
        attestedBy: '',
        attestorName: '',
        attestorDesignation: '',
      },
    },
    // {
    //   documentType: 'ADDNLQUALIFICATION',
    //   documentNo: '',
    //   issuedDate: '',
    //   validTill: '',
    //   attachment: {
    //     name: '',
    //     url: '',
    //   },
    //   attestation: {
    //     attestedBy: OPTIONS_MEDICALINCHARGE_ATTESTEDBY[0].value,
    //     attestorName: '',
    //     attestorDesignation: '',
    //   },
    // },
    {
      documentType: 'MMC',
      documentNo: '',
      issuedDate: '',
      validTill: '',
      attachment: {
        name: '',
        url: '',
      },
      attestation: {
        attestedBy: '',
        attestorName: '',
        attestorDesignation: '',
      },
    },
    {
      documentType: 'EXPERIENCECERT',
      documentNo: '',
      issuedDate: '',
      validTill: '',
      attachment: {
        name: '',
        url: '',
      },
      attestation: {
        attestedBy: '',
        attestorName: '',
        attestorDesignation: '',
      },
    },
  ],
  formStatus: OPTIONS_MEDICALINCHARGEFORMSTATUS.NEW,
};

export const SPECIALISTDOCUMENT = {
  documentType: OPTIONS_SPECIALIST_DOCTYPE[4].value,
  documentNo: '',
  issuedDate: parseInt(moment().format(FORMAT_DATE_UNIXTS), 10),
  validTill: parseInt(moment().format(FORMAT_DATE_UNIXTS), 10),
  attachment: {
    name: '',
    url: '',
  },
  attestation: {
    attestedBy: OPTIONS_SPECIALIST_ATTESTEDBY[0].value,
    attestorName: '',
    attestorDesignation: '',
  },
};

export const HOSPITAL_MEDICALINCHARGE_DOCUMENT = {
  documentType: OPTIONS_SPECIALIST_DOCTYPE[0].value,
  documentNo: '',
  issuedDate: parseInt(moment().format(FORMAT_DATE_UNIXTS), 10),
  validTill: parseInt(moment().format(FORMAT_DATE_UNIXTS), 10),
  attachment: {
    name: '',
    url: '',
  },
  attestation: {
    attestedBy: OPTIONS_MEDICALINCHARGE_ATTESTEDBY[0].value,
    attestorName: '',
    attestorDesignation: '',
  },
};

export const TRANSPLANTCOORDINATOR = {
  name: '',
  email: '',
  userType: USERTYPE_HSPTLIC.PRGINCHARGE.value,
  contact: '',
  documents: [
    {
      documentType: 'UG',
      documentNo: '',
      issuedDate: '',
      attachment: {
        name: '',
        url: '',
      },
    },
    {
      documentType: 'TRAININGCERT',
      documentNo: '',
      issuedDate: '',
      attachment: {
        name: '',
        url: '',
      },
    },
    {
      documentType: 'CV',
      documentNo: '',
      issuedDate: '',
      attachment: {
        name: '',
        url: '',
      },
    },
  ],
  approval: { status: OPTIONS_APPROVALSTATUS.NEW, comment: '' },
};

export const TRNSPLNTCOORDDOCUMENT = {
  documentType: OPTIONS_TRANSPLANTCOORDDOCTYPE[0].value,
  documentNo: '',
  issuedDate: parseInt(moment().format(FORMAT_DATE_UNIXTS), 10),
  validTill: parseInt(moment().format(FORMAT_DATE_UNIXTS), 10),
  attachment: {
    name: '',
    url: '',
  },
};

export const REPORTING_TRANSPLANT = {
  transplantStatus: '',
  donor: {
    name: '',
    age: null,
    donorHospital: '',
    donorHospitalName: '',
    donorHospitalZtccZone: '',
    otherStateDonorHospitalName: '',
    photo: {
      name: '',
      url: '',
      key: '',
    },
    nottoIdAttachment: {
      name: '',
      key: '',
      url: '',
    },
    nottoRegnNo: '',
    sex: '',
    nationality: OPTIONS_NATIONALITY[100].value,
    address: '',
    occupation: '',
    smoking: null,
    alcohol: null,
    comorbidities: null,
    hypertension: null,
    diabetes: null,
    ischemicHeartDisease: null,
    pulmonary: null,
    previousSurgery: null,
    uhid: '',
    bloodGroup: '',
    admissionDate: undefined,
    retrievalDate: undefined,
    discharged: undefined,
    dischargeDate: undefined,
    retrievingSurgeonId: '',
    retrievingAnaesthetistId: '',
    treatingDoctor: '',
    otherSupportiveSpecialists: [],
    survival: {
      oneMonth: {
        status: undefined,
        remarks: '',
      },
      threeMonth: {
        status: undefined,
        remarks: '',
      },
      sixMonth: {
        status: undefined,
        remarks: '',
      },
      oneTwoYear: {
        status: undefined,
        remarks: '',
      },
      threeFiveYear: {
        status: undefined,
        remarks: '',
      },
      sixYearAbove: {
        status: undefined,
        remarks: '',
      },
    },
  },
  recipient: {
    name: '',
    age: undefined,
    photo: {
      name: '',
      url: '',
      key: '',
    },
    nottoRegnNo: '',
    sex: '',
    nationality: OPTIONS_NATIONALITY[100].value,
    address: '',
    uhid: '',
    bloodGroup: '',
    admissionDate: undefined,
    transplantDate: undefined,
    discharged: undefined,
    dischargeDate: undefined,
    transplantSurgeonId: '',
    transplantAnaesthetistId: '',
    treatingDoctor: '',
    basicDiagnosis: '',
    indicationForTransplant: '',
    prognosis: '',
    noOfCorneaTransplanted:'',
    opticalTheraputic: '',
    physicianId: '',
    otherSupportiveSpecialists: [],
    recipientStatus: '',
    expiredDate: undefined,
    survival: {
      oneMonth: {
        status: '',
        remarks: '',
        expiryDate: '',
      },
      threeMonth: {
        status: '',
        remarks: '',
        expiryDate: '',
      },
      sixMonth: {
        status: '',
        remarks: '',
        expiryDate: '',
      },
      oneTwoYear: {
        status: '',
        remarks: '',
        expiryDate: '',
      },
      threeFiveYear: {
        status: '',
        remarks: '',
        expiryDate: '',
      },
      sixYearAbove: {
        status: '',
        remarks: '',
        expiryDate: '',
      },
    },
  },
  resultOfTransplant: '',
  cost: {
    preOperative: 0,
    operative: 0,
    postOperative: '',
  },
  donorRelation: '',
  periodOfMarriage: '',
  inhouseDonor: undefined,
  bsdDonorIdAvailable: true,
  relationWithRecipient: '',
  otherRelationWithRecipient: '',
  reasonForDonation: '',
  authorisationCommittee: OPTIONS_AUTHORISATIONCOMMITTEE[0].value,
  authorisationCommitteeDetails: '',
  relatedForms: [
    // {
    //   formType: '',
    //   attachment: {
    //     name: '',
    //     key: '',
    //   },
    // },
  ],
  approvalDate: undefined,
  approval: {
    approverId: '',
    txHash: '',
    timestamp: 0,
  },
  registration: {
    coordinatorId: '',
    txHash: '',
    timestamp: 0,
  },
  organ: {
    organType: '',
  },
  tissue: {
    tissueType: '',
  },
  fillFormForOrganOrTissue: '',
  causeOfDeathOtherReason: '',
  causeOfDeath: '',
  cadaver: false,
  bsdDonorId: '',
  status: OPTIONS_TRANSPLANTSTATUS.NEW,
};

export const TRANSPLANTRELATEDFORM = {
  formType: OPTIONS_TXFORMTYPE[0].value,
  attachment: {
    name: '',
    url: '',
    key: '',
  },
};

export const ORGAN_RECIPIENT = {
  firstName: '',
  middleName: '',
  lastName: '',
  registrationDate: '',
  nottoId: '',
  age: '',
  sex: '',
  bloodGroup: '',
  height: '',
  weight: '',
  email: '',
  contact: '',
  address: '',
  district: '',
  state: '',
  nationality: '',
  uhid: '',
  hospitalName: '',
  heartUrgencyStatus: URGENCY.REGULAR.value,
  liverUrgencyStatus: URGENCY.REGULAR.value,
  heartUrgencyDocument: {
    name: '',
    url: '',
    key: '',
  },
  liverUrgencyDocument: {
    name: '',
    url: '',
    key: '',
  },
  organs: [],
  formStatus: RECIPIENT_FORMSTATUS.NEW,
  recipientStatus: '',
};

export const DONOR_ORGANDETAILS = {
  organType: '',
  recipientHospital: '',
  recipientHospitalName: '',
  otherStateRecipientHospitalName: '',
  otherStateSurgeonName: '',
  otherStateSurgeonIMAId: '',
  otherStateAnaesthetistName: '',
  otherStateAnaesthetistIMAId: '',
  hospitalDetails: {
    hospitalId: '',
  },
  retrievingSurgeonId: '',
  retrievingSurgeonUser: {},
  retrievingAnaesthetistId: '',
  retrievingAnaesthetistUser: {},
  otherSupportiveSpecialists: [],
};

export const ORGAN_DONOR = {
  firstName: '',
  lastName: '',
  middleName: '',
  donorId: '',
  registrationDate: '',
  admissionDate: 0,
  retrievalDate: 0,
  ventilatorDays: '',
  ventilatorSupport: '',
  firstApneaTime: '',
  firstApneaBaselineReport: {
    name: '',
    key: '',
    url: '',
  },
  firstApneaAfterTenMinutesReport: {
    name: '',
    key: '',
    url: '',
  },
  secondApneaTime: '',
  secondApneaBaselineReport: {
    name: '',
    key: '',
    url: '',
  },
  secondApneaAfterTenMinutesReport: {
    name: '',
    key: '',
    url: '',
  },
  contact: '',
  nationality: '',
  religion: '',
  height: '',
  weight: '',
  address: '',
  district: '',
  state: '',
  email: '',
  nottoId: '',
  nottoIdAttachment: {
    name: '',
    key: '',
    url: '',
  },
  age: '',
  sex: '',
  bloodGroup: '',
  uhid: '',
  hospitalName: '',
  diagnosis: '',
  organs: [],
  occupation: '',
    smoking: null,
    alcohol: null,
    comorbidities: null,
    hypertension: null,
    diabetes: null,
    ischemicHeartDisease: null,
    pulmonary: null,
    previousSurgery: null,
  allocationStatus: OPTIONS_ALLOCATIONSTATUS[0].value,
  otherOrgan: '',
  reasonForNotDonatingOrgan: '',
  reasonForNotDonatingOrganOther: '',
  discharged: DISCHARGESTATUS.DISCHARGED.value,
  dischargeDate: parseInt(moment().format(FORMAT_DATE_UNIXTS), 10),
  retrievingSurgeonId: '',
  retrievingAnaesthetistId: '',
  otherSupportiveSpecialists: [],
  donorOrgans: [],
  donorTissues: [],
  tissues: [],
  donorHeart: {
    recipientHospital: '',
    recipientHospitalName: '',
    otherStateRecipientHospitalName: '',
    otherStateSurgeonName: '',
    otherStateSurgeonIMAId: '',
    otherStateAnaesthetistName: '',
    otherStateAnaesthetistIMAId: '',
    hospitalDetails: {
      hospitalId: '',
    },
    retrievingSurgeonId: '',
    retrievingSurgeonUser: {},
    retrievingAnaesthetistId: '',
    retrievingAnaesthetistUser: {},
    otherSupportiveSpecialists: [],
  },
  donorLeftKidney: {
    recipientHospital: '',
    recipientHospitalName: '',
    otherStateRecipientHospitalName: '',
    otherStateSurgeonName: '',
    otherStateSurgeonIMAId: '',
    otherStateAnaesthetistName: '',
    otherStateAnaesthetistIMAId: '',
    retrievingSurgeonId: '',
    retrievingSurgeonUser: {},
    retrievingAnaesthetistId: '',
    retrievingAnaesthetistUser: {},
    otherSupportiveSpecialists: [],
  },
  donorRightKidney: {
    recipientHospital: '',
    recipientHospitalName: '',
    otherStateRecipientHospitalName: '',
    otherStateSurgeonName: '',
    otherStateSurgeonIMAId: '',
    otherStateAnaesthetistName: '',
    otherStateAnaesthetistIMAId: '',
    retrievingSurgeonId: '',
    retrievingSurgeonUser: {},
    retrievingAnaesthetistId: '',
    retrievingAnaesthetistUser: {},
    otherSupportiveSpecialists: [],
  },
  donorLiver: {
    recipientHospital: '',
    recipientHospitalName: '',
    otherStateRecipientHospitalName: '',
    otherStateSurgeonName: '',
    otherStateSurgeonIMAId: '',
    otherStateAnaesthetistName: '',
    otherStateAnaesthetistIMAId: '',
    retrievingSurgeonId: '',
    retrievingSurgeonUser: {},
    retrievingAnaesthetistId: '',
    retrievingAnaesthetistUser: {},
    otherSupportiveSpecialists: [],
  },
  donorLiverRightExtendedLobe: {
    recipientHospital: '',
    recipientHospitalName: '',
    otherStateRecipientHospitalName: '',
    otherStateSurgeonName: '',
    otherStateSurgeonIMAId: '',
    otherStateAnaesthetistName: '',
    otherStateAnaesthetistIMAId: '',
    retrievingSurgeonId: '',
    retrievingSurgeonUser: {},
    retrievingAnaesthetistId: '',
    retrievingAnaesthetistUser: {},
    otherSupportiveSpecialists: [],
  },
  donorLiverLeftLateralSegment: {
    recipientHospital: '',
    recipientHospitalName: [],
    otherStateRecipientHospitalName: '',
    otherStateSurgeonName: '',
    otherStateSurgeonIMAId: '',
    otherStateAnaesthetistName: '',
    otherStateAnaesthetistIMAId: '',
    hospitalDetails: [],
    retrievingSurgeonId: '',
    retrievingSurgeonUser: {},
    retrievingAnaesthetistId: '',
    retrievingAnaesthetistUser: {},
    otherSupportiveSpecialists: [],
  },
  donorLungs: {
    recipientHospital: '',
    recipientHospitalName: '',
    otherStateRecipientHospitalName: '',
    otherStateSurgeonName: '',
    otherStateSurgeonIMAId: '',
    otherStateAnaesthetistName: '',
    otherStateAnaesthetistIMAId: '',
    retrievingSurgeonId: '',
    retrievingSurgeonUser: {},
    retrievingAnaesthetistId: '',
    retrievingAnaesthetistUser: {},
    otherSupportiveSpecialists: [],
  },
  donorHeartLungs: {
    recipientHospital: '',
    recipientHospitalName: '',
    otherStateRecipientHospitalName: '',
    otherStateSurgeonName: '',
    otherStateSurgeonIMAId: '',
    otherStateAnaesthetistName: '',
    otherStateAnaesthetistIMAId: '',
    retrievingSurgeonId: '',
    retrievingSurgeonUser: {},
    retrievingAnaesthetistId: '',
    retrievingAnaesthetistUser: {},
    otherSupportiveSpecialists: [],
  },
  donorPancreas: {
    recipientHospital: '',
    recipientHospitalName: '',
    otherStateRecipientHospitalName: '',
    otherStateSurgeonName: '',
    otherStateSurgeonIMAId: '',
    otherStateAnaesthetistName: '',
    otherStateAnaesthetistIMAId: '',
    retrievingSurgeonId: '',
    retrievingSurgeonUser: {},
    retrievingAnaesthetistId: '',
    retrievingAnaesthetistUser: {},
    otherSupportiveSpecialists: [],
  },
  donorTissue: {
    recipientHospital: '',
    recipientHospitalName: '',
    otherStateRecipientHospitalName: '',
    otherStateSurgeonName: '',
    otherStateSurgeonIMAId: '',
    otherStateAnaesthetistName: '',
    otherStateAnaesthetistIMAId: '',
    retrievingSurgeonId: '',
    retrievingSurgeonUser: {},
    retrievingAnaesthetistId: '',
    retrievingAnaesthetistUser: {},
    otherSupportiveSpecialists: [],
  },
  donorHand: {
    recipientHospital: '',
    recipientHospitalName: '',
    otherStateRecipientHospitalName: '',
    otherStateSurgeonName: '',
    otherStateSurgeonIMAId: '',
    otherStateAnaesthetistName: '',
    otherStateAnaesthetistIMAId: '',
    retrievingSurgeonId: '',
    retrievingSurgeonUser: {},
    retrievingAnaesthetistId: '',
    retrievingAnaesthetistUser: {},
    otherSupportiveSpecialists: [],
  },
  donorIntestine: {
    recipientHospital: '',
    recipientHospitalName: '',
    otherStateRecipientHospitalName: '',
    otherStateSurgeonName: '',
    otherStateSurgeonIMAId: '',
    otherStateAnaesthetistName: '',
    otherStateAnaesthetistIMAId: '',
    retrievingSurgeonId: '',
    retrievingSurgeonUser: {},
    retrievingAnaesthetistId: '',
    retrievingAnaesthetistUser: {},
    otherSupportiveSpecialists: [],
  },
  photo: {
    name: '',
    url: '',
    key: '',
  },
  relatedForms: [],
  form8: {
    name: '',
    url: '',
    key: '',
  },
  form10: {
    name: '',
    url: '',
    key: '',
  },
  policeReport: {
    value: '',
    attachment: {
      name: '',
      url: '',
    },
  },
  formStatus: DONOR_FORMSTATUS.NEW,
  donorStatus: '',
  noOfKidneys: '',
};

export const RECIPIENT_HEART = {
  recipientDetails: {
    uhid: '',
    registrationDate: '',
    firstName: '',
    middleName: '',
    lastName: '',
    contact: '',
    age: 0,
    gender: '',
    nationality: '',
    height: '',
    weight: '',
    bloodGroup: '',
    address: '',
    district: '',
    state: '',
    email: '',
    hospitalName: '',
  },
  causeOfDeath: '',
  causeOfDeathOtherReason: '',
  diagnosis: '',
  physicianName: '',
  physicianContact: '',
  physicianEmail: '',
  echoReport: '',
  remark: '',
  viralMarks: {
    hbsAgPositive: false,
    hbsAgNegative: true,
    hcvPositive: false,
    hcvNegative: true,
    hivPositive: false,
    hivNegative: true,
  },
  heartUrgencyStatus: '',
  formStatus: RECIPIENT_FORMSTATUS.NEW,
};

export const RECIPIENT_KIDNEY = {
  recipientDetails: {
    uhid: '',
    registrationDate: '',
    firstName: '',
    middleName: '',
    lastName: '',
    contact: '',
    age: 0,
    gender: '',
    nationality: '',
    height: '',
    weight: '',
    bloodGroup: '',
    address: '',
    district: '',
    state: '',
    email: '',
    hospitalName: '',
  },
  diagnosis: '',
  physicianName: '',
  physicianContact: '',
  physicianEmail: '',
  echoReport: '',
  remark: '',
  viralMarks: {
    hbsAgPositive: false,
    hbsAgNegative: true,
    hcvPositive: false,
    hcvNegative: true,
    hivPositive: false,
    hivNegative: true,
  },
  formStatus: RECIPIENT_FORMSTATUS.NEW,
};

export const DONOR_HEART = {
  donorDetails: {
    uhid: '',
    registrationDate: '',
    firstName: '',
    middleName: '',
    donorId: '',
    lastName: '',
    contact: '',
    age: 0,
    gender: '',
    nationality: '',
    height: '',
    weight: '',
    bloodGroup: '',
    address: '',
    district: '',
    state: '',
    email: '',
    hospitalName: '',
  },
  diagnosis: '',
  retrievingSurgeonId: '',
  retrievingAnaesthetistId: '',
  otherSupportiveSpecialists: [],
  praI: '',
  praII: '',
  transpulmonaryGradient: '',
  otherTransplantCenter: '',
  hepC: '',
  pvr: '',
  priority1: '',
  priority2: '',
  priority3: '',
  patientsStatus: '',
  hbsAgPositive: false,
  hbsAgNegative: false,
  hcvPositive: false,
  hcvNegative: false,
  hivPositive: false,
  hivNegative: false,
  formStatus: DONOR_FORMSTATUS.NEW,
};

export const RECIPIENT_LIVER = {
  recipientDetails: {
    uhid: '',
    registrationDate: '',
    firstName: '',
    middleName: '',
    lastName: '',
    contact: '',
    age: 0,
    gender: '',
    nationality: '',
    height: '',
    weight: '',
    bloodGroup: '',
    address: '',
    district: '',
    state: '',
    email: '',
    hospitalName: '',
  },
  diagnosis: '',
  physicianName: '',
  physicianContact: '',
  physicianEmail: '',
  liverScan: '',
  remark: '',
  viralMarks: {
    hbsAgPositive: false,
    hbsAgNegative: false,
    hcvPositive: false,
    hcvNegative: false,
    hivPositive: false,
    hivNegative: false,
  },
  liverUrgencyStatus: '',
  formStatus: RECIPIENT_FORMSTATUS.NEW,
};

export const DONOR_LIVER = {
  donorDetails: {
    uhid: '',
    registrationDate: '',
    firstName: '',
    middleName: '',
    lastName: '',
    contact: '',
    age: 0,
    gender: '',
    nationality: '',
    height: '',
    weight: '',
    bloodGroup: '',
    address: '',
    district: '',
    state: '',
    email: '',
    hospitalName: '',
  },
  diagnosis: '',
  physicianName: '',
  physicianContact: '',
  physicianEmail: '',
  retrievingSurgeonId: '',
  retrievingAnaesthetistId: '',
  otherSupportiveSpecialists: [],
  liverScan: '',
  remark: '',
  hbsAgPositive: false,
  hbsAgNegative: false,
  hcvPositive: false,
  hcvNegative: false,
  hivPositive: false,
  hivNegative: false,
  formStatus: DONOR_FORMSTATUS.NEW,
};

export const DONORFORM = {
  formType: OPTIONS_DONORFORMTYPE[0].value,
  attachment: {
    name: '',
    url: '',
    key: '',
  },
};

export const ATTACHMENT = {
  name: '',
  url: '',
  key: '',
};

export const LIVING_ORGAN_TRANSPLANT = {
  hospitalName: '',
  organ: '',
  hospitalRegnNo: '',
  hospitalLocation: {},
  recipientDetails: {
    nottoId: '',
    firstName: '',
    middleName: '',
    lastName: '',
    mobileNo: null,
    aadhaarNo: '',
    uhid: '',
    bloodGroup: '',
    dateOfBirth: null,
    gender: '',
    occupation: '',
    identityCardDetails: [
      {
        identityCardType: '',
        identityCardNo: '',
        identityCardAttachment: { name: '', key: '', url: '' },
      },
    ],
    recipientRelationWithDonor: [
      {
        donorNottoId: '',
        relationWithDonor: '',
        otherRelationwithDonor: '',
      },
    ],
    maritalStatus: '',
    nearRelativeType: '',
    nearRelativeName: '',
    financiallyIndependent: null,
    financiallyIndependentOtherRelation: '',
    financialSupporterName: '',
    financialSupporterRelation: '',
    financialSupporterOccupation: '',
    financialDetailsDocumentType: '',
    financialDetailsAttachment: {
      name: '',
      key: '',
      url: '',
    },

    doesHaveDisease: null,
    typeOfDisease: [],
    diseaseSince: '',
    etiology: [],
    otherEtiology: '',
    familyMemberDetails: [
      {
        relation: '',
        otherRelation: '',
        name: '',
        age: null,
        bloodGroup: '',
        reasonForNotDonating: '',
        attachment: {
          name: '',
          key: '',
          url: '',
        },
      },
    ],
    uploadFamilyTree: null,
    uploadFamilyTreeAttachment: { name: '', key: '', url: '' },
    uploadFamilyTreeAttachmentBase64: '',
    religion: '',
    otherReligion: '',
    citizen: '',
    isRecipientPermanentAddressSameAsPresentAddress: null,
    presentAddress: {
      address: '',
      nationality: '',
      state: '',
      district: '',
      pinCode: null,
    },
    permanentAddress: {
      address: '',
      nationality: '',
      state: '',
      district: '',
      pinCode: null,
    },
    admissionDate: null,
    transplantDate: null,
    treatingDoctorName: '',
    transplantSurgeon: [],
    transplantPhysician: [],
    transplantAnaesthetist: [],
    recipientPhoto: '',
    financialDetails: [
      {
        financialYear: lastYearM2,
        annualIncome: 0,
      },
      {
        financialYear: lastYearM1,
        annualIncome: 0,
      },
      {
        financialYear: lastYear,
        annualIncome: 0,
      },
    ],
    witnessDetails: {
      firstName: '',
      middleName: '',
      lastName: '',
      mobileNo: null,
      aadhaarNo: '',
      age: null,
      addressDetails: {
        address: '',
        country: '',
        state: '',
        district: '',
        pinCode: null,
      },
      witnessRelationWithDonor: [
        {
          donorNottoId: '',
          relationWithDonor: '',
          otherRelationwithDonor: '',
        },
      ],
      relationWithDonor: '',
      relationWithRecipient: '',
      otherRelationWithDonor: '',
      otherRelationWithRecipient: '',
      recipientRelativePhoto: '',
      // identityAttachment: {
      //   name: '',
      //   key: '',
      //   url: '',
      // },
      identityCardDetails: [
        {
          identityCardType: '',
          identityCardNo: '',
          identityCardAttachment: { name: '', key: '', url: '' },
        },
      ],
    },

    familyTreeDetails: [
      {
        firstName: '',
        middleName: '',
        lastName: '',
        dateOfBirth: null,
        age: null,
        relationType: '',
        isAlive: null,
        deathYear: null,
      },
    ],
    recipientFamilyTree: {
      familyTreeElements: [],
      familyTreeBase64: null,
    },
  },
  donorDetails: [
    {
      nottoId: '',
      firstName: '',
      middleName: '',
      lastName: '',
      mobileNo: null,
      occupation: '',
      identityCardDetails: [
        {
          identityCardType: '',
          identityCardNo: '',
          identityCardAttachment: { name: '', key: '', url: '' },
        },
      ],
      uhid: '',
      bloodGroup: '',
      dateOfBirth: null,
      gender: '',
      relationWithRecipient: '',
      otherRelationWithRecipient: '',
      maritalStatus: '',
      nearRelativeType: '',
      nearRelativeName: '',
      isDonorPermanentAddressSameAsPresentAddress: null,
      presentAddress: {
        address: '',
        nationality: '',
        state: '',
        district: '',
        pinCode: null,
      },
      permanentAddress: {
        address: '',
        nationality: '',
        state: '',
        district: '',
        pinCode: null,
      },
      citizen: '',
      religion: '',
      otherReligion: '',
      admissionDate: null,
      retrievalDate: null,
      financiallyIndependent: null,
      financiallyIndependentOtherRelation: '',
      financialSupporterName: '',
      financialSupporterRelation: '',
      financialSupporterOccupation: '',
      financialDetailsDocumentType: '',
      financialDetailsAttachment: {
        name: '',
        key: '',
        url: '',
      },
      treatingDoctorName: '',
      retrievingSurgeon: [],
      retrievingPhysician: [],
      retrievingAnaesthetist: [],
      donorPhoto: '',
      reasonForOrganDonation: '',
      familyMemberDetails: [
        {
          relation: '',
          otherRelation: '',
          name: '',
          age: null,
          bloodGroup: '',
          consentGiven: null,
          attachment: {
            name: '',
            key: '',
            url: '',
          },
        },
      ],
      uploadFamilyTree: null,
      uploadFamilyTreeAttachment: { name: '', key: '', url: '' },
      uploadFamilyTreeAttachmentBase64: '',
      familyTreeElements: [],
      familyTreeBase64: null,
      witnessDetails: [
        {
          firstName: '',
          middleName: '',
          lastName: '',
          mobileNo: null,
          aadhaarNo: '',
          age: null,
          addressDetails: {
            address: '',
            country: '',
            state: '',
            district: '',
            pinCode: null,
          },
          relationWithDonor: '',
          relationWithRecipient: '',
          otherRelationWithDonor: '',
          otherRelationWithRecipient: '',
          donorRelativePhoto: '',
          identityAttachment: {
            name: '',
            key: '',
            url: '',
          },
          identityCardDetails: [
            {
              identityCardType: '',
              identityCardNo: '',
              identityCardAttachment: { name: '', key: '', url: '' },
            },
          ],
        },
      ],

      financialDetails: [
        {
          financialYear: lastYearM2,
          annualIncome: 0,
        },
        {
          financialYear: lastYearM1,
          annualIncome: 0,
        },
        {
          financialYear: lastYear,
          annualIncome: 0,
        },
      ],
      familyTreeDetails: [
        {
          firstName: '',
          middleName: '',
          lastName: '',
          dateOfBirth: null,
          age: null,
          relationType: '',
          isAlive: null,
          deathYear: null,
        },
      ],
      donorFamilyTree: {
        familyTreeElements: [],
        familyTreeBase64: null,
      },
    },
  ],
  committeeMemberDetails: {
    committeeMembers: [],
  },

  formStatus: 'NEW',
};

export const LOTCOMMITTEEMEMBER = {
  name: '',
  email: '',
  userType: USERTYPE_HSPTLIC.PRGINCHARGE.value,
  contact: '',
  qualification: '',
  designation: '',
  rolePlay: '',
  approval: { status: OPTIONS_APPROVALSTATUS.NEW },
};

export const SWAP_TRANSPLANT = {
  hospitalName: '',
  organ: '',
  recipientDetails: {
    nottoId: '',
    firstName: '',
    middleName: '',
    lastName: '',
    bloodGroup: '',
    weight: '',
    recepOkWithMoving: null,
    isRecipientPermanentAddressSameAsPresentAddress: null,
    presentAddress: {
      address: '',
      nationality: '',
      state: '',
      district: '',
      pinCode: '',
    },
    permanentAddress: {
      address: '',
      nationality: '',
      state: '',
      district: '',
      pinCode: '',
    },
  },
  donorDetails: {
    nottoId: '',
    firstName: '',
    middleName: '',
    lastName: '',
    bloodGroup: '',
    rightLobeGraftVolume: '',
    leftLobeGraftVolume: '',
    isDonorPermanentAddressSameAsPresentAddress: null,
    presentAddress: {
      address: '',
      nationality: '',
      state: '',
      district: '',
      pinCode: '',
    },
    permanentAddress: {
      address: '',
      nationality: '',
      state: '',
      district: '',
      pinCode: '',
    },
  },
  swapDonorDetails: {
    firstName: '',
    middleName: '',
    lastName: '',
    bloodGroup: '',
    rightLobeVolume: '',
    leftLobeVolume: '',
  },

  formStatus: 'NEW',
};

export const DDHSMEMBER = {
  zone: '',
  name: '',
  email: '',
  contact: '',
  designation: '',
  userType: USERTYPE_DHS.DDHS.value,
  approval: { status: OPTIONS_APPROVALSTATUS.NEW },
};

export const PATIENTACCESS = {
  name: '',
  email: '',
  userType: USERTYPE_HSPTLIC.PATIENT.value,
  contact: '',
  nottoId: '',
  nottoIdProof: { name: '', key: '', url: '' },
  approval: { status: OPTIONS_APPROVALSTATUS.NEW },
  organs: [],
};
