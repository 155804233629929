import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import moment from 'moment';
import { BLOODGROUP, FORMAT_DATE_UNIXTS } from '../../../constants';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

pdfMake.fonts = {
  Muli: {
    normal: 'https://chainworksbucket.s3.ap-south-1.amazonaws.com/MuliFont/Muli-Regular.woff',
    bold: 'https://chainworksbucket.s3.ap-south-1.amazonaws.com/MuliFont/Muli-Bold.woff',
  },
  Sintony: {
    bold: 'https://db.onlinewebfonts.com/t/0704722187831ae1fb775341c9842851.woff',
  },
  Roboto: {
    normal: 'Roboto-Regular.ttf',
    bold: 'Roboto-Medium.ttf',
    italics: 'Roboto-Italic.ttf',
    bolditalics: 'Roboto-Italic.ttf',
  },
};

class AllocationRecipientRecordFormPdf {
  formData = {};

  constructor(formData) {
    const {
      basicDetails,
      contactNos,
      contactEmailIds,
      heartDetails,
      handDetails,
      pancreasDetails,
      intestineDetails,
      liverCategoryFormDetails,
      kidneyDetails,
      lungDetails,
      hospitalName,
    } = formData;
    this.formData = formData;
    const { organs } = basicDetails;

    const selectedOrgans = [];
    organs.forEach((organ) => {
      if (!selectedOrgans.includes(organ.organType)) {
        selectedOrgans.push(organ.organType);
      } else {
        return '-';
      }
    });

    const { superUrgentInfo, semiUrgentInfo, generalInfo } = heartDetails || {};
    const {
      superUrgentInfo: liverSuperUrgentInfo,
      routineInfo,
      meldExceptionCriteria,
      meldPeld,
      complicationScore,
    } = liverCategoryFormDetails || {};

    const _textOrEmptyFormatter = (value) => {
      if (value) {
        return value;
      }
      return '-';
    };

    const typeOfDiabeticsFormatter = (value) => {
      if (value === 'TYPE1DM') {
        return 'TYPE I DM';
      }
      if (value === 'TYPE2DM') {
        return 'TYPE II DM';
      }
      if (value === 'NONE') {
        return 'None';
      }
      return '-';
    };

    const handTransplantFormatter = (value) => {
      if (value === 'WRISTANDDISTAL') {
        return 'Wrist and Distal to Wrist';
      }
      if (value === 'MIDFOREARMANDDISTAL') {
        return 'Mid-Forearm upto Wrist';
      }
      if (value === 'ELBOWANDDISTAL') {
        return 'Elbow upto Mid-Forearm';
      }
      if (value === 'MIDARMANDDISTAL') {
        return 'Mid-Arm upto Elbow';
      }
      if (value === 'SHOULDER') {
        return 'Shoulder Level upto Mid-Arm';
      }

      return '-';
    };

    const _getMlasScore = () => {
      let scoreAbc = 0;
      let scoreCd = 0;
      if (meldPeld?.meld) {
        scoreAbc += meldPeld?.meld;
      }
      if (complicationScore?.complicationScoreResult?.complicationPoints) {
        scoreAbc += complicationScore?.complicationScoreResult?.complicationPoints;
      }
      if (meldExceptionCriteria?.meldExceptionCriteriaResult?.meldExceptionCriteriaPoints) {
        scoreCd += meldExceptionCriteria?.meldExceptionCriteriaResult?.meldExceptionCriteriaPoints;
      }
      return Math.max(scoreAbc, scoreCd);
    };

    const _yesNoAndEmptyFormatter = (value) => {
      if (value === true) {
        return 'YES';
      }
      if (value === false) {
        return 'NO';
      }
      if (value === 'true') {
        return 'YES';
      }
      if (value === 'false') {
        return 'NO';
      }
      return '-';
    };

    const _yesNoAndEmptyBooleanFormatter = (value) => {
      if (value === true) {
        return 'YES';
      }
      if (value === false) {
        return 'NO';
      }
      return '-';
    };

    const _yesNoAndEmptyStringFormatter = (value) => {
      if (value === 'YES') {
        return 'YES';
      }
      if (value === 'NO') {
        return 'NO';
      }
      return '-';
    };

    const calculateCurrentAge = () => {
      if (basicDetails.dateOfBirth) {
        const duration = moment.duration(
          moment().diff(moment(basicDetails.dateOfBirth, FORMAT_DATE_UNIXTS))
        );
        return `${duration?._data?.years} Years  ${duration?._data?.months} Months ${duration?._data?.days} Days`;
      }
      if (basicDetails.currentAge) {
        return basicDetails.currentAge;
      }
      return '-';
    };

    const _getZtccZoneLabelById = (zone) => {
      if (zone === '7') {
        return 'ZTCC MUMBAI';
      }
      if (zone === '8') {
        return 'ZTCC PUNE';
      }
      if (zone === '9') {
        return 'ZTCC NAGPUR';
      }
      if (zone === '10') {
        return 'ZTCC AURANGABAD';
      }
      if (zone) {
        return zone;
      }

      return '';
    };

    const _nationalityFormatter = (value) => {
      if (value === 'INDIAN_NATIONAL') {
        return 'Indian';
      }
      if (value === 'OCI_CARDHOLDER') {
        return 'Overseas Citizen of India (OCI) card holder';
      }
      if (value === 'FOREIGN_NATIONAL') {
        return 'Foreign national';
      }
      return '-';
    };

    const _praFormatter = (value) => {
      if (value === 'CLASSONE') {
        return 'Class 1';
      }
      if (value === 'CLASSTWO') {
        return 'Class 2';
      }
      if (value === 'BOTHCLASSES') {
        return 'Both Class 1 and Class 2';
      }
      if (value === 'NOTDONE') {
        return 'Not Done';
      }
      return '-';
    };

    const _viralMrakerRemarkAndDateFormatter = (remark, date) => {
      // if (remark && date) {
      if (remark === 'NOTDONE') {
        return 'NOT DONE';
      }
      return `${remark} - ${date ? moment(date, FORMAT_DATE_UNIXTS).format('DD/MM/YYYY') : '-'}`;
      // }
      return '-';
    };

    const _getCurrentAgeByDateOfBirth = (value) => {
      const duration = moment.duration(moment().diff(moment(value, FORMAT_DATE_UNIXTS)));
      return duration?._data?.years;
    };

    const _getMcsDate = (value) => {
      if (value) {
        return moment(value, FORMAT_DATE_UNIXTS).format('DD/MM/YYYY');
      }

      return '-';
    };

    const _getMcsCannulaeSites = (value) => {
      if (value) {
        return value;
      }
      return '-';
    };

    const _getMcsLessThan30DaysDate = (value) => {
      if (value && value === 'IABP') {
        if (superUrgentInfo?.mechanicalCirculatorySupportLessThan30Days?.iabp?.mcsInitiatedDate) {
          return moment(
            superUrgentInfo?.mechanicalCirculatorySupportLessThan30Days?.iabp?.mcsInitiatedDate,
            FORMAT_DATE_UNIXTS
          ).format('DD/MM/YYYY');
        }
      }
      if (value && value === 'ECMO') {
        if (superUrgentInfo?.mechanicalCirculatorySupportLessThan30Days?.ecmo?.mcsInitiatedDate) {
          return moment(
            superUrgentInfo?.mechanicalCirculatorySupportLessThan30Days?.ecmo?.mcsInitiatedDate,
            FORMAT_DATE_UNIXTS
          ).format('DD/MM/YYYY');
        }
      }
      if (value && value === 'LVAD') {
        if (superUrgentInfo?.mechanicalCirculatorySupportLessThan30Days?.lvad?.mcsInitiatedDate) {
          return moment(
            superUrgentInfo?.mechanicalCirculatorySupportLessThan30Days?.lvad?.mcsInitiatedDate,
            FORMAT_DATE_UNIXTS
          ).format('DD/MM/YYYY');
        }
      }
      return '-';
    };

    const _getMcsLessThan30DaysCannulaeSites = (value) => {
      if (value && value === 'ECMO') {
        if (superUrgentInfo?.mechanicalCirculatorySupportLessThan30Days?.ecmo?.cannulaeSites) {
          return superUrgentInfo?.mechanicalCirculatorySupportLessThan30Days?.ecmo?.cannulaeSites;
        }
      }
      if (value && value === 'LVAD') {
        if (superUrgentInfo?.mechanicalCirculatorySupportLessThan30Days?.lvad?.cannulaeSites) {
          return superUrgentInfo?.mechanicalCirculatorySupportLessThan30Days?.lvad?.cannulaeSites;
        }
      }
      return '-';
    };

    const _getMcsMoreThan30DaysDate = (value) => {
      if (value && value === 'IABP') {
        if (superUrgentInfo?.mechanicalCirculatorySupportMoreThan30Days?.iabp?.mcsInitiatedDate) {
          return moment(
            superUrgentInfo?.mechanicalCirculatorySupportMoreThan30Days?.iabp?.mcsInitiatedDate,
            FORMAT_DATE_UNIXTS
          ).format('DD/MM/YYYY');
        }
      }
      if (value && value === 'ECMO') {
        if (superUrgentInfo?.mechanicalCirculatorySupportMoreThan30Days?.ecmo?.mcsInitiatedDate) {
          return moment(
            superUrgentInfo?.mechanicalCirculatorySupportMoreThan30Days?.ecmo?.mcsInitiatedDate,
            FORMAT_DATE_UNIXTS
          ).format('DD/MM/YYYY');
        }
      }
      if (value && value === 'LVAD') {
        if (superUrgentInfo?.mechanicalCirculatorySupportMoreThan30Days?.lvad?.mcsInitiatedDate) {
          return moment(
            superUrgentInfo?.mechanicalCirculatorySupportMoreThan30Days?.lvad?.mcsInitiatedDate,
            FORMAT_DATE_UNIXTS
          ).format('DD/MM/YYYY');
        }
      }
      return '-';
    };

    const getMcsMoreThan30DaysCannulaeSites = (value) => {
      if (value && value === 'ECMO') {
        if (superUrgentInfo?.mechanicalCirculatorySupportMoreThan30Days?.ecmo?.cannulaeSites) {
          return superUrgentInfo?.mechanicalCirculatorySupportMoreThan30Days?.ecmo?.cannulaeSites;
        }
      }
      if (value && value === 'LVAD') {
        if (superUrgentInfo?.mechanicalCirculatorySupportMoreThan30Days?.lvad?.cannulaeSites) {
          return superUrgentInfo?.mechanicalCirculatorySupportMoreThan30Days?.lvad?.cannulaeSites;
        }
      }
      return '-';
    };

    const _positiveNegativeAndEmptyFormatter = (value) => {
      if (value === 'POSITIVE') {
        return 'POSITIVE';
      }
      if (value === 'NEGATIVE') {
        return 'NO';
      }
      return '-';
    };

    const reportDocDefinition = {
      content: [
        {
          text: '',
        },

        {
          style: 'table',
          table: {
            widths: [250, 100, 150, 'auto', 'auto'],
            heights: [
              10,
              20,
              'auto',
              'auto',
              'auto',
              'auto',
              10,
              'auto',
              'auto',
              'auto',
              // 40,
              // 40,
              'auto',
              'auto',
              'auto',
              'auto',
              // 40,
              // 40,
              'auto',
              'auto',
              'auto',
            ],
            body: [
              [
                {
                  text: 'State Organ and Tissue Transplant Organisation (SOTTO),\n  Maharashtra',
                  style: 'tableHeader',
                  colSpan: 3,
                  alignment: 'center',
                  bold: true,
                },
                {},
                {},
              ],
              [
                {
                  text: 'State Appropriate Authority of Maharashtra',
                  style: 'textStyle',
                  alignment: 'center',
                  colSpan: 3,
                },
                {},
                {},
              ],
              [
                {
                  // text: 'RECIPIENT REGISTRATION for {``}',
                  text: `RECIPIENT REGISTRATION FOR ${selectedOrgans.join(' , ')}`,
                  style: 'textStyle',
                  alignment: 'center',
                  colSpan: 3,
                },
                {},
                {},
              ],
              [
                {
                  text: `Date: ${moment(
                    basicDetails?.dateOfRegistration,
                    FORMAT_DATE_UNIXTS
                  ).format('DD MMMM YYYY')}`,
                  style: 'textStyle',
                  alignment: 'left',
                  colSpan: 3,
                },
                {
                  text: `Registration Date: ${moment(
                    basicDetails?.dateOfRegistration,
                    FORMAT_DATE_UNIXTS
                  ).format('DD MMMM YYYY')}`,
                  style: 'textStyle',
                  alignment: 'left',
                  colSpan: 3,
                },
                {},
              ],
              [
                {
                  text: `ZTCC Zone: ${_getZtccZoneLabelById(
                    basicDetails?.registeredHosp?.ztccZone
                  )}`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 3,
                },
                {},
                {},
              ],
              [
                {
                  text: `Name: ${basicDetails?.name ? basicDetails?.name : '-'}`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 3,
                },
                {},
                {},
              ],
              [
                {
                  text: `NOTTO ID : ${basicDetails?.nottoId ? basicDetails?.nottoId : '-'}`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 3,
                },
                {},
                {},
              ],
              [
                {
                  text: `Date of Birth: `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ` ${
                    basicDetails?.dateOfBirth
                      ? moment(basicDetails?.dateOfBirth, FORMAT_DATE_UNIXTS).format('DD MMMM YYYY')
                      : '-'
                  } `,

                  colSpan: 2,
                },
                {},
              ],

              [
                {
                  text: `Age: `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: `${calculateCurrentAge()}`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Gender: `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: `${basicDetails?.sex ? basicDetails?.sex : '-'}`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Blood Group: `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${
                    basicDetails?.bloodGroup ? BLOODGROUP[basicDetails?.bloodGroup].label : '-'
                  }`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Weight (Kg): `,
                  colSpan: 1,
                },
                {
                  text: `${basicDetails?.weightKg}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Height (in cm):`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: `${basicDetails?.heightCm}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Height (in inches):`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: `${basicDetails?.heightIn ? basicDetails?.heightIn : '-'}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Hospital Name: `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                { text: `${hospitalName || '-'}`, colSpan: 2 },
                {},
              ],

              [
                {
                  text: `Address & Telephone No: `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${basicDetails?.presentAddress ? basicDetails?.presentAddress : '-'} - ${
                    contactNos.length > 0 ? contactNos[0] : '-'
                  }`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: 'Patient contact emails:',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: `${contactEmailIds?.length > 0 ? contactEmailIds[0] : '-'}`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 2,
                },
              ],
              [
                {
                  text: `Economic Status: `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${basicDetails?.economicStatus ? basicDetails?.economicStatus : '-'}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: 'Occupation',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: `${basicDetails?.occupation}`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 2,
                },
              ],

              [
                {
                  text: `Nationality: `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${_nationalityFormatter(
                    basicDetails?.nationality ? basicDetails?.nationality : ''
                  )}`,
                  colSpan: 2,
                },
                {},
              ],
              ...(basicDetails?.nationality !== 'INDIAN_NATIONAL'
                ? [
                    [
                      {
                        text: `Indian Contact Number : `,
                        style: 'tableHeader',
                        alignment: 'left',
                        colSpan: 1,
                      },
                      {
                        text: `${
                          basicDetails?.indianContactNumber
                            ? basicDetails?.indianContactNumber
                            : '-'
                        }`,
                        colSpan: 2,
                      },
                      {},
                    ],
                  ]
                : []),

              [
                {
                  text: `Country of Residence: `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${
                    basicDetails?.countryOfResidence ? basicDetails?.countryOfResidence : '-'
                  }`,
                  colSpan: 2,
                },
                {},
              ],

              // // [
              // //   {
              // //     text: 'Date of RT PCR',
              // //     style: 'tableHeader',
              // //     alignment: 'left',
              // //     colSpan: 1,
              // //   },
              // //   {
              // //     text: `${
              // //       basicDetails.rtpcrdate
              // //         ? moment(basicDetails.rtpcrdate, FORMAT_DATE_UNIXTS).format('DD/MM/YYYY')
              // //         : '-'
              // //     }`,
              // //     style: 'tableHeader',
              // //     alignment: 'left',
              // //     colSpan: 2,
              // //   },
              // // ],
              // // [
              // //   {
              // //     text: 'Is Covid Vaccination Done?',
              // //     style: 'tableHeader',
              // //     alignment: 'left',
              // //     colSpan: 1,
              // //   },
              // //   {
              // //     text: `${_covidVaccinationFormatter(basicDetails?.covidVaccination?.dosesDone)}`,
              // //     style: 'tableHeader',
              // //     alignment: 'left',
              // //     colSpan: 2,
              // //   },
              // // ],

              [
                {
                  text: 'UHID',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: `${basicDetails?.uhid || ''}`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 2,
                },
              ],
              [
                {
                  text: 'Present Address',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: `${basicDetails?.presentAddress || ''}`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 2,
                },
              ],
              [
                {
                  text: 'Permanent Address',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: `${basicDetails?.permanentAddress || ''}`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 2,
                },
              ],
              [
                {
                  text: 'Date of Admission / OPD',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: `${
                    basicDetails?.dateOfAdmission
                      ? moment(basicDetails?.dateOfAdmission, FORMAT_DATE_UNIXTS).format(
                          'DD/MM/YYYY'
                        )
                      : '-'
                  }`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 2,
                },
              ],

              [
                {
                  text: 'Primary Physician Name:',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: `${basicDetails?.primaryPhysicianName || ''}`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 2,
                },
              ],
              [
                {
                  text: 'Primary Physician Contact Numbers:',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: ` ${
                    basicDetails?.primaryPhysicianContactNo
                      ? basicDetails?.primaryPhysicianContactNo
                      : '-'
                  }`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: 'Primary Physician Contact Email:',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${
                    basicDetails?.primaryPhysicianEmail ? basicDetails?.primaryPhysicianEmail : '-'
                  }`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: 'Primary Questions Information ',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 3,
                  bold: true,
                },
                {},
                {},
              ],
              [
                {
                  text: 'Does the patient have active / metastatic malignancy?',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${_yesNoAndEmptyFormatter(
                    basicDetails?.prerequisites?.noActiveMalignancy
                  )}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: 'Does the patient have active MDR infection with sepsis or septic shock?',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${_yesNoAndEmptyFormatter(
                    basicDetails?.prerequisites?.noActiveMdrInfection
                  )}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: 'Does the patient have severe irreversible systemic disease (cardiac, pulmonary, neurological or others with very short longevity)?',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${_yesNoAndEmptyFormatter(
                    basicDetails?.prerequisites?.noSevereIrreversibleSystemicDisease
                  )}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: 'Does the patient have active or recent major unresolved psychiatric illness?',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${_yesNoAndEmptyFormatter(
                    basicDetails?.prerequisites?.noMajorUnresolvedPsychiatricIllness
                  )}`,
                  colSpan: 2,
                },
                {},
              ],

              [
                {
                  text: 'Does the patient have high risk of non-compliance with long-term immunosuppression?',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${_yesNoAndEmptyFormatter(
                    basicDetails?.prerequisites?.noHighRiskOfNonCompliance
                  )}`,
                  colSpan: 2,
                },
                {},
              ],

              [
                {
                  text: 'Does the patient have adequate social or family support?',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${_yesNoAndEmptyFormatter(basicDetails?.prerequisites?.familySupport)}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: 'Identity Proof Document Details',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 3,
                  bold: true,
                },

                {
                  text: ``,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: 'Document Type',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: `${basicDetails?.identityProof?.documentType}`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 2,
                },
              ],
              [
                {
                  text: 'Identify Proof Document Number',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: `${basicDetails?.identityProof?.documentNumber}`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 2,
                },
              ],
              [
                {
                  text: 'Identity Proof Document Attachment',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: `${basicDetails?.identityProof?.attachment?.name ? 'ATTACHED' : '-'}`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 2,
                },
              ],
            ],
          },
        },
        {
          text: 'Family Member Details',
          style: 'subHeader',
        },
        {
          style: 'table',
          table: {
            headerRows: 1,
            widths: ['*', '*', '*', '*'],
            body: [
              [
                { text: 'Family Members Name', style: 'tableHeader', alignment: 'left' },
                { text: 'Relation With Patient', style: 'tableHeader', alignment: 'left' },
                { text: 'Family Contact Number', style: 'tableHeader', alignment: 'left' },
                { text: 'Family Contact Email', style: 'tableHeader', alignment: 'left' },
              ],
              ...basicDetails.familyMembers.map((a) => [
                { text: `${a.name ? a.name : '-'}`, style: 'tableCell', alignment: 'left' },
                { text: `${a.relation ? a.relation : '-'}`, style: 'tableCell', alignment: 'left' },
                { text: `${a.contact ? a.contact : '-'}`, style: 'tableCell', alignment: 'left' },
                { text: `${a.email ? a.email : '-'}`, style: 'tableCell', alignment: 'left' },
              ]),
            ],
          },
        },
        {
          text: 'General Information',
          style: 'subHeader',
        },
        {
          style: 'table',
          table: {
            headerRows: 1,
            widths: ['*', '*'],
            body: [
              [
                { text: 'Requested Organs', style: 'tableHeader', alignment: 'left' },
                { text: 'Requested Organs Listing Type', style: 'tableHeader', alignment: 'left' },
              ],
              ...organs.map((o) => [
                {
                  text: `${o.organType ? o.organType : '-'}`,
                  style: 'tableCell',
                  alignment: 'left',
                },
                { text: `${o.category ? o.category : '-'}`, style: 'tableCell', alignment: 'left' },
              ]),
            ],
          },
        },

        {
          style: 'table',
          table: {
            widths: [250, 100, 150, 'auto', 'auto'],
            heights: [
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              // 20,
              // 20,
              // 10,
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
            ],
            body: [
              [
                {
                  text: 'General Information ',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 3,
                  bold: true,
                },
                {},
                {},
              ],

              [
                {
                  text: 'Is Patient Willing to Accept ECD:',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: `${_yesNoAndEmptyBooleanFormatter(
                    basicDetails?.patientListingCategory?.primaryEcdDetails?.primaryEcd
                  )}`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 2,
                },
              ],
              [
                {
                  text: 'ECD Attachment',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: `${
                    basicDetails?.patientListingCategory?.primaryEcdDetails?.primaryEcdAttachment
                      ?.name
                      ? 'ATTACHED'
                      : '-'
                  }`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 2,
                },
              ],
              [
                {
                  text: 'Is Patient Willing to Accept DCD:',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: `${_yesNoAndEmptyBooleanFormatter(
                    basicDetails?.patientListingCategory?.primaryDcdDetails?.primaryDcd
                  )}`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 2,
                },
              ],
              [
                {
                  text: 'DCD Attachment',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: `${
                    basicDetails?.patientListingCategory?.primaryDcdDetails?.primaryDcdAttachment
                      ?.name
                      ? 'ATTACHED'
                      : '-'
                  }`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 2,
                },
              ],
              [
                {
                  text: 'Has the patient been registered in Maharashtra in Past:',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: `${_yesNoAndEmptyBooleanFormatter(
                    basicDetails?.pastZtccListingInfo?.pastZtccListing
                  )}`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 2,
                },
              ],
              [
                {
                  text: 'If YES, Status',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: `${basicDetails?.pastZtccListingInfo?.pastListingStatus}`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 2,
                },
              ],
              [
                {
                  text: 'If YES, Further Details',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: `${basicDetails?.pastZtccListingInfo?.pastZtccDetails}`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 2,
                },
              ],
              // ...(organs.some((organ) => organ.organType === 'HEART' || organ.organType === 'LUNGS')
              //   ? [
              //       [
              //         {
              //           text: 'Covid Details',
              //           style: 'tableHeader',
              //           alignment: 'left',
              //           colSpan: 3,
              //           bold: true,
              //         },
              //         {},
              //         {},
              //       ],
              //       [
              //         {
              //           text: 'Date of RT PCR',
              //           style: 'tableHeader',
              //           alignment: 'left',
              //           colSpan: 1,
              //         },
              //         {
              //           text: `${
              //             basicDetails.rtpcrDate
              //               ? moment(basicDetails.rtpcrDate, FORMAT_DATE_UNIXTS).format(
              //                   'DD/MM/YYYY'
              //                 )
              //               : '-'
              //           }`,
              //           style: 'tableHeader',
              //           alignment: 'left',
              //           colSpan: 2,
              //         },
              //       ],
              //       [
              //         {
              //           text: 'RT PCR Attachment',
              //           style: 'tableHeader',
              //           alignment: 'left',
              //           colSpan: 1,
              //         },
              //         {
              //           text: `${basicDetails?.rtpcrReport?.name ? 'ATTACHED' : '-'}`,
              //           style: 'tableHeader',
              //           alignment: 'left',
              //           colSpan: 2,
              //         },
              //       ],
              //       [
              //         {
              //           text: 'Any history of Covid in the past?',
              //           style: 'tableHeader',
              //           alignment: 'left',
              //           colSpan: 1,
              //         },

              //         {
              //           text: `${_yesNoAndEmptyFormatter(basicDetails?.covidHistory)}`,
              //           colSpan: 2,
              //         },
              //         {},
              //       ],
              //       [
              //         {
              //           text: 'If Yes, Date',
              //           style: 'tableHeader',
              //           alignment: 'left',
              //           colSpan: 1,
              //         },
              //         {
              //           text: `${
              //             basicDetails.covidHistoryDate
              //               ? moment(basicDetails.covidHistoryDate, FORMAT_DATE_UNIXTS).format(
              //                   'DD/MM/YYYY'
              //                 )
              //               : '-'
              //           }`,
              //           style: 'tableHeader',
              //           alignment: 'left',
              //           colSpan: 2,
              //         },
              //       ],
              //       [
              //         {
              //           text: 'Is Covid Vaccination Done?',
              //           style: 'tableHeader',
              //           alignment: 'left',
              //           colSpan: 1,
              //         },
              //         {
              //           text: `${_covidVaccinationFormatter(
              //             basicDetails?.covidVaccination?.dosesDone
              //           )}`,
              //           style: 'tableHeader',
              //           alignment: 'left',
              //           colSpan: 2,
              //         },
              //       ],
              //     ]
              //   : []),
            ],
          },
        },
        // ..._renderOrganWiseDetails(),
        // organs.includes('HEART') && basicDetails?.waitingStatus === 'SUPERURGENT'
        organs.some((organ) => organ.organType === 'HEART' && organ.category === 'SUPERURGENT')
          ? {
              style: 'table',
              table: {
                widths: [250, 100, 150, 'auto', 'auto'],
                heights: [
                  'auto',
                  'auto',
                  'auto',
                  'auto',
                  'auto',
                  'auto',
                  'auto',
                  'auto',
                  'auto',
                  'auto',
                  'auto',
                  'auto',
                  'auto',
                  'auto',
                  'auto',
                  'auto',

                  'auto',
                ],
                body: [
                  [
                    {
                      text: 'State Organ and Tissue Transplant Organisation (SOTTO),\n  Maharashtra',
                      style: 'tableHeader',
                      colSpan: 3,
                      alignment: 'center',
                      bold: true,
                    },
                    {},
                    {},
                  ],
                  [
                    {
                      text: 'State Appropriate Authority of Maharashtra',
                      style: 'textStyle',
                      alignment: 'center',
                      colSpan: 3,
                    },
                    {},
                    {},
                  ],
                  [
                    {
                      text: 'SUPER URGENT HEART REGISTRATION',
                      style: 'textStyle',
                      alignment: 'center',
                      colSpan: 3,
                    },
                    {},
                    {},
                  ],
                  [
                    {
                      text: '2D Echo ',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `${generalInfo?.echo2d || '-'}`,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: '2D Echo Report ',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `${generalInfo?.echo2dReport?.name ? 'ATTACHED' : '-'}`,
                      colSpan: 2,
                    },
                    {},
                  ],

                  [
                    {
                      text: 'Patient Admission File ',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `${superUrgentInfo?.patientAdmissionfile?.name ? 'ATTACHED' : '-'}`,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'Treating Doctor Clinical Summary Report ',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `${
                        superUrgentInfo?.treatingDoctorClinicalSummaryReport?.name
                          ? 'ATTACHED'
                          : '-'
                      }`,
                      colSpan: 2,
                    },
                    {},
                  ],

                  [
                    {
                      text: 'Does the patient have Severe Cardiac Cachexia: Nephropathy, Neuropathy etc?',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },
                    {
                      text: `${_yesNoAndEmptyBooleanFormatter(
                        heartDetails?.generalInfo?.severeCardiacCachexia
                      )}`,
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 2,
                    },
                  ],

                  [
                    {
                      text: 'Does the patient have Diabetes with end organ diseases?',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },
                    {
                      text: `${_yesNoAndEmptyBooleanFormatter(
                        heartDetails?.generalInfo?.diabetesWithEndOrganDiseases
                      )}`,
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 2,
                    },
                  ],
                  [
                    {
                      text: 'Baseline GFR (ml/min)',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },
                    {
                      text: `${
                        heartDetails?.generalInfo?.baselineGfr
                          ? heartDetails?.generalInfo?.baselineGfr
                          : '-'
                      }`,
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 2,
                    },
                  ],
                  [
                    {
                      text: 'Baseline serum creatinine (mg/dl)',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },
                    {
                      text: `${
                        heartDetails?.generalInfo?.baselineSerumCreatinine
                          ? heartDetails?.generalInfo?.baselineSerumCreatinine
                          : '-'
                      }`,
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 2,
                    },
                  ],
                  [
                    {
                      text: 'Does the patient have Severe cerebrovascular disease?',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },
                    {
                      text: `${_yesNoAndEmptyBooleanFormatter(
                        heartDetails?.generalInfo?.severeCerebrovascularDisease
                      )}`,
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 2,
                    },
                  ],
                  [
                    {
                      text: 'Severe Obesity BMI',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },
                    {
                      text: `${
                        heartDetails?.generalInfo?.severeObesity
                          ? heartDetails?.generalInfo?.severeObesity
                          : '-'
                      }`,
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 2,
                    },
                  ],
                  [
                    {
                      text: 'Does the patient have Severe primary pulmonary disease?',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },
                    {
                      text: `${_yesNoAndEmptyBooleanFormatter(
                        heartDetails?.generalInfo?.severePrimaryPulmonaryDisease
                      )}`,
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 2,
                    },
                  ],
                  [
                    {
                      text: 'Does the patient have Pulmonary Hypertension PASP > 50 mmHg unresponsive to vacodilator challenge?',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },
                    {
                      text: `${_yesNoAndEmptyBooleanFormatter(
                        heartDetails?.generalInfo?.pulmonaryHypertension
                      )}`,
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 2,
                    },
                  ],
                  [
                    {
                      text: 'Viral Marker Status : ',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 3,
                      bold: true,
                    },
                    {},
                    {},
                  ],
                  [
                    {
                      text: `HBs AG Report Date \n (Positive/Negative) `,
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `${_viralMrakerRemarkAndDateFormatter(
                        heartDetails?.generalInfo?.viralMarkerStatus?.hbsagRemark,
                        heartDetails?.generalInfo?.viralMarkerStatus?.hbsagReportDate
                      )}`,
                      colSpan: 2,
                    },
                    {},
                  ],

                  [
                    {
                      text: `Anti HCV Report Date \n (Positive/Negative) `,
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `${_viralMrakerRemarkAndDateFormatter(
                        heartDetails?.generalInfo?.viralMarkerStatus?.antiHcvRemark,
                        heartDetails?.generalInfo?.viralMarkerStatus?.antiHcvReportDate
                      )}`,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: `HCV RNA Quantitative Report Date \n (Positive/Negative/Not Done) `,
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },
                    heartDetails?.generalInfo?.viralMarkerStatus?.hcvRnaQuantitativeRemark ===
                    'NOTDONE'
                      ? {
                          text: `${_viralMrakerRemarkAndDateFormatter(
                            heartDetails?.generalInfo?.viralMarkerStatus?.hcvRnaQuantitativeRemark
                          )}`,
                          colSpan: 2,
                        }
                      : {
                          text: `${_viralMrakerRemarkAndDateFormatter(
                            heartDetails?.generalInfo?.viralMarkerStatus?.hcvRnaQuantitativeRemark,
                            heartDetails?.generalInfo?.viralMarkerStatus
                              ?.hcvRnaQuantitativeReportDate
                          )}`,
                          colSpan: 2,
                        },
                    {},
                  ],
                  [
                    {
                      text: `HCV DNA Report Date \n (Positive/Negative/Not Done) `,
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },
                    heartDetails?.generalInfo?.viralMarkerStatus?.hcvDnaRemark === 'NOTDONE'
                      ? {
                          text: `${_viralMrakerRemarkAndDateFormatter(
                            heartDetails?.generalInfo?.viralMarkerStatus?.hcvDnaRemark
                          )}`,
                          colSpan: 2,
                        }
                      : {
                          text: `${_viralMrakerRemarkAndDateFormatter(
                            heartDetails?.generalInfo?.viralMarkerStatus?.hcvDnaRemark,
                            heartDetails?.generalInfo?.viralMarkerStatus?.hcvDnaReportDate
                          )}`,
                          colSpan: 2,
                        },
                    {},
                  ],
                  [
                    {
                      text: `HIV Report Date \n (Positive/Negative) `,
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `${_viralMrakerRemarkAndDateFormatter(
                        heartDetails?.generalInfo?.viralMarkerStatus?.hivRemark,
                        heartDetails?.generalInfo?.viralMarkerStatus?.hivReportDate
                      )}`,
                      colSpan: 2,
                    },
                    {},
                  ],
                  // [
                  //   {
                  //     text: 'COVID status (RT PCR)',
                  //     style: 'tableHeader',
                  //     alignment: 'left',
                  //     colSpan: 1,
                  //   },
                  //   {
                  //     text: `${_textOrEmptyFormatter(heartDetails?.generalInfo?.covidStatus)}`,
                  //     style: 'tableHeader',
                  //     alignment: 'left',
                  //     colSpan: 2,
                  //   },
                  // ],
                  // [
                  //   {
                  //     text: 'Date of RT PCR',
                  //     style: 'tableHeader',
                  //     alignment: 'left',
                  //     colSpan: 1,
                  //   },
                  //   {
                  //     text: `${
                  //       heartDetails?.generalInfo?.rtpcrdate
                  //         ? moment(heartDetails?.generalInfo?.rtpcrdate, FORMAT_DATE_UNIXTS).format(
                  //             'DD/MM/YYYY'
                  //           )
                  //         : '-'
                  //     }`,
                  //     style: 'tableHeader',
                  //     alignment: 'left',
                  //     colSpan: 2,
                  //   },
                  // ],
                  // [
                  //   {
                  //     text: 'Is Covid Vaccination Done?',
                  //     style: 'tableHeader',
                  //     alignment: 'left',
                  //     colSpan: 1,
                  //   },
                  //   {
                  //     text: `${_covidVaccinationFormatter(heartRegCovidVaccination)}`,
                  //     style: 'tableHeader',
                  //     alignment: 'left',
                  //     colSpan: 2,
                  //   },
                  // ],
                ],
              },
            }
          : null,
        // organs.includes('HEART') && basicDetails?.waitingStatus === 'SUPERURGENT'
        organs.some((organ) => organ.organType === 'HEART' && organ.category === 'SUPERURGENT')
          ? {
              stack: [
                {
                  style: 'table',
                  table: {
                    widths: [250, 100, 150, 'auto', 'auto'],
                    heights: [
                      'auto',
                      'auto',
                      'auto',
                      'auto',
                      'auto',
                      'auto',

                      'auto',

                      'auto',
                      'auto',

                      'auto',
                      'auto',
                      'auto',
                      'auto',
                      'auto',
                      'auto',
                      'auto',
                      'auto',
                      'auto',
                    ],
                    body: [
                      [
                        {
                          text: 'Super Urgent ',
                          pageBreak: 'before',
                          style: 'tableHeader',
                          colSpan: 3,
                          alignment: 'center',
                          bold: true,
                        },
                        {},
                        {},
                      ],

                      [
                        {
                          text: 'Recipient requires mechanical circulatory support (less than 30 days)? \n a)IABP (Infra-Aortic Ballon Pump) \n b)ECMO (Extra Corporeal Membrane Oxygenation) \n c)LVAD / RVAD / Bi-VAD (Left Ventricular Assist Device or Right Ventricular Assist Device or Bi Ventricular Assist Device)',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${_yesNoAndEmptyBooleanFormatter(
                            superUrgentInfo?.mechanicalCirculatorySupportLessThan30Days
                              ?.mechanicalCirculatorySupportLessThan30DaysYesNo
                          )} - ${
                            superUrgentInfo?.mechanicalCirculatorySupportLessThan30Days
                              ?.mechanicalCirculatorySupportLessThan30DaysDetails
                              ? superUrgentInfo?.mechanicalCirculatorySupportLessThan30Days
                                  ?.mechanicalCirculatorySupportLessThan30DaysDetails
                              : '-'
                          }`,
                          colSpan: 2,
                        },
                        {},
                      ],
                      [
                        {
                          text: 'Date of MCS Initiated',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${_getMcsDate(
                            superUrgentInfo?.mechanicalCirculatorySupportLessThan30Days
                              .dateOfMcsInitiated
                          )}`,
                          colSpan: 2,
                        },
                        {},
                      ],

                      [
                        {
                          text: 'Cannula Sites',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${_getMcsCannulaeSites(
                            superUrgentInfo?.mechanicalCirculatorySupportLessThan30Days
                              ?.cannulaeSites
                          )}`,
                          colSpan: 2,
                        },
                        {},
                      ],
                      [
                        {
                          text: 'Recipient requires mechanical circulatory support (for more than 30 days)? \n a)IABP (Infra-Aortic Ballon Pump) \n b)ECMO (Extra Corporeal Membrane Oxygenation) \n c)LVAD / RVAD / Bi-VAD (Left Ventricular Assist Device or Right Ventricular Assist Device or Bi Ventricular Assist Device)',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${_yesNoAndEmptyBooleanFormatter(
                            superUrgentInfo?.mechanicalCirculatorySupportMoreThan30Days
                              ?.mechanicalCirculatorySupportMoreThan30DaysYesNo
                          )} - ${
                            superUrgentInfo?.mechanicalCirculatorySupportMoreThan30Days
                              .mechanicalCirculatorySupportMoreThan30DaysDetails
                              ? superUrgentInfo?.mechanicalCirculatorySupportMoreThan30Days
                                  .mechanicalCirculatorySupportMoreThan30DaysDetails
                              : '-'
                          }`,
                          colSpan: 2,
                        },
                        {},
                      ],
                      [
                        {
                          text: 'Date of MCS Initiated',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${_getMcsDate(
                            superUrgentInfo?.mechanicalCirculatorySupportMoreThan30Days
                              ?.dateOfMcsInitiated
                          )}`,
                          colSpan: 2,
                        },
                        {},
                      ],
                      [
                        {
                          text: 'Cannula Sites',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${_getMcsCannulaeSites(
                            superUrgentInfo?.mechanicalCirculatorySupportMoreThan30Days
                              ?.cannulaeSites
                          )}`,
                          colSpan: 2,
                        },
                        {},
                      ],

                      [
                        {
                          text: 'Does the Recipient require mechanical ventilation with high inotropic support?',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${_yesNoAndEmptyBooleanFormatter(
                            superUrgentInfo?.mechanicalCirculatoryWithHighInotropeSupport
                          )}`,
                          colSpan: 2,
                        },
                        {},
                      ],
                      [
                        {
                          text: 'Date of Ventilation',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${
                            superUrgentInfo?.dateOfVentilation
                              ? moment(
                                  superUrgentInfo?.dateOfVentilation,
                                  FORMAT_DATE_UNIXTS
                                ).format('DD/MM/YYYY')
                              : '-'
                          }`,
                          colSpan: 2,
                        },
                        {},
                      ],
                      [
                        {
                          text: 'Does the Recipient have any evidence of Sepsis?',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${_yesNoAndEmptyBooleanFormatter(superUrgentInfo.sepsis)}`,
                          colSpan: 2,
                        },
                        {},
                      ],
                      [
                        {
                          text: 'Date of Sepsis Report',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${
                            superUrgentInfo?.sepsisReportDate
                              ? moment(
                                  superUrgentInfo?.sepsisReportDate,
                                  FORMAT_DATE_UNIXTS
                                ).format('DD/MM/YYYY')
                              : '-'
                          }`,
                          colSpan: 2,
                        },
                        {},
                      ],
                      [
                        {
                          text: 'Sepsis Report ',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${superUrgentInfo?.sepsisReport?.name ? 'ATTACHED' : '-'}`,
                          colSpan: 2,
                        },
                        {},
                      ],
                      [
                        {
                          text: 'Does the Recipient have any evidence of Culture?',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${_yesNoAndEmptyBooleanFormatter(superUrgentInfo?.culture)}`,
                          colSpan: 2,
                        },
                        {},
                      ],
                      [
                        {
                          text: 'Date of Culture Report',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${
                            superUrgentInfo?.cultureReportDate
                              ? moment(
                                  superUrgentInfo?.cultureReportDate,
                                  FORMAT_DATE_UNIXTS
                                ).format('DD/MM/YYYY')
                              : '-'
                          }`,
                          colSpan: 2,
                        },
                        {},
                      ],
                      [
                        {
                          text: 'Culture Report ',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${superUrgentInfo?.cultureReport?.name ? 'ATTACHED' : '-'}`,
                          colSpan: 2,
                        },
                        {},
                      ],
                      [
                        {
                          text: 'Inotropes details with doses(mcg/kg/min)',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${
                            superUrgentInfo?.inotropeDetails
                              ? superUrgentInfo?.inotropeDetails
                              : '-'
                          }`,
                          colSpan: 2,
                        },
                        {},
                      ],
                      [
                        {
                          text: 'Reports Coagulation Profile',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${
                            superUrgentInfo?.coagulationProfileReport?.name ? 'ATTACHED' : '-'
                          }`,
                          colSpan: 2,
                        },
                        {},
                      ],
                      [
                        {
                          text: 'Other Reports: \n Reports Kidney Functional Test \n Reports Liver Functional Test \n Infection Profile Report ',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `\n${
                            superUrgentInfo?.liverFunctionalReport?.name ? 'ATTACHED' : '-'
                          } - 
                    ${superUrgentInfo?.kidneyFucntionalReport?.name ? 'ATTACHED' : '-'} - 
                    ${superUrgentInfo?.infectionProfileReport?.name ? 'ATTACHED' : '-'}`,

                          colSpan: 2,
                        },
                        {},
                      ],
                    ],
                  },
                },
                {
                  style: 'table',
                  table: {
                    headerRows: 1,
                    widths: ['*', '*'],
                    body: [
                      [
                        { text: 'Etiology', style: 'tableHeader', alignment: 'left' },
                        { text: 'Etiology Report', style: 'tableHeader', alignment: 'left' },
                      ],
                      ...heartDetails?.etiologies?.map((e) => [
                        {
                          text: `${e.etiology ? e.etiology : '-'}`,
                          style: 'tableCell',
                          alignment: 'left',
                        },
                        {
                          text: `${e.attachment?.name ? 'ATTACHED' : '-'}`,
                          style: 'tableCell',
                          alignment: 'left',
                        },
                      ]),
                    ],
                  },
                },
                {
                  style: 'table',
                  table: {
                    widths: [250, 100, 150, 'auto', 'auto'],
                    heights: ['auto', 'auto', 'auto'],
                    body: [
                      [
                        {
                          text: 'Etiology Details ',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${
                            heartDetails?.etiologyDetails ? heartDetails?.etiologyDetails : '-'
                          }`,
                          colSpan: 2,
                        },
                        {},
                      ],
                    ],
                  },
                },
                {
                  text: 'Any Other Attachments',
                  style: 'subHeader',
                },
                {
                  style: 'table',
                  table: {
                    headerRows: 1,
                    widths: ['*', '*'],
                    body: [
                      [
                        { text: 'Attachment Name', style: 'tableHeader', alignment: 'left' },
                        { text: 'Report', style: 'tableHeader', alignment: 'left' },
                      ],
                      ...heartDetails?.otherAttachments?.map((e) => [
                        {
                          text: `${e.attachmentName ? e.attachmentName : '-'}`,
                          style: 'tableCell',
                          alignment: 'left',
                        },
                        {
                          text: `${e.attachment?.name ? 'ATTACHED' : '-'}`,
                          style: 'tableCell',
                          alignment: 'left',
                        },
                      ]),
                    ],
                  },
                },
                {
                  style: 'table',
                  table: {
                    headerRows: 1,
                    widths: ['*', '*'],
                    body: [
                      [
                        { text: 'Cardiologist Team', style: 'tableHeader', alignment: 'left' },

                        { text: 'Transplant Surgeon', style: 'tableHeader', alignment: 'left' },
                      ],
                      [
                        (heartDetails?.cardiologistTeam || [])?.map((e) => [
                          {
                            text: `${e.user.name ? e.user.name : '-'}`,
                            style: 'tableCell',
                            alignment: 'left',
                          },
                        ]),
                        (heartDetails?.transplantSurgeon || [])?.map((e) => [
                          {
                            text: `${e.user.name ? e.user.name : '-'}`,
                            style: 'tableCell',
                            alignment: 'left',
                          },
                        ]),
                      ],
                    ],
                  },
                },
              ],
            }
          : null,
        // organs.includes('HEART') && basicDetails?.waitingStatus === 'SEMIURGENT'
        organs.some((organ) => organ.organType === 'HEART' && organ.category === 'SEMIURGENT')
          ? {
              style: 'table',
              table: {
                widths: [250, 100, 150, 'auto', 'auto'],
                heights: [
                  'auto',
                  'auto',
                  'auto',
                  'auto',
                  'auto',
                  'auto',
                  'auto',
                  'auto',
                  'auto',
                  'auto',
                  'auto',
                  'auto',
                  'auto',
                  'auto',
                  'auto',
                  'auto',
                  'auto',
                ],
                body: [
                  [
                    {
                      text: 'State Organ and Tissue Transplant Organisation (SOTTO),\n  Maharashtra',
                      style: 'tableHeader',
                      colSpan: 3,
                      alignment: 'center',
                      bold: true,
                    },
                    {},
                    {},
                  ],
                  [
                    {
                      text: 'State Appropriate Authority of Maharashtra',
                      style: 'textStyle',
                      alignment: 'center',
                      colSpan: 3,
                    },
                    {},
                    {},
                  ],
                  [
                    {
                      text: 'SEMI URGENT HEART REGISTRATION',
                      style: 'textStyle',
                      alignment: 'center',
                      colSpan: 3,
                    },
                    {},
                    {},
                  ],

                  [
                    {
                      text: '2D Echo ',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `${generalInfo?.echo2d || '-'}`,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: '2D Echo Report ',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `${generalInfo?.echo2dReport?.name ? 'ATTACHED' : '-'}`,
                      colSpan: 2,
                    },
                    {},
                  ],

                  [
                    {
                      text: 'Does the patient have Severe Cardiac Cachexia: Nephropathy, Neuropathy etc?',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },
                    {
                      text: `${_yesNoAndEmptyBooleanFormatter(
                        heartDetails?.generalInfo?.severeCardiacCachexia
                      )}`,
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 2,
                    },
                  ],

                  [
                    {
                      text: 'Does the patient have Diabetes with end organ diseases?',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },
                    {
                      text: `${_yesNoAndEmptyBooleanFormatter(
                        heartDetails?.generalInfo?.diabetesWithEndOrganDiseases
                      )}`,
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 2,
                    },
                  ],
                  [
                    {
                      text: 'Baseline GFR (ml/min)',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },
                    {
                      text: `${
                        heartDetails?.generalInfo?.baselineGfr
                          ? heartDetails?.generalInfo?.baselineGfr
                          : '-'
                      }`,
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 2,
                    },
                  ],
                  [
                    {
                      text: 'Baseline serum creatinine (mg/dl)',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },
                    {
                      text: `${
                        heartDetails?.generalInfo?.baselineSerumCreatinine
                          ? heartDetails?.generalInfo?.baselineSerumCreatinine
                          : '-'
                      }`,
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 2,
                    },
                  ],
                  [
                    {
                      text: 'Does the patient have Severe cerebrovascular disease?',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },
                    {
                      text: `${_yesNoAndEmptyBooleanFormatter(
                        heartDetails?.generalInfo?.severeCerebrovascularDisease
                      )}`,
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 2,
                    },
                  ],
                  [
                    {
                      text: 'Severe Obesity BMI',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },
                    {
                      text: `${
                        heartDetails?.generalInfo?.severeObesity
                          ? heartDetails?.generalInfo?.severeObesity
                          : '-'
                      }`,
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 2,
                    },
                  ],
                  [
                    {
                      text: 'Does the patient have Severe primary pulmonary disease?',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },
                    {
                      text: `${_yesNoAndEmptyBooleanFormatter(
                        heartDetails?.generalInfo?.severePrimaryPulmonaryDisease
                      )}`,
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 2,
                    },
                  ],
                  [
                    {
                      text: 'Does the patient have Pulmonary Hypertension PASP > 50 mmHg unresponsive to vacodilator challenge?',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },
                    {
                      text: `${_yesNoAndEmptyBooleanFormatter(
                        heartDetails?.generalInfo?.pulmonaryHypertension
                      )}`,
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 2,
                    },
                  ],
                  [
                    {
                      text: 'Viral Marker Status : ',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 3,
                      bold: true,
                    },
                    {},
                    {},
                  ],
                  [
                    {
                      text: `HBs AG Report Date \n (Positive/Negative) `,
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `${_viralMrakerRemarkAndDateFormatter(
                        heartDetails?.generalInfo?.viralMarkerStatus?.hbsagRemark,
                        heartDetails?.generalInfo?.viralMarkerStatus?.hbsagReportDate
                      )}`,
                      colSpan: 2,
                    },
                    {},
                  ],

                  [
                    {
                      text: `Anti HCV Report Date \n (Positive/Negative) `,
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `${_viralMrakerRemarkAndDateFormatter(
                        heartDetails?.generalInfo?.viralMarkerStatus?.antiHcvRemark,
                        heartDetails?.generalInfo?.viralMarkerStatus?.antiHcvReportDate
                      )}`,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: `HCV RNA Quantitative Report Date \n (Positive/Negative/Not Done) `,
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },
                    heartDetails?.generalInfo?.viralMarkerStatus?.hcvRnaQuantitativeRemark ===
                    'NOTDONE'
                      ? {
                          text: `${_viralMrakerRemarkAndDateFormatter(
                            heartDetails?.generalInfo?.viralMarkerStatus?.hcvRnaQuantitativeRemark
                          )}`,
                          colSpan: 2,
                        }
                      : {
                          text: `${_viralMrakerRemarkAndDateFormatter(
                            heartDetails?.generalInfo?.viralMarkerStatus?.hcvRnaQuantitativeRemark,
                            heartDetails?.generalInfo?.viralMarkerStatus
                              ?.hcvRnaQuantitativeReportDate
                          )}`,
                          colSpan: 2,
                        },
                    {},
                  ],
                  [
                    {
                      text: `HCV DNA Report Date \n (Positive/Negative/Not Done) `,
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },
                    heartDetails?.generalInfo?.viralMarkerStatus?.hcvDnaRemark === 'NOTDONE'
                      ? {
                          text: `${_viralMrakerRemarkAndDateFormatter(
                            heartDetails?.generalInfo?.viralMarkerStatus?.hcvDnaRemark
                          )}`,
                          colSpan: 2,
                        }
                      : {
                          text: `${_viralMrakerRemarkAndDateFormatter(
                            heartDetails?.generalInfo?.viralMarkerStatus?.hcvDnaRemark,
                            heartDetails?.generalInfo?.viralMarkerStatus?.hcvDnaReportDate
                          )}`,
                          colSpan: 2,
                        },
                    {},
                  ],
                  [
                    {
                      text: `HIV Report Date \n (Positive/Negative) `,
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `${_viralMrakerRemarkAndDateFormatter(
                        heartDetails?.generalInfo?.viralMarkerStatus?.hivRemark,
                        heartDetails?.generalInfo?.viralMarkerStatus?.hivReportDate
                      )}`,
                      colSpan: 2,
                    },
                    {},
                  ],
                  // [
                  //   {
                  //     text: 'COVID status (RT PCR)',
                  //     style: 'tableHeader',
                  //     alignment: 'left',
                  //     colSpan: 1,
                  //   },
                  //   {
                  //     text: `${_textOrEmptyFormatter(heartDetails?.generalInfo?.covidStatus)}`,
                  //     style: 'tableHeader',
                  //     alignment: 'left',
                  //     colSpan: 2,
                  //   },
                  // ],
                  // [
                  //   {
                  //     text: 'Date of RT PCR',
                  //     style: 'tableHeader',
                  //     alignment: 'left',
                  //     colSpan: 1,
                  //   },
                  //   {
                  //     text: `${
                  //       heartDetails?.generalInfo?.rtpcrdate
                  //         ? moment(heartDetails?.generalInfo?.rtpcrdate, FORMAT_DATE_UNIXTS).format(
                  //             'DD/MM/YYYY'
                  //           )
                  //         : '-'
                  //     }`,
                  //     style: 'tableHeader',
                  //     alignment: 'left',
                  //     colSpan: 2,
                  //   },
                  // ],
                  // [
                  //   {
                  //     text: 'Is Covid Vaccination Done?',
                  //     style: 'tableHeader',
                  //     alignment: 'left',
                  //     colSpan: 1,
                  //   },
                  //   {
                  //     text: `${_covidVaccinationFormatter(heartRegCovidVaccination)}`,
                  //     style: 'tableHeader',
                  //     alignment: 'left',
                  //     colSpan: 2,
                  //   },
                  // ],
                ],
              },
            }
          : null,

        // organs.includes('HEART') && basicDetails?.waitingStatus === 'SEMIURGENT'
        organs.some((organ) => organ.organType === 'HEART' && organ.category === 'SEMIURGENT')
          ? {
              stack: [
                {
                  style: 'table',
                  table: {
                    widths: [250, 100, 150, 'auto', 'auto'],
                    heights: [
                      'auto',

                      'auto',
                      'auto',

                      'auto',

                      'auto',
                      'auto',

                      'auto',
                      'auto',
                      'auto',
                      'auto',
                      'auto',
                      'auto',
                      'auto',
                      'auto',
                      'auto',

                      'auto',
                      'auto',
                      'auto',
                    ],
                    body: [
                      [
                        {
                          text: 'Semi Urgent Details ',
                          pageBreak: 'before',
                          style: 'tableHeader',
                          colSpan: 3,
                          alignment: 'center',
                          bold: true,
                        },
                        {},
                        {},
                      ],

                      [
                        {
                          text: 'Recipient requires mechanical circulatory support (less than 30 days)? \n a)IABP (Infra-Aortic Balloon Pump) \n b)ECMO (Extra Corporeal Membrane Oxygenation) \n c)LVAD / RVAD / Bi-VAD (Left Ventricular Assist Device or Right Ventricular Assist Device or Bi Ventricular Assist Device)',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${_yesNoAndEmptyBooleanFormatter(
                            semiUrgentInfo?.mechanicalCirculatorySupportLessThan30Days
                              ?.mechanicalCirculatorySupportLessThan30DaysYesNo
                          )} - ${
                            semiUrgentInfo?.mechanicalCirculatorySupportLessThan30Days
                              ?.mechanicalCirculatorySupportLessThan30DaysDetails
                              ? semiUrgentInfo?.mechanicalCirculatorySupportLessThan30Days
                                  ?.mechanicalCirculatorySupportLessThan30DaysDetails
                              : '-'
                          }`,
                          colSpan: 2,
                        },
                        {},
                      ],
                      [
                        {
                          text: 'Date of MCS Initiated',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${_getMcsDate(
                            semiUrgentInfo?.mechanicalCirculatorySupportLessThan30Days
                              ?.dateOfMcsInitiated
                          )}`,
                          colSpan: 2,
                        },
                        {},
                      ],

                      [
                        {
                          text: 'Cannula Sites',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${_getMcsCannulaeSites(
                            semiUrgentInfo?.mechanicalCirculatorySupportLessThan30Days
                              ?.cannulaeSites
                          )}`,
                          colSpan: 2,
                        },
                        {},
                      ],
                      [
                        {
                          text: 'Recipient requires mechanical circulatory support (for more than 30 days) ? \n a)IABP (Infra-Aortic Balloon Pump) \n b)ECMO (Extra Corporeal Membrane Oxygenation) \n c)LVAD / RVAD / Bi-VAD (Left Ventricular Assist Device or Right Ventricular Assist Device or Bi Ventricular Assist Device)',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${_yesNoAndEmptyBooleanFormatter(
                            semiUrgentInfo?.mechanicalCirculatorySupportMoreThan30Days
                              ?.mechanicalCirculatorySupportMoreThan30DaysYesNo
                          )} - ${
                            semiUrgentInfo?.mechanicalCirculatorySupportMoreThan30Days
                              ?.mechanicalCirculatorySupportMoreThan30DaysDetails
                              ? semiUrgentInfo?.mechanicalCirculatorySupportMoreThan30Days
                                  ?.mechanicalCirculatorySupportMoreThan30DaysDetails
                              : '-'
                          }`,
                          colSpan: 2,
                        },
                        {},
                      ],
                      [
                        {
                          text: 'Date of MCS Initiated',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${_getMcsDate(
                            semiUrgentInfo?.mechanicalCirculatorySupportMoreThan30Days
                              ?.dateOfMcsInitiated
                          )}`,
                          colSpan: 2,
                        },
                        {},
                      ],
                      [
                        {
                          text: 'Cannula Sites',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${_getMcsCannulaeSites(
                            semiUrgentInfo?.mechanicalCirculatorySupportMoreThan30Days.cannulaeSites
                          )}`,
                          colSpan: 2,
                        },
                        {},
                      ],

                      // [
                      //   {
                      //     text: 'Does the Recipient require mechanical ventilation with high ionotropic support?',
                      //     style: 'tableHeader',
                      //     alignment: 'left',
                      //     colSpan: 1,
                      //   },

                      //   {
                      //     text: `${_yesNoAndEmptyBooleanFormatter(
                      //       semiUrgentInfo?.mechanicalCirculatoryWithHighInotropeSupport
                      //     )}`,
                      //     colSpan: 2,
                      //   },
                      //   {},
                      // ],
                      [
                        {
                          text: 'Inotropes details with doses(mcg/kg/min)',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${
                            semiUrgentInfo?.inotropeDetails ? semiUrgentInfo?.inotropeDetails : '-'
                          }`,
                          colSpan: 2,
                        },
                        {},
                      ],
                    ],
                  },
                },
                {
                  style: 'table',
                  table: {
                    headerRows: 1,
                    widths: ['*', '*'],
                    body: [
                      [
                        { text: 'Etiology', style: 'tableHeader', alignment: 'left' },
                        { text: 'Etiology Report', style: 'tableHeader', alignment: 'left' },
                      ],
                      ...heartDetails?.etiologies?.map((e) => [
                        {
                          text: `${e.etiology ? e.etiology : '-'}`,
                          style: 'tableCell',
                          alignment: 'left',
                        },
                        {
                          text: `${e.attachment?.name ? 'ATTACHED' : '-'}`,
                          style: 'tableCell',
                          alignment: 'left',
                        },
                      ]),
                    ],
                  },
                },
                {
                  style: 'table',
                  table: {
                    widths: [250, 100, 150, 'auto', 'auto'],
                    heights: ['auto', 'auto', 'auto'],
                    body: [
                      [
                        {
                          text: 'Etiology Details ',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${
                            heartDetails?.etiologyDetails ? heartDetails?.etiologyDetails : '-'
                          }`,
                          colSpan: 2,
                        },
                        {},
                      ],
                    ],
                  },
                },
                {
                  text: 'Any Other Attachments',
                  style: 'subHeader',
                },
                {
                  style: 'table',
                  table: {
                    headerRows: 1,
                    widths: ['*', '*'],
                    body: [
                      [
                        { text: 'Attachment Name', style: 'tableHeader', alignment: 'left' },
                        { text: 'Report', style: 'tableHeader', alignment: 'left' },
                      ],
                      ...heartDetails?.otherAttachments?.map((e) => [
                        {
                          text: `${e.attachmentName ? e.attachmentName : '-'}`,
                          style: 'tableCell',
                          alignment: 'left',
                        },
                        {
                          text: `${e.attachment?.name ? 'ATTACHED' : '-'}`,
                          style: 'tableCell',
                          alignment: 'left',
                        },
                      ]),
                    ],
                  },
                },
                {
                  style: 'table',
                  table: {
                    headerRows: 1,
                    widths: ['*', '*'],
                    body: [
                      [
                        { text: 'Cardiologist Team', style: 'tableHeader', alignment: 'left' },

                        { text: 'Transplant Surgeon', style: 'tableHeader', alignment: 'left' },
                      ],
                      [
                        (heartDetails?.cardiologistTeam || [])?.map((e) => [
                          {
                            text: `${e.user.name ? e.user.name : '-'}`,
                            style: 'tableCell',
                            alignment: 'left',
                          },
                        ]),
                        (heartDetails?.transplantSurgeon || [])?.map((e) => [
                          {
                            text: `${e.user.name ? e.user.name : '-'}`,
                            style: 'tableCell',
                            alignment: 'left',
                          },
                        ]),
                      ],
                    ],
                  },
                },
              ],
            }
          : null,
        // organs.includes('HEART') && basicDetails?.waitingStatus === 'ROUTINE'
        organs.some((organ) => organ.organType === 'HEART' && organ.category === 'ROUTINE')
          ? {
              style: 'table',
              table: {
                widths: [250, 100, 150, 'auto', 'auto'],
                heights: [
                  'auto',
                  'auto',
                  'auto',
                  'auto',
                  'auto',
                  'auto',
                  'auto',
                  'auto',

                  'auto',
                  'auto',
                  'auto',
                  'auto',
                  'auto',
                  'auto',
                  'auto',
                  'auto',

                  'auto',
                ],
                body: [
                  [
                    {
                      text: 'State Organ and Tissue Transplant Organisation (SOTTO),\n  Maharashtra',
                      style: 'tableHeader',
                      colSpan: 3,
                      alignment: 'center',
                      bold: true,
                    },
                    {},
                    {},
                  ],
                  [
                    {
                      text: 'State Appropriate Authority of Maharashtra',
                      style: 'textStyle',
                      alignment: 'center',
                      colSpan: 3,
                    },
                    {},
                    {},
                  ],
                  [
                    {
                      text: 'ROUTINE HEART REGISTRATION',
                      style: 'textStyle',
                      alignment: 'center',
                      colSpan: 3,
                    },
                    {},
                    {},
                  ],
                  // [
                  //   {
                  //     text: `Date: ${moment().format('DD MMMM YYYY')}`,
                  //     style: 'textStyle',
                  //     alignment: 'left',
                  //     colSpan: 3,
                  //   },
                  //   {},
                  //   {},
                  // ],
                  // [
                  //   {
                  //     text: `ZTCC Zone: ${_getZtccZoneLabelById(
                  //       basicDetails?.registeredHosp?.ztccZone
                  //     )}`,
                  //     style: 'tableHeader',
                  //     alignment: 'left',
                  //     colSpan: 1,
                  //   },
                  //   { text: '', colSpan: 2 },
                  //   { text: '' },
                  //   { text: '' },
                  // ],

                  // [
                  //   {
                  //     text: `Name: ${basicDetails?.name}`,
                  //     style: 'tableHeader',
                  //     alignment: 'left',
                  //     colSpan: 3,
                  //   },
                  //   {},
                  //   {},
                  // ],
                  // [
                  //   {
                  //     text: `Age: ${basicDetails?.currentAge}`,
                  //     style: 'tableHeader',
                  //     alignment: 'left',
                  //     colSpan: 1,
                  //   },
                  //   {
                  //     text: `Sex: ${basicDetails?.sex}`,
                  //     style: 'tableHeader',
                  //     alignment: 'left',
                  //     colSpan: 2,
                  //   },
                  //   {},
                  // ],
                  // [
                  //   {
                  //     text: `NOTTO ID : ${basicDetails?.nottoId}`,
                  //     style: 'tableHeader',
                  //     alignment: 'left',
                  //     colSpan: 1,
                  //   },
                  //   {
                  //     text: `Weight: ${basicDetails?.weightKg}`,
                  //     colSpan: 1,
                  //   },

                  //   {
                  //     text: `Height: ${basicDetails?.heightCm}`,
                  //     style: 'tableHeader',
                  //     alignment: 'left',
                  //     colSpan: 2,
                  //   },
                  //   {
                  //     text: '',
                  //   },
                  // ],
                  // [
                  //   {
                  //     text: `Hospital`,
                  //     style: 'tableHeader',
                  //     alignment: 'left',
                  //     colSpan: 1,
                  //   },
                  //   {
                  //     text: `${basicDetails?.registeredHosp?.name}`,
                  //     style: 'tableHeader',
                  //     alignment: 'left',
                  //     colSpan: 2,
                  //   },
                  // ],
                  // [
                  //   {
                  //     text: `Blood Group `,
                  //     style: 'tableHeader',
                  //     alignment: 'left',
                  //     colSpan: 1,
                  //   },
                  //   {
                  //     text: `${
                  //       basicDetails?.bloodGroup ? BLOODGROUP[basicDetails?.bloodGroup].label : '-'
                  //     }`,
                  //     style: 'tableHeader',
                  //     alignment: 'left',
                  //     colSpan: 2,
                  //   },
                  // ],

                  // [
                  //   {
                  //     text: `Nationality:`,
                  //     style: 'tableHeader',
                  //     alignment: 'left',
                  //     colSpan: 1,
                  //   },
                  //   {
                  //     text: `${_nationalityFormatter(
                  //       basicDetails?.nationality ? basicDetails?.nationality : ''
                  //     )}`,
                  //     style: 'tableHeader',
                  //     alignment: 'left',
                  //     colSpan: 2,
                  //   },
                  // ],
                  // [
                  //   {
                  //     text: `Economic Status`,
                  //     style: 'tableHeader',
                  //     alignment: 'left',
                  //     colSpan: 1,
                  //   },
                  //   {
                  //     text: `${basicDetails?.economicStatus}`,
                  //     style: 'tableHeader',
                  //     alignment: 'left',
                  //     colSpan: 2,
                  //   },
                  // ],
                  // [
                  //   {
                  //     text: `Address & Telephone No`,
                  //     style: 'tableHeader',
                  //     alignment: 'left',
                  //     colSpan: 1,
                  //   },
                  //   {
                  //     text: `${basicDetails?.presentAddress} - ${contactNos[0]}`,
                  //     style: 'tableHeader',
                  //     alignment: 'left',
                  //     colSpan: 2,
                  //   },
                  // ],
                  // [
                  //   {
                  //     text: 'Patient contact numbers',
                  //     style: 'tableHeader',
                  //     alignment: 'left',
                  //     colSpan: 1,
                  //   },
                  //   {
                  //     text: `${contactNos[0]}`,
                  //     style: 'tableHeader',
                  //     alignment: 'left',
                  //     colSpan: 2,
                  //   },
                  // ],

                  // [
                  //   {
                  //     text: 'Primary physician contact numbers:',
                  //     style: 'tableHeader',
                  //     alignment: 'left',
                  //     colSpan: 1,
                  //   },
                  //   {
                  //     text: `${basicDetails?.primaryPhysicianContactNo}`,
                  //     style: 'tableHeader',
                  //     alignment: 'left',
                  //     colSpan: 2,
                  //   },
                  // ],
                  // [
                  //   {
                  //     text: 'Primary physician contact email:',
                  //     style: 'tableHeader',
                  //     alignment: 'left',
                  //     colSpan: 1,
                  //   },
                  //   {
                  //     text: `${basicDetails?.primaryPhysicianEmail}`,
                  //     style: 'tableHeader',
                  //     alignment: 'left',
                  //     colSpan: 2,
                  //   },
                  // ],
                  [
                    {
                      text: '2D Echo ',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `${generalInfo?.echo2d || '-'}`,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: '2D Echo Report ',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `${generalInfo?.echo2dReport?.name ? 'ATTACHED' : '-'}`,
                      colSpan: 2,
                    },
                    {},
                  ],

                  [
                    {
                      text: 'Does the patient have Severe Cardiac Cachexia: Nephropathy, Neuropathy etc?',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },
                    {
                      text: `${_yesNoAndEmptyFormatter(
                        heartDetails?.generalInfo?.severeCardiacCachexia
                      )}`,
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 2,
                    },
                  ],

                  [
                    {
                      text: 'Does the patient have Diabetes with end organ diseases?',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },
                    {
                      text: `${_yesNoAndEmptyFormatter(
                        heartDetails?.generalInfo?.diabetesWithEndOrganDiseases
                      )}`,
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 2,
                    },
                  ],
                  [
                    {
                      text: 'Baseline GFR (ml/min)',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },
                    {
                      text: `${
                        heartDetails?.generalInfo?.baselineGfr
                          ? heartDetails?.generalInfo?.baselineGfr
                          : '-'
                      }`,
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 2,
                    },
                  ],
                  [
                    {
                      text: 'Baseline serum creatinine (mg/dl)',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },
                    {
                      text: `${
                        heartDetails?.generalInfo?.baselineGfr
                          ? heartDetails?.generalInfo?.baselineSerumCreatinine
                          : '-'
                      }`,
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 2,
                    },
                  ],
                  [
                    {
                      text: 'Does the patient have Severe cerebrovascular disease?',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },
                    {
                      text: `${_yesNoAndEmptyFormatter(
                        heartDetails?.generalInfo?.severeCerebrovascularDisease
                      )}`,
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 2,
                    },
                  ],
                  [
                    {
                      text: 'Severe Obesity BMI',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },
                    {
                      text: `${
                        heartDetails?.generalInfo?.severeObesity
                          ? heartDetails?.generalInfo?.severeObesity
                          : '-'
                      }`,
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 2,
                    },
                  ],
                  [
                    {
                      text: 'Does the patient have Severe primary pulmonary disease?',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },
                    {
                      text: `${_yesNoAndEmptyFormatter(
                        heartDetails?.generalInfo?.severePrimaryPulmonaryDisease
                      )}`,
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 2,
                    },
                  ],
                  [
                    {
                      text: 'Does the patient have Pulmonary Hypertension PASP > 50 mmHg unresponsive to vacodilator challenge?',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },
                    {
                      text: `${_yesNoAndEmptyFormatter(
                        heartDetails?.generalInfo?.pulmonaryHypertension
                      )}`,
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 2,
                    },
                  ],
                  [
                    {
                      text: 'Viral Marker Status : ',
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 3,
                      bold: true,
                    },
                    {},
                    {},
                  ],
                  [
                    {
                      text: `HBs AG Report Date \n (Positive/Negative) `,
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `${_viralMrakerRemarkAndDateFormatter(
                        heartDetails?.generalInfo?.viralMarkerStatus?.hbsagRemark,
                        heartDetails?.generalInfo?.viralMarkerStatus?.hbsagReportDate
                      )}`,
                      colSpan: 2,
                    },
                    {},
                  ],

                  [
                    {
                      text: `Anti HCV Report Date \n (Positive/Negative) `,
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `${_viralMrakerRemarkAndDateFormatter(
                        heartDetails?.generalInfo?.viralMarkerStatus?.antiHcvRemark,
                        heartDetails?.generalInfo?.viralMarkerStatus?.antiHcvReportDate
                      )}`,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: `HCV RNA Quantitative Report Date \n (Positive/Negative/Not Done) `,
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },
                    heartDetails?.generalInfo?.viralMarkerStatus?.hcvRnaQuantitativeRemark ===
                    'NOTDONE'
                      ? {
                          text: `${_viralMrakerRemarkAndDateFormatter(
                            heartDetails?.generalInfo?.viralMarkerStatus?.hcvRnaQuantitativeRemark
                          )}`,
                          colSpan: 2,
                        }
                      : {
                          text: `${_viralMrakerRemarkAndDateFormatter(
                            heartDetails?.generalInfo?.viralMarkerStatus?.hcvRnaQuantitativeRemark,
                            heartDetails?.generalInfo?.viralMarkerStatus
                              ?.hcvRnaQuantitativeReportDate
                          )}`,
                          colSpan: 2,
                        },
                    {},
                  ],
                  [
                    {
                      text: `HCV DNA Report Date \n (Positive/Negative/Not Done) `,
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },
                    heartDetails?.generalInfo?.viralMarkerStatus?.hcvDnaRemark === 'NOTDONE'
                      ? {
                          text: `${_viralMrakerRemarkAndDateFormatter(
                            heartDetails?.generalInfo?.viralMarkerStatus?.hcvDnaRemark
                          )}`,
                          colSpan: 2,
                        }
                      : {
                          text: `${_viralMrakerRemarkAndDateFormatter(
                            heartDetails?.generalInfo?.viralMarkerStatus?.hcvDnaRemark,
                            heartDetails?.generalInfo?.viralMarkerStatus?.hcvDnaReportDate
                          )}`,
                          colSpan: 2,
                        },
                    {},
                  ],
                  [
                    {
                      text: `HIV Report Date \n (Positive/Negative) `,
                      style: 'tableHeader',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `${_viralMrakerRemarkAndDateFormatter(
                        heartDetails?.generalInfo?.viralMarkerStatus?.hivRemark,
                        heartDetails?.generalInfo?.viralMarkerStatus?.hivReportDate
                      )}`,
                      colSpan: 2,
                    },
                    {},
                  ],
                ],
              },
            }
          : null,

        // organs.includes('HEART') && basicDetails?.waitingStatus === 'ROUTINE'
        organs.some((organ) => organ.organType === 'HEART' && organ.category === 'ROUTINE')
          ? {
              stack: [
                {
                  style: 'table',
                  table: {
                    widths: [250, 100, 150, 'auto', 'auto'],
                    heights: [
                      'auto',
                      'auto',
                      'auto',
                      'auto',
                      'auto',
                      'auto',
                      'auto',
                      'auto',
                      'auto',
                      'auto',
                      'auto',
                      'auto',
                      'auto',
                      'auto',
                      'auto',
                      'auto',
                      'auto',
                      'auto',
                      'auto',
                      'auto',
                    ],
                    body: [
                      [
                        {
                          text: 'Routine Registration',
                          pageBreak: 'before',
                          style: 'tableHeader',
                          colSpan: 3,
                          alignment: 'center',
                          bold: true,
                        },
                        {},
                        {},
                      ],

                      [
                        {
                          text: 'Stable with symptomatic in NYHA class III / IV on maximal medical treatment with oral medicine',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },
                        {
                          text: `${_yesNoAndEmptyBooleanFormatter(
                            heartDetails?.routineInfo?.stableWithSymptomaticNyhaClassYesNo
                          )}`,
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 2,
                        },
                      ],
                      [
                        {
                          text: 'Stable With Symptomatic NYHA Class Details',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },
                        {
                          text: `${_textOrEmptyFormatter(
                            heartDetails?.routineInfo?.stableWithSymptomaticNyhaClassDetails
                          )}`,
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 2,
                        },
                      ],

                      [
                        {
                          text: 'On Inotropes, optimally stale at home',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },
                        {
                          text: `${_yesNoAndEmptyBooleanFormatter(
                            heartDetails?.routineInfo?.onIonotropesYesNo
                          )}`,
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 2,
                        },
                      ],
                      [
                        {
                          text: 'Inotropes Details',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },
                        {
                          text: `${_textOrEmptyFormatter(
                            heartDetails?.routineInfo?.onIonotropesDetails
                          )}`,
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 2,
                        },
                      ],
                      [
                        {
                          text: 'Severe Heart Failure (EF < 20%) despite maximal medical therapy',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },
                        {
                          text: `${_yesNoAndEmptyBooleanFormatter(
                            heartDetails?.routineInfo?.severeHeartFailureYesNo
                          )}`,
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 2,
                        },
                      ],
                      [
                        {
                          text: 'Severe Heart Failure Details',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },
                        {
                          text: `${_textOrEmptyFormatter(
                            heartDetails?.routineInfo?.severeHeartFailureDetails
                          )}`,
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 2,
                        },
                      ],

                      [
                        {
                          text: 'Ischemic Cardiomyopathy',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },
                        {
                          text: `${_yesNoAndEmptyBooleanFormatter(
                            heartDetails?.routineInfo?.ichemicCardiomyopathyYesNo
                          )}`,
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 2,
                        },
                      ],
                      [
                        {
                          text: 'Non-ischemic Cardiomyopathy: Dilated, Hypertrophic, Restrictive',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },
                        {
                          text: `${_yesNoAndEmptyBooleanFormatter(
                            heartDetails?.routineInfo?.nonIschemicCardiomyopathyYesNo
                          )}`,
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 2,
                        },
                      ],
                      [
                        {
                          text: 'Non Ischemic Cardiomyopathy Details',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },
                        {
                          text: `${_textOrEmptyFormatter(
                            heartDetails?.routineInfo?.nonIschemicCardiomyopathyDetails
                          )}`,
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 2,
                        },
                      ],
                      [
                        {
                          text: 'Irreversible Myocarditis',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },
                        {
                          text: `${_yesNoAndEmptyBooleanFormatter(
                            heartDetails?.routineInfo?.irriversibleMyocarditisYesNo
                          )}`,
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 2,
                        },
                      ],

                      [
                        {
                          text: 'Intractable Arrhythmias',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },
                        {
                          text: `${_yesNoAndEmptyBooleanFormatter(
                            heartDetails?.routineInfo?.intractableArrhythmiasYesNo
                          )}`,
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 2,
                        },
                      ],

                      [
                        {
                          text: 'Intractable Arrhythmias Details',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },
                        {
                          text: `${_textOrEmptyFormatter(
                            heartDetails?.routineInfo?.intractableArrhythmiasDetails
                          )}`,
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 2,
                        },
                      ],
                      [
                        {
                          text: 'Complex Congenital Heart disease no amenable to corrections',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },
                        {
                          text: `${_yesNoAndEmptyBooleanFormatter(
                            heartDetails?.routineInfo?.complexCongentialHeartDiseaseYesNo
                          )}`,
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 2,
                        },
                      ],
                      [
                        {
                          text: 'Complex Congenital Heart Disease Details',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },
                        {
                          text: `${_textOrEmptyFormatter(
                            heartDetails?.routineInfo?.complexCongentialHeartDiseaseDetails
                          )}`,
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 2,
                        },
                      ],
                      [
                        {
                          text: 'Complicated Valvular Heart Disease',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },
                        {
                          text: `${_yesNoAndEmptyBooleanFormatter(
                            heartDetails?.routineInfo?.complicatedValvularHeartDiseaseYesNo
                          )}`,
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 2,
                        },
                      ],
                      [
                        {
                          text: 'Complicated Valvular Heart Disease Details',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },
                        {
                          text: `${_textOrEmptyFormatter(
                            heartDetails?.routineInfo?.complicatedValvularHeartDiseaseDetails
                          )}`,
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 2,
                        },
                      ],
                      [
                        {
                          text: 'Significant functional limitation despite maximal drug therapy: NYHA III-IV',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },
                        {
                          text: `${_yesNoAndEmptyBooleanFormatter(
                            heartDetails?.routineInfo?.significantFunctionalLimitationYesNo
                          )}`,
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 2,
                        },
                      ],
                      [
                        {
                          text: 'Significant Functional Limitation Details',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },
                        {
                          text: `${_textOrEmptyFormatter(
                            heartDetails?.routineInfo?.significantFunctionalLimitationDetails
                          )}`,
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 2,
                        },
                      ],
                      [
                        {
                          text: 'Congenital / Valvular heart diseases not amenable to interventional or surgical procedure',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },
                        {
                          text: `${_yesNoAndEmptyBooleanFormatter(
                            heartDetails?.routineInfo?.congentialValvularHeartDiseaseYesNo
                          )}`,
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 2,
                        },
                      ],
                      [
                        {
                          text: 'Congenital Valvular Heart Disease Details',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },
                        {
                          text: `${_textOrEmptyFormatter(
                            heartDetails?.routineInfo?.congentialValvularHeartDiseaseDetails
                          )}`,
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 2,
                        },
                      ],
                      [
                        {
                          text: 'Life threatening arrhythmia despite maximal medical / device therapies',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },
                        {
                          text: `${_yesNoAndEmptyBooleanFormatter(
                            heartDetails?.routineInfo?.lifeThreateningArrhythmiaYesNo
                          )}`,
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 2,
                        },
                      ],
                      [
                        {
                          text: 'Life Threatening Arrhythmia Details',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },
                        {
                          text: `${_textOrEmptyFormatter(
                            heartDetails?.routineInfo?.lifeThreateningArrhythmiaDetails
                          )}`,
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 2,
                        },
                      ],
                      [
                        {
                          text: 'Acceptance of CEPT Criteria',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },
                        {
                          text: `${_yesNoAndEmptyBooleanFormatter(
                            heartDetails?.routineInfo?.acceptanceOfCeptYesNo
                          )}`,
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 2,
                        },
                      ],
                    ],
                  },
                },
                {
                  style: 'table',
                  table: {
                    headerRows: 1,
                    widths: ['*', '*'],
                    body: [
                      [
                        { text: 'Etiology', style: 'tableHeader', alignment: 'left' },
                        { text: 'Etiology Report', style: 'tableHeader', alignment: 'left' },
                      ],
                      ...heartDetails?.etiologies?.map((e) => [
                        {
                          text: `${e.etiology ? e.etiology : '-'}`,
                          style: 'tableCell',
                          alignment: 'left',
                        },
                        {
                          text: `${e.attachment?.name ? 'ATTACHED' : '-'}`,
                          style: 'tableCell',
                          alignment: 'left',
                        },
                      ]),
                    ],
                  },
                },
                {
                  style: 'table',
                  table: {
                    widths: [250, 100, 150, 'auto', 'auto'],
                    heights: ['auto', 'auto', 'auto'],
                    body: [
                      [
                        {
                          text: 'Etiology Details ',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${
                            heartDetails?.etiologyDetails ? heartDetails?.etiologyDetails : '-'
                          }`,
                          colSpan: 2,
                        },
                        {},
                      ],
                    ],
                  },
                },
                {
                  text: 'Any Other Attachments',
                  style: 'subHeader',
                },
                {
                  style: 'table',
                  table: {
                    headerRows: 1,
                    widths: ['*', '*'],
                    body: [
                      [
                        { text: 'Attachment Name', style: 'tableHeader', alignment: 'left' },
                        { text: 'Report', style: 'tableHeader', alignment: 'left' },
                      ],
                      ...heartDetails?.otherAttachments?.map((e) => [
                        {
                          text: `${e.attachmentName ? e.attachmentName : '-'}`,
                          style: 'tableCell',
                          alignment: 'left',
                        },
                        {
                          text: `${e.attachment?.name ? 'ATTACHED' : '-'}`,
                          style: 'tableCell',
                          alignment: 'left',
                        },
                      ]),
                    ],
                  },
                },
                {
                  style: 'table',
                  table: {
                    headerRows: 1,
                    widths: ['*', '*'],
                    body: [
                      [
                        { text: 'Cardiologist Team', style: 'tableHeader', alignment: 'left' },

                        { text: 'Transplant Surgeon', style: 'tableHeader', alignment: 'left' },
                      ],
                      [
                        (heartDetails?.cardiologistTeam || [])?.map((e) => [
                          {
                            text: `${e.user.name ? e.user.name : '-'}`,
                            style: 'tableCell',
                            alignment: 'left',
                          },
                        ]),
                        (heartDetails?.transplantSurgeon || [])?.map((e) => [
                          {
                            text: `${e.user.name ? e.user.name : '-'}`,
                            style: 'tableCell',
                            alignment: 'left',
                          },
                        ]),
                      ],
                    ],
                  },
                },
              ],
            }
          : null,

        // organs.includes('HAND')
        organs.some((organ) => organ.organType === 'HAND')
          ? {
              stack: [
                {
                  style: 'table',
                  table: {
                    widths: [250, 100, 150, 'auto', 'auto'],
                    heights: [
                      'auto',
                      'auto',
                      'auto',
                      'auto',
                      'auto',
                      'auto',
                      'auto',
                      'auto',
                      'auto',
                      'auto',
                      'auto',
                      'auto',
                      20,
                      20,
                      10,
                      'auto',
                      'auto',
                      'auto',
                      'auto',
                      'auto',
                      'auto',
                      'auto',
                      'auto',
                    ],
                    body: [
                      [
                        {
                          text: 'State Organ and Tissue Transplant Organisation (SOTTO),\n  Maharashtra',
                          style: 'tableHeader',
                          colSpan: 3,
                          alignment: 'center',
                          bold: true,
                        },
                        {},
                        {},
                      ],
                      [
                        {
                          text: 'State Appropriate Authority of Maharashtra',
                          style: 'textStyle',
                          alignment: 'center',
                          colSpan: 3,
                        },
                        {},
                        {},
                      ],
                      [
                        {
                          text: 'HAND REGISTRATION',
                          style: 'textStyle',
                          alignment: 'center',
                          colSpan: 3,
                        },
                        {},
                        {},
                      ],
                      [
                        {
                          text: `Name: ${basicDetails?.name ? basicDetails?.name : '-'}`,
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 3,
                        },
                        {},
                        {},
                      ],
                      [
                        {
                          text: `ZTCC Zone: ${_getZtccZoneLabelById(
                            basicDetails?.registeredHosp?.ztccZone
                          )}`,
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 3,
                        },
                        {},
                        {},
                      ],
                      [
                        {
                          text: `NOTTO ID : `,
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },
                        {
                          text: `${basicDetails?.nottoId ? basicDetails?.nottoId : '-'}`,
                          colSpan: 2,
                        },
                        { text: '' },
                        { text: '' },
                      ],
                      [
                        {
                          text: `Date of Registration : `,
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${
                            basicDetails?.dateOfRegistration
                              ? moment(basicDetails?.dateOfRegistration, FORMAT_DATE_UNIXTS).format(
                                  'DD/MM/YYYY'
                                )
                              : '-'
                          }`,
                          colSpan: 2,
                        },
                        {},
                      ],
                      [
                        {
                          text: 'Does the patient need a bilateral or unilateral hand transplant?',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },
                        {
                          text: `${handDetails?.handAmputationOptions?.amputation}`,
                          colSpan: 2,
                        },
                        {},
                      ],

                      ...(handDetails?.handAmputationOptions?.amputation === 'UNILATERAL'
                        ? [
                            [
                              {
                                text: 'Select Hand for transplant',
                                style: 'tableHeader',
                                alignment: 'left',
                                colSpan: 1,
                              },
                              {
                                text: `${handDetails?.handAmputationOptions?.unliateralLeftOrRight}`,
                                colSpan: 2,
                              },
                              {},
                            ],
                          ]
                        : []),

                      ...(handDetails?.handAmputationOptions?.amputation === 'UNILATERAL'
                        ? [
                            [
                              {
                                text: 'Select Level (Unilateral)',
                                style: 'tableHeader',
                                alignment: 'left',
                                colSpan: 1,
                              },
                              {
                                text: `${handTransplantFormatter(
                                  handDetails?.handAmputationOptions?.unilateralLevelOptions
                                )}`,
                                colSpan: 2,
                              },
                              {},
                            ],
                          ]
                        : []),
                      ...(handDetails?.handAmputationOptions?.amputation === 'BILATERAL'
                        ? [
                            [
                              {
                                text: 'Select Level for Right',
                                style: 'tableHeader',
                                alignment: 'left',
                                colSpan: 1,
                              },
                              {
                                text: `${handTransplantFormatter(
                                  handDetails?.handAmputationOptions?.bilateralRightLevel
                                )}`,
                                colSpan: 2,
                              },
                              {},
                            ],
                          ]
                        : []),

                      ...(handDetails?.handAmputationOptions?.amputation === 'BILATERAL'
                        ? [
                            [
                              {
                                text: 'Select Level for Left',
                                style: 'tableHeader',
                                alignment: 'left',
                                colSpan: 1,
                              },
                              {
                                text: `${handTransplantFormatter(
                                  handDetails?.handAmputationOptions?.bilateralLeftLevel
                                )}`,
                                colSpan: 2,
                              },
                              {},
                            ],
                          ]
                        : []),

                      [
                        {
                          text: 'Does the patient have the significant functional limitation despite using prosthesis',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${_yesNoAndEmptyBooleanFormatter(
                            handDetails?.siginificantFunctionalLimitationDespiteUsingProsthesis
                          )}`,
                          colSpan: 2,
                        },
                        {},
                      ],
                      [
                        {
                          text: 'Loss of hand or part of upper limb not suitable to any other surgical procedure for near complete hand function for activities of daily living.',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${_yesNoAndEmptyBooleanFormatter(
                            handDetails?.lossOfHandOrPartOfUpperLimb
                          )}`,
                          colSpan: 2,
                        },
                        {},
                      ],
                      [
                        {
                          text: 'Does the patient have the congenital absence of limb (relative contraindication)?',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${_yesNoAndEmptyBooleanFormatter(
                            handDetails?.congenitalAbsenceOfLimb
                          )}`,
                          colSpan: 2,
                        },
                        {},
                      ],
                      [
                        {
                          text: 'Does the patient have the severe cachexia: Nephropathy, Neuropathy etc. ?',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${_yesNoAndEmptyBooleanFormatter(handDetails?.severeCachexia)}`,
                          colSpan: 2,
                        },
                        {},
                      ],
                      [
                        {
                          text: 'Does the patient have any diabetes with end organ diseases?',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${_yesNoAndEmptyBooleanFormatter(
                            handDetails?.diabetesWithEndOrganDiseases
                          )}`,
                          colSpan: 2,
                        },
                        {},
                      ],

                      [
                        {
                          text: 'Does the patient have the severe cerebrovascular disease?',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${_yesNoAndEmptyBooleanFormatter(
                            handDetails?.severeCerebrovascularDisease
                          )}`,
                          colSpan: 2,
                        },
                        {},
                      ],
                      [
                        {
                          text: 'Does the patient have the Malignancies with expected life span less than 10 yrs',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${_yesNoAndEmptyBooleanFormatter(handDetails?.malignancies)}`,
                          colSpan: 2,
                        },
                        {},
                      ],

                      [
                        {
                          text: 'Does the patient have the severe primary pulmonary disease',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${_yesNoAndEmptyBooleanFormatter(
                            handDetails?.severePrimaryPulmonaryDisease
                          )}`,
                          colSpan: 2,
                        },
                        {},
                      ],

                      [
                        {
                          text: 'Pulmonary hypertension PASP > 50 mmHg unresponsive to vasodilator challenge',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${_yesNoAndEmptyBooleanFormatter(
                            handDetails?.pulmonaryHypertension
                          )}`,
                          colSpan: 2,
                        },
                        {},
                      ],
                      [
                        {
                          text: 'Does the patient have any past history of hematological events?',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${_yesNoAndEmptyBooleanFormatter(
                            handDetails?.hematologicalEvents
                          )}`,
                          colSpan: 2,
                        },
                        {},
                      ],
                      [
                        {
                          text: 'Hematology Attachment',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${handDetails?.hematologyAttachment?.name ? 'ATTACHED' : '-'}`,
                          colSpan: 2,
                        },
                        {},
                      ],
                      [
                        {
                          text: 'Pulmonary Fitness Certificate',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${handDetails?.pulmonaryCertificate?.name ? 'ATTACHED' : '-'}`,
                          colSpan: 2,
                        },
                        {},
                      ],
                      [
                        {
                          text: 'Psychiatry Fitness Certificate',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${handDetails?.psychiatryCertificate?.name ? 'ATTACHED' : '-'}`,
                          colSpan: 2,
                        },
                        {},
                      ],
                      [
                        {
                          text: 'Nephrology Fitness Certificate',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${handDetails?.nephrologyCertificate?.name ? 'ATTACHED' : '-'}`,
                          colSpan: 2,
                        },
                        {},
                      ],
                      [
                        {
                          text: 'Cardiology Fitness Certificate',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${handDetails?.cardiologyCertificate?.name ? 'ATTACHED' : '-'}`,
                          colSpan: 2,
                        },
                        {},
                      ],
                      [
                        {
                          text: 'X-Ray Attachment',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${handDetails?.xRayAttachment?.name ? 'ATTACHED' : '-'}`,
                          colSpan: 2,
                        },
                        {},
                      ],
                      [
                        {
                          text: 'Colour Dopplers Attachment',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${handDetails?.colourDopplersAttachment?.name ? 'ATTACHED' : '-'}`,
                          colSpan: 2,
                        },
                        {},
                      ],
                      [
                        {
                          text: 'Angiography Attachment (NC)',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${handDetails?.angiographyAttachment?.name ? 'ATTACHED' : '-'}`,
                          colSpan: 2,
                        },
                        {},
                      ],
                      [
                        {
                          text: 'EMG/MCV (NC)',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${handDetails?.emgMcv?.name ? 'ATTACHED' : '-'}`,
                          colSpan: 2,
                        },
                        {},
                      ],
                      [
                        {
                          text: 'Photograph of Amputated Limb',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${handDetails?.photoOfAmputatedLimb?.name ? 'ATTACHED' : '-'}`,
                          colSpan: 2,
                        },
                        {},
                      ],
                      [
                        {
                          text: 'MRI Attachment',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${handDetails?.mriAttachment?.name ? 'ATTACHED' : '-'}`,
                          colSpan: 2,
                        },
                        {},
                      ],
                      [
                        {
                          text: 'Viral Marker Status ',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 3,
                        },
                        {},
                        {},
                      ],
                      [
                        {
                          text: `HBsAg`,
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${_viralMrakerRemarkAndDateFormatter(
                            handDetails?.viralMarkerStatus?.hbsagRemark,
                            handDetails?.viralMarkerStatus?.hbsagReportDate
                          )}`,
                          colSpan: 2,
                        },
                        {},
                      ],

                      [
                        {
                          text: `Anti HCV Report Date \n (Positive/Negative) `,
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${_viralMrakerRemarkAndDateFormatter(
                            handDetails?.viralMarkerStatus?.antiHcvRemark,
                            handDetails?.viralMarkerStatus?.antiHcvReportDate
                          )}`,
                          colSpan: 2,
                        },
                        {},
                      ],
                      [
                        {
                          text: `HCV RNA Quantitative Report Date \n (Positive/Negative/Not Done) `,
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${_viralMrakerRemarkAndDateFormatter(
                            handDetails?.viralMarkerStatus?.hcvRnaQuantitativeRemark,
                            handDetails?.viralMarkerStatus?.hcvRnaQuantitativeReportDate
                          )}`,
                          colSpan: 2,
                        },
                        {},
                      ],
                      [
                        {
                          text: `HCV DNA Report Date \n (Positive/Negative/Not Done) `,
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${_viralMrakerRemarkAndDateFormatter(
                            handDetails?.viralMarkerStatus?.hcvDnaRemark,
                            handDetails?.viralMarkerStatus?.hcvDnaReportDate
                          )}`,
                          colSpan: 2,
                        },
                        {},
                      ],
                      [
                        {
                          text: `HIV Report Date \n (Positive/Negative) `,
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${_viralMrakerRemarkAndDateFormatter(
                            handDetails?.viralMarkerStatus?.hivRemark,
                            handDetails?.viralMarkerStatus?.hivReportDate
                          )}`,
                          colSpan: 2,
                        },
                        {},
                      ],
                    ],
                  },
                },
                {
                  style: 'table',
                  table: {
                    headerRows: 1,
                    widths: ['*', '*'],
                    body: [
                      [
                        { text: 'Etiology', style: 'tableHeader', alignment: 'left' },
                        { text: 'Etiology Report', style: 'tableHeader', alignment: 'left' },
                      ],
                      ...handDetails.etiologies.map((e) => [
                        {
                          text: `${e.etiology ? e.etiology : '-'}`,
                          style: 'tableCell',
                          alignment: 'left',
                        },
                        {
                          text: `${e.attachment?.name ? 'ATTACHED' : '-'}`,
                          style: 'tableCell',
                          alignment: 'left',
                        },
                      ]),
                    ],
                  },
                },
                {
                  style: 'table',
                  table: {
                    widths: [250, 100, 150, 'auto', 'auto'],
                    heights: ['auto', 'auto', 'auto'],
                    body: [
                      [
                        {
                          text: 'Etiology Details ',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${
                            handDetails?.etiologyDetails ? handDetails?.etiologyDetails : '-'
                          }`,
                          colSpan: 2,
                        },
                        {},
                      ],
                    ],
                  },
                },
                {
                  text: 'Any Other Attachments',
                  style: 'subHeader',
                },
                {
                  style: 'table',
                  table: {
                    headerRows: 1,
                    widths: ['*', '*'],
                    body: [
                      [
                        { text: 'Attachment Name', style: 'tableHeader', alignment: 'left' },
                        { text: 'Report', style: 'tableHeader', alignment: 'left' },
                      ],
                      ...handDetails?.otherAttachments?.map((e) => [
                        {
                          text: `${e.attachmentName ? e.attachmentName : '-'}`,
                          style: 'tableCell',
                          alignment: 'left',
                        },
                        {
                          text: `${e.attachment?.name ? 'ATTACHED' : '-'}`,
                          style: 'tableCell',
                          alignment: 'left',
                        },
                      ]),
                    ],
                  },
                },
                {
                  style: 'table',
                  table: {
                    headerRows: 1,
                    widths: ['*', '*'],
                    body: [
                      [{ text: 'Transplant Surgeon', style: 'tableHeader', alignment: 'left' }],
                      [
                        (handDetails?.transplantSurgeon || [])?.map((e) => [
                          {
                            text: `${e.user.name ? e.user.name : '-'}`,
                            style: 'tableCell',
                            alignment: 'left',
                          },
                        ]),
                      ],
                    ],
                  },
                },
              ],
            }
          : null,

        // organs.includes('KIDNEY')
        organs.some((organ) => organ.organType === 'KIDNEY')
          ? {
              stack: [
                {
                  style: 'table',
                  table: {
                    widths: [250, 100, 150, 'auto', 'auto'],
                    heights: [
                      'auto',
                      'auto',
                      'auto',
                      'auto',
                      'auto',
                      'auto',
                      'auto',
                      'auto',
                      'auto',
                      'auto',
                      'auto',
                      'auto',
                      20,
                      20,
                      10,
                      'auto',
                      'auto',
                      'auto',
                      'auto',
                      'auto',
                      'auto',
                      'auto',
                      'auto',
                    ],
                    body: [
                      [
                        {
                          text: 'State Organ and Tissue Transplant Organisation (SOTTO),\n  Maharashtra',
                          style: 'tableHeader',
                          colSpan: 3,
                          alignment: 'center',
                          bold: true,
                        },
                        {},
                        {},
                      ],
                      [
                        {
                          text: 'State Appropriate Authority of Maharashtra',
                          style: 'textStyle',
                          alignment: 'center',
                          colSpan: 3,
                        },
                        {},
                        {},
                      ],
                      [
                        {
                          text: 'KIDNEY REGISTRATION',
                          style: 'textStyle',
                          alignment: 'center',
                          colSpan: 3,
                        },
                        {},
                        {},
                      ],
                      [
                        {
                          text: `Name: ${basicDetails?.name ? basicDetails?.name : '-'}`,
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 3,
                        },
                        {},
                        {},
                      ],
                      [
                        {
                          text: `ZTCC Zone: ${_getZtccZoneLabelById(
                            basicDetails?.registeredHosp?.ztccZone
                          )}`,
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 3,
                        },
                        {},
                        {},
                      ],
                      [
                        {
                          text: `NOTTO ID : `,
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },
                        {
                          text: `${basicDetails?.nottoId ? basicDetails?.nottoId : '-'}`,
                          colSpan: 2,
                        },
                        { text: '' },
                        { text: '' },
                      ],
                      [
                        {
                          text: `Date of Registration : `,
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${
                            basicDetails?.dateOfRegistration
                              ? moment(basicDetails?.dateOfRegistration, FORMAT_DATE_UNIXTS).format(
                                  'DD/MM/YYYY'
                                )
                              : '-'
                          }`,
                          colSpan: 2,
                        },
                        {},
                      ],
                      // [
                      //   {
                      //     text: `Address & Telephone No: `,
                      //     style: 'tableHeader',
                      //     alignment: 'left',
                      //     colSpan: 1,
                      //   },

                      //   {
                      //     text: `${
                      //       basicDetails?.presentAddress ? basicDetails?.presentAddress : '-'
                      //     } - ${contactNos.length > 0 ? contactNos[0] : '-'}`,
                      //     colSpan: 2,
                      //   },
                      //   {},
                      // ],
                      // [
                      //   {
                      //     text: `Nationality: `,
                      //     style: 'tableHeader',
                      //     alignment: 'left',
                      //     colSpan: 1,
                      //   },

                      //   {
                      //     text: `${_nationalityFormatter(basicDetails?.nationality)}`,
                      //     colSpan: 2,
                      //   },
                      //   {},
                      // ],
                      // [
                      //   {
                      //     text: `Hospital Name: `,
                      //     style: 'tableHeader',
                      //     alignment: 'left',
                      //     colSpan: 1,
                      //   },

                      //   { text: `${hospitalName || '-'}`, colSpan: 2 },
                      //   {},
                      // ],

                      // [
                      //   {
                      //     text: `Age: ${basicDetails?.currentAge ? basicDetails?.currentAge : '-'}`,
                      //     style: 'tableHeader',
                      //     alignment: 'left',
                      //     colSpan: 1,
                      //   },
                      //   {
                      //     text: `Sex: ${basicDetails?.sex ? basicDetails?.sex : '-'}`,
                      //     style: 'tableHeader',
                      //     alignment: 'left',
                      //     colSpan: 2,
                      //   },
                      //   {},
                      // ],
                      // [
                      //   {
                      //     text: `Economic Status: `,
                      //     style: 'tableHeader',
                      //     alignment: 'left',
                      //     colSpan: 1,
                      //   },

                      //   {
                      //     text: `${basicDetails?.economicStatus ? basicDetails?.economicStatus : '-'}`,
                      //     colSpan: 2,
                      //   },
                      //   {},
                      // ],
                      // [
                      //   {
                      //     text: `Blood Group: `,
                      //     style: 'tableHeader',
                      //     alignment: 'left',
                      //     colSpan: 1,
                      //   },

                      //   {
                      //     text: `${
                      //       basicDetails?.bloodGroup ? BLOODGROUP[basicDetails?.bloodGroup].label : '-'
                      //     }`,
                      //     colSpan: 2,
                      //   },
                      //   {},
                      // ],
                      [
                        {
                          text: `Date of Initiation of Dialysis:  `,
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${
                            kidneyDetails?.dateOfDialysis
                              ? moment(kidneyDetails?.dateOfDialysis, FORMAT_DATE_UNIXTS).format(
                                  'DD/MM/YYYY'
                                )
                              : '-'
                          }`,
                          colSpan: 3,
                        },
                        {},
                      ],

                      [
                        {
                          text: `Report of Initiation of Dialysis:  `,
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${
                            kidneyDetails?.dialysisInitiationReport?.name ? 'ATTACHED' : '-'
                          }`,
                          colSpan: 3,
                        },
                        {},
                      ],

                      [
                        {
                          text: `Failed Previous Transplant \n (if yes give details) : `,
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${_yesNoAndEmptyFormatter(kidneyDetails?.previousFailedGraft)}`,
                          colSpan: 3,
                        },
                        {},
                      ],

                      [
                        {
                          text: `If Yes, proof of kidney Transplant  `,
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${
                            kidneyDetails?.proofOfKidneyTransplant?.name ? 'ATTACHED' : '-'
                          }`,
                          colSpan: 3,
                        },
                        {},
                      ],

                      [
                        {
                          text: `Is the patient past kidney donor?  `,
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${_yesNoAndEmptyFormatter(
                            kidneyDetails?.livingDonorNeedingTransplant
                          )}`,
                          colSpan: 3,
                        },
                        {},
                      ],
                      [
                        {
                          text: `If Yes, proof of Living Donation:  `,
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${kidneyDetails?.livingDonationReport?.name ? 'ATTACHED' : '-'}`,
                          colSpan: 3,
                        },
                        {},
                      ],

                      [
                        {
                          text: 'HLA Typing : ',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 3,
                          bold: true,
                        },
                        {},
                        {},
                      ],
                      [
                        {
                          text: 'A',
                          style: 'tableHeader',
                          alignment: 'left',
                        },
                        {
                          text: kidneyDetails?.hlaTyping?.a,
                          colSpan: 2,
                        },
                        {},
                      ],
                      [
                        {
                          text: 'B',
                          style: 'tableHeader',
                          alignment: 'left',
                        },
                        {
                          text: kidneyDetails?.hlaTyping?.b,
                          colSpan: 2,
                        },
                        {},
                      ],
                      [
                        {
                          text: 'C',
                          style: 'tableHeader',
                          alignment: 'left',
                        },
                        {
                          text: kidneyDetails?.hlaTyping?.c,
                          colSpan: 2,
                        },
                        {},
                      ],
                      [
                        {
                          text: 'DQ',
                          style: 'tableHeader',
                          alignment: 'left',
                        },
                        {
                          text: kidneyDetails?.hlaTyping?.dq,
                          colSpan: 2,
                        },
                        {},
                      ],
                      [
                        {
                          text: 'DR',
                          style: 'tableHeader',
                          alignment: 'left',
                        },
                        {
                          text: kidneyDetails?.hlaTyping?.dr,
                          colSpan: 2,
                        },
                        {},
                      ],
                      [
                        {
                          text: 'DM',
                          style: 'tableHeader',
                          alignment: 'left',
                        },
                        {
                          text: kidneyDetails?.hlaTyping?.dm,
                          colSpan: 2,
                        },
                        {},
                      ],
                      [
                        {
                          text: 'DP',
                          style: 'tableHeader',
                          alignment: 'left',
                        },
                        {
                          text: kidneyDetails?.hlaTyping?.dp,
                          colSpan: 2,
                        },
                        {},
                      ],
                      [
                        {
                          text: 'TAP',
                          style: 'tableHeader',
                          alignment: 'left',
                        },
                        {
                          text: kidneyDetails?.hlaTyping?.tap,
                          colSpan: 2,
                        },
                        {},
                      ],
                      [
                        {
                          text: 'TNF',
                          style: 'tableHeader',
                          alignment: 'left',
                        },
                        {
                          text: kidneyDetails?.hlaTyping?.tnf,
                          colSpan: 2,
                        },
                        {},
                      ],
                      [
                        {
                          text: `VAF AV Graft \n (if yes give details): `,
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${_yesNoAndEmptyFormatter(kidneyDetails.vafGraft.vafGraft)} `,
                          colSpan: 2,
                        },
                        {},
                      ],

                      [
                        {
                          text: `If Yes, Vascular Access Failure Remarks`,
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${kidneyDetails.vafGraft.vafGraftRemarks} `,
                          colSpan: 2,
                        },
                        {},
                      ],
                      [
                        {
                          text: `Report of Vascular Access Failure`,
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${
                            kidneyDetails?.vafGraft?.vafGraftReport?.name ? 'ATTACHED' : '-'
                          }`,
                          colSpan: 2,
                        },
                        {},
                      ],
                      [
                        {
                          text: `Number of VAF Failed`,
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${
                            kidneyDetails.vafGraft.vafGraftNumber
                              ? kidneyDetails.vafGraft.vafGraftNumber
                              : ' -'
                          } `,
                          colSpan: 2,
                        },
                        {},
                      ],
                      //   [
                      //     {
                      //       text: `Date of Failure`,
                      //       style: 'tableHeader',
                      //       alignment: 'left',
                      //       colSpan: 1,
                      //     },

                      //     kidneyDetails.vafGraft.vafGraftFailure.map((e) => [
                      //       {
                      //         text: `${
                      //           e.dateOfFailure
                      //             ? moment(e.dateOfFailure, FORMAT_DATE_UNIXTS).format('DD/MM/YYYY')
                      //             : '-'
                      //         }`,
                      //       },
                      //     ]),

                      //     {
                      //       text: '',
                      //       colSpan: 2,
                      //     },
                      //     {},
                      //   ],
                      // [
                      //   {
                      //     text: `Report`,
                      //     style: 'tableHeader',
                      //     alignment: 'left',
                      //     colSpan: 1,
                      //   },
                      //   kidneyDetails.vafGraft.vafGraftFailure.map((a) => [
                      //     {
                      //       text: `${a.report?.name ? 'ATTACHED' : '-'}`,
                      //     },
                      //   ]),

                      //   {
                      //     text: '',
                      //     colSpan: 2,
                      //   },
                      //   {},
                      // ],
                    ],
                  },
                },

                {
                  style: 'table',
                  table: {
                    headerRows: 1,
                    widths: ['*', '*'],
                    body: [
                      [
                        { text: 'Date of Failure', style: 'tableHeader', alignment: 'left' },
                        { text: 'Report', style: 'tableHeader', alignment: 'left' },
                      ],
                      ...kidneyDetails.vafGraft.vafGraftFailure.map((a) => [
                        {
                          text: `${
                            a.dateOfFailure
                              ? moment(a.dateOfFailure, FORMAT_DATE_UNIXTS).format('DD/MM/YYYY')
                              : '-'
                          }`,
                          style: 'tableCell',
                          alignment: 'left',
                        },
                        {
                          text: `${a.report?.name ? 'ATTACHED' : '-'}`,
                          style: 'tableCell',
                          alignment: 'left',
                        },
                      ]),
                    ],
                  },
                },

                {
                  style: 'table',
                  table: {
                    widths: [250, 100, 150, 'auto', 'auto'],
                    heights: [
                      'auto',
                      'auto',
                      'auto',
                      'auto',
                      'auto',
                      'auto',
                      'auto',
                      'auto',
                      'auto',
                      'auto',
                      'auto',
                      'auto',
                      // 20,
                      // 20,
                      // 10,
                      'auto',
                      'auto',
                      'auto',
                      'auto',
                      'auto',
                      'auto',
                      'auto',
                      'auto',
                      'auto',
                      'auto',
                      'auto',
                    ],
                    body: [
                      [
                        {
                          text: `PD Access Failure \n (if yes give details): `,
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },
                        {
                          text: `${_yesNoAndEmptyFormatter(kidneyDetails.vafFitsula.vafFitsula)}`,
                          colSpan: 2,
                        },
                        {},
                      ],

                      [
                        {
                          text: `If Yes, PD Access Failure Remarks`,
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${kidneyDetails.vafFitsula.vafFitsulaRemarks} `,
                          colSpan: 2,
                        },
                        {},
                      ],
                      [
                        {
                          text: `Report of PD Access Failure`,
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${
                            kidneyDetails?.vafFitsula?.vafFitsulaReport?.name ? 'ATTACHED' : '-'
                          }`,
                          colSpan: 2,
                        },
                        {},
                      ],
                      [
                        {
                          text: `Number of PDAF Failed`,
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${
                            kidneyDetails.vafFitsula.vafFitsulaNumber
                              ? kidneyDetails.vafFitsula.vafFitsulaNumber
                              : '-'
                          } `,
                          colSpan: 2,
                        },
                        {},
                      ],
                    ],
                  },
                },

                {
                  style: 'table',
                  table: {
                    headerRows: 1,
                    widths: ['*', '*'],
                    body: [
                      [
                        { text: 'Date of Failure', style: 'tableHeader', alignment: 'left' },
                        { text: 'Report', style: 'tableHeader', alignment: 'left' },
                      ],
                      ...kidneyDetails.vafFitsula.vafFitsulaFailure.map((b) => [
                        {
                          text: `${
                            b.dateOfFailure
                              ? moment(b.dateOfFailure, FORMAT_DATE_UNIXTS).format('DD/MM/YYYY')
                              : '-'
                          }`,
                          style: 'tableCell',
                          alignment: 'left',
                        },
                        {
                          text: `${b.report?.name ? 'ATTACHED' : '-'}`,
                          style: 'tableCell',
                          alignment: 'left',
                        },
                      ]),
                    ],
                  },
                },

                {
                  style: 'table',
                  table: {
                    widths: [250, 100, 150, 'auto', 'auto'],
                    heights: [
                      'auto',
                      'auto',
                      'auto',
                      'auto',
                      'auto',
                      'auto',
                      'auto',
                      'auto',
                      'auto',
                      'auto',
                      'auto',
                      'auto',
                      // 20,
                      // 20,
                      // 10,
                      'auto',
                      'auto',
                      'auto',
                      'auto',
                      'auto',
                      'auto',
                      'auto',
                      'auto',
                      'auto',
                      'auto',
                      'auto',
                    ],
                    body: [
                      // [
                      //   {
                      //     text: `Date of Failure`,
                      //     style: 'tableHeader',
                      //     alignment: 'left',
                      //     colSpan: 1,
                      //   },

                      //   kidneyDetails.vafFitsula.vafFitsulaFailure.map((b) => [
                      //     {
                      //       text: `${
                      //         b.dateOfFailure
                      //           ? moment(b.dateOfFailure, FORMAT_DATE_UNIXTS).format('DD/MM/YYYY')
                      //           : '-'
                      //       }`,
                      //     },
                      //   ]),

                      //   {
                      //     text: '',
                      //     colSpan: 2,
                      //   },
                      //   {},
                      // ],
                      // [
                      //   {
                      //     text: `Report`,
                      //     style: 'tableHeader',
                      //     alignment: 'left',
                      //     colSpan: 1,
                      //   },

                      //   kidneyDetails.vafFitsula.vafFitsulaFailure.map((v) => [
                      //     {
                      //       text: `${v.report?.name ? 'ATTACHED' : '-'}`,
                      //     },
                      //   ]),

                      //   {
                      //     text: '',
                      //     colSpan: 2,
                      //   },
                      //   {},
                      // ],

                      [
                        {
                          text: 'PRA Positivity : ',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 3,
                          bold: true,
                        },
                        {},
                        {},
                      ],
                      [
                        {
                          text: `PRA \n a) For Positivity to Class 1 or 2 \n b) For Positivity to both Classes `,
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        { text: `${_praFormatter(kidneyDetails?.pra?.praYesNo)}`, colSpan: 2 },
                        {},
                      ],

                      [
                        {
                          text: 'Viral Marker Status : ',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 3,
                          bold: true,
                        },
                        {},
                        {},
                      ],
                      [
                        {
                          text: `HBs AG Report Date \n (Positive/Negative) `,
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${_viralMrakerRemarkAndDateFormatter(
                            kidneyDetails?.viralMarkerStatus?.hbsagRemark,
                            kidneyDetails?.viralMarkerStatus?.hbsagReportDate
                          )}`,
                          colSpan: 2,
                        },
                        {},
                      ],

                      [
                        {
                          text: `Anti HCV Report Date \n (Positive/Negative) `,
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${_viralMrakerRemarkAndDateFormatter(
                            kidneyDetails?.viralMarkerStatus?.antiHcvRemark,
                            kidneyDetails?.viralMarkerStatus?.antiHcvReportDate
                          )}`,
                          colSpan: 2,
                        },
                        {},
                      ],
                      [
                        {
                          text: `HCV RNA Quantitative Report Date \n (Positive/Negative/Not Done) `,
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        kidneyDetails?.viralMarkerStatus?.hcvRnaQuantitativeRemark === 'NOTDONE'
                          ? {
                              text: `${_viralMrakerRemarkAndDateFormatter(
                                kidneyDetails?.viralMarkerStatus?.hcvRnaQuantitativeRemark
                              )}`,
                              colSpan: 2,
                            }
                          : {
                              text: `${_viralMrakerRemarkAndDateFormatter(
                                kidneyDetails?.viralMarkerStatus?.hcvRnaQuantitativeRemark,
                                kidneyDetails?.viralMarkerStatus?.hcvRnaQuantitativeReportDate
                              )}`,
                              colSpan: 2,
                            },

                        {},
                      ],
                      [
                        {
                          text: `HCV DNA Report Date \n (Positive/Negative/Not Done) `,
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        kidneyDetails?.viralMarkerStatus?.hcvDnaRemark === 'NOTDONE'
                          ? {
                              text: `${_viralMrakerRemarkAndDateFormatter(
                                kidneyDetails?.viralMarkerStatus?.hcvDnaRemark
                              )}`,
                              colSpan: 2,
                            }
                          : {
                              text: `${_viralMrakerRemarkAndDateFormatter(
                                kidneyDetails?.viralMarkerStatus?.hcvDnaRemark,
                                kidneyDetails?.viralMarkerStatus?.hcvDnaReportDate
                              )}`,
                              colSpan: 2,
                            },

                        {},
                      ],
                      [
                        {
                          text: `HIV Report Date \n (Positive/Negative) `,
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${_viralMrakerRemarkAndDateFormatter(
                            kidneyDetails?.viralMarkerStatus?.hivRemark,
                            kidneyDetails?.viralMarkerStatus?.hivReportDate
                          )}`,
                          colSpan: 2,
                        },
                        {},
                      ],

                      [
                        {
                          text: 'Cardiology Fitness Certificate',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${
                            kidneyDetails?.cardiacFitnessCertificate?.name ? 'ATTACHED' : '-'
                          }`,
                          colSpan: 2,
                        },
                        {},
                      ],
                      [
                        {
                          text: 'Pulmonary Fitness Certificate',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${_yesNoAndEmptyFormatter(
                            kidneyDetails?.pulmonaryFitnessCertificateYesNo
                          )}`,
                          colSpan: 2,
                        },
                        {},
                      ],
                      [
                        {
                          text: 'If Yes, Pulmonary Fitness Certificate',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${
                            kidneyDetails?.pulmonaryFitnessCertificate?.name
                              ? 'ATTACHED'
                              : 'NOT ATTACHED'
                          }`,
                          colSpan: 2,
                        },
                        {},
                      ],
                      [
                        {
                          text: 'If No, Details ',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${
                            kidneyDetails?.pulmonaryFitnessCertificateDetails
                              ? kidneyDetails?.pulmonaryFitnessCertificateDetails
                              : '-'
                          }`,
                          colSpan: 2,
                        },
                        {},
                      ],
                      [
                        {
                          text: 'Psychiatry Fitness Certificate',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${
                            kidneyDetails?.psychiatricFitnessCertificate?.name ? 'ATTACHED' : '-'
                          }`,
                          colSpan: 2,
                        },
                        {},
                      ],
                      [
                        {
                          text: 'Urology Fitness Certificate',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${
                            kidneyDetails?.urologyFitnessCertificate?.name ? 'ATTACHED' : '-'
                          }`,
                          colSpan: 2,
                        },
                        {},
                      ],
                      // [
                      //   {
                      //     text: 'Any Other Attachment',
                      //     style: 'tableHeader',
                      //     alignment: 'left',
                      //     colSpan: 1,
                      //   },

                      //   {
                      //     text: `${kidneyDetails?.otherAttachments?.length > 0 ? 'ATTACHED' : '-'}`,
                      //     colSpan: 2,
                      //   },
                      //   {},
                      // ],
                    ],
                  },
                },

                {
                  text: 'Any Other Attachments',
                  style: 'subHeader',
                },
                {
                  style: 'table',
                  table: {
                    headerRows: 1,
                    widths: ['*', '*'],
                    body: [
                      [
                        { text: 'Attachment Name', style: 'tableHeader', alignment: 'left' },
                        { text: 'Report', style: 'tableHeader', alignment: 'left' },
                      ],
                      ...kidneyDetails?.otherAttachments?.map((e) => [
                        {
                          text: `${e.attachmentName ? e.attachmentName : '-'}`,
                          style: 'tableCell',
                          alignment: 'left',
                        },
                        {
                          text: `${e.attachment?.name ? 'ATTACHED' : '-'}`,
                          style: 'tableCell',
                          alignment: 'left',
                        },
                      ]),
                    ],
                  },
                },

                {
                  style: 'table',
                  table: {
                    headerRows: 1,
                    widths: ['*', '*'],
                    body: [
                      [
                        { text: 'Etiology of ESKD', style: 'tableHeader', alignment: 'left' },
                        { text: 'Etiology Report', style: 'tableHeader', alignment: 'left' },
                      ],
                      ...kidneyDetails.etiologies.map((e) => [
                        {
                          text: `${e.etiology ? e.etiology : '-'}`,
                          style: 'tableCell',
                          alignment: 'left',
                        },
                        {
                          text: `${e.attachment?.name ? 'ATTACHED' : '-'}`,
                          style: 'tableCell',
                          alignment: 'left',
                        },
                      ]),
                    ],
                  },
                },

                {
                  style: 'table',
                  table: {
                    widths: [250, 100, 150, 'auto', 'auto'],
                    heights: [
                      'auto',
                      'auto',
                      'auto',
                      'auto',
                      'auto',
                      'auto',
                      'auto',
                      'auto',
                      'auto',
                      'auto',
                      'auto',
                      'auto',
                      // 20,
                      // 20,
                      // 10,
                      'auto',
                      'auto',
                      'auto',
                      'auto',
                      'auto',
                      'auto',
                      'auto',
                      'auto',
                      'auto',
                      'auto',
                      'auto',
                    ],
                    body: [
                      // [
                      //   {
                      //     text: `Etiology of ESKD`,
                      //     style: 'tableHeader',
                      //     alignment: 'left',
                      //     colSpan: 1,
                      //   },
                      //   kidneyDetails.etiologies.map((e) => [
                      //     { text: `${e.etiology ? e.etiology : '-'}` },
                      //   ]),

                      //   {},
                      // ],
                      // [
                      //   {
                      //     text: `Etiology Report`,
                      //     style: 'tableHeader',
                      //     alignment: 'left',
                      //     colSpan: 1,
                      //   },
                      //   kidneyDetails.etiologies.map((et) => [
                      //     {
                      //       text: `${et.attachment?.name ? 'ATTACHED' : '-'}`,
                      //     },
                      //   ]),

                      //   {
                      //     text: '',
                      //     colSpan: 2,
                      //   },
                      //   {},
                      // ],
                      [
                        {
                          text: 'Etiology Details ',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${
                            kidneyDetails?.etiologyDetails ? kidneyDetails?.etiologyDetails : '-'
                          }`,
                          colSpan: 2,
                        },
                        {},
                      ],
                      [
                        {
                          text: 'MKAS Score ',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                          bold: true,
                        },
                        {
                          text: `${
                            basicDetails?.recipientMkasScore
                              ? basicDetails?.recipientMkasScore
                              : '-'
                          }`,
                          colSpan: 2,
                        },
                        {},
                      ],
                      // [
                      //   {
                      //     text: 'Nephrologist',
                      //     style: 'tableHeader',
                      //     alignment: 'left',
                      //     colSpan: 1,
                      //   },

                      //   kidneyDetails?.nephrologistTeam?.map((e) => [
                      //     { text: `${e.user.name || '-'}` },
                      //   ]),

                      //   {},
                      // ],
                      // [
                      //   {
                      //     text: 'Transplant Surgeon',
                      //     style: 'tableHeader',
                      //     alignment: 'left',
                      //     colSpan: 1,
                      //   },

                      //   kidneyDetails?.transplantSurgeon?.map((e) => [
                      //     { text: `${e.user.name || '-'}` },
                      //   ]),

                      //   {},
                      // ],
                    ],
                  },
                },
                {
                  style: 'table',
                  table: {
                    headerRows: 1,
                    widths: ['*', '*'],
                    body: [
                      [
                        { text: 'Nephrologist', style: 'tableHeader', alignment: 'left' },

                        { text: 'Transplant Surgeon', style: 'tableHeader', alignment: 'left' },
                      ],
                      [
                        (kidneyDetails?.nephrologistTeam || [])?.map((e) => [
                          {
                            text: `${e.user.name ? e.user.name : '-'}`,
                            style: 'tableCell',
                            alignment: 'left',
                          },
                        ]),
                        (kidneyDetails?.transplantSurgeon || [])?.map((e) => [
                          {
                            text: `${e.user.name ? e.user.name : '-'}`,
                            style: 'tableCell',
                            alignment: 'left',
                          },
                        ]),
                      ],
                    ],
                  },
                },
              ],
            }
          : null,
        // organs.includes('LIVER') && basicDetails?.waitingStatus === 'ROUTINE' ?  : null
        organs.some((organ) => organ.organType === 'LIVER' && organ.category === 'ROUTINE')
          ? {
              stack: [
                {
                  style: 'table',
                  table: {
                    widths: [250, 100, 150, 'auto', 'auto'],
                    heights: [
                      'auto',
                      'auto',
                      'auto',
                      'auto',
                      'auto',
                      'auto',
                      'auto',
                      'auto',
                      'auto',
                      'auto',
                      'auto',
                      'auto',
                      'auto',
                      'auto',
                    ],
                    body: [
                      [
                        {
                          text: 'State Organ and Tissue Transplant Organisation (SOTTO),\n  Maharashtra',
                          style: 'tableHeader',
                          colSpan: 3,
                          alignment: 'center',
                          bold: true,
                        },
                        {},
                        {},
                      ],
                      [
                        {
                          text: 'State Appropriate Authority of Maharashtra',
                          style: 'textStyle',
                          alignment: 'center',
                          colSpan: 3,
                        },
                        {},
                        {},
                      ],
                      [
                        {
                          text: 'ROUTINE LIVER REGISTRATION',
                          style: 'textStyle',
                          alignment: 'center',
                          colSpan: 3,
                        },
                        {},
                        {},
                      ],

                      [
                        {
                          text: 'MELD',
                          style: 'tableHeader',
                          alignment: 'center',
                          colSpan: 3,
                          bold: true,
                        },
                        {},
                        {},
                      ],
                      [
                        {
                          text: 'Listing Bilirubin (mg/dl)',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },
                        {
                          text: `${meldPeld?.listingBilirubin}`,
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 2,
                        },
                      ],
                      [
                        {
                          text: 'Listing INR',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },
                        {
                          text: `${meldPeld?.listingInr}`,
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 2,
                        },
                      ],
                      [
                        {
                          text: 'Listing Creatinine',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },
                        {
                          text: `${meldPeld?.listingCreatinine}`,
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 2,
                        },
                      ],

                      [
                        {
                          text: 'Listing Albumin (g/dL)',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },
                        {
                          text: `${meldPeld?.listingAlbumin}`,
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 2,
                        },
                      ],
                      [
                        {
                          text: 'Listing Na',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },
                        {
                          text: `${meldPeld?.listingNa}`,
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 2,
                        },
                      ],

                      [
                        {
                          text: 'Growth failure at Listing less than 2 standard deviation',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${_yesNoAndEmptyBooleanFormatter(
                            meldPeld?.listingGrowthFailure ? meldPeld?.listingGrowthFailure : '-'
                          )}`,
                          colSpan: 2,
                        },
                        {},
                      ],
                      [
                        {
                          text: 'PELD',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },
                        {
                          text: `${meldPeld?.listingOptnPeld}`,
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 2,
                        },
                      ],

                      [
                        {
                          text: 'MLAS (A)',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },
                        {
                          text: `${meldPeld?.meld}`,
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 2,
                        },
                      ],
                      [
                        {
                          text: 'Viral Marker Status',
                          style: 'tableHeader',
                          alignment: 'center',
                          colSpan: 3,
                          bold: true,
                        },
                        {},
                        {},
                      ],

                      [
                        {
                          text: 'HBsAg',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${routineInfo?.viralMarkerStatus?.hbsagRemark}`,
                          colSpan: 2,
                        },
                        {},
                      ],

                      [
                        {
                          text: `HBsAg Report Date: `,
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${
                            routineInfo?.viralMarkerStatus?.hbsagReportDate
                              ? moment(
                                  routineInfo?.viralMarkerStatus?.hbsagReportDate,
                                  FORMAT_DATE_UNIXTS
                                ).format('DD/MM/YYYY')
                              : '-'
                          }`,
                          colSpan: 2,
                        },
                        {},
                      ],

                      [
                        {
                          text: `HBsAg Report`,
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${
                            routineInfo?.viralMarkerStatus?.hbsagReport?.name ? 'ATTACHED' : '-'
                          }`,
                          colSpan: 2,
                        },
                        {},
                      ],

                      [
                        {
                          text: 'Anti HCV',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${routineInfo?.viralMarkerStatus?.antiHcvRemark}`,
                          colSpan: 2,
                        },
                        {},
                      ],

                      [
                        {
                          text: `Anti HCV Report Date `,
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${
                            routineInfo?.viralMarkerStatus?.antiHcvReportDate
                              ? moment(
                                  routineInfo?.viralMarkerStatus?.antiHcvReportDate,
                                  FORMAT_DATE_UNIXTS
                                ).format('DD/MM/YYYY')
                              : '-'
                          }`,
                          colSpan: 2,
                        },
                        {},
                      ],

                      [
                        {
                          text: `Anti HCV Report`,
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${
                            routineInfo?.viralMarkerStatus?.antiHcvReport?.name ? 'ATTACHED' : '-'
                          }`,
                          colSpan: 2,
                        },
                        {},
                      ],

                      [
                        {
                          text: 'HCV RNA Quantitative',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${
                            routineInfo?.viralMarkerStatus?.hcvRnaQuantitativeRemark
                              ? routineInfo?.viralMarkerStatus?.hcvRnaQuantitativeRemark
                              : '-'
                          }`,
                          colSpan: 2,
                        },
                        {},
                      ],

                      [
                        {
                          text: `HCV RNA Quantitative Report Date: `,
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },
                        routineInfo?.viralMarkerStatus?.hcvRnaQuantitativeRemark === 'NOTDONE'
                          ? {
                              text: `-`,
                              colSpan: 2,
                            }
                          : {
                              text: `${
                                routineInfo?.viralMarkerStatus?.hcvRnaQuantitativeReportDate
                                  ? moment(
                                      routineInfo?.viralMarkerStatus?.hcvRnaQuantitativeReportDate,
                                      FORMAT_DATE_UNIXTS
                                    ).format('DD/MM/YYYY')
                                  : '-'
                              }`,
                              colSpan: 2,
                            },
                        {},
                      ],

                      [
                        {
                          text: `HCV RNA Quantitative Report`,
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        routineInfo?.viralMarkerStatus?.hcvRnaQuantitativeRemark === 'NOTDONE'
                          ? {
                              text: `-`,
                              colSpan: 2,
                            }
                          : {
                              text: `${
                                routineInfo?.viralMarkerStatus?.hcvRnaQuantitativeReport?.name
                                  ? 'ATTACHED'
                                  : '-'
                              }`,
                              colSpan: 2,
                            },
                        {},
                      ],
                      [
                        {
                          text: 'HCV DNA',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${
                            routineInfo?.viralMarkerStatus?.hcvDnaRemark
                              ? routineInfo?.viralMarkerStatus?.hcvDnaRemark
                              : '-'
                          }`,
                          colSpan: 2,
                        },
                        {},
                      ],

                      [
                        {
                          text: `HCV DNA Report Date: `,
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },
                        routineInfo?.viralMarkerStatus?.hcvDnaRemark === 'NOTDONE'
                          ? {
                              text: `-`,
                              colSpan: 2,
                            }
                          : {
                              text: `${
                                routineInfo?.viralMarkerStatus?.hcvDnaReportDate
                                  ? moment(
                                      routineInfo?.viralMarkerStatus?.hcvDnaReportDate,
                                      FORMAT_DATE_UNIXTS
                                    ).format('DD/MM/YYYY')
                                  : '-'
                              }`,
                              colSpan: 2,
                            },
                        {},
                      ],

                      [
                        {
                          text: `HCV DNA Report`,
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },
                        routineInfo?.viralMarkerStatus?.hcvDnaRemark === 'NOTDONE'
                          ? {
                              text: `-`,
                              colSpan: 2,
                            }
                          : {
                              text: `${
                                routineInfo?.viralMarkerStatus?.hcvDnaReport?.name
                                  ? 'ATTACHED'
                                  : '-'
                              }`,
                              colSpan: 2,
                            },
                        {},
                      ],

                      [
                        {
                          text: 'HIV',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${routineInfo?.viralMarkerStatus?.hivRemark}`,
                          colSpan: 2,
                        },
                        {},
                      ],

                      [
                        {
                          text: `HIV Report Date: `,
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${
                            routineInfo?.viralMarkerStatus?.hivReportDate
                              ? moment(
                                  routineInfo?.viralMarkerStatus?.hivReportDate,
                                  FORMAT_DATE_UNIXTS
                                ).format('DD/MM/YYYY')
                              : '-'
                          }`,
                          colSpan: 2,
                        },
                        {},
                      ],

                      [
                        {
                          text: `HIV Report`,
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${
                            routineInfo?.viralMarkerStatus?.hivReport?.name ? 'ATTACHED' : '-'
                          }`,
                          colSpan: 2,
                        },
                        {},
                      ],
                    ],
                  },
                },
                //       [
                //         {
                //           text: `Report Type `,
                //           style: 'tableHeader',
                //           alignment: 'left',
                //           colSpan: 1,
                //         },

                //         meldPeld.reports.map((e) => [{ text: `${e.reportType ? e.reportType : '-'}` }]),

                //         {},
                //       ],

                //       [
                //         {
                //           text: `Date Of Report `,
                //           style: 'tableHeader',
                //           alignment: 'left',
                //           colSpan: 1,
                //         },
                //         meldPeld.reports.map((e) => [
                //           {
                //             text: `${moment(e.dateOfReport, FORMAT_DATE_UNIXTS).format('DD/MM/YYYY')}`,
                //           },
                //         ]),

                //         {},
                //       ],

                //       [
                //         {
                //           text: `Lab Report`,
                //           style: 'tableHeader',
                //           alignment: 'left',
                //           colSpan: 1,
                //         },
                //         meldPeld.reports.map((e) => [
                //           {
                //             text: `${e.attachment.name}`,
                //           },
                //         ]),
                //         {},
                //       ],
                //       [
                //         {
                //           text: `Syndromes `,
                //           style: 'tableHeader',
                //           alignment: 'left',
                //           colSpan: 1,
                //         },

                //         meldPeld.syndromes.map((e) => [{ text: `${e.syndrome ? e.syndrome : '-'}` }]),

                //         {},
                //       ],
                //       [
                //         {
                //           text: `Syndrome Report`,
                //           style: 'tableHeader',
                //           alignment: 'left',
                //           colSpan: 1,
                //         },
                //         meldPeld.syndromes.map((e) => [
                //           {
                //             text: `${e.attachment.name}`,
                //           },
                //         ]),
                //         {},
                //       ],

                //       [
                //         {
                //           text: `Etiologies `,
                //           style: 'tableHeader',
                //           alignment: 'left',
                //           colSpan: 1,
                //         },

                //         meldPeld.etiologies.map((e) => [{ text: `${e.etiology ? e.etiology : '-'}` }]),

                //         {},
                //       ],
                //       [
                //         {
                //           text: `Etiology Report`,
                //           style: 'tableHeader',
                //           alignment: 'left',
                //           colSpan: 1,
                //         },
                //         meldPeld.etiologies.map((e) => [
                //           {
                //             text: `${e.attachment.name}`,
                //           },
                //         ]),
                //         {},
                //       ],
                //       [
                //         {
                //           text: 'Complication Score',
                //           style: 'tableHeader',
                //           alignment: 'center',
                //           colSpan: 3,
                //           bold: true,
                //         },
                //         {},
                //         {},
                //       ],
                //       [
                //         {
                //           text: 'Does the patient have Ascites?',
                //           style: 'tableHeader',
                //           alignment: 'left',
                //           colSpan: 3,
                //           bold: true,
                //         },
                //         {},
                //         {},
                //       ],
                //       [
                //         {
                //           text: 'Does the patient have moderate to severe Refractory ascites?',
                //           style: 'tableHeader',
                //           alignment: 'left',
                //           colSpan: 1,
                //         },

                //         {
                //           text: `${_yesNoAndEmptyBooleanFormatter(
                //             complicationScore?.ascites?.refractoryAscitesYesNo
                //           )}`,
                //           colSpan: 2,
                //         },
                //         {},
                //       ],
                //       [
                //         {
                //           text: 'DRefractory ascites (≥ 2 LVPs (≥ 5L) / month)',
                //           style: 'tableHeader',
                //           alignment: 'left',
                //           colSpan: 1,
                //         },

                //         {
                //           text: `${_yesNoAndEmptyBooleanFormatter(
                //             complicationScore?.hepaticHydrothoraxModToSevere?.refractoryAscites
                //           )}`,
                //           colSpan: 2,
                //         },
                //         {},
                //       ],
                //       [
                //         {
                //           text: 'Refractory ascites (diuretic resistance)',
                //           style: 'tableHeader',
                //           alignment: 'left',
                //           colSpan: 1,
                //         },

                //         {
                //           text: `${_yesNoAndEmptyBooleanFormatter(
                //             complicationScore?.hepaticHydrothoraxModToSevere?.refractoryAscitesDiuretic
                //           )}`,
                //           colSpan: 2,
                //         },
                //         {},
                //       ],
                //       [
                //         {
                //           text: 'Refractory ascites (needing noradrenaline / midodrine / terlipressin)',
                //           style: 'tableHeader',
                //           alignment: 'left',
                //           colSpan: 1,
                //         },

                //         {
                //           text: `${_yesNoAndEmptyBooleanFormatter(
                //             complicationScore?.hepaticHydrothoraxModToSevere
                //               ?.refractoryAscitesNoradrenaline
                //           )}`,
                //           colSpan: 2,
                //         },
                //         {},
                //       ],
                //       [
                //         {
                //           text: `Attachment`,
                //           style: 'tableHeader',
                //           alignment: 'left',
                //           colSpan: 1,
                //         },

                //         {
                //           text: `${
                //             complicationScore?.ascites?.refractoryAscitesAttachment?.name
                //               ? 'ATTACHED'
                //               : '-'
                //           }`,
                //           colSpan: 2,
                //         },
                //         {},
                //       ],
                //       [
                //         {
                //           text: 'Does the patient have moderate to severe Hepatic Hydrothorax',
                //           style: 'tableHeader',
                //           alignment: 'left',
                //           colSpan: 1,
                //         },

                //         {
                //           text: `${_yesNoAndEmptyBooleanFormatter(
                //             complicationScore?.ascites?.hepaticHydrothoraxYesNo
                //           )}`,
                //           colSpan: 2,
                //         },
                //         {},
                //       ],
                //       [
                //         {
                //           text: 'Hepatic Hydrothorax (moderate to severe on HRCT/Chest Xray/USG Thorax)',
                //           style: 'tableHeader',
                //           alignment: 'left',
                //           colSpan: 1,
                //         },

                //         {
                //           text: `${_yesNoAndEmptyBooleanFormatter(
                //             complicationScore?.hepaticHydrothoraxModToSevere
                //               ?.hepaticHydrothoraxModerateOnHrctChestXrayUsgThorax
                //           )}`,
                //           colSpan: 2,
                //         },
                //         {},
                //       ],
                //       [
                //         {
                //           text: 'Hepatic hydrothorax (≥ 2 therapeutic taps)',
                //           style: 'tableHeader',
                //           alignment: 'left',
                //           colSpan: 1,
                //         },

                //         {
                //           text: `${_yesNoAndEmptyBooleanFormatter(
                //             complicationScore?.hepaticHydrothoraxModToSevere
                //               ?.hepaticHydrothoraxTherapeuticTaps
                //           )}`,
                //           colSpan: 2,
                //         },
                //         {},
                //       ],
                //       [
                //         {
                //           text: `Attachment`,
                //           style: 'tableHeader',
                //           alignment: 'left',
                //           colSpan: 1,
                //         },

                //         {
                //           text: `${
                //             complicationScore?.ascites?.hepaticHydrothoraxAttachment?.name
                //               ? 'ATTACHED'
                //               : '-'
                //           }`,
                //           colSpan: 2,
                //         },
                //         {},
                //       ],

                //       [
                //         {
                //           text: 'Does the patient have Spontaneous Bacterial Peritonitis (SBP)?)',
                //           style: 'tableHeader',
                //           alignment: 'left',
                //           colSpan: 1,
                //         },

                //         {
                //           text: `${_yesNoAndEmptyBooleanFormatter(
                //             complicationScore?.hepaticHydrothoraxModToSevere
                //               ?.hepaticHydrothoraxTherapeuticTaps
                //           )}`,
                //           colSpan: 2,
                //         },
                //         {},
                //       ],
                //       [
                //         {
                //           text: 'Ascities Fluid Absolute Neutrophil count',
                //           style: 'tableHeader',
                //           alignment: 'left',
                //           colSpan: 1,
                //         },
                //         {
                //           text: `${complicationScore?.sbp?.sbpAnc}`,
                //           style: 'tableHeader',
                //           alignment: 'left',
                //           colSpan: 2,
                //         },
                //       ],
                //       [
                //         {
                //           text: `SBP Report`,
                //           style: 'tableHeader',
                //           alignment: 'left',
                //           colSpan: 1,
                //         },

                //         {
                //           text: `${complicationScore?.sbp?.sbpAncAttachment?.name ? 'ATTACHED' : '-'}`,
                //           colSpan: 2,
                //         },
                //         {},
                //       ],
                //       [
                //         {
                //           text: `SBP Reports Date `,
                //           style: 'tableHeader',
                //           alignment: 'left',
                //           colSpan: 1,
                //         },

                //         {
                //           text: `${
                //             complicationScore?.sbp?.sbpAncAttachmentDate
                //               ? moment(
                //                   complicationScore?.sbp?.sbpAncAttachmentDate,
                //                   FORMAT_DATE_UNIXTS
                //                 ).format('DD/MM/YYYY')
                //               : '-'
                //           }`,
                //           colSpan: 2,
                //         },
                //         {},
                //       ],
                //       [
                //         {
                //           text: ' Eligible for SBP points',
                //           style: 'tableHeader',
                //           alignment: 'left',
                //           colSpan: 1,
                //         },
                //         {
                //           text: `${complicationScore?.sbp?.spbPointsEligible}`,
                //           style: 'tableHeader',
                //           alignment: 'left',
                //           colSpan: 2,
                //         },
                //       ],
                //       [
                //         {
                //           text: 'SBP points',
                //           style: 'tableHeader',
                //           alignment: 'left',
                //           colSpan: 1,
                //         },
                //         {
                //           text: `${complicationScore?.sbp?.spbPoints}`,
                //           style: 'tableHeader',
                //           alignment: 'left',
                //           colSpan: 2,
                //         },
                //       ],
                //       [
                //         {
                //           text: 'Does the patient have HRS?',
                //           style: 'tableHeader',
                //           alignment: 'left',
                //           colSpan: 1,
                //         },

                //         {
                //           text: `${_yesNoAndEmptyBooleanFormatter(
                //             complicationScore?.ascites?.hrsYesNo
                //           )}`,
                //           colSpan: 2,
                //         },
                //         {},
                //       ],
                //       [
                //         {
                //           text: `Attachment`,
                //           style: 'tableHeader',
                //           alignment: 'left',
                //           colSpan: 1,
                //         },

                //         {
                //           text: `${complicationScore?.ascites?.hrsAttachment?.name ? 'ATTACHED' : '-'}`,
                //           colSpan: 2,
                //         },
                //         {},
                //       ],
                //       [
                //         {
                //           text: 'Does the patient have Hepatic Encephalopathy (HE) needing ICU?',
                //           style: 'tableHeader',
                //           alignment: 'left',
                //           colSpan: 3,
                //           bold: true,
                //         },
                //         {},
                //         {},
                //       ],
                //       [
                //         {
                //           text: 'HE (Past / Current) (≥ grade 3 requiring hospitalization (ICU))',
                //           style: 'tableHeader',
                //           alignment: 'left',
                //           colSpan: 1,
                //         },

                //         {
                //           text: `${_yesNoAndEmptyBooleanFormatter(
                //             complicationScore?.heNeedingHIcu?.heNeedingGreaterThanEquals3
                //           )}`,
                //           colSpan: 2,
                //         },
                //         {},
                //       ],
                //       [
                //         {
                //           text: 'Structural neurological diseases',
                //           style: 'tableHeader',
                //           alignment: 'left',
                //           colSpan: 1,
                //         },

                //         {
                //           text: `${_yesNoAndEmptyBooleanFormatter(
                //             complicationScore?.heNeedingHIcu?.structuralNeurologicalDisease
                //           )}`,
                //           colSpan: 2,
                //         },
                //         {},
                //       ],
                //       [
                //         {
                //           text: `Attachment`,
                //           style: 'tableHeader',
                //           alignment: 'left',
                //           colSpan: 1,
                //         },

                //         {
                //           text: `${
                //             complicationScore?.heNeedingHIcu?.heNeedingHIcuAttachment?.name
                //               ? 'ATTACHED'
                //               : '-'
                //           }`,
                //           colSpan: 2,
                //         },
                //         {},
                //       ],
                //       [
                //         {
                //           text: ' Eligible for HE points',
                //           style: 'tableHeader',
                //           alignment: 'left',
                //           colSpan: 1,
                //         },
                //         {
                //           text: `${complicationScore?.heNeedingHIcu?.hePointsEligible}`,
                //           style: 'tableHeader',
                //           alignment: 'left',
                //           colSpan: 2,
                //         },
                //       ],
                //       [
                //         {
                //           text: 'HE points',
                //           style: 'tableHeader',
                //           alignment: 'left',
                //           colSpan: 1,
                //         },
                //         {
                //           text: `${complicationScore?.heNeedingHIcu?.hePoints}`,
                //           style: 'tableHeader',
                //           alignment: 'left',
                //           colSpan: 2,
                //         },
                //       ],

                //       [
                //         {
                //           text: 'Does the patient have Variceal Bleed?',
                //           style: 'tableHeader',
                //           alignment: 'left',
                //           colSpan: 3,
                //           bold: true,
                //         },
                //         {},
                //         {},
                //       ],
                //       [
                //         {
                //           text: 'Recurrent variceal bleed (not amenable to endotherapy and / or TIPSS)',
                //           style: 'tableHeader',
                //           alignment: 'left',
                //           colSpan: 1,
                //         },

                //         {
                //           text: `${_yesNoAndEmptyBooleanFormatter(
                //             complicationScore?.varicealBleedNoEndo?.recurrentVaricealBleedNotAmenable
                //           )}`,
                //           colSpan: 2,
                //         },
                //         {},
                //       ],

                //       [
                //         {
                //           text: `Attachment`,
                //           style: 'tableHeader',
                //           alignment: 'left',
                //           colSpan: 1,
                //         },

                //         {
                //           text: `${
                //             complicationScore?.varicealBleedNoEndo?.varicealBleedNoEndoAttachment?.name
                //               ? 'ATTACHED'
                //               : '-'
                //           }`,
                //           colSpan: 2,
                //         },
                //         {},
                //       ],
                //       [
                //         {
                //           text: ' Eligible for Recurrent variceal bleed points',
                //           style: 'tableHeader',
                //           alignment: 'left',
                //           colSpan: 1,
                //         },
                //         {
                //           text: `${complicationScore?.varicealBleedNoEndo?.recurrentVaricealEligible}`,
                //           style: 'tableHeader',
                //           alignment: 'left',
                //           colSpan: 2,
                //         },
                //       ],
                //       [
                //         {
                //           text: 'Recurrent variceal bleed points',
                //           style: 'tableHeader',
                //           alignment: 'left',
                //           colSpan: 1,
                //         },
                //         {
                //           text: `${complicationScore?.varicealBleedNoEndo?.recurrentVaricealPoints}`,
                //           style: 'tableHeader',
                //           alignment: 'left',
                //           colSpan: 2,
                //         },
                //       ],
                //       [
                //         {
                //           text: 'Does the patient have Jaudice?',
                //           style: 'tableHeader',
                //           alignment: 'left',
                //           colSpan: 3,
                //           bold: true,
                //         },
                //         {},
                //         {},
                //       ],
                //       [
                //         {
                //           text: `Attachment`,
                //           style: 'tableHeader',
                //           alignment: 'left',
                //           colSpan: 1,
                //         },

                //         {
                //           text: `${complicationScore?.jaundiceAttachment?.name ? 'ATTACHED' : '-'}`,
                //           colSpan: 2,
                //         },
                //         {},
                //       ],
                //       [
                //         {
                //           text: 'Does the patient have PVT?',
                //           style: 'tableHeader',
                //           alignment: 'left',
                //           colSpan: 3,
                //           bold: true,
                //         },
                //         {},
                //         {},
                //       ],
                //       [
                //         {
                //           text: `Attachment`,
                //           style: 'tableHeader',
                //           alignment: 'left',
                //           colSpan: 1,
                //         },

                //         {
                //           text: `${complicationScore?.pvtAttachment?.name ? 'ATTACHED' : '-'}`,
                //           colSpan: 2,
                //         },
                //         {},
                //       ],
                //       [
                //         {
                //           text: 'MELD Exception Criteria',
                //           style: 'tableHeader',
                //           alignment: 'center',
                //           colSpan: 3,
                //           bold: true,
                //         },
                //         {},
                //         {},
                //       ],
                //       [
                //         {
                //           text: 'Does the patient have Hepatopulmonary Syndrome (HPS)?',
                //           style: 'tableHeader',
                //           alignment: 'left',
                //           colSpan: 3,
                //           bold: true,
                //         },
                //         {},
                //         {},
                //       ],
                //       [
                //         {
                //           text: `Attachment`,
                //           style: 'tableHeader',
                //           alignment: 'left',
                //           colSpan: 1,
                //         },

                //         {
                //           text: `${
                //             meldExceptionCriteria?.hps?.hpsYesNoAttachment?.name ? 'ATTACHED' : '-'
                //           }`,
                //           colSpan: 2,
                //         },
                //         {},
                //       ],
                //       [
                //         {
                //           text: 'PaO2 less than 60 mmHg on room air',
                //           style: 'tableHeader',
                //           alignment: 'left',
                //           colSpan: 1,
                //         },

                //         {
                //           text: `${_yesNoAndEmptyBooleanFormatter(
                //             meldExceptionCriteria?.hps?.hpsPao2
                //           )}`,
                //           colSpan: 2,
                //         },
                //         {},
                //       ],
                //       [
                //         {
                //           text: 'Alveolar arterial oxygen gradient greater 15 mmHg /greater 20 mmHg if greater than 64 yrs age',
                //           style: 'tableHeader',
                //           alignment: 'left',
                //           colSpan: 1,
                //         },

                //         {
                //           text: `${_yesNoAndEmptyBooleanFormatter(
                //             meldExceptionCriteria?.hps?.hpsAlveolarGradient
                //           )}`,
                //           colSpan: 2,
                //         },
                //         {},
                //       ],
                //       [
                //         {
                //           text: 'Bubble ECHO / MAA scan positive for Shunt',
                //           style: 'tableHeader',
                //           alignment: 'left',
                //           colSpan: 1,
                //         },

                //         {
                //           text: `${_yesNoAndEmptyBooleanFormatter(
                //             meldExceptionCriteria?.hps?.hpsShunt
                //           )}`,
                //           colSpan: 2,
                //         },
                //         {},
                //       ],
                //       [
                //         {
                //           text: 'Clinical e/o liver disease and portal hypertension',
                //           style: 'tableHeader',
                //           alignment: 'left',
                //           colSpan: 1,
                //         },

                //         {
                //           text: `${_yesNoAndEmptyBooleanFormatter(
                //             meldExceptionCriteria?.hps?.hpsLiverDiseaseAndHyperTension
                //           )}`,
                //           colSpan: 2,
                //         },
                //         {},
                //       ],
                //       [
                //         {
                //           text: 'E/O underlying primary pulmonary disease',
                //           style: 'tableHeader',
                //           alignment: 'left',
                //           colSpan: 1,
                //         },

                //         {
                //           text: `${_yesNoAndEmptyBooleanFormatter(
                //             meldExceptionCriteria?.hps?.hpsPulmonaryDisease
                //           )}`,
                //           colSpan: 2,
                //         },
                //         {},
                //       ],
                //       [
                //         {
                //           text: ' Eligible for HPS exception point',
                //           style: 'tableHeader',
                //           alignment: 'left',
                //           colSpan: 1,
                //         },
                //         {
                //           text: `${meldExceptionCriteria?.hps?.eligibleForHpsExceptionPoints}`,
                //           style: 'tableHeader',
                //           alignment: 'left',
                //           colSpan: 2,
                //         },
                //       ],
                //       [
                //         {
                //           text: 'HPS exception points',
                //           style: 'tableHeader',
                //           alignment: 'left',
                //           colSpan: 1,
                //         },
                //         {
                //           text: `${meldExceptionCriteria?.hps?.hpsExceptionPoints}`,
                //           style: 'tableHeader',
                //           alignment: 'left',
                //           colSpan: 2,
                //         },
                //       ],
                //       [
                //         {
                //           text: 'Does the patient have Pulmonary Arterial Hypertension (PAH)?',
                //           style: 'tableHeader',
                //           alignment: 'left',
                //           colSpan: 3,
                //           bold: true,
                //         },
                //         {},
                //         {},
                //       ],
                //       [
                //         {
                //           text: `Attachment`,
                //           style: 'tableHeader',
                //           alignment: 'left',
                //           colSpan: 1,
                //         },

                //         {
                //           text: `${
                //             meldExceptionCriteria?.pah?.pahYesNoAttachment?.name ? 'ATTACHED' : '-'
                //           }`,
                //           colSpan: 2,
                //         },
                //         {},
                //       ],
                //       [
                //         {
                //           text: 'Post-treatment Mean pulmonary arterial pressure (MPAP) 25 to 35 mmHg',
                //           style: 'tableHeader',
                //           alignment: 'left',
                //           colSpan: 1,
                //         },

                //         {
                //           text: `${_yesNoAndEmptyBooleanFormatter(
                //             meldExceptionCriteria?.pah?.pahPostTreatmentMeanPulmonaryArterialPressure
                //           )}`,
                //           colSpan: 2,
                //         },
                //         {},
                //       ],
                //       [
                //         {
                //           text: 'Post-treatment pulmonary vascular resistance (PVR) 120 to 400 dynes/sec/cm',
                //           style: 'tableHeader',
                //           alignment: 'left',
                //           colSpan: 1,
                //         },

                //         {
                //           text: `${_yesNoAndEmptyBooleanFormatter(
                //             meldExceptionCriteria?.pah?.pahPostTreatmentPulmonaryVascularResistance
                //           )}`,
                //           colSpan: 2,
                //         },
                //         {},
                //       ],
                //       [
                //         {
                //           text: 'Pulmonary Capillary Wedge Pressure (PCWP) less than 15 mmg',
                //           style: 'tableHeader',
                //           alignment: 'left',
                //           colSpan: 1,
                //         },

                //         {
                //           text: `${_yesNoAndEmptyBooleanFormatter(
                //             meldExceptionCriteria?.pah?.pahPulmonaryCapillaryWedgePressure
                //           )}`,
                //           colSpan: 2,
                //         },
                //         {},
                //       ],
                //       [
                //         {
                //           text: `Right Heart Catheterization Reports`,
                //           style: 'tableHeader',
                //           alignment: 'left',
                //           colSpan: 1,
                //         },

                //         {
                //           text: `${
                //             meldExceptionCriteria?.pah?.pahHeartCathReport?.name ? 'ATTACHED' : '-'
                //           }`,
                //           colSpan: 2,
                //         },
                //         {},
                //       ],

                //       [
                //         {
                //           text: `Heart Cath Report Date `,
                //           style: 'tableHeader',
                //           alignment: 'left',
                //           colSpan: 1,
                //         },

                //         {
                //           text: `${
                //             meldExceptionCriteria?.pah?.pahHeartCathReportDate
                //               ? moment(
                //                   meldExceptionCriteria?.pah?.pahHeartCathReportDate,
                //                   FORMAT_DATE_UNIXTS
                //                 ).format('DD/MM/YYYY')
                //               : '-'
                //           }`,
                //           colSpan: 2,
                //         },
                //         {},
                //       ],
                //       [
                //         {
                //           text: ' Eligible for PAH exception points',
                //           style: 'tableHeader',
                //           alignment: 'left',
                //           colSpan: 1,
                //         },
                //         {
                //           text: `${meldExceptionCriteria?.pah?.eligibleForPahExceptionPoints}`,
                //           style: 'tableHeader',
                //           alignment: 'left',
                //           colSpan: 2,
                //         },
                //       ],
                //       [
                //         {
                //           text: 'PAH exception points',
                //           style: 'tableHeader',
                //           alignment: 'left',
                //           colSpan: 1,
                //         },
                //         {
                //           text: `${meldExceptionCriteria?.pah?.pahExceptionPoints}`,
                //           style: 'tableHeader',
                //           alignment: 'left',
                //           colSpan: 2,
                //         },
                //       ],

                //       [
                //         {
                //           text: 'Does the patient have Hepatocellular Carcinoma (HCC)?',
                //           style: 'tableHeader',
                //           alignment: 'left',
                //           colSpan: 3,
                //           bold: true,
                //         },
                //         {},
                //         {},
                //       ],
                //       [
                //         {
                //           text: `Attachment`,
                //           style: 'tableHeader',
                //           alignment: 'left',
                //           colSpan: 1,
                //         },

                //         {
                //           text: `${
                //             meldExceptionCriteria?.hcc?.hccYesNoAttachment?.name ? 'ATTACHED' : '-'
                //           }`,
                //           colSpan: 2,
                //         },
                //         {},
                //       ],
                //       [
                //         {
                //           text: 'Single tumor less than equals 6.5cm',
                //           style: 'tableHeader',
                //           alignment: 'left',
                //           colSpan: 1,
                //         },

                //         {
                //           text: `${_yesNoAndEmptyBooleanFormatter(
                //             meldExceptionCriteria?.hcc?.hccSingleTumorLessThan6AndHalfCm
                //           )}`,
                //           colSpan: 2,
                //         },
                //         {},
                //       ],
                //       [
                //         {
                //           text: 'less than equals 3 tumors with largest tumor less than equals 4.5cm',
                //           style: 'tableHeader',
                //           alignment: 'left',
                //           colSpan: 1,
                //         },

                //         {
                //           text: `${_yesNoAndEmptyBooleanFormatter(
                //             meldExceptionCriteria?.hcc?.hccLessThan3TumorWithLargestLessThan4AndHalfcm
                //           )}`,
                //           colSpan: 2,
                //         },
                //         {},
                //       ],
                //       [
                //         {
                //           text: 'less than equals 3 tumors with total tumor diameter less than equals 8cm',
                //           style: 'tableHeader',
                //           alignment: 'left',
                //           colSpan: 1,
                //         },

                //         {
                //           text: `${_yesNoAndEmptyBooleanFormatter(
                //             meldExceptionCriteria?.hcc?.hccLessThan3TumorDiameterLessThan8cm
                //           )}`,
                //           colSpan: 2,
                //         },
                //         {},
                //       ],
                //       [
                //         {
                //           text: ' Within UCSF criteria',
                //           style: 'tableHeader',
                //           alignment: 'left',
                //           colSpan: 1,
                //         },
                //         {
                //           text: `${meldExceptionCriteria?.hcc?.hccWithinUcsfCriteria}`,
                //           style: 'tableHeader',
                //           alignment: 'left',
                //           colSpan: 2,
                //         },
                //       ],
                //       [
                //         {
                //           text: 'Largest tumor size (cm)',
                //           style: 'tableHeader',
                //           alignment: 'left',
                //           colSpan: 1,
                //         },
                //         {
                //           text: `${meldExceptionCriteria?.hcc?.hccLargestTumorSize}`,
                //           style: 'tableHeader',
                //           alignment: 'left',
                //           colSpan: 2,
                //         },
                //       ],
                //       [
                //         {
                //           text: 'Active portal vein tumor thrombus (PVTT) (bland PVT is acceptable)',
                //           style: 'tableHeader',
                //           alignment: 'left',
                //           colSpan: 1,
                //         },

                //         {
                //           text: `${_yesNoAndEmptyBooleanFormatter(
                //             meldExceptionCriteria?.hcc?.hccActivePvtt
                //           )}`,
                //           colSpan: 2,
                //         },
                //         {},
                //       ],
                //       [
                //         {
                //           text: 'Extrahepatic disease',
                //           style: 'tableHeader',
                //           alignment: 'left',
                //           colSpan: 1,
                //         },

                //         {
                //           text: `${_yesNoAndEmptyBooleanFormatter(
                //             meldExceptionCriteria?.hcc?.hccExtrahepaticDisease
                //           )}`,
                //           colSpan: 2,
                //         },
                //         {},
                //       ],
                //       [
                //         {
                //           text: 'At least one lesion should be greater than 2 cms',
                //           style: 'tableHeader',
                //           alignment: 'left',
                //           colSpan: 1,
                //         },

                //         {
                //           text: `${_yesNoAndEmptyBooleanFormatter(
                //             meldExceptionCriteria?.hcc?.hccAtleastOneLesionGreaterThan2cm
                //           )}`,
                //           colSpan: 2,
                //         },
                //         {},
                //       ],
                //       [
                //         {
                //           text: 'AFP value',
                //           style: 'tableHeader',
                //           alignment: 'left',
                //           colSpan: 1,
                //         },
                //         {
                //           text: `${meldExceptionCriteria?.hcc?.hccAfp}`,
                //           style: 'tableHeader',
                //           alignment: 'left',
                //           colSpan: 2,
                //         },
                //       ],
                //       [
                //         {
                //           text: `AFP Report`,
                //           style: 'tableHeader',
                //           alignment: 'left',
                //           colSpan: 1,
                //         },

                //         {
                //           text: `${meldExceptionCriteria?.hcc?.hccAfpReports?.name ? 'ATTACHED' : '-'}`,
                //           colSpan: 2,
                //         },
                //         {},
                //       ],

                //       [
                //         {
                //           text: `AFP Report Date`,
                //           style: 'tableHeader',
                //           alignment: 'left',
                //           colSpan: 1,
                //         },

                //         {
                //           text: `${
                //             meldExceptionCriteria?.hcc?.hccAfpReportsDate
                //               ? moment(
                //                   meldExceptionCriteria?.hcc?.hccAfpReportsDate,
                //                   FORMAT_DATE_UNIXTS
                //                 ).format('DD/MM/YYYY')
                //               : '-'
                //           }`,
                //           colSpan: 2,
                //         },
                //         {},
                //       ],
                //       [
                //         {
                //           text: `Date of last Triphasic CT / MRI`,
                //           style: 'tableHeader',
                //           alignment: 'left',
                //           colSpan: 1,
                //         },

                //         {
                //           text: `${
                //             meldExceptionCriteria?.hcc?.hccDateOfLastTriphasicCt
                //               ? moment(
                //                   meldExceptionCriteria?.hcc?.hccDateOfLastTriphasicCt,
                //                   FORMAT_DATE_UNIXTS
                //                 ).format('DD/MM/YYYY')
                //               : '-'
                //           }`,
                //           colSpan: 2,
                //         },
                //         {},
                //       ],
                //       [
                //         {
                //           text: 'Duration from last Triphasic CT/ MRI',
                //           style: 'tableHeader',
                //           alignment: 'left',
                //           colSpan: 1,
                //         },

                //         {
                //           text: `${meldExceptionCriteria?.hcc?.hccDurationFromLastTriphasicCt}`,
                //           colSpan: 2,
                //         },
                //         {},
                //       ],

                //       [
                //         {
                //           text: `Triphasic CT Scan`,
                //           style: 'tableHeader',
                //           alignment: 'left',
                //           colSpan: 1,
                //         },

                //         {
                //           text: `${
                //             meldExceptionCriteria?.hcc?.hccTriphasisCtScan?.name ? 'ATTACHED' : '-'
                //           }`,
                //           colSpan: 2,
                //         },
                //         {},
                //       ],

                //       [
                //         {
                //           text: `Date of last bone scan`,
                //           style: 'tableHeader',
                //           alignment: 'left',
                //           colSpan: 1,
                //         },

                //         {
                //           text: `${
                //             meldExceptionCriteria?.hcc?.hccDateOfLastBoneScan
                //               ? moment(
                //                   meldExceptionCriteria?.hcc?.hccDateOfLastBoneScan,
                //                   FORMAT_DATE_UNIXTS
                //                 ).format('DD/MM/YYYY')
                //               : '-'
                //           }`,
                //           colSpan: 2,
                //         },
                //         {},
                //       ],
                //       [
                //         {
                //           text: 'Duration from last bone scan',
                //           style: 'tableHeader',
                //           alignment: 'left',
                //           colSpan: 1,
                //         },

                //         {
                //           text: `${meldExceptionCriteria?.hcc?.hccDurationFromlastBoneScan}`,
                //           colSpan: 2,
                //         },
                //         {},
                //       ],

                //       [
                //         {
                //           text: `Bone Scan`,
                //           style: 'tableHeader',
                //           alignment: 'left',
                //           colSpan: 1,
                //         },

                //         {
                //           text: `${meldExceptionCriteria?.hcc?.hccBoneScan?.name ? 'ATTACHED' : '-'}`,
                //           colSpan: 2,
                //         },
                //         {},
                //       ],

                //       [
                //         {
                //           text: `Date of last HRCT chest`,
                //           style: 'tableHeader',
                //           alignment: 'left',
                //           colSpan: 1,
                //         },

                //         {
                //           text: `${
                //             meldExceptionCriteria?.hcc?.hccDateOfLastHrctChest
                //               ? moment(
                //                   meldExceptionCriteria?.hcc?.hccDateOfLastHrctChest,
                //                   FORMAT_DATE_UNIXTS
                //                 ).format('DD/MM/YYYY')
                //               : '-'
                //           }`,
                //           colSpan: 2,
                //         },
                //         {},
                //       ],
                //       [
                //         {
                //           text: 'Duration from last  HRCT chest',
                //           style: 'tableHeader',
                //           alignment: 'left',
                //           colSpan: 1,
                //         },

                //         {
                //           text: `${meldExceptionCriteria?.hcc?.hccDurationFromLastHrctChest}`,
                //           colSpan: 2,
                //         },
                //         {},
                //       ],

                //       [
                //         {
                //           text: `HRCT Chest Scan`,
                //           style: 'tableHeader',
                //           alignment: 'left',
                //           colSpan: 1,
                //         },

                //         {
                //           text: `${
                //             meldExceptionCriteria?.hcc?.hccHrctChestScan?.name ? 'ATTACHED' : '-'
                //           }`,
                //           colSpan: 2,
                //         },
                //         {},
                //       ],

                //       [
                //         {
                //           text: `Date of last PET CT `,
                //           style: 'tableHeader',
                //           alignment: 'left',
                //           colSpan: 1,
                //         },

                //         {
                //           text: `${
                //             meldExceptionCriteria?.hcc?.hccDateOfLastPetCt
                //               ? moment(
                //                   meldExceptionCriteria?.hcc?.hccDateOfLastPetCt,
                //                   FORMAT_DATE_UNIXTS
                //                 ).format('DD/MM/YYYY')
                //               : '-'
                //           }`,
                //           colSpan: 2,
                //         },
                //         {},
                //       ],
                //       [
                //         {
                //           text: 'Duration from last PET CT',
                //           style: 'tableHeader',
                //           alignment: 'left',
                //           colSpan: 1,
                //         },

                //         {
                //           text: `${meldExceptionCriteria?.hcc?.hccDurationFromLastPetCt}`,
                //           colSpan: 2,
                //         },
                //         {},
                //       ],

                //       [
                //         {
                //           text: `PET CT Scan`,
                //           style: 'tableHeader',
                //           alignment: 'left',
                //           colSpan: 1,
                //         },

                //         {
                //           text: `${meldExceptionCriteria?.hcc?.hccPetCtScan?.name ? 'ATTACHED' : '-'}`,
                //           colSpan: 2,
                //         },
                //         {},
                //       ],
                //       [
                //         {
                //           text: 'Eligible for HCC exception points',
                //           style: 'tableHeader',
                //           alignment: 'left',
                //           colSpan: 1,
                //         },

                //         {
                //           text: `${meldExceptionCriteria?.hcc?.eligibleForHccExceptionPoints}`,
                //           colSpan: 2,
                //         },
                //         {},
                //       ],
                //       [
                //         {
                //           text: 'HCC exception points',
                //           style: 'tableHeader',
                //           alignment: 'left',
                //           colSpan: 1,
                //         },

                //         {
                //           text: `${meldExceptionCriteria?.hcc?.hccExceptionPoints}`,
                //           colSpan: 2,
                //         },
                //         {},
                //       ],
                //       [
                //         {
                //           text: 'Does the patient have Hepatocellular Carcinoma (HCC)?',
                //           style: 'tableHeader',
                //           alignment: 'left',
                //           colSpan: 3,
                //         },
                //         {},
                //         {},
                //       ],
                //       [
                //         {
                //           text: 'TACE',
                //           style: 'tableHeader',
                //           alignment: 'left',
                //           colSpan: 1,
                //         },

                //         {
                //           text: `${_yesNoAndEmptyBooleanFormatter(routineInfo?.hccTreatment?.tace)}`,
                //           colSpan: 2,
                //         },
                //         {},
                //       ],
                //       [
                //         {
                //           text: 'TARE',
                //           style: 'tableHeader',
                //           alignment: 'left',
                //           colSpan: 1,
                //         },

                //         {
                //           text: `${_yesNoAndEmptyBooleanFormatter(routineInfo?.hccTreatment?.tare)}`,
                //           colSpan: 2,
                //         },
                //         {},
                //       ],
                //       [
                //         {
                //           text: 'RFA',
                //           style: 'tableHeader',
                //           alignment: 'left',
                //           colSpan: 1,
                //         },

                //         {
                //           text: `${_yesNoAndEmptyBooleanFormatter(routineInfo?.hccTreatment?.rfa)}`,
                //           colSpan: 2,
                //         },
                //         {},
                //       ],
                //       [
                //         {
                //           text: 'Resection',
                //           style: 'tableHeader',
                //           alignment: 'left',
                //           colSpan: 1,
                //         },

                //         {
                //           text: `${_yesNoAndEmptyBooleanFormatter(
                //             routineInfo?.hccTreatment?.resection
                //           )}`,
                //           colSpan: 2,
                //         },
                //         {},
                //       ],
                //       [
                //         {
                //           text: 'MWA',
                //           style: 'tableHeader',
                //           alignment: 'left',
                //           colSpan: 1,
                //         },

                //         {
                //           text: `${_yesNoAndEmptyBooleanFormatter(routineInfo?.hccTreatment?.mwa)}`,
                //           colSpan: 2,
                //         },
                //         {},
                //       ],

                //       [
                //         {
                //           text: 'Other',
                //           style: 'tableHeader',
                //           alignment: 'left',
                //           colSpan: 1,
                //         },

                //         {
                //           text: `${routineInfo?.hccTreatment?.other}`,
                //           colSpan: 2,
                //         },
                //         {},
                //       ],
                //       [
                //         {
                //           text: `Attachment`,
                //           style: 'tableHeader',
                //           alignment: 'left',
                //           colSpan: 1,
                //         },

                //         {
                //           text: `${routineInfo?.hccTreatment?.attachment?.name ? 'ATTACHED' : '-'}`,
                //           colSpan: 2,
                //         },
                //         {},
                //       ],
                //       [
                //         {
                //           text: 'Does the patient have Primary Sclerosing Cholangitis (PSC)?',
                //           style: 'tableHeader',
                //           alignment: 'left',
                //           colSpan: 3,
                //           bold: true,
                //         },
                //         {},
                //         {},
                //       ],
                //       [
                //         {
                //           text: `Attachment`,
                //           style: 'tableHeader',
                //           alignment: 'left',
                //           colSpan: 1,
                //         },

                //         {
                //           text: `${
                //             meldExceptionCriteria?.psc?.pscYesNoAttachment?.name ? 'ATTACHED' : '-'
                //           }`,
                //           colSpan: 2,
                //         },
                //         {},
                //       ],
                //       [
                //         {
                //           text: 'Proven on MRCP',
                //           style: 'tableHeader',
                //           alignment: 'left',
                //           colSpan: 1,
                //         },

                //         {
                //           text: `${_yesNoAndEmptyBooleanFormatter(
                //             meldExceptionCriteria?.psc?.pscProvenOnMrcp
                //           )}`,
                //           colSpan: 2,
                //         },
                //         {},
                //       ],
                //       [
                //         {
                //           text: 'Recurrent cholangitis (>= 4 episodes in a year)',
                //           style: 'tableHeader',
                //           alignment: 'left',
                //           colSpan: 1,
                //         },

                //         {
                //           text: `${_yesNoAndEmptyBooleanFormatter(
                //             meldExceptionCriteria?.psc?.pscRecurrentCholangitisLessThan4Episodes
                //           )}`,
                //           colSpan: 2,
                //         },
                //         {},
                //       ],
                //       [
                //         {
                //           text: 'Refractory cholangitis',
                //           style: 'tableHeader',
                //           alignment: 'left',
                //           colSpan: 1,
                //         },

                //         {
                //           text: `${_yesNoAndEmptyBooleanFormatter(
                //             meldExceptionCriteria?.psc?.pscRefactoryCholangitis
                //           )}`,
                //           colSpan: 2,
                //         },
                //         {},
                //       ],
                //       [
                //         {
                //           text: 'Severe Intractable itching after therapy',
                //           style: 'tableHeader',
                //           alignment: 'left',
                //           colSpan: 1,
                //         },

                //         {
                //           text: `${_yesNoAndEmptyBooleanFormatter(
                //             meldExceptionCriteria?.psc?.pscSevereIntractableItchingAfterTherapy
                //           )}`,
                //           colSpan: 2,
                //         },
                //         {},
                //       ],
                //       [
                //         {
                //           text: 'Eligible for PSC exception points',
                //           style: 'tableHeader',
                //           alignment: 'left',
                //           colSpan: 1,
                //         },
                //         {
                //           text: `${meldExceptionCriteria?.psc?.eligibleForPscExceptionPoints}`,
                //           style: 'tableHeader',
                //           alignment: 'left',
                //           colSpan: 2,
                //         },
                //       ],
                //       [
                //         {
                //           text: 'PSC exception points',
                //           style: 'tableHeader',
                //           alignment: 'left',
                //           colSpan: 1,
                //         },
                //         {
                //           text: `${meldExceptionCriteria?.psc?.pscExceptionPoints}`,
                //           style: 'tableHeader',
                //           alignment: 'left',
                //           colSpan: 2,
                //         },
                //       ],

                //       [
                //         {
                //           text: 'Does the patient have Primary Biliary Cholangitis (PBC)?',
                //           style: 'tableHeader',
                //           alignment: 'left',
                //           colSpan: 3,
                //           bold: true,
                //         },
                //         {},
                //         {},
                //       ],
                //       [
                //         {
                //           text: `Attachment`,
                //           style: 'tableHeader',
                //           alignment: 'left',
                //           colSpan: 1,
                //         },

                //         {
                //           text: `${
                //             meldExceptionCriteria?.pbc?.pbcYesNoAttachment?.name ? 'ATTACHED' : '-'
                //           }`,
                //           colSpan: 2,
                //         },
                //         {},
                //       ],
                //       [
                //         {
                //           text: 'AMA positive ± Biopsy proven',
                //           style: 'tableHeader',
                //           alignment: 'left',
                //           colSpan: 1,
                //         },

                //         {
                //           text: `${_yesNoAndEmptyBooleanFormatter(
                //             meldExceptionCriteria?.pbc?.pbcAmaPositive
                //           )}`,
                //           colSpan: 2,
                //         },
                //         {},
                //       ],
                //       [
                //         {
                //           text: 'Severe intractable pruritus after treatment',
                //           style: 'tableHeader',
                //           alignment: 'left',
                //           colSpan: 1,
                //         },

                //         {
                //           text: `${_yesNoAndEmptyBooleanFormatter(
                //             meldExceptionCriteria?.pbc?.pbcSevereIntractablePruritusAfterTreatment
                //           )}`,
                //           colSpan: 2,
                //         },
                //         {},
                //       ],
                //       [
                //         {
                //           text: 'Severe metabolic bone disease (non-traumatic fractures)',
                //           style: 'tableHeader',
                //           alignment: 'left',
                //           colSpan: 1,
                //         },

                //         {
                //           text: `${_yesNoAndEmptyBooleanFormatter(
                //             meldExceptionCriteria?.pbc
                //               ?.pbcSevereMetabolicBoneDiseaseNonTraumaticFractures
                //           )}`,
                //           colSpan: 2,
                //         },
                //         {},
                //       ],
                //       [
                //         {
                //           text: 'Severe metabolic bone disease (Z score less than -4 on DEXA scan)',
                //           style: 'tableHeader',
                //           alignment: 'left',
                //           colSpan: 1,
                //         },

                //         {
                //           text: `${_yesNoAndEmptyBooleanFormatter(
                //             meldExceptionCriteria?.pbc?.pbcSevereMetabolicBoneDiseaseZScore
                //           )}`,
                //           colSpan: 2,
                //         },
                //         {},
                //       ],
                //       [
                //         {
                //           text: 'Eligible for PBC exception points',
                //           style: 'tableHeader',
                //           alignment: 'left',
                //           colSpan: 1,
                //         },
                //         {
                //           text: `${meldExceptionCriteria?.pbc?.eligibleForPbcExceptionPoints}`,
                //           style: 'tableHeader',
                //           alignment: 'left',
                //           colSpan: 2,
                //         },
                //       ],
                //       [
                //         {
                //           text: 'PBC exception points',
                //           style: 'tableHeader',
                //           alignment: 'left',
                //           colSpan: 1,
                //         },
                //         {
                //           text: `${meldExceptionCriteria?.pbc?.pbcExceptionPoints}`,
                //           style: 'tableHeader',
                //           alignment: 'left',
                //           colSpan: 2,
                //         },
                //       ],
                //       [
                //         {
                //           text: 'Does the post transplant patient require Retransplant?',
                //           style: 'tableHeader',
                //           alignment: 'left',
                //           colSpan: 3,
                //           bold: true,
                //         },
                //         {},
                //         {},
                //       ],
                //       [
                //         {
                //           text: `Attachment`,
                //           style: 'tableHeader',
                //           alignment: 'left',
                //           colSpan: 1,
                //         },

                //         {
                //           text: `${
                //             meldExceptionCriteria?.postTransplantPatientRequiringRetransplant
                //               ?.postTransplantPatientRequiringRetransplantYesNoAttachment?.name
                //               ? 'ATTACHED'
                //               : '-'
                //           }`,
                //           colSpan: 2,
                //         },
                //         {},
                //       ],
                //       [
                //         {
                //           text: 'Eligible for super-urgent listing(rejection/late HAT/ITBL)',
                //           style: 'tableHeader',
                //           alignment: 'left',
                //           colSpan: 1,
                //         },

                //         {
                //           text: `${_yesNoAndEmptyBooleanFormatter(
                //             meldExceptionCriteria?.postTransplantPatientRequiringRetransplant
                //               ?.postTransplantPatientRequiringRetransplantEligibleForSuperUrgentListing
                //           )}`,
                //           colSpan: 2,
                //         },
                //         {},
                //       ],
                //       [
                //         {
                //           text: 'Eligible for Post Transplant Patient Requiring Retransplant exception points',
                //           style: 'tableHeader',
                //           alignment: 'left',
                //           colSpan: 1,
                //         },
                //         {
                //           text: `${meldExceptionCriteria?.postTransplantPatientRequiringRetransplant?.eligibleForPostTransplantPatientRequiringRetransplantExceptionPoints}`,
                //           style: 'tableHeader',
                //           alignment: 'left',
                //           colSpan: 2,
                //         },
                //       ],
                //       [
                //         {
                //           text: 'Post Transplant Patient Requiring Retransplant exception points',
                //           style: 'tableHeader',
                //           alignment: 'left',
                //           colSpan: 1,
                //         },
                //         {
                //           text: `${meldExceptionCriteria?.postTransplantPatientRequiringRetransplant?.postTransplantPatientRequiringRetransplantExceptionPoints}`,
                //           style: 'tableHeader',
                //           alignment: 'left',
                //           colSpan: 2,
                //         },
                //       ],
                //       [
                //         {
                //           text: 'Does the patient have Metabolic Liver Diseases?',
                //           style: 'tableHeader',
                //           alignment: 'left',
                //           colSpan: 3,
                //           bold: true,
                //         },
                //         {},
                //         {},
                //       ],
                //       [
                //         {
                //           text: `Attachment`,
                //           style: 'tableHeader',
                //           alignment: 'left',
                //           colSpan: 1,
                //         },

                //         {
                //           text: `${
                //             meldExceptionCriteria?.mld?.mldYesNoAttachment?.name ? 'ATTACHED' : '-'
                //           }`,
                //           colSpan: 2,
                //         },
                //         {},
                //       ],
                //       [
                //         {
                //           text: 'Familial amyloid polyneuropathy',
                //           style: 'tableHeader',
                //           alignment: 'left',
                //           colSpan: 1,
                //         },

                //         {
                //           text: `${_yesNoAndEmptyBooleanFormatter(
                //             meldExceptionCriteria?.mld?.mldFamilialAmyloidPolyneurpathy
                //           )}`,
                //           colSpan: 2,
                //         },
                //         {},
                //       ],
                //       [
                //         {
                //           text: 'Maple syrup urine disease',
                //           style: 'tableHeader',
                //           alignment: 'left',
                //           colSpan: 1,
                //         },
                //         {
                //           text: `${meldExceptionCriteria?.mld?.mldMapleSyrupUrineDisease}`,
                //           style: 'tableHeader',
                //           alignment: 'left',
                //           colSpan: 2,
                //         },
                //       ],
                //       [
                //         {
                //           text: 'Eligible for Metabolic liver diseases (Native liver offered for Domino liver transplant) exception points',
                //           style: 'tableHeader',
                //           alignment: 'left',
                //           colSpan: 1,
                //         },
                //         {
                //           text: `${meldExceptionCriteria?.mld?.eligibleForMldExceptionPoints}`,
                //           style: 'tableHeader',
                //           alignment: 'left',
                //           colSpan: 2,
                //         },
                //       ],

                //       [
                //         {
                //           text: 'Does the patient have Metabolic Liver Diseases (other than Wilsons)?',
                //           style: 'tableHeader',
                //           alignment: 'left',
                //           colSpan: 3,
                //           bold: true,
                //         },
                //         {},
                //         {},
                //       ],
                //       [
                //         {
                //           text: `Attachment`,
                //           style: 'tableHeader',
                //           alignment: 'left',
                //           colSpan: 1,
                //         },

                //         {
                //           text: `${
                //             meldExceptionCriteria?.mldOtw?.mldOtwYesNoAttachment?.name
                //               ? 'ATTACHED'
                //               : '-'
                //           }`,
                //           colSpan: 2,
                //         },
                //         {},
                //       ],
                //       [
                //         {
                //           text: 'Familial hypercholesterolemia',
                //           style: 'tableHeader',
                //           alignment: 'left',
                //           colSpan: 1,
                //         },

                //         {
                //           text: `${_yesNoAndEmptyBooleanFormatter(
                //             meldExceptionCriteria?.mldOtw?.mldOtwFamilialHypercholesterolemia
                //           )}`,
                //           colSpan: 2,
                //         },
                //         {},
                //       ],
                //       [
                //         {
                //           text: 'Sickle cell hepatopathy',
                //           style: 'tableHeader',
                //           alignment: 'left',
                //           colSpan: 1,
                //         },
                //         {
                //           text: `${_yesNoAndEmptyBooleanFormatter(
                //             meldExceptionCriteria?.mldOtw?.mldOtwSickleCellHepatopathy
                //           )}`,
                //           style: 'tableHeader',
                //           alignment: 'left',
                //           colSpan: 2,
                //         },
                //       ],
                //       [
                //         {
                //           text: 'Primary hyperoxaluria',
                //           style: 'tableHeader',
                //           alignment: 'left',
                //           colSpan: 1,
                //         },
                //         {
                //           text: `${_yesNoAndEmptyBooleanFormatter(
                //             meldExceptionCriteria?.mldOtw?.mldOtwPrimaryHyperoxaluria
                //           )}`,
                //           style: 'tableHeader',
                //           alignment: 'left',
                //           colSpan: 2,
                //         },
                //       ],
                //       [
                //         {
                //           text: 'Polycystic liver disease',
                //           style: 'tableHeader',
                //           alignment: 'left',
                //           colSpan: 1,
                //         },
                //         {
                //           text: `${_yesNoAndEmptyBooleanFormatter(
                //             meldExceptionCriteria?.mldOtw?.mldOtwPolycysticLiverDisease
                //           )}`,
                //           style: 'tableHeader',
                //           alignment: 'left',
                //           colSpan: 2,
                //         },
                //       ],
                //       [
                //         {
                //           text: 'Eligible for Metabolic liver diseases (other than Wilsons) exception points',
                //           style: 'tableHeader',
                //           alignment: 'left',
                //           colSpan: 1,
                //         },
                //         {
                //           text: `${meldExceptionCriteria?.mldOtw?.eligibleForMldOtwExceptionPoints}`,
                //           style: 'tableHeader',
                //           alignment: 'left',
                //           colSpan: 2,
                //         },
                //       ],
                //       [
                //         {
                //           text: 'Metabolic liver diseases (other than Wilsons) exception points',
                //           style: 'tableHeader',
                //           alignment: 'left',
                //           colSpan: 1,
                //         },
                //         {
                //           text: `${meldExceptionCriteria?.mldOtw?.mldOtwExceptionPoints}`,
                //           style: 'tableHeader',
                //           alignment: 'left',
                //           colSpan: 2,
                //         },
                //       ],

                //       [
                //         {
                //           text: 'Does the patient have Non Hepatocellular Carcinoma (Non-HCC)?',
                //           style: 'tableHeader',
                //           alignment: 'left',
                //           colSpan: 3,
                //           bold: true,
                //         },
                //         {},
                //         {},
                //       ],
                //       [
                //         {
                //           text: `Attachment`,
                //           style: 'tableHeader',
                //           alignment: 'left',
                //           colSpan: 1,
                //         },

                //         {
                //           text: `${
                //             meldExceptionCriteria?.nonHcc?.nonHccYesNoAttachment?.name
                //               ? 'ATTACHED'
                //               : '-'
                //           }`,
                //           colSpan: 2,
                //         },
                //         {},
                //       ],
                //       [
                //         {
                //           text: 'Hepatic epithelioid haemangioendothelioma',
                //           style: 'tableHeader',
                //           alignment: 'left',
                //           colSpan: 1,
                //         },

                //         {
                //           text: `${_yesNoAndEmptyBooleanFormatter(
                //             meldExceptionCriteria?.nonHcc?.nonHccHepaticEpithelioid
                //           )}`,
                //           colSpan: 2,
                //         },
                //         {},
                //       ],
                //       [
                //         {
                //           text: 'Bilobar NET (Neuro Endocrine Tumor) liver metastases: at least 6 months after resolution of primary tumor',
                //           style: 'tableHeader',
                //           alignment: 'left',
                //           colSpan: 1,
                //         },
                //         {
                //           text: `${_yesNoAndEmptyBooleanFormatter(
                //             meldExceptionCriteria?.nonHcc?.nonHccBilobarNetSixMonths
                //           )}`,
                //           style: 'tableHeader',
                //           alignment: 'left',
                //           colSpan: 2,
                //         },
                //       ],
                //       [
                //         {
                //           text: 'Bilobar NET (Neuro Endocrine Tumor) liver metastases:confirmed metastasis restricted to liver by whole body special scans',
                //           style: 'tableHeader',
                //           alignment: 'left',
                //           colSpan: 1,
                //         },
                //         {
                //           text: `${_yesNoAndEmptyBooleanFormatter(
                //             meldExceptionCriteria?.nonHcc?.nonHccBilobarNetSpecialScans
                //           )}`,
                //           style: 'tableHeader',
                //           alignment: 'left',
                //           colSpan: 2,
                //         },
                //       ],

                //       [
                //         {
                //           text: 'Eligible for Non HCC exception points ',
                //           style: 'tableHeader',
                //           alignment: 'left',
                //           colSpan: 1,
                //         },
                //         {
                //           text: `${meldExceptionCriteria?.nonHcc?.eligibleForNonHccExceptionPoints}`,
                //           style: 'tableHeader',
                //           alignment: 'left',
                //           colSpan: 2,
                //         },
                //       ],
                //       [
                //         {
                //           text: 'Non HCC exception points',
                //           style: 'tableHeader',
                //           alignment: 'left',
                //           colSpan: 1,
                //         },
                //         {
                //           text: `${meldExceptionCriteria?.nonHcc?.nonHccExceptionPoints}`,
                //           style: 'tableHeader',
                //           alignment: 'left',
                //           colSpan: 2,
                //         },
                //       ],
                //       [
                //         {
                //           text: 'Eligible for Meld Exception Criteria Points',
                //           style: 'tableHeader',
                //           alignment: 'left',
                //           colSpan: 1,
                //           bold: true,
                //         },
                //         {
                //           text: `${meldExceptionCriteria?.meldExceptionCriteriaResult?.meldExceptionCriteriaPointsEligible}`,
                //           style: 'tableHeader',
                //           alignment: 'left',
                //           colSpan: 2,
                //         },
                //       ],
                //       [
                //         {
                //           text: 'Meld Exception Criteria points (D)',
                //           style: 'tableHeader',
                //           alignment: 'left',
                //           colSpan: 1,
                //           bold: true,
                //         },
                //         {
                //           text: `${meldExceptionCriteria?.meldExceptionCriteriaResult?.meldExceptionCriteriaPoints}`,
                //           style: 'tableHeader',
                //           alignment: 'left',
                //           colSpan: 2,
                //         },
                //       ],
                //       [
                //         {
                //           text: 'MLAS Score',
                //           style: 'tableHeader',
                //           alignment: 'center',
                //           colSpan: 3,
                //           bold: true,
                //         },
                //         {},
                //         {},
                //       ],
                //       [
                //         {
                //           text: 'MLAS (A)',
                //           style: 'tableHeader',
                //           alignment: 'left',
                //           colSpan: 1,
                //           bold: true,
                //         },
                //         {
                //           text: `${meldPeld?.meld ? meldPeld?.meld : '-'}`,
                //           style: 'tableHeader',
                //           alignment: 'left',
                //           colSpan: 2,
                //         },
                //       ],
                //       [
                //         {
                //           text: 'Complication Score (B)',
                //           style: 'tableHeader',
                //           alignment: 'left',
                //           colSpan: 1,
                //           bold: true,
                //         },
                //         {
                //           text: `${
                //             complicationScore?.complicationScoreResult?.complicationPoints
                //               ? complicationScore?.complicationScoreResult?.complicationPoints
                //               : '-'
                //           }`,
                //           style: 'tableHeader',
                //           alignment: 'left',
                //           colSpan: 2,
                //         },
                //       ],
                //       [
                //         {
                //           text: 'Waiting Time Score (C) ',
                //           style: 'tableHeader',
                //           alignment: 'left',
                //           colSpan: 1,
                //           bold: true,
                //         },
                //         {
                //           text: `${'0'}`,
                //           style: 'tableHeader',
                //           alignment: 'left',
                //           colSpan: 2,
                //         },
                //       ],
                //       [
                //         {
                //           text: 'MELD Exception Score (D)',
                //           style: 'tableHeader',
                //           alignment: 'left',
                //           colSpan: 1,
                //           bold: true,
                //         },
                //         {
                //           text: `${
                //             meldExceptionCriteria?.meldExceptionCriteriaResult
                //               ?.meldExceptionCriteriaPoints
                //               ? meldExceptionCriteria?.meldExceptionCriteriaResult
                //                   ?.meldExceptionCriteriaPoints
                //               : '-'
                //           }`,
                //           style: 'tableHeader',
                //           alignment: 'left',
                //           colSpan: 2,
                //         },
                //       ],
                //       [
                //         {
                //           text: 'Listing MLAS Score',
                //           style: 'tableHeader',
                //           alignment: 'left',
                //           colSpan: 1,
                //           bold: true,
                //         },
                //         {
                //           text: `${_getMlasScore()}`,
                //           style: 'tableHeader',
                //           alignment: 'left',
                //           colSpan: 2,
                //         },
                //       ],
                //       [
                //         {
                //           text: 'Updated MLAS Score',
                //           style: 'tableHeader',
                //           alignment: 'left',
                //           colSpan: 1,
                //           bold: true,
                //         },
                //         {
                //           text: `${'NA'}`,
                //           style: 'tableHeader',
                //           alignment: 'left',
                //           colSpan: 2,
                //         },
                //       ],
                //       [
                //         {
                //           text: 'Current MLAS Score',
                //           style: 'tableHeader',
                //           alignment: 'left',
                //           colSpan: 1,
                //           bold: true,
                //         },
                //         {
                //           text: `${_getMlasScore()}`,
                //           style: 'tableHeader',
                //           alignment: 'left',
                //           colSpan: 2,
                //         },
                //       ],
                //     ],
                //   },
                // }

                {
                  style: 'table',
                  table: {
                    headerRows: 1,
                    widths: ['*', '*', '*'],
                    body: [
                      [
                        { text: 'Report Type', style: 'tableHeader', alignment: 'left' },
                        { text: 'Date of Report', style: 'tableHeader', alignment: 'left' },
                        { text: 'Lab Report', style: 'tableHeader', alignment: 'left' },
                      ],
                      ...meldPeld?.reports?.map((e) => [
                        {
                          text: `${e.reportType ? e.reportType : '-'}`,
                          style: 'tableCell',
                          alignment: 'left',
                        },
                        {
                          text: `${moment(e.dateOfReport, FORMAT_DATE_UNIXTS).format(
                            'DD/MM/YYYY'
                          )}`,
                          style: 'tableCell',
                          alignment: 'left',
                        },
                        {
                          text: `${e.attachment?.name ? 'ATTACHED' : '-'}`,
                          style: 'tableCell',
                          alignment: 'left',
                        },
                      ]),
                    ],
                  },
                },
                {
                  style: 'table',
                  table: {
                    headerRows: 1,
                    widths: ['*', '*'],
                    body: [
                      [
                        { text: 'Syndrome', style: 'tableHeader', alignment: 'left' },
                        { text: 'Syndrome Report', style: 'tableHeader', alignment: 'left' },
                      ],
                      ...meldPeld?.syndromes?.map((e) => [
                        {
                          text: `${e.syndrome ? e.syndrome : '-'}`,
                          style: 'tableCell',
                          alignment: 'left',
                        },
                        {
                          text: `${e.attachment?.name ? 'ATTACHED' : '-'}`,
                          style: 'tableCell',
                          alignment: 'left',
                        },
                      ]),
                    ],
                  },
                },
                {
                  style: 'table',
                  table: {
                    headerRows: 1,
                    widths: ['*', '*'],
                    body: [
                      [
                        { text: 'Etiology', style: 'tableHeader', alignment: 'left' },
                        { text: 'Etiology Report', style: 'tableHeader', alignment: 'left' },
                      ],
                      ...meldPeld?.etiologies?.map((e) => [
                        {
                          text: `${e.etiology ? e.etiology : '-'}`,
                          style: 'tableCell',
                          alignment: 'left',
                        },
                        {
                          text: `${e.attachment?.name ? 'ATTACHED' : '-'}`,
                          style: 'tableCell',
                          alignment: 'left',
                        },
                      ]),
                    ],
                  },
                },
                {
                  style: 'table',
                  table: {
                    widths: [250, 100, 150, 'auto', 'auto'],
                    heights: [
                      'auto',
                      'auto',
                      'auto',
                      'auto',
                      'auto',
                      'auto',
                      'auto',
                      'auto',
                      'auto',
                      'auto',
                      'auto',
                      'auto',
                      // 20,
                      // 20,
                      // 10,
                      'auto',
                      'auto',
                      'auto',
                      'auto',
                      'auto',
                      'auto',
                      'auto',
                      'auto',
                      'auto',
                      'auto',
                      'auto',
                    ],
                    body: [
                      [
                        {
                          text: 'Etiology Details ',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${meldPeld?.etiologyDetails ? meldPeld?.etiologyDetails : '-'}`,
                          colSpan: 2,
                        },
                        {},
                      ],
                      [
                        {
                          text: 'Complication Score',
                          style: 'tableHeader',
                          alignment: 'center',
                          colSpan: 3,
                          bold: true,
                        },
                        {},
                        {},
                      ],
                      [
                        {
                          text: 'Does the patient have Ascites?',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                          bold: true,
                        },
                        {
                          text: `${_yesNoAndEmptyBooleanFormatter(
                            complicationScore?.ascites?.ascitesYesNo
                          )}`,
                          colSpan: 2,
                        },
                        {},
                      ],
                      [
                        {
                          text: 'Does the patient have moderate to severe Refractory ascites?',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${_yesNoAndEmptyBooleanFormatter(
                            complicationScore?.ascites?.refractoryAscitesYesNo
                          )}`,
                          colSpan: 2,
                        },
                        {},
                      ],
                      [
                        {
                          text: 'DRefractory ascites (≥ 2 LVPs (≥ 5L) / month)',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${_yesNoAndEmptyBooleanFormatter(
                            complicationScore?.hepaticHydrothoraxModToSevere?.refractoryAscites
                          )}`,
                          colSpan: 2,
                        },
                        {},
                      ],
                      [
                        {
                          text: 'Refractory ascites (diuretic resistance)',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${_yesNoAndEmptyBooleanFormatter(
                            complicationScore?.hepaticHydrothoraxModToSevere
                              ?.refractoryAscitesDiuretic
                          )}`,
                          colSpan: 2,
                        },
                        {},
                      ],
                      [
                        {
                          text: 'Refractory ascites (needing noradrenaline / midodrine / terlipressin)',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${_yesNoAndEmptyBooleanFormatter(
                            complicationScore?.hepaticHydrothoraxModToSevere
                              ?.refractoryAscitesNoradrenaline
                          )}`,
                          colSpan: 2,
                        },
                        {},
                      ],
                      [
                        {
                          text: `Attachment`,
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${
                            complicationScore?.ascites?.refractoryAscitesAttachment?.name
                              ? 'ATTACHED'
                              : '-'
                          }`,
                          colSpan: 2,
                        },
                        {},
                      ],
                      [
                        {
                          text: 'Does the patient have moderate to severe Hepatic Hydrothorax',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${_yesNoAndEmptyBooleanFormatter(
                            complicationScore?.ascites?.hepaticHydrothoraxYesNo
                          )}`,
                          colSpan: 2,
                        },
                        {},
                      ],
                      [
                        {
                          text: 'Hepatic Hydrothorax (moderate to severe on HRCT/Chest Xray/USG Thorax)',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${_yesNoAndEmptyBooleanFormatter(
                            complicationScore?.hepaticHydrothoraxModToSevere
                              ?.hepaticHydrothoraxModerateOnHrctChestXrayUsgThorax
                          )}`,
                          colSpan: 2,
                        },
                        {},
                      ],
                      [
                        {
                          text: 'Hepatic hydrothorax (≥ 2 therapeutic taps)',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${_yesNoAndEmptyBooleanFormatter(
                            complicationScore?.hepaticHydrothoraxModToSevere
                              ?.hepaticHydrothoraxTherapeuticTaps
                          )}`,
                          colSpan: 2,
                        },
                        {},
                      ],
                      [
                        {
                          text: `Attachment`,
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${
                            complicationScore?.ascites?.hepaticHydrothoraxAttachment?.name
                              ? 'ATTACHED'
                              : '-'
                          }`,
                          colSpan: 2,
                        },
                        {},
                      ],

                      [
                        {
                          text: 'Does the patient have Spontaneous Bacterial Peritonitis (SBP)?)',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${_yesNoAndEmptyBooleanFormatter(
                            complicationScore?.hepaticHydrothoraxModToSevere
                              ?.hepaticHydrothoraxTherapeuticTaps
                          )}`,
                          colSpan: 2,
                        },
                        {},
                      ],
                      [
                        {
                          text: 'Ascites Fluid Absolute Neutrophil count',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },
                        {
                          text: `${complicationScore?.sbp?.sbpAnc}`,
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 2,
                        },
                      ],
                      [
                        {
                          text: `SBP Report`,
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${
                            complicationScore?.sbp?.sbpAncAttachment?.name ? 'ATTACHED' : '-'
                          }`,
                          colSpan: 2,
                        },
                        {},
                      ],
                      [
                        {
                          text: `SBP Reports Date `,
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${
                            complicationScore?.sbp?.sbpAncAttachmentDate
                              ? moment(
                                  complicationScore?.sbp?.sbpAncAttachmentDate,
                                  FORMAT_DATE_UNIXTS
                                ).format('DD/MM/YYYY')
                              : '-'
                          }`,
                          colSpan: 2,
                        },
                        {},
                      ],
                      [
                        {
                          text: ' Eligible for SBP points',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },
                        {
                          text: `${complicationScore?.sbp?.spbPointsEligible}`,
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 2,
                        },
                      ],
                      [
                        {
                          text: 'SBP points',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },
                        {
                          text: `${complicationScore?.sbp?.spbPoints}`,
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 2,
                        },
                      ],
                      [
                        {
                          text: 'Does the patient have HRS?',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${_yesNoAndEmptyBooleanFormatter(
                            complicationScore?.ascites?.hrsYesNo
                          )}`,
                          colSpan: 2,
                        },
                        {},
                      ],
                      [
                        {
                          text: `Attachment`,
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${
                            complicationScore?.ascites?.hrsAttachment?.name ? 'ATTACHED' : '-'
                          }`,
                          colSpan: 2,
                        },
                        {},
                      ],
                      [
                        {
                          text: 'Does the patient have Hepatic Encephalopathy (HE) needing ICU?',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                          bold: true,
                        },
                        {
                          text: `${_yesNoAndEmptyBooleanFormatter(
                            complicationScore?.heNeedingHIcu?.heNeedingHIcuYesNo
                          )}`,
                          colSpan: 2,
                        },
                        {},
                      ],
                      [
                        {
                          text: 'HE (Past / Current) (≥ grade 3 requiring hospitalization (ICU))',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${_yesNoAndEmptyBooleanFormatter(
                            complicationScore?.heNeedingHIcu?.heNeedingGreaterThanEquals3
                          )}`,
                          colSpan: 2,
                        },
                        {},
                      ],
                      [
                        {
                          text: 'Structural neurological diseases',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${_yesNoAndEmptyBooleanFormatter(
                            complicationScore?.heNeedingHIcu?.structuralNeurologicalDisease
                          )}`,
                          colSpan: 2,
                        },
                        {},
                      ],
                      [
                        {
                          text: `Attachment`,
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${
                            complicationScore?.heNeedingHIcu?.heNeedingHIcuAttachment?.name
                              ? 'ATTACHED'
                              : '-'
                          }`,
                          colSpan: 2,
                        },
                        {},
                      ],
                      [
                        {
                          text: ' Eligible for HE points',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },
                        {
                          text: `${complicationScore?.heNeedingHIcu?.hePointsEligible}`,
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 2,
                        },
                      ],
                      [
                        {
                          text: 'HE points',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },
                        {
                          text: `${complicationScore?.heNeedingHIcu?.hePoints}`,
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 2,
                        },
                      ],

                      [
                        {
                          text: 'Does the patient have Variceal Bleed?',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                          bold: true,
                        },
                        {
                          text: `${_yesNoAndEmptyBooleanFormatter(
                            complicationScore?.varicealBleedNoEndo?.varicealBleedNoEndoYesNo
                          )}`,
                          colSpan: 2,
                        },
                        {},
                      ],
                      [
                        {
                          text: 'Recurrent variceal bleed (not amenable to endotherapy and / or TIPSS)',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${_yesNoAndEmptyBooleanFormatter(
                            complicationScore?.varicealBleedNoEndo
                              ?.recurrentVaricealBleedNotAmenable
                          )}`,
                          colSpan: 2,
                        },
                        {},
                      ],

                      [
                        {
                          text: `Attachment`,
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${
                            complicationScore?.varicealBleedNoEndo?.varicealBleedNoEndoAttachment
                              ?.name
                              ? 'ATTACHED'
                              : '-'
                          }`,
                          colSpan: 2,
                        },
                        {},
                      ],
                      [
                        {
                          text: ' Eligible for Recurrent variceal bleed points',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },
                        {
                          text: `${complicationScore?.varicealBleedNoEndo?.recurrentVaricealEligible}`,
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 2,
                        },
                      ],
                      [
                        {
                          text: 'Recurrent variceal bleed points',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },
                        {
                          text: `${complicationScore?.varicealBleedNoEndo?.recurrentVaricealPoints}`,
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 2,
                        },
                      ],
                      [
                        {
                          text: 'Does the patient have Jaundice?',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                          bold: true,
                        },
                        {
                          text: `${_yesNoAndEmptyBooleanFormatter(
                            complicationScore?.jaundiceYesNo
                          )}`,
                          colSpan: 2,
                        },
                        {},
                      ],
                      [
                        {
                          text: `Attachment`,
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${complicationScore?.jaundiceAttachment?.name ? 'ATTACHED' : '-'}`,
                          colSpan: 2,
                        },
                        {},
                      ],
                      [
                        {
                          text: 'Does the patient have PVT?',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                          bold: true,
                        },
                        {
                          text: `${_yesNoAndEmptyBooleanFormatter(complicationScore?.pvtYesNo)}`,
                          colSpan: 2,
                        },
                        {},
                      ],
                      [
                        {
                          text: `Attachment`,
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${complicationScore?.pvtAttachment?.name ? 'ATTACHED' : '-'}`,
                          colSpan: 2,
                        },
                        {},
                      ],
                      [
                        {
                          text: 'MELD Exception Criteria',
                          style: 'tableHeader',
                          alignment: 'center',
                          colSpan: 3,
                          bold: true,
                        },
                        {},
                        {},
                      ],
                      [
                        {
                          text: 'Does the patient have Hepatopulmonary Syndrome (HPS)?',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                          bold: true,
                        },
                        {
                          text: `${_yesNoAndEmptyBooleanFormatter(
                            meldExceptionCriteria?.hps?.hpsYesNo
                          )}`,
                          colSpan: 2,
                        },
                        {},
                      ],
                      [
                        {
                          text: `Attachment`,
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${
                            meldExceptionCriteria?.hps?.hpsYesNoAttachment?.name ? 'ATTACHED' : '-'
                          }`,
                          colSpan: 2,
                        },
                        {},
                      ],
                      [
                        {
                          text: 'PaO2 less than 60 mmHg on room air',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${_yesNoAndEmptyBooleanFormatter(
                            meldExceptionCriteria?.hps?.hpsPao2
                          )}`,
                          colSpan: 2,
                        },
                        {},
                      ],
                      [
                        {
                          text: 'Alveolar arterial oxygen gradient greater 15 mmHg /greater 20 mmHg if greater than 64 yrs age',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${_yesNoAndEmptyBooleanFormatter(
                            meldExceptionCriteria?.hps?.hpsAlveolarGradient
                          )}`,
                          colSpan: 2,
                        },
                        {},
                      ],
                      [
                        {
                          text: 'Bubble ECHO / MAA scan positive for Shunt',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${_yesNoAndEmptyBooleanFormatter(
                            meldExceptionCriteria?.hps?.hpsShunt
                          )}`,
                          colSpan: 2,
                        },
                        {},
                      ],
                      [
                        {
                          text: 'Clinical e/o liver disease and portal hypertension',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${_yesNoAndEmptyBooleanFormatter(
                            meldExceptionCriteria?.hps?.hpsLiverDiseaseAndHyperTension
                          )}`,
                          colSpan: 2,
                        },
                        {},
                      ],
                      [
                        {
                          text: 'E/O underlying primary pulmonary disease',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${_yesNoAndEmptyBooleanFormatter(
                            meldExceptionCriteria?.hps?.hpsPulmonaryDisease
                          )}`,
                          colSpan: 2,
                        },
                        {},
                      ],
                      [
                        {
                          text: ' Eligible for HPS exception point',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },
                        {
                          text: `${meldExceptionCriteria?.hps?.eligibleForHpsExceptionPoints}`,
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 2,
                        },
                      ],
                      [
                        {
                          text: 'HPS exception points',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },
                        {
                          text: `${meldExceptionCriteria?.hps?.hpsExceptionPoints}`,
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 2,
                        },
                      ],
                      [
                        {
                          text: 'Does the patient have Pulmonary Arterial Hypertension (PAH)?',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                          bold: true,
                        },
                        {
                          text: `${_yesNoAndEmptyBooleanFormatter(
                            meldExceptionCriteria?.pah?.pahYesNo
                          )}`,
                          colSpan: 2,
                        },
                        {},
                      ],
                      [
                        {
                          text: `Attachment`,
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${
                            meldExceptionCriteria?.pah?.pahYesNoAttachment?.name ? 'ATTACHED' : '-'
                          }`,
                          colSpan: 2,
                        },
                        {},
                      ],
                      [
                        {
                          text: 'Post-treatment Mean pulmonary arterial pressure (MPAP) 25 to 35 mmHg',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${_yesNoAndEmptyBooleanFormatter(
                            meldExceptionCriteria?.pah
                              ?.pahPostTreatmentMeanPulmonaryArterialPressure
                          )}`,
                          colSpan: 2,
                        },
                        {},
                      ],
                      [
                        {
                          text: 'Post-treatment pulmonary vascular resistance (PVR) 120 to 400 dynes/sec/cm',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${_yesNoAndEmptyBooleanFormatter(
                            meldExceptionCriteria?.pah?.pahPostTreatmentPulmonaryVascularResistance
                          )}`,
                          colSpan: 2,
                        },
                        {},
                      ],
                      [
                        {
                          text: 'Pulmonary Capillary Wedge Pressure (PCWP) less than 15 mmg',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${_yesNoAndEmptyBooleanFormatter(
                            meldExceptionCriteria?.pah?.pahPulmonaryCapillaryWedgePressure
                          )}`,
                          colSpan: 2,
                        },
                        {},
                      ],
                      [
                        {
                          text: `Right Heart Catheterization Reports`,
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${
                            meldExceptionCriteria?.pah?.pahHeartCathReport?.name ? 'ATTACHED' : '-'
                          }`,
                          colSpan: 2,
                        },
                        {},
                      ],

                      [
                        {
                          text: `Heart Cath Report Date `,
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${
                            meldExceptionCriteria?.pah?.pahHeartCathReportDate
                              ? moment(
                                  meldExceptionCriteria?.pah?.pahHeartCathReportDate,
                                  FORMAT_DATE_UNIXTS
                                ).format('DD/MM/YYYY')
                              : '-'
                          }`,
                          colSpan: 2,
                        },
                        {},
                      ],
                      [
                        {
                          text: ' Eligible for PAH exception points',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },
                        {
                          text: `${meldExceptionCriteria?.pah?.eligibleForPahExceptionPoints}`,
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 2,
                        },
                      ],
                      [
                        {
                          text: 'PAH exception points',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },
                        {
                          text: `${meldExceptionCriteria?.pah?.pahExceptionPoints}`,
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 2,
                        },
                      ],

                      [
                        {
                          text: 'Does the patient have Hepatocellular Carcinoma (HCC)?',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                          bold: true,
                        },
                        {
                          text: `${_yesNoAndEmptyBooleanFormatter(
                            meldExceptionCriteria?.hcc?.hccYesNo
                          )}`,
                          colSpan: 2,
                        },
                        {},
                      ],
                      [
                        {
                          text: `Attachment`,
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${
                            meldExceptionCriteria?.hcc?.hccYesNoAttachment?.name ? 'ATTACHED' : '-'
                          }`,
                          colSpan: 2,
                        },
                        {},
                      ],
                      [
                        {
                          text: 'Single tumor less than equals 6.5cm',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${_yesNoAndEmptyBooleanFormatter(
                            meldExceptionCriteria?.hcc?.hccSingleTumorLessThan6AndHalfCm
                          )}`,
                          colSpan: 2,
                        },
                        {},
                      ],
                      [
                        {
                          text: 'less than equals 3 tumors with largest tumor less than equals 4.5cm',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${_yesNoAndEmptyBooleanFormatter(
                            meldExceptionCriteria?.hcc
                              ?.hccLessThan3TumorWithLargestLessThan4AndHalfcm
                          )}`,
                          colSpan: 2,
                        },
                        {},
                      ],
                      [
                        {
                          text: 'less than equals 3 tumors with total tumor diameter less than equals 8cm',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${_yesNoAndEmptyBooleanFormatter(
                            meldExceptionCriteria?.hcc?.hccLessThan3TumorDiameterLessThan8cm
                          )}`,
                          colSpan: 2,
                        },
                        {},
                      ],
                      [
                        {
                          text: ' Within UCSF criteria',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },
                        {
                          text: `${meldExceptionCriteria?.hcc?.hccWithinUcsfCriteria}`,
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 2,
                        },
                      ],
                      [
                        {
                          text: 'Largest tumor size (cm)',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },
                        {
                          text: `${meldExceptionCriteria?.hcc?.hccLargestTumorSize}`,
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 2,
                        },
                      ],
                      [
                        {
                          text: 'Active portal vein tumor thrombus (PVTT) (bland PVT is acceptable)',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${_yesNoAndEmptyBooleanFormatter(
                            meldExceptionCriteria?.hcc?.hccActivePvtt
                          )}`,
                          colSpan: 2,
                        },
                        {},
                      ],
                      [
                        {
                          text: 'Extrahepatic disease',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${_yesNoAndEmptyBooleanFormatter(
                            meldExceptionCriteria?.hcc?.hccExtrahepaticDisease
                          )}`,
                          colSpan: 2,
                        },
                        {},
                      ],
                      [
                        {
                          text: 'At least one lesion should be greater than 2 cms',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${_yesNoAndEmptyBooleanFormatter(
                            meldExceptionCriteria?.hcc?.hccAtleastOneLesionGreaterThan2cm
                          )}`,
                          colSpan: 2,
                        },
                        {},
                      ],
                      [
                        {
                          text: 'AFP value',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },
                        {
                          text: `${meldExceptionCriteria?.hcc?.hccAfp}`,
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 2,
                        },
                      ],
                      [
                        {
                          text: `AFP Report`,
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${
                            meldExceptionCriteria?.hcc?.hccAfpReports?.name ? 'ATTACHED' : '-'
                          }`,
                          colSpan: 2,
                        },
                        {},
                      ],

                      [
                        {
                          text: `AFP Report Date`,
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${
                            meldExceptionCriteria?.hcc?.hccAfpReportsDate
                              ? moment(
                                  meldExceptionCriteria?.hcc?.hccAfpReportsDate,
                                  FORMAT_DATE_UNIXTS
                                ).format('DD/MM/YYYY')
                              : '-'
                          }`,
                          colSpan: 2,
                        },
                        {},
                      ],
                      [
                        {
                          text: `Date of last Triphasic CT / MRI`,
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${
                            meldExceptionCriteria?.hcc?.hccDateOfLastTriphasicCt
                              ? moment(
                                  meldExceptionCriteria?.hcc?.hccDateOfLastTriphasicCt,
                                  FORMAT_DATE_UNIXTS
                                ).format('DD/MM/YYYY')
                              : '-'
                          }`,
                          colSpan: 2,
                        },
                        {},
                      ],
                      [
                        {
                          text: 'Duration from last Triphasic CT/ MRI',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${meldExceptionCriteria?.hcc?.hccDurationFromLastTriphasicCt}`,
                          colSpan: 2,
                        },
                        {},
                      ],

                      [
                        {
                          text: `Triphasic CT Scan`,
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${
                            meldExceptionCriteria?.hcc?.hccTriphasisCtScan?.name ? 'ATTACHED' : '-'
                          }`,
                          colSpan: 2,
                        },
                        {},
                      ],

                      [
                        {
                          text: `Date of last bone scan`,
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${
                            meldExceptionCriteria?.hcc?.hccDateOfLastBoneScan
                              ? moment(
                                  meldExceptionCriteria?.hcc?.hccDateOfLastBoneScan,
                                  FORMAT_DATE_UNIXTS
                                ).format('DD/MM/YYYY')
                              : '-'
                          }`,
                          colSpan: 2,
                        },
                        {},
                      ],
                      [
                        {
                          text: 'Duration from last bone scan',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${meldExceptionCriteria?.hcc?.hccDurationFromlastBoneScan}`,
                          colSpan: 2,
                        },
                        {},
                      ],

                      [
                        {
                          text: `Bone Scan`,
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${
                            meldExceptionCriteria?.hcc?.hccBoneScan?.name ? 'ATTACHED' : '-'
                          }`,
                          colSpan: 2,
                        },
                        {},
                      ],

                      [
                        {
                          text: `Date of last HRCT chest`,
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${
                            meldExceptionCriteria?.hcc?.hccDateOfLastHrctChest
                              ? moment(
                                  meldExceptionCriteria?.hcc?.hccDateOfLastHrctChest,
                                  FORMAT_DATE_UNIXTS
                                ).format('DD/MM/YYYY')
                              : '-'
                          }`,
                          colSpan: 2,
                        },
                        {},
                      ],
                      [
                        {
                          text: 'Duration from last  HRCT chest',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${meldExceptionCriteria?.hcc?.hccDurationFromLastHrctChest}`,
                          colSpan: 2,
                        },
                        {},
                      ],

                      [
                        {
                          text: `HRCT Chest Scan`,
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${
                            meldExceptionCriteria?.hcc?.hccHrctChestScan?.name ? 'ATTACHED' : '-'
                          }`,
                          colSpan: 2,
                        },
                        {},
                      ],

                      [
                        {
                          text: `Date of last PET CT `,
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${
                            meldExceptionCriteria?.hcc?.hccDateOfLastPetCt
                              ? moment(
                                  meldExceptionCriteria?.hcc?.hccDateOfLastPetCt,
                                  FORMAT_DATE_UNIXTS
                                ).format('DD/MM/YYYY')
                              : '-'
                          }`,
                          colSpan: 2,
                        },
                        {},
                      ],
                      [
                        {
                          text: 'Duration from last PET CT',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${meldExceptionCriteria?.hcc?.hccDurationFromLastPetCt}`,
                          colSpan: 2,
                        },
                        {},
                      ],

                      [
                        {
                          text: `PET CT Scan`,
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${
                            meldExceptionCriteria?.hcc?.hccPetCtScan?.name ? 'ATTACHED' : '-'
                          }`,
                          colSpan: 2,
                        },
                        {},
                      ],
                      [
                        {
                          text: 'Eligible for HCC exception points',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${meldExceptionCriteria?.hcc?.eligibleForHccExceptionPoints}`,
                          colSpan: 2,
                        },
                        {},
                      ],
                      [
                        {
                          text: 'HCC exception points',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${meldExceptionCriteria?.hcc?.hccExceptionPoints}`,
                          colSpan: 2,
                        },
                        {},
                      ],
                      [
                        {
                          text: 'Treatment Details',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 3,
                        },
                        {},
                        {},
                      ],
                      [
                        {
                          text: 'TACE',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${_yesNoAndEmptyBooleanFormatter(
                            routineInfo?.hccTreatment?.tace
                          )}`,
                          colSpan: 2,
                        },
                        {},
                      ],
                      [
                        {
                          text: 'TARE',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${_yesNoAndEmptyBooleanFormatter(
                            routineInfo?.hccTreatment?.tare
                          )}`,
                          colSpan: 2,
                        },
                        {},
                      ],
                      [
                        {
                          text: 'Does the patient have done any treatment for HCC?',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${_yesNoAndEmptyBooleanFormatter(routineInfo?.hccTreatment?.rfa)}`,
                          colSpan: 2,
                        },
                        {},
                      ],
                      [
                        {
                          text: 'Resection',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${_yesNoAndEmptyBooleanFormatter(
                            routineInfo?.hccTreatment?.resection
                          )}`,
                          colSpan: 2,
                        },
                        {},
                      ],
                      [
                        {
                          text: 'MWA',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${_yesNoAndEmptyBooleanFormatter(routineInfo?.hccTreatment?.mwa)}`,
                          colSpan: 2,
                        },
                        {},
                      ],

                      [
                        {
                          text: 'Other',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${routineInfo?.hccTreatment?.other}`,
                          colSpan: 2,
                        },
                        {},
                      ],
                      [
                        {
                          text: `Attachment`,
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${routineInfo?.hccTreatment?.attachment?.name ? 'ATTACHED' : '-'}`,
                          colSpan: 2,
                        },
                        {},
                      ],
                      [
                        {
                          text: 'Does the patient have Primary Sclerosing Cholangitis (PSC)?',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                          bold: true,
                        },
                        {
                          text: `${_yesNoAndEmptyBooleanFormatter(
                            meldExceptionCriteria?.psc?.pscYesNo
                          )}`,
                          colSpan: 2,
                        },
                        {},
                      ],
                      [
                        {
                          text: `Attachment`,
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${
                            meldExceptionCriteria?.psc?.pscYesNoAttachment?.name ? 'ATTACHED' : '-'
                          }`,
                          colSpan: 2,
                        },
                        {},
                      ],
                      [
                        {
                          text: 'Proven on MRCP',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${_yesNoAndEmptyBooleanFormatter(
                            meldExceptionCriteria?.psc?.pscProvenOnMrcp
                          )}`,
                          colSpan: 2,
                        },
                        {},
                      ],
                      [
                        {
                          text: 'Recurrent cholangitis (>= 4 episodes in a year)',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${_yesNoAndEmptyBooleanFormatter(
                            meldExceptionCriteria?.psc?.pscRecurrentCholangitisLessThan4Episodes
                          )}`,
                          colSpan: 2,
                        },
                        {},
                      ],
                      [
                        {
                          text: 'Refractory cholangitis',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${_yesNoAndEmptyBooleanFormatter(
                            meldExceptionCriteria?.psc?.pscRefactoryCholangitis
                          )}`,
                          colSpan: 2,
                        },
                        {},
                      ],
                      [
                        {
                          text: 'Severe Intractable itching after therapy',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${_yesNoAndEmptyBooleanFormatter(
                            meldExceptionCriteria?.psc?.pscSevereIntractableItchingAfterTherapy
                          )}`,
                          colSpan: 2,
                        },
                        {},
                      ],
                      [
                        {
                          text: 'Eligible for PSC exception points',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },
                        {
                          text: `${meldExceptionCriteria?.psc?.eligibleForPscExceptionPoints}`,
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 2,
                        },
                      ],
                      [
                        {
                          text: 'PSC exception points',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },
                        {
                          text: `${meldExceptionCriteria?.psc?.pscExceptionPoints}`,
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 2,
                        },
                      ],

                      [
                        {
                          text: 'Does the patient have Primary Biliary Cholangitis (PBC)?',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                          bold: true,
                        },
                        {
                          text: `${_yesNoAndEmptyBooleanFormatter(
                            meldExceptionCriteria?.pbc?.pbcYesNo
                          )}`,
                          colSpan: 2,
                        },
                        {},
                      ],
                      [
                        {
                          text: `Attachment`,
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${
                            meldExceptionCriteria?.pbc?.pbcYesNoAttachment?.name ? 'ATTACHED' : '-'
                          }`,
                          colSpan: 2,
                        },
                        {},
                      ],
                      [
                        {
                          text: 'AMA positive ± Biopsy proven',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${_yesNoAndEmptyBooleanFormatter(
                            meldExceptionCriteria?.pbc?.pbcAmaPositive
                          )}`,
                          colSpan: 2,
                        },
                        {},
                      ],
                      [
                        {
                          text: 'Severe intractable pruritus after treatment',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${_yesNoAndEmptyBooleanFormatter(
                            meldExceptionCriteria?.pbc?.pbcSevereIntractablePruritusAfterTreatment
                          )}`,
                          colSpan: 2,
                        },
                        {},
                      ],
                      [
                        {
                          text: 'Severe metabolic bone disease (non-traumatic fractures)',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${_yesNoAndEmptyBooleanFormatter(
                            meldExceptionCriteria?.pbc
                              ?.pbcSevereMetabolicBoneDiseaseNonTraumaticFractures
                          )}`,
                          colSpan: 2,
                        },
                        {},
                      ],
                      [
                        {
                          text: 'Severe metabolic bone disease (Z score less than -4 on DEXA scan)',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${_yesNoAndEmptyBooleanFormatter(
                            meldExceptionCriteria?.pbc?.pbcSevereMetabolicBoneDiseaseZScore
                          )}`,
                          colSpan: 2,
                        },
                        {},
                      ],
                      [
                        {
                          text: 'Eligible for PBC exception points',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },
                        {
                          text: `${meldExceptionCriteria?.pbc?.eligibleForPbcExceptionPoints}`,
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 2,
                        },
                      ],
                      [
                        {
                          text: 'PBC exception points',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },
                        {
                          text: `${meldExceptionCriteria?.pbc?.pbcExceptionPoints}`,
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 2,
                        },
                      ],
                      [
                        {
                          text: 'Does the post transplant patient require Retransplant?',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                          bold: true,
                        },
                        {
                          text: `${_yesNoAndEmptyBooleanFormatter(
                            meldExceptionCriteria?.postTransplantPatientRequiringRetransplant
                              ?.postTransplantPatientRequiringRetransplantYesNo
                          )}`,
                          colSpan: 2,
                        },
                        {},
                      ],
                      [
                        {
                          text: `Attachment`,
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${
                            meldExceptionCriteria?.postTransplantPatientRequiringRetransplant
                              ?.postTransplantPatientRequiringRetransplantYesNoAttachment?.name
                              ? 'ATTACHED'
                              : '-'
                          }`,
                          colSpan: 2,
                        },
                        {},
                      ],
                      [
                        {
                          text: 'Eligible for super-urgent listing(rejection/late HAT/ITBL)',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${_yesNoAndEmptyBooleanFormatter(
                            meldExceptionCriteria?.postTransplantPatientRequiringRetransplant
                              ?.postTransplantPatientRequiringRetransplantEligibleForSuperUrgentListing
                          )}`,
                          colSpan: 2,
                        },
                        {},
                      ],
                      [
                        {
                          text: 'Eligible for Post Transplant Patient Requiring Retransplant exception points',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },
                        {
                          text: `${meldExceptionCriteria?.postTransplantPatientRequiringRetransplant?.eligibleForPostTransplantPatientRequiringRetransplantExceptionPoints}`,
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 2,
                        },
                      ],
                      [
                        {
                          text: 'Post Transplant Patient Requiring Retransplant exception points',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },
                        {
                          text: `${meldExceptionCriteria?.postTransplantPatientRequiringRetransplant?.postTransplantPatientRequiringRetransplantExceptionPoints}`,
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 2,
                        },
                      ],
                      [
                        {
                          text: 'Does the patient have Metabolic Liver Diseases?',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                          bold: true,
                        },
                        {
                          text: `${_yesNoAndEmptyBooleanFormatter(
                            meldExceptionCriteria?.mld?.mldYesNo
                          )}`,
                          colSpan: 2,
                        },
                        {},
                      ],
                      [
                        {
                          text: `Attachment`,
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${
                            meldExceptionCriteria?.mld?.mldYesNoAttachment?.name ? 'ATTACHED' : '-'
                          }`,
                          colSpan: 2,
                        },
                        {},
                      ],
                      [
                        {
                          text: 'Familial amyloid polyneuropathy',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${_yesNoAndEmptyBooleanFormatter(
                            meldExceptionCriteria?.mld?.mldFamilialAmyloidPolyneurpathy
                          )}`,
                          colSpan: 2,
                        },
                        {},
                      ],
                      [
                        {
                          text: 'Maple syrup urine disease',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${_yesNoAndEmptyBooleanFormatter(
                            meldExceptionCriteria?.mld?.mldMapleSyrupUrineDisease
                          )}`,
                          colSpan: 2,
                        },
                      ],
                      [
                        {
                          text: 'Eligible for Metabolic liver diseases (Native liver offered for Domino liver transplant) exception points',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },
                        {
                          text: `${meldExceptionCriteria?.mld?.eligibleForMldExceptionPoints}`,
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 2,
                        },
                      ],
                      [
                        {
                          text: 'Metabolic liver diseases (Native liver offered for Domino liver transplant) exception points',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },
                        {
                          text: `${meldExceptionCriteria?.mld?.mldExceptionPoints}`,
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 2,
                        },
                      ],

                      [
                        {
                          text: 'Does the patient have Metabolic Liver Diseases (other than Wilsons)?',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                          bold: true,
                        },
                        {
                          text: `${_yesNoAndEmptyBooleanFormatter(
                            meldExceptionCriteria?.mldOtw?.mldOtwYesNo
                          )}`,
                          colSpan: 2,
                        },
                        {},
                      ],
                      [
                        {
                          text: `Attachment`,
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${
                            meldExceptionCriteria?.mldOtw?.mldOtwYesNoAttachment?.name
                              ? 'ATTACHED'
                              : '-'
                          }`,
                          colSpan: 2,
                        },
                        {},
                      ],
                      [
                        {
                          text: 'Familial hypercholesterolemia',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${_yesNoAndEmptyBooleanFormatter(
                            meldExceptionCriteria?.mldOtw?.mldOtwFamilialHypercholesterolemia
                          )}`,
                          colSpan: 2,
                        },
                        {},
                      ],
                      [
                        {
                          text: 'Sickle cell hepatopathy',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },
                        {
                          text: `${_yesNoAndEmptyBooleanFormatter(
                            meldExceptionCriteria?.mldOtw?.mldOtwSickleCellHepatopathy
                          )}`,
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 2,
                        },
                      ],
                      [
                        {
                          text: 'Primary hyperoxaluria',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },
                        {
                          text: `${_yesNoAndEmptyBooleanFormatter(
                            meldExceptionCriteria?.mldOtw?.mldOtwPrimaryHyperoxaluria
                          )}`,
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 2,
                        },
                      ],
                      [
                        {
                          text: 'Polycystic liver disease',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },
                        {
                          text: `${_yesNoAndEmptyBooleanFormatter(
                            meldExceptionCriteria?.mldOtw?.mldOtwPolycysticLiverDisease
                          )}`,
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 2,
                        },
                      ],
                      [
                        {
                          text: 'Eligible for Metabolic liver diseases (other than Wilsons) exception points',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },
                        {
                          text: `${meldExceptionCriteria?.mldOtw?.eligibleForMldOtwExceptionPoints}`,
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 2,
                        },
                      ],
                      [
                        {
                          text: 'Metabolic liver diseases (other than Wilsons) exception points',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },
                        {
                          text: `${meldExceptionCriteria?.mldOtw?.mldOtwExceptionPoints}`,
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 2,
                        },
                      ],

                      [
                        {
                          text: 'Does the patient have Non Hepatocellular Carcinoma (Non-HCC)?',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                          bold: true,
                        },
                        {
                          text: `${_yesNoAndEmptyBooleanFormatter(
                            meldExceptionCriteria?.nonHcc?.nonHccYesNo
                          )}`,
                          colSpan: 2,
                        },
                        {},
                      ],
                      [
                        {
                          text: `Attachment`,
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${
                            meldExceptionCriteria?.nonHcc?.nonHccYesNoAttachment?.name
                              ? 'ATTACHED'
                              : '-'
                          }`,
                          colSpan: 2,
                        },
                        {},
                      ],
                      [
                        {
                          text: 'Hepatic epithelioid hemangioendothelioma',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${_yesNoAndEmptyBooleanFormatter(
                            meldExceptionCriteria?.nonHcc?.nonHccHepaticEpithelioid
                          )}`,
                          colSpan: 2,
                        },
                        {},
                      ],
                      [
                        {
                          text: 'Bilobar NET (Neuro Endocrine Tumor) liver metastases: at least 6 months after resolution of primary tumor',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },
                        {
                          text: `${_yesNoAndEmptyBooleanFormatter(
                            meldExceptionCriteria?.nonHcc?.nonHccBilobarNetSixMonths
                          )}`,
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 2,
                        },
                      ],
                      [
                        {
                          text: 'Bilobar NET (Neuro Endocrine Tumor) liver metastases:confirmed metastasis restricted to liver by whole body special scans',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },
                        {
                          text: `${_yesNoAndEmptyBooleanFormatter(
                            meldExceptionCriteria?.nonHcc?.nonHccBilobarNetSpecialScans
                          )}`,
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 2,
                        },
                      ],

                      [
                        {
                          text: 'Eligible for Non HCC exception points ',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },
                        {
                          text: `${meldExceptionCriteria?.nonHcc?.eligibleForNonHccExceptionPoints}`,
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 2,
                        },
                      ],
                      [
                        {
                          text: 'Non HCC exception points',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },
                        {
                          text: `${meldExceptionCriteria?.nonHcc?.nonHccExceptionPoints}`,
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 2,
                        },
                      ],
                      [
                        {
                          text: 'Eligible for Meld Exception Criteria Points',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                          bold: true,
                        },
                        {
                          text: `${meldExceptionCriteria?.meldExceptionCriteriaResult?.meldExceptionCriteriaPointsEligible}`,
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 2,
                        },
                      ],
                      [
                        {
                          text: 'Meld Exception Criteria points (D)',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                          bold: true,
                        },
                        {
                          text: `${meldExceptionCriteria?.meldExceptionCriteriaResult?.meldExceptionCriteriaPoints}`,
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 2,
                        },
                      ],
                      [
                        {
                          text: 'MLAS Score',
                          style: 'tableHeader',
                          alignment: 'center',
                          colSpan: 3,
                          bold: true,
                        },
                        {},
                        {},
                      ],
                      [
                        {
                          text: 'MLAS (A)',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                          bold: true,
                        },
                        {
                          text: `${meldPeld?.meld ? meldPeld?.meld : '-'}`,
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 2,
                        },
                      ],
                      [
                        {
                          text: 'Complication Score (B)',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                          bold: true,
                        },
                        {
                          text: `${
                            complicationScore?.complicationScoreResult?.complicationPoints
                              ? complicationScore?.complicationScoreResult?.complicationPoints
                              : '-'
                          }`,
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 2,
                        },
                      ],
                      [
                        {
                          text: 'Waiting Time Score (C) ',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                          bold: true,
                        },
                        {
                          text: `${'0'}`,
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 2,
                        },
                      ],
                      [
                        {
                          text: 'MELD Exception Score (D)',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                          bold: true,
                        },
                        {
                          text: `${
                            meldExceptionCriteria?.meldExceptionCriteriaResult
                              ?.meldExceptionCriteriaPoints
                              ? meldExceptionCriteria?.meldExceptionCriteriaResult
                                  ?.meldExceptionCriteriaPoints
                              : '-'
                          }`,
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 2,
                        },
                      ],
                      [
                        {
                          text: 'Listing MLAS Score',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                          bold: true,
                        },
                        {
                          text: `${_getMlasScore()}`,
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 2,
                        },
                      ],
                      [
                        {
                          text: 'Updated MLAS Score',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                          bold: true,
                        },
                        {
                          text: `${'NA'}`,
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 2,
                        },
                      ],
                      [
                        {
                          text: 'Current MLAS Score',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                          bold: true,
                        },
                        {
                          text: `${_getMlasScore()}`,
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 2,
                        },
                      ],
                    ],
                  },
                },
                {
                  style: 'table',
                  table: {
                    headerRows: 1,
                    widths: ['*', '*', '*'],
                    body: [
                      [
                        {
                          text: 'Gastroenterologist Team',
                          style: 'tableHeader',
                          alignment: 'left',
                        },

                        { text: 'Hepatologist Team', style: 'tableHeader', alignment: 'left' },

                        { text: 'Transplant Surgeon', style: 'tableHeader', alignment: 'left' },
                      ],
                      [
                        (liverCategoryFormDetails?.gastroenterologistTeam || [])?.map((e) => [
                          {
                            text: `${e.user.name ? e.user.name : '-'}`,
                            style: 'tableCell',
                            alignment: 'left',
                          },
                        ]),
                        (liverCategoryFormDetails?.hepatologistTeam || [])?.map((e) => [
                          {
                            text: `${e.user.name ? e.user.name : '-'}`,
                            style: 'tableCell',
                            alignment: 'left',
                          },
                        ]),
                        (liverCategoryFormDetails?.transplantSurgeon || [])?.map((e) => [
                          {
                            text: `${e.user.name ? e.user.name : '-'}`,
                            style: 'tableCell',
                            alignment: 'left',
                          },
                        ]),
                      ],
                    ],
                  },
                },
              ],
            }
          : null,

        // organs.includes('LIVER') && basicDetails?.waitingStatus === 'SUPERURGENT'
        organs.some((organ) => organ.organType === 'LIVER' && organ.category === 'SUPERURGENT')
          ? {
              style: 'table',
              table: {
                widths: [250, 100, 150, 'auto', 'auto'],
                heights: [
                  'auto',
                  20,
                  'auto',
                  'auto',
                  'auto',
                  'auto',
                  'auto',
                  'auto',
                  'auto',
                  'auto',
                  20,
                  'auto',
                  'auto',
                  'auto',
                  20,
                  'auto',
                  'auto',
                  'auto',
                ],
                body: [
                  [
                    {
                      text: 'Liver Super Urgent Medical Information',
                      style: 'tableHeader',
                      colSpan: 3,
                      alignment: 'center',
                      bold: true,
                    },
                    {},
                    {},
                  ],

                  [
                    {
                      text: 'Grade of Hepatic Encephalopathy',
                      style: 'textStyle',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `${
                        liverSuperUrgentInfo?.general?.hepaticEncephalopathyGrade
                          ? liverSuperUrgentInfo?.general?.hepaticEncephalopathyGrade
                          : '-'
                      }`,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'Bilirubin (mg/dL)',
                      style: 'textStyle',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `${
                        liverSuperUrgentInfo?.general?.bilirubin
                          ? liverSuperUrgentInfo?.general?.bilirubin
                          : '-'
                      }`,
                      colSpan: 2,
                    },
                    {},
                  ],

                  [
                    {
                      text: 'Aspartate Aminotransferase(AST (IU/L)',
                      style: 'textStyle',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `${
                        liverSuperUrgentInfo?.general?.aspartateAminotransferase
                          ? liverSuperUrgentInfo?.general?.aspartateAminotransferase
                          : '-'
                      }`,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'Alkaline Phosphatase (IU/L)',
                      style: 'textStyle',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `${
                        liverSuperUrgentInfo?.general?.alkalinePhosphatase
                          ? liverSuperUrgentInfo?.general?.alkalinePhosphatase
                          : '-'
                      }`,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'Prothrombin Time(seconds)',
                      style: 'textStyle',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `${
                        liverSuperUrgentInfo?.general?.prothrombinTime
                          ? liverSuperUrgentInfo?.general?.prothrombinTime
                          : '-'
                      }`,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'INR',
                      style: 'textStyle',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `${
                        liverSuperUrgentInfo?.general?.inr
                          ? liverSuperUrgentInfo?.general?.inr
                          : '-'
                      }`,
                      colSpan: 2,
                    },
                    {},
                  ],

                  [
                    {
                      text: 'Arterial pH',
                      style: 'textStyle',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `${
                        liverSuperUrgentInfo?.general?.arterialpH
                          ? liverSuperUrgentInfo?.general?.arterialpH
                          : '-'
                      }`,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'Venous pH',
                      style: 'textStyle',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `${
                        liverSuperUrgentInfo?.general?.venouspH
                          ? liverSuperUrgentInfo?.general?.venouspH
                          : '-'
                      }`,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'Arterial Lactate (mmol/L) on admission',
                      style: 'textStyle',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `${
                        liverSuperUrgentInfo?.general?.arterialLactateOnAdmission
                          ? liverSuperUrgentInfo?.general?.arterialLactateOnAdmission
                          : '-'
                      }`,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'Arterial Lactate(mmol/L) after fluid resuscitation',
                      style: 'textStyle',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `${
                        liverSuperUrgentInfo?.general?.arterialLactateAfterFluidResuscitation
                          ? liverSuperUrgentInfo?.general?.arterialLactateAfterFluidResuscitation
                          : '-'
                      }`,
                      colSpan: 2,
                    },
                    {},
                  ],

                  [
                    {
                      text: 'Creatinine (mg/dL)',
                      style: 'textStyle',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `${
                        liverSuperUrgentInfo?.general?.creatinine
                          ? liverSuperUrgentInfo?.general?.creatinine
                          : '-'
                      }`,
                      colSpan: 2,
                    },
                    {},
                  ],

                  [
                    {
                      text: 'Albumin (g/L)',
                      style: 'textStyle',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `${
                        liverSuperUrgentInfo?.general?.albumin
                          ? liverSuperUrgentInfo?.general?.albumin
                          : '-'
                      }`,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'WBC / TLC (giga(10[object Object])/L)',
                      style: 'textStyle',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `${
                        liverSuperUrgentInfo?.general?.wbc
                          ? liverSuperUrgentInfo?.general?.wbc
                          : '-'
                      }`,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'ALF Phenotype',
                      style: 'textStyle',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    { text: `${liverSuperUrgentInfo?.alfPhenotype || '-'}`, colSpan: 2 },
                    {},
                  ],

                  [
                    {
                      text: 'Liver Status',
                      style: 'tableHeader',
                      alignment: 'center',
                      colSpan: 3,
                      bold: true,
                    },

                    {
                      text: `${
                        liverSuperUrgentInfo?.liverStatus?.appearanceOfLiver
                          ? liverSuperUrgentInfo?.liverStatus?.appearanceOfLiver
                          : '-'
                      }`,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'Appearance of Liver',
                      style: 'textStyle',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `${
                        liverSuperUrgentInfo?.liverStatus?.appearanceOfLiver
                          ? liverSuperUrgentInfo?.liverStatus?.appearanceOfLiver
                          : '-'
                      }`,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'Size of Spleen (in Cm)',
                      style: 'textStyle',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `${
                        liverSuperUrgentInfo?.liverStatus?.sizeOfSpleen
                          ? liverSuperUrgentInfo?.liverStatus?.sizeOfSpleen
                          : '-'
                      }`,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'Does the patient have ascites?',
                      style: 'textStyle',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `${
                        liverSuperUrgentInfo?.liverStatus?.ascites
                          ? liverSuperUrgentInfo?.liverStatus?.ascites
                          : '-'
                      }`,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'Does the patient have cirrhosis on biopsy?',
                      style: 'textStyle',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `${_yesNoAndEmptyStringFormatter(
                        liverSuperUrgentInfo?.liverStatus?.cirrhosis
                      )}`,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'Collaterals / Varices +',
                      style: 'textStyle',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `${_yesNoAndEmptyStringFormatter(
                        liverSuperUrgentInfo?.liverStatus?.collaterals
                      )}`,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'Previous hospitalization for decompensation of cirrhosis',
                      style: 'textStyle',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `${_yesNoAndEmptyStringFormatter(
                        liverSuperUrgentInfo?.liverStatus
                          ?.previousHospitalizationForDecompesationOfcirrhosis
                      )}`,
                      colSpan: 2,
                    },
                    {},
                  ],

                  [
                    {
                      text: 'Living Donor - Liver Failure',
                      style: 'tableHeader',
                      alignment: 'center',
                      colSpan: 3,
                      bold: true,
                    },

                    { text: '', colSpan: 2 },
                    {},
                  ],
                  [
                    {
                      text: 'Date of Living Donor Hepatectomy',
                      style: 'textStyle',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `${
                        liverSuperUrgentInfo?.livingDonorLiverFailure?.dateOfLivingDonorHepatectomy
                          ? moment(
                              liverSuperUrgentInfo?.livingDonorLiverFailure
                                ?.dateOfLivingDonorHepatectomy,
                              FORMAT_DATE_UNIXTS
                            ).format('DD/MM/YYYY')
                          : '-'
                      }`,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'Attachment Report - Living Donor Hepatectomy',
                      style: 'textStyle',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `${
                        liverSuperUrgentInfo?.livingDonorLiverFailure?.hepatectomyReport
                          ? 'ATTACHED'
                          : 'NOT ATTACHED'
                      }`,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'Eligible for Super-Urgent Listing under Living Donor with Liver failure',
                      style: 'textStyle',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `${
                        liverSuperUrgentInfo?.livingDonorLiverFailure
                          ?.eligibleForSuperUrgentListingUnderLivingDonorWithLiverFailure
                          ? liverSuperUrgentInfo?.livingDonorLiverFailure
                              ?.eligibleForSuperUrgentListingUnderLivingDonorWithLiverFailure
                          : '-'
                      }`,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'Primary Non-Function',
                      style: 'tableHeader',
                      alignment: 'center',
                      colSpan: 3,
                      bold: true,
                    },

                    { text: '', colSpan: 2 },
                    {},
                  ],
                  [
                    {
                      text: 'Does the patient the DDLT(Deceased Donor Liver Transplantation) graft dysfunction?',
                      style: 'textStyle',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `${_yesNoAndEmptyBooleanFormatter(
                        liverSuperUrgentInfo?.primaryNonFunction?.ddlt
                      )}`,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'Date of Deceased Donor Liver Transplantion?',
                      style: 'textStyle',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `${
                        liverSuperUrgentInfo?.primaryNonFunction
                          ?.dateOfDeceasedDonorLiverTransplantation
                          ? moment(
                              liverSuperUrgentInfo?.primaryNonFunction
                                ?.dateOfDeceasedDonorLiverTransplantation,
                              FORMAT_DATE_UNIXTS
                            ).format('DD/MM/YYYY')
                          : '-'
                      }`,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'Attachment Report - Liver Transplantation',
                      style: 'textStyle',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `${
                        liverSuperUrgentInfo?.primaryNonFunction?.liverTransplantationReport
                          ? 'ATTACHED'
                          : 'NOT ATTACHED'
                      }`,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'Vascular Complications',
                      style: 'textStyle',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `${_yesNoAndEmptyBooleanFormatter(
                        liverSuperUrgentInfo?.primaryNonFunction?.vascularComplications
                      )}`,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'Sepsis',
                      style: 'textStyle',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `${_yesNoAndEmptyBooleanFormatter(
                        liverSuperUrgentInfo?.primaryNonFunction?.sepsis
                      )}`,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'Eligible for Super-Urgent Listing under Primary Non-Function',
                      style: 'textStyle',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `${
                        liverSuperUrgentInfo?.primaryNonFunction
                          ?.eligibleForSuperUrgentListingUnderPrimaryNonFunction
                          ? liverSuperUrgentInfo?.primaryNonFunction
                              ?.eligibleForSuperUrgentListingUnderPrimaryNonFunction
                          : '-'
                      }`,
                      colSpan: 2,
                    },
                    {},
                  ],

                  [
                    {
                      text: 'Anhepetic Patient',
                      style: 'tableHeader',
                      alignment: 'center',
                      colSpan: 3,
                      bold: true,
                    },

                    { text: '', colSpan: 2 },
                    {},
                  ],
                  [
                    {
                      text: 'Does the patient have anhepatic after total hepatectomy e.g. for trauma etc?',
                      style: 'textStyle',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `${_yesNoAndEmptyBooleanFormatter(
                        liverSuperUrgentInfo?.anhepaticPatient?.anhepaticAfterTotalHepatectomy
                      )}`,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'Eligible for Super-Urgent Listing under Anhepatic Patient',
                      style: 'textStyle',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `${
                        liverSuperUrgentInfo?.anhepaticPatient
                          ?.eligibleForSuperUrgentListingUnderAnhepaticPatient
                          ? liverSuperUrgentInfo?.anhepaticPatient
                              ?.eligibleForSuperUrgentListingUnderAnhepaticPatient
                          : '-'
                      }`,
                      colSpan: 2,
                    },
                    {},
                  ],

                  [
                    {
                      text: 'Paracetamol Poisoning',
                      style: 'tableHeader',
                      colSpan: 3,
                      alignment: 'center',
                      bold: true,
                    },
                    {},
                    {},
                  ],

                  [
                    {
                      text: 'Date of Deceased Donor Liver Transplantion?',
                      style: 'textStyle',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `${
                        liverSuperUrgentInfo?.paracetamolPoisioning
                          ?.dateOfDeceasedDonorLiverTransplantation
                          ? moment(
                              liverSuperUrgentInfo?.paracetamolPoisioning
                                ?.dateOfDeceasedDonorLiverTransplantation,
                              FORMAT_DATE_UNIXTS
                            ).format('DD/MM/YYYY')
                          : '-'
                      }`,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'Does the patient have any significant liver injury and coagulopathy after adequate fluid resuscitation ?',
                      style: 'textStyle',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `${_yesNoAndEmptyBooleanFormatter(
                        liverSuperUrgentInfo?.paracetamolPoisioning
                          ?.phGreaterThanSevenPointTwentyFive
                      )}`,
                      colSpan: 2,
                    },
                    {},
                  ],

                  [
                    {
                      text: 'Does the patient have any causes of hyperlactatemia( e.g. pancreatitis, intestinal ischemia) ?',
                      style: 'textStyle',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `${_yesNoAndEmptyBooleanFormatter(
                        liverSuperUrgentInfo?.paracetamolPoisioning?.liverInjuryAndCoagulopathy
                      )}`,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'Does the patient have anuria?',
                      style: 'textStyle',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `${_yesNoAndEmptyBooleanFormatter(
                        liverSuperUrgentInfo?.paracetamolPoisioning?.anuria
                      )}`,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'Does the patient have any absence of clinical sepsis?',
                      style: 'textStyle',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `${_yesNoAndEmptyBooleanFormatter(
                        liverSuperUrgentInfo?.paracetamolPoisioning?.absenceOfClinicalSepsis
                      )}`,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'Does the patient have any clinical evidence of deterioration?',
                      style: 'textStyle',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `${_yesNoAndEmptyBooleanFormatter(
                        liverSuperUrgentInfo?.paracetamolPoisioning?.clinicalEvidenceOfDeterioration
                      )}`,
                      colSpan: 2,
                    },
                    {},
                  ],

                  [
                    {
                      text: 'Intracranial Pressure ICP (mmHg)',
                      style: 'textStyle',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `${
                        liverSuperUrgentInfo?.paracetamolPoisioning?.intracranialPressure
                          ? liverSuperUrgentInfo?.paracetamolPoisioning?.intracranialPressure
                          : '-'
                      }`,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'FiO2 (in %)',
                      style: 'textStyle',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `${
                        liverSuperUrgentInfo?.paracetamolPoisioning?.fio2
                          ? liverSuperUrgentInfo?.paracetamolPoisioning?.fio2
                          : '-'
                      }`,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'Inotrope',
                      style: 'textStyle',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `${
                        liverSuperUrgentInfo?.paracetamolPoisioning?.inotrope
                          ? liverSuperUrgentInfo?.paracetamolPoisioning?.inotrope
                          : '-'
                      }`,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'NHS Criteria',
                      style: 'textStyle',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `${
                        liverSuperUrgentInfo?.paracetamolPoisioning?.nhsCriteria
                          ? liverSuperUrgentInfo?.paracetamolPoisioning?.nhsCriteria
                          : '-'
                      }`,
                      colSpan: 2,
                    },
                    {},
                  ],

                  [
                    {
                      text: 'Eligible for Super-Urgent Listing under Paracetamol Poissioning',
                      style: 'textStyle',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `${
                        liverSuperUrgentInfo?.paracetamolPoisioning
                          ?.eligibleForSuperUrgentListingUnderParacetamolPoissioning
                          ? liverSuperUrgentInfo?.paracetamolPoisioning
                              ?.eligibleForSuperUrgentListingUnderParacetamolPoissioning
                          : '-'
                      }`,
                      colSpan: 2,
                    },
                    {},
                  ],

                  [
                    {
                      text: 'Favourable Etiologies',
                      style: 'tableHeader',
                      alignment: 'center',
                      colSpan: 3,
                      bold: true,
                    },

                    { text: '', colSpan: 2 },
                    {},
                  ],
                  [
                    {
                      text: 'Select list of favourable etiologies',
                      style: 'textstyle',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `${
                        liverSuperUrgentInfo?.favourableEtiologies?.favourableEtiologies
                          ? liverSuperUrgentInfo?.favourableEtiologies?.favourableEtiologies
                          : '-'
                      }`,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'Does the patient have jaundice? ',
                      style: 'textStyle',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `${_yesNoAndEmptyBooleanFormatter(
                        liverSuperUrgentInfo?.favourableEtiologies?.jaundice
                      )}`,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'Date of onset of Jaundice',
                      style: 'textStyle',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `${
                        liverSuperUrgentInfo?.favourableEtiologies?.dateOfOnsetOfJaundice
                          ? moment(
                              liverSuperUrgentInfo?.favourableEtiologies?.dateOfOnsetOfJaundice,
                              FORMAT_DATE_UNIXTS
                            ).format('DD/MM/YYYY')
                          : '-'
                      }`,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'Date of onset of Encephalopathy',
                      style: 'textStyle',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `${
                        liverSuperUrgentInfo?.favourableEtiologies?.dateOfOnsetOfEncephalopathy
                          ? moment(
                              liverSuperUrgentInfo?.favourableEtiologies
                                ?.dateOfOnsetOfEncephalopathy,
                              FORMAT_DATE_UNIXTS
                            ).format('DD/MM/YYYY')
                          : '-'
                      }`,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'NHS Criteria',
                      style: 'textStyle',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `${
                        liverSuperUrgentInfo?.favourableEtiologies?.nhsCriteria
                          ? liverSuperUrgentInfo?.favourableEtiologies?.nhsCriteria
                          : '-'
                      }`,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'Eligible for Super-Urgent Listing under Favourable Etiologiesn',
                      style: 'textStyle',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `${
                        liverSuperUrgentInfo?.favourableEtiologies
                          ?.eligibleForSuperUrgentListingUnderFavourableEtiologies
                          ? liverSuperUrgentInfo?.favourableEtiologies
                              ?.eligibleForSuperUrgentListingUnderFavourableEtiologies
                          : '-'
                      }`,
                      colSpan: 2,
                    },
                    {},
                  ],

                  [
                    {
                      text: 'Unfavourable Etiologies',
                      style: 'tableHeader',
                      alignment: 'center',
                      colSpan: 3,
                      bold: true,
                    },

                    { text: '', colSpan: 2 },
                    {},
                  ],
                  [
                    {
                      text: 'Select list of unfavourable etiologies',
                      style: 'textstyle',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `${
                        liverSuperUrgentInfo?.unfavourableEtiologies?.unfavourableEtiologies
                          ? liverSuperUrgentInfo?.unfavourableEtiologies?.unfavourableEtiologies
                          : '-'
                      }`,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'Does the patient have jaundice? ',
                      style: 'textStyle',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `${_yesNoAndEmptyBooleanFormatter(
                        liverSuperUrgentInfo?.unfavourableEtiologies?.jaundice
                      )}`,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'Date of onset of Jaundice',
                      style: 'textStyle',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `${
                        liverSuperUrgentInfo?.unfavourableEtiologies?.dateOfOnsetOfJaundice
                          ? moment(
                              liverSuperUrgentInfo?.unfavourableEtiologies?.dateOfOnsetOfJaundice,
                              FORMAT_DATE_UNIXTS
                            ).format('DD/MM/YYYY')
                          : '-'
                      }`,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'Date of onset of Encephalopathy',
                      style: 'textStyle',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `${
                        liverSuperUrgentInfo?.unfavourableEtiologies?.dateOfOnsetOfEncephalopathy
                          ? moment(
                              liverSuperUrgentInfo?.unfavourableEtiologies
                                ?.dateOfOnsetOfEncephalopathy,
                              FORMAT_DATE_UNIXTS
                            ).format('DD/MM/YYYY')
                          : '-'
                      }`,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'Does the patient any vitamin K repletion?',
                      style: 'textStyle',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `${_yesNoAndEmptyBooleanFormatter(
                        liverSuperUrgentInfo?.unfavourableEtiologies?.vitaminKRepletion
                      )}`,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'NHS Criteria',
                      style: 'textStyle',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `${
                        liverSuperUrgentInfo?.unfavourableEtiologies?.nhsCriteria
                          ? liverSuperUrgentInfo?.unfavourableEtiologies?.nhsCriteria
                          : '-'
                      }`,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'Eligible for Super-Urgent Listing under Unfavourable Etiologiesn',
                      style: 'textStyle',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `${
                        liverSuperUrgentInfo?.unfavourableEtiologies
                          ?.eligibleForSuperUrgentListingUnderUnfavourableEtiologies
                          ? liverSuperUrgentInfo?.unfavourableEtiologies
                              ?.eligibleForSuperUrgentListingUnderUnfavourableEtiologies
                          : '-'
                      }`,
                      colSpan: 2,
                    },
                    {},
                  ],

                  [
                    {
                      text: 'Ratol Poisoning',
                      style: 'tableHeader',
                      alignment: 'center',
                      colSpan: 3,
                      bold: true,
                    },

                    { text: '', colSpan: 2 },
                    {},
                  ],
                  [
                    {
                      text: 'Does the patient any vitamin K repletion?',
                      style: 'textstyle',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `${_yesNoAndEmptyBooleanFormatter(
                        liverSuperUrgentInfo?.ratolPoisioning?.vitaminKRepletion
                      )}`,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'Is the patient undergoing any plasmapheresis? ',
                      style: 'textStyle',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `${_yesNoAndEmptyBooleanFormatter(
                        liverSuperUrgentInfo?.ratolPoisioning?.patientUndergoingPlasmapheresis
                      )}`,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'Has the patient undergone through Second set of Plasmapheresis?',
                      style: 'textStyle',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `${_yesNoAndEmptyBooleanFormatter(
                        liverSuperUrgentInfo?.ratolPoisioning
                          ?.patientUndergoneSecondSetPlasmapheresis
                      )}`,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'Does the patient have any Active Cardiac dysfunction (EF < 50%)?',
                      style: 'textStyle',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `${_yesNoAndEmptyBooleanFormatter(
                        liverSuperUrgentInfo?.ratolPoisioning?.activeCardiacDysfunction
                      )}`,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'Eligible for Super-Urgent Listing under Unfavourable Etiologie',
                      style: 'textStyle',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `${
                        liverSuperUrgentInfo?.ratolPoisioning
                          ?.eligibleForSuperUrgentListingUnderRatolPoisoning
                          ? liverSuperUrgentInfo?.ratolPoisioning
                              ?.eligibleForSuperUrgentListingUnderRatolPoisoning
                          : '-'
                      }`,
                      colSpan: 2,
                    },
                    {},
                  ],

                  [
                    {
                      text: 'Hepatic Artery Thrombosis',
                      style: 'tableHeader',
                      alignment: 'center',
                      colSpan: 3,
                      bold: true,
                    },

                    { text: '', colSpan: 2 },
                    {},
                  ],
                  [
                    {
                      text: 'Does the patient have any failure of revascularization(Radiologi or Surgical)?',
                      style: 'textstyle',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `${_yesNoAndEmptyBooleanFormatter(
                        liverSuperUrgentInfo?.hepaticArteryThrombosis?.failureOfRevascularization
                      )}`,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'Date of Liver Transplantation ',
                      style: 'textStyle',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `${
                        liverSuperUrgentInfo?.hepaticArteryThrombosis?.dateOfLiverTransplantation
                          ? moment(
                              liverSuperUrgentInfo?.hepaticArteryThrombosis
                                ?.dateOfLiverTransplantation,
                              FORMAT_DATE_UNIXTS
                            ).format('DD/MM/YYYY')
                          : '-'
                      }`,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'Eligible for Super-Urgent Listing under Hepatic Artery Thrombosis(HAT)',
                      style: 'textStyle',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `${
                        liverSuperUrgentInfo?.hepaticArteryThrombosis
                          ?.eligibleForSuperUrgentListingUnderHepaticArteryThrombosis
                          ? liverSuperUrgentInfo?.hepaticArteryThrombosis
                              ?.eligibleForSuperUrgentListingUnderHepaticArteryThrombosis
                          : '-'
                      }`,
                      colSpan: 2,
                    },
                    {},
                  ],

                  [
                    {
                      text: 'Acute Wilsons Disease',
                      style: 'tableHeader',
                      colSpan: 3,
                      alignment: 'center',
                      bold: true,
                    },
                    {},
                    {},
                  ],

                  [
                    {
                      text: 'Coombs-negative hemolytic anemia',
                      style: 'textStyle',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `${
                        liverSuperUrgentInfo?.acuteWilsonDisease?.coombsNegative
                          ? liverSuperUrgentInfo?.acuteWilsonDisease?.coombsNegative
                          : '-'
                      }`,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'Kayser-Fleischer rings',
                      style: 'textStyle',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `${
                        liverSuperUrgentInfo?.acuteWilsonDisease?.kayserFleischerRings
                          ? liverSuperUrgentInfo?.acuteWilsonDisease?.kayserFleischerRings
                          : '-'
                      }`,
                      colSpan: 2,
                    },
                    {},
                  ],

                  [
                    {
                      text: 'Neurologic symptoms',
                      style: 'textStyle',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `${
                        liverSuperUrgentInfo?.acuteWilsonDisease?.neurologicSymptoms
                          ? liverSuperUrgentInfo?.acuteWilsonDisease?.neurologicSymptoms
                          : '-'
                      }`,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'Serum ceruloplasmin(g/L)',
                      style: 'textStyle',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `${
                        liverSuperUrgentInfo?.acuteWilsonDisease?.serumCeruloplasmin
                          ? liverSuperUrgentInfo?.acuteWilsonDisease?.serumCeruloplasmin
                          : '-'
                      }`,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'Liver copper (in the absence of cholestasis)',
                      style: 'textStyle',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `${
                        liverSuperUrgentInfo?.acuteWilsonDisease?.liverCopper
                          ? liverSuperUrgentInfo?.acuteWilsonDisease?.liverCopper
                          : '-'
                      }`,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'Urinary copper (in the absence of acute hepatitis)',
                      style: 'textStyle',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `${
                        liverSuperUrgentInfo?.acuteWilsonDisease?.urinaryCopper
                          ? liverSuperUrgentInfo?.acuteWilsonDisease?.urinaryCopper
                          : '-'
                      }`,
                      colSpan: 2,
                    },
                    {},
                  ],

                  [
                    {
                      text: 'Mutation Analysis',
                      style: 'textStyle',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `${
                        liverSuperUrgentInfo?.acuteWilsonDisease?.mutationAnalysis
                          ? liverSuperUrgentInfo?.acuteWilsonDisease?.mutationAnalysis
                          : '-'
                      }`,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'History of Wilson’s Disease in a Family members',
                      style: 'textStyle',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `${
                        liverSuperUrgentInfo?.acuteWilsonDisease?.historyOfWilsonDiseaseInFamily
                          ? liverSuperUrgentInfo?.acuteWilsonDisease?.historyOfWilsonDiseaseInFamily
                          : '-'
                      }`,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'Wilsons Disease Scoring System (Leipzig Score)',
                      style: 'textStyle',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `${
                        liverSuperUrgentInfo?.acuteWilsonDisease?.wilsonsDiseaseScoringSystem
                          ? liverSuperUrgentInfo?.acuteWilsonDisease?.wilsonsDiseaseScoringSystem
                          : '-'
                      }`,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'Kings College Wilson Disease Prognostic Index',
                      style: 'textStyle',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `${
                        liverSuperUrgentInfo?.acuteWilsonDisease
                          ?.kingsCollegeWilsonDiseasePrognosticIndex
                          ? liverSuperUrgentInfo?.acuteWilsonDisease
                              ?.kingsCollegeWilsonDiseasePrognosticIndex
                          : '-'
                      }`,
                      colSpan: 2,
                    },
                    {},
                  ],

                  [
                    {
                      text: 'Bilirubin / Alkaline Phosphatase Ratio',
                      style: 'textStyle',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `${
                        liverSuperUrgentInfo?.acuteWilsonDisease?.bilirubinAlkalineRatio
                          ? liverSuperUrgentInfo?.acuteWilsonDisease?.bilirubinAlkalineRatio
                          : '-'
                      }`,
                      colSpan: 2,
                    },
                    {},
                  ],

                  [
                    {
                      text: 'Evidence of Hemolysis',
                      style: 'textStyle',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `${
                        liverSuperUrgentInfo?.acuteWilsonDisease?.evidenceOfHemolysis
                          ? liverSuperUrgentInfo?.acuteWilsonDisease?.evidenceOfHemolysis
                          : '-'
                      }`,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'Are there any etiologies found?',
                      style: 'textStyle',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `${
                        liverSuperUrgentInfo?.acuteWilsonDisease?.anyEtiologies
                          ? liverSuperUrgentInfo?.acuteWilsonDisease?.anyEtiologies
                          : '-'
                      }`,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'ALF Phenotype',
                      style: 'textStyle',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `${
                        liverSuperUrgentInfo?.acuteWilsonDisease
                          ?.eligibleForSuperUrgentListingUnderAcuteWilsonDisease
                          ? liverSuperUrgentInfo?.acuteWilsonDisease
                              ?.eligibleForSuperUrgentListingUnderAcuteWilsonDisease
                          : '-'
                      }`,
                      colSpan: 2,
                    },
                    {},
                  ],

                  [
                    {
                      text: 'Acute Budd-Chiari Syndrome',
                      style: 'tableHeader',
                      colSpan: 3,
                      alignment: 'center',
                      bold: true,
                    },
                    {},
                    {},
                  ],

                  [
                    {
                      text: 'Does the cross sectional imaging showing Chronic Hepatic Vein Thrombus?',
                      style: 'textStyle',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `${_yesNoAndEmptyBooleanFormatter(
                        liverSuperUrgentInfo?.acuteBuddChiariSyndrome?.chronicHepaticVeinThrombus
                      )}`,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'Does the cross sectional imaging showing Atrophy Hypertrophy Complex?',
                      style: 'textStyle',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `${_yesNoAndEmptyBooleanFormatter(
                        liverSuperUrgentInfo?.acuteBuddChiariSyndrome?.atrophyHypertrophyComplex
                      )}`,
                      colSpan: 2,
                    },
                    {},
                  ],

                  [
                    {
                      text: 'Cross - Section (CT / MRI) Imaging Date',
                      style: 'textStyle',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `${
                        liverSuperUrgentInfo?.acuteBuddChiariSyndrome?.crossSectionCtMriDate
                          ? moment(
                              liverSuperUrgentInfo?.acuteBuddChiariSyndrome?.crossSectionCtMriDate,
                              FORMAT_DATE_UNIXTS
                            ).format('DD/MM/YYYY')
                          : '-'
                      }`,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'Cross - Section (CT / MRI) Imaging Attachment',
                      style: 'textStyle',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `${
                        liverSuperUrgentInfo?.acuteBuddChiariSyndrome?.crossSectionCtMriAttachment
                          ? 'ATTACHED'
                          : 'NOT ATTACHED'
                      }`,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'Is the TIPSS Contradicted or Not Feasible?',
                      style: 'textStyle',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `${_yesNoAndEmptyBooleanFormatter(
                        liverSuperUrgentInfo?.acuteBuddChiariSyndrome?.tipss
                      )}`,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'Eligible for Super-Urgent Listing under Acute Budd-Chiari Syndrome',
                      style: 'textStyle',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `${
                        liverSuperUrgentInfo?.acuteBuddChiariSyndrome
                          ?.eligibleForSuperUrgentListingUnderAcuteBuddChiariSyndrome
                          ? liverSuperUrgentInfo?.acuteBuddChiariSyndrome
                              ?.eligibleForSuperUrgentListingUnderAcuteBuddChiariSyndrome
                          : '-'
                      }`,
                      colSpan: 2,
                    },
                    {},
                  ],

                  [
                    {
                      text: 'Acute AutoImmune Hepatitis',
                      style: 'tableHeader',
                      colSpan: 3,
                      alignment: 'center',
                      bold: true,
                    },
                    {},
                    {},
                  ],

                  [
                    {
                      text: 'Is the Liver Biopsys Done?',
                      style: 'textStyle',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `${_yesNoAndEmptyBooleanFormatter(
                        liverSuperUrgentInfo?.acuteAutoImmuneHepatitis?.liverBiopsysDone
                      )}`,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'Confluent or massive hepatic necrosis (AIH / DILI)',
                      style: 'textStyle',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `${_yesNoAndEmptyBooleanFormatter(
                        liverSuperUrgentInfo?.acuteAutoImmuneHepatitis?.massiveHepaticNecrosis
                      )}`,
                      colSpan: 2,
                    },
                    {},
                  ],

                  [
                    {
                      text: 'Marked microvesicular steatosis (DILI)',
                      style: 'textStyle',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `${_yesNoAndEmptyBooleanFormatter(
                        liverSuperUrgentInfo?.acuteAutoImmuneHepatitis?.microvesicularSteatosis
                      )}`,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'Cholestatic hepatitis with ductopenia (DILI)',
                      style: 'textStyle',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `${_yesNoAndEmptyBooleanFormatter(
                        liverSuperUrgentInfo?.acuteAutoImmuneHepatitis?.cholestaticHepatitis
                      )}`,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'MELD Score',
                      style: 'textStyle',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `${
                        liverSuperUrgentInfo?.acuteAutoImmuneHepatitis?.meldScore
                          ? liverSuperUrgentInfo?.acuteAutoImmuneHepatitis?.meldScore
                          : '-'
                      }`,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'Does the patient have improved in Bilirubin / INR with steroids for 5 days?',
                      style: 'textStyle',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `${_yesNoAndEmptyBooleanFormatter(
                        liverSuperUrgentInfo?.acuteAutoImmuneHepatitis?.improvedBilirubinOrInr
                      )}`,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'Reports Date',
                      style: 'textStyle',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `${
                        liverSuperUrgentInfo?.acuteAutoImmuneHepatitis?.reportsDate
                          ? moment(
                              liverSuperUrgentInfo?.acuteAutoImmuneHepatitis?.reportsDate,
                              FORMAT_DATE_UNIXTS
                            ).format('DD/MM/YYYY')
                          : '-'
                      }`,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'Reports Attachment',
                      style: 'textStyle',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `${
                        liverSuperUrgentInfo?.acuteAutoImmuneHepatitis?.reportsAttachment
                          ? 'ATTACHED'
                          : 'NOT ATTACHED'
                      }`,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'Eligible for Super-Urgent Listing under Acute Auto-immune Hepatitis ',
                      style: 'textStyle',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `${
                        liverSuperUrgentInfo?.acuteAutoImmuneHepatitis
                          ?.eligibleForSuperUrgentListingUnderAcuteAutoImmnuneHepatitis
                          ? liverSuperUrgentInfo?.acuteAutoImmuneHepatitis
                              ?.eligibleForSuperUrgentListingUnderAcuteAutoImmnuneHepatitis
                          : '-'
                      }`,
                      colSpan: 2,
                    },
                    {},
                  ],

                  [
                    {
                      text: 'HBV Reactivation',
                      style: 'tableHeader',
                      colSpan: 3,
                      alignment: 'center',
                      bold: true,
                    },
                    {},
                    {},
                  ],

                  [
                    {
                      text: 'lgM Core AntiBody Total',
                      style: 'textStyle',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `${
                        liverSuperUrgentInfo?.hbvReactivation?.lgmCore
                          ? liverSuperUrgentInfo?.hbvReactivation?.lgmCore
                          : '-'
                      }`,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'Any other causes',
                      style: 'textStyle',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `${
                        liverSuperUrgentInfo?.hbvReactivation?.otherCauses
                          ? liverSuperUrgentInfo?.hbvReactivation?.otherCauses
                          : '-'
                      }`,
                      colSpan: 2,
                    },
                    {},
                  ],

                  [
                    {
                      text: 'Eligible for Super-Urgent Listing under HBV reactivation',
                      style: 'textStyle',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `${
                        liverSuperUrgentInfo?.hbvReactivation
                          ?.eligibleForSuperUrgentListingUnderHbvReactivation
                          ? liverSuperUrgentInfo?.hbvReactivation
                              ?.eligibleForSuperUrgentListingUnderHbvReactivation
                          : '-'
                      }`,
                      colSpan: 2,
                    },
                    {},
                  ],

                  [
                    {
                      text: 'Pediatric Patient',
                      style: 'textStyle',
                      colSpan: 3,
                      alignment: 'center',
                      bold: true,
                    },
                    {},
                    {},
                  ],

                  [
                    {
                      text: 'Does the patient have multisystem disorder causing severe acute impairment of liver function?',
                      style: 'textStyle',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `${_yesNoAndEmptyBooleanFormatter(
                        liverSuperUrgentInfo?.pediatricPatients?.impairmentOfLiverFunction
                      )}`,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'Does the patient have hepatocellular necrosis?',
                      style: 'textStyle',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `${_yesNoAndEmptyBooleanFormatter(
                        liverSuperUrgentInfo?.pediatricPatients?.hepatocellularNecrosis
                      )}`,
                      colSpan: 2,
                    },
                    {},
                  ],

                  [
                    {
                      text: 'Does the patient have any leukaemia, lymphoma, haemophagocytosis and disseminated intra-vascular coagulopath?',
                      style: 'textStyle',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `${_yesNoAndEmptyBooleanFormatter(
                        liverSuperUrgentInfo?.pediatricPatients?.leukaemia
                      )}`,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'Eligible for Super-Urgent Listing under Pediatric Patients',
                      style: 'textStyle',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `${
                        liverSuperUrgentInfo?.pediatricPatients
                          ?.eligibleForSuperUrgentListingUnderPediatricPatients
                          ? liverSuperUrgentInfo?.pediatricPatients
                              ?.eligibleForSuperUrgentListingUnderPediatricPatients
                          : '-'
                      }`,
                      colSpan: 2,
                    },
                    {},
                  ],

                  [
                    {
                      text: 'Hepatoblastoma',
                      style: 'tableHeader',
                      colSpan: 3,
                      alignment: 'center',
                      bold: true,
                    },
                    {},
                    {},
                  ],

                  [
                    {
                      text: 'Does the patient have any extra hepatic disease?',
                      style: 'textStyle',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `${_yesNoAndEmptyBooleanFormatter(
                        liverSuperUrgentInfo?.hepatoblastoma?.extraHepaticDisease
                      )}`,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'Does the patient have the complete response to chemotherapy / R0 resection of any extra-hepatic disease ?',
                      style: 'textStyle',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `${_yesNoAndEmptyBooleanFormatter(
                        liverSuperUrgentInfo?.hepatoblastoma?.completeResponseToChemotherapy
                      )}`,
                      colSpan: 2,
                    },
                    {},
                  ],

                  [
                    {
                      text: 'Does the patient have pretext III/IV disease with good response to 6 cycles of chemotherapy (total Hepatoctomy is the only surgical measure to achieve RO)?',
                      style: 'textStyle',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `${_yesNoAndEmptyBooleanFormatter(
                        liverSuperUrgentInfo?.hepatoblastoma?.pretextDisease
                      )}`,
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: 'Eligible for Super-Urgent Listing under Hepatoblastoma',
                      style: 'textStyle',
                      alignment: 'left',
                      colSpan: 1,
                    },

                    {
                      text: `${
                        liverSuperUrgentInfo?.hepatoblastoma
                          ?.eligibleForSuperUrgentListingUnderHepatoblastoma
                          ? liverSuperUrgentInfo?.hepatoblastoma
                              ?.eligibleForSuperUrgentListingUnderHepatoblastoma
                          : '-'
                      }`,
                      colSpan: 2,
                    },
                    {},
                  ],
                ],
              },
            }
          : null,

        // organs.includes('PANCREAS')
        organs.some((organ) => organ.organType === 'PANCREAS')
          ? {
              stack: [
                {
                  style: 'table',
                  table: {
                    widths: [250, 100, 150, 'auto', 'auto'],
                    heights: [
                      'auto',
                      'auto',
                      'auto',
                      'auto',
                      'auto',
                      'auto',
                      'auto',
                      'auto',
                      'auto',
                      'auto',
                      'auto',
                      'auto',
                      'auto',
                      'auto',
                      'auto',
                    ],
                    body: [
                      [
                        {
                          text: 'State Organ and Tissue Transplant Organisation (SOTTO),\n  Maharashtra',
                          style: 'tableHeader',
                          colSpan: 3,
                          alignment: 'center',
                          bold: true,
                        },
                        {},
                        {},
                      ],
                      [
                        {
                          text: 'State Appropriate Authority of Maharashtra',
                          style: 'textStyle',
                          alignment: 'center',
                          colSpan: 3,
                        },
                        {},
                        {},
                      ],
                      [
                        {
                          text: 'PANCREAS REGISTRATION',
                          style: 'textStyle',
                          alignment: 'center',
                          colSpan: 3,
                        },
                        {},
                        {},
                      ],
                      [
                        {
                          text: ' C-Peptide(ng/mL)',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${pancreasDetails?.peptide ? pancreasDetails?.peptide : '-'}`,
                          colSpan: 2,
                        },
                        {},
                      ],

                      [
                        {
                          text: `C-Peptide Report`,
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${pancreasDetails?.peptideReport?.name ? 'ATTACHED' : '-'}`,
                          colSpan: 2,
                        },
                        {},
                      ],

                      [
                        {
                          text: 'Does the patient have Frequent episodes of Hypoglycemia unawareness?',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${_yesNoAndEmptyFormatter(pancreasDetails?.hypoglycemia)}`,
                          colSpan: 2,
                        },
                        {},
                      ],
                      [
                        {
                          text: ' Does the patient have Brittle Diabetes? ',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${_yesNoAndEmptyFormatter(pancreasDetails?.brittleDiabetes)}`,
                          colSpan: 2,
                        },
                        {},
                      ],
                      [
                        {
                          text: ' Does the patient have the pancreatic exocrine insufficiency?',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${_yesNoAndEmptyFormatter(
                            pancreasDetails?.pancreaticExocrineInsuff
                          )}`,
                          colSpan: 2,
                        },
                        {},
                      ],

                      [
                        {
                          text: ' Does the patient have Episode of Diabetic Ketoacidosis?',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${_yesNoAndEmptyFormatter(pancreasDetails?.diabeticKetoacidosis)}`,
                          colSpan: 2,
                        },
                        {},
                      ],

                      [
                        {
                          text: 'Does the patient have the poor quality of life with Insulin?',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${_yesNoAndEmptyFormatter(
                            pancreasDetails?.poorQualityOfLifeWithInsulin
                          )}`,
                          colSpan: 2,
                        },
                        {},
                      ],

                      [
                        {
                          text: 'INSULIN REQUIREMENT PER DAY (UNITS/KILOGRAMS)',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${
                            pancreasDetails?.insulinRequiredPerDay
                              ? pancreasDetails?.insulinRequiredPerDay
                              : '-'
                          }`,
                          colSpan: 2,
                        },
                        {},
                      ],

                      [
                        {
                          text: `FBS Report`,
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${pancreasDetails?.fbsReport?.name ? 'ATTACHED' : '-'}`,
                          colSpan: 2,
                        },
                        {},
                      ],

                      [
                        {
                          text: `Date of FBS Report : `,
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${
                            pancreasDetails?.fbsReportDate
                              ? moment(pancreasDetails?.fbsReportDate, FORMAT_DATE_UNIXTS).format(
                                  'DD/MM/YYYY'
                                )
                              : '-'
                          }`,
                          colSpan: 2,
                        },
                        {},
                      ],

                      [
                        {
                          text: `HbA1c Report`,
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${pancreasDetails?.hba1cReport?.name ? 'ATTACHED' : '-'}`,
                          colSpan: 2,
                        },
                        {},
                      ],
                      [
                        {
                          text: `Date of HbA1c Report : `,
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${
                            pancreasDetails?.hba1cReportDate
                              ? moment(pancreasDetails?.hba1cReportDate, FORMAT_DATE_UNIXTS).format(
                                  'DD/MM/YYYY'
                                )
                              : '-'
                          }`,
                          colSpan: 2,
                        },
                        {},
                      ],

                      [
                        {
                          text: 'Anti-Insulin Antibody',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${_yesNoAndEmptyFormatter(pancreasDetails?.antiInsulinYesNo)}`,
                          colSpan: 2,
                        },
                        {},
                      ],

                      [
                        {
                          text: `Anti-Insulin Antibodies Report`,
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${pancreasDetails?.antiInsulinReport?.name ? 'ATTACHED' : '-'}`,
                          colSpan: 2,
                        },
                        {},
                      ],
                      [
                        {
                          text: `Date of Anti-Insulin Antibodies Report: `,
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${
                            pancreasDetails?.antiInsulinReportDate
                              ? moment(
                                  pancreasDetails?.antiInsulinReportDate,
                                  FORMAT_DATE_UNIXTS
                                ).format('DD/MM/YYYY')
                              : '-'
                          }`,
                          colSpan: 2,
                        },
                        {},
                      ],

                      [
                        {
                          text: 'AntiGAD Antibody',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${_yesNoAndEmptyFormatter(pancreasDetails?.antigadYesNo)}`,
                          colSpan: 2,
                        },
                        {},
                      ],

                      [
                        {
                          text: `AntiGAD Antibodies Report`,
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${pancreasDetails?.antigadReport?.name ? 'ATTACHED' : '-'}`,
                          colSpan: 2,
                        },
                        {},
                      ],
                      [
                        {
                          text: `Date of AntiGAD Antibodies Report: `,
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${
                            pancreasDetails?.antigadReportDate
                              ? moment(
                                  pancreasDetails?.antigadReportDate,
                                  FORMAT_DATE_UNIXTS
                                ).format('DD/MM/YYYY')
                              : '-'
                          }`,
                          colSpan: 2,
                        },
                        {},
                      ],

                      [
                        {
                          text: 'Does the patient require the procurement or transplantation of a pancreas as part of a multiple organ transplant for technical reasons',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${_yesNoAndEmptyFormatter(
                            pancreasDetails?.pancreasAsMultipleOrgan
                          )}`,
                          colSpan: 2,
                        },
                        {},
                      ],
                      // [
                      //   {
                      //     text: 'Does the patient have diagnosed with diabetes for Type I DM? \n a)Secondary Complications of diabetes \n b) Poor quality of life with Insulin',
                      //     style: 'tableHeader',
                      //     alignment: 'left',
                      //     colSpan: 1,
                      //   },

                      //   {
                      //     text: `${_yesNoAndEmptyFormatter(
                      //       pancreasDetails?.diabetes?.secondaryComplications
                      //         ? pancreasDetails?.diabetes?.secondaryComplications
                      //         : '-'
                      //     )}`,
                      //     // ${
                      //     //   pancreasDetails?.diabetes?.pooerQualityOfLifeWithInsulin
                      //     //     ? pancreasDetails?.diabetes?.pooerQualityOfLifeWithInsulin
                      //     //     : '-'
                      //     // }`,
                      //     colSpan: 2,
                      //   },
                      //   {},
                      // ],
                      [
                        {
                          text: 'Type of diabetes',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                          bold: true,
                        },

                        {
                          text: `${typeOfDiabeticsFormatter(
                            pancreasDetails?.diabetes?.diabetesType
                          )}`,

                          colSpan: 2,
                        },
                        {},
                      ],
                      [
                        {
                          text: 'If TYPE I DM',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 3,
                          bold: true,
                        },
                        {},
                        {},
                      ],
                      [
                        {
                          text: 'Secondary Complications of diabetes?',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${_yesNoAndEmptyFormatter(
                            pancreasDetails?.diabetes?.secondaryComplications
                          )}`,
                          colSpan: 2,
                        },
                        {},
                      ],
                      [
                        {
                          text: 'If TYPE II DM',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 3,
                          bold: true,
                        },
                        {},
                        {},
                      ],
                      [
                        {
                          text: 'Does the patient have Insulin dependency?',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${_yesNoAndEmptyFormatter(
                            pancreasDetails?.diabetes?.insulinDependency
                          )}`,
                          colSpan: 2,
                        },
                        {},
                      ],
                      [
                        {
                          text: 'Does the patient have any minimal coronary risk or with corrected coronary disease and therefore low cardiac risk?',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${_yesNoAndEmptyFormatter(
                            pancreasDetails?.diabetes?.minimalCoronaryRisk
                          )}`,
                          colSpan: 2,
                        },
                        {},
                      ],
                      // [
                      //   {
                      //     text: 'Treatment Details',
                      //     style: 'tableHeader',
                      //     alignment: 'left',
                      //     colSpan: 1,
                      //   },

                      //   {
                      //     text: `${
                      //       pancreasDetails?.diabetes?.treatmentDetails
                      //         ? pancreasDetails?.diabetes?.treatmentDetails
                      //         : '-'
                      //     }`,
                      //     colSpan: 2,
                      //   },
                      //   {},
                      // ],

                      [
                        {
                          text: 'Treatment Details',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${
                            pancreasDetails?.diabetes?.treatmentDetails
                              ? pancreasDetails?.diabetes?.treatmentDetails
                              : '-'
                          }`,
                          colSpan: 2,
                        },
                        {},
                      ],
                      [
                        {
                          text: `Diabetes Report`,
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${
                            pancreasDetails?.diabetes?.diabeticReport?.name ? 'ATTACHED' : '-'
                          }`,
                          colSpan: 2,
                        },
                        {},
                      ],
                      [
                        {
                          text: 'Viral Marker Status',
                          style: 'tableHeader',
                          alignment: 'center',
                          colSpan: 3,
                          bold: true,
                        },
                        {},
                        {},
                      ],

                      [
                        {
                          text: 'HBsAg',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${pancreasDetails?.viralMarkerStatus?.hbsagRemark}`,
                          colSpan: 2,
                        },
                        {},
                      ],

                      [
                        {
                          text: `HBsAg Report Date: `,
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${
                            pancreasDetails?.viralMarkerStatus?.hbsagReportDate
                              ? moment(
                                  pancreasDetails?.viralMarkerStatus?.hbsagReportDate,
                                  FORMAT_DATE_UNIXTS
                                ).format('DD/MM/YYYY')
                              : '-'
                          }`,
                          colSpan: 2,
                        },
                        {},
                      ],

                      [
                        {
                          text: `HBsAg Report`,
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${
                            pancreasDetails?.viralMarkerStatus?.hbsagReport?.name ? 'ATTACHED' : '-'
                          }`,
                          colSpan: 2,
                        },
                        {},
                      ],

                      [
                        {
                          text: 'Anti HCV',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${pancreasDetails?.viralMarkerStatus?.antiHcvRemark}`,
                          colSpan: 2,
                        },
                        {},
                      ],

                      [
                        {
                          text: `Anti HCV Report Date `,
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${
                            pancreasDetails?.viralMarkerStatus?.antiHcvReportDate
                              ? moment(
                                  pancreasDetails?.viralMarkerStatus?.antiHcvReportDate,
                                  FORMAT_DATE_UNIXTS
                                ).format('DD/MM/YYYY')
                              : '-'
                          }`,
                          colSpan: 2,
                        },
                        {},
                      ],

                      [
                        {
                          text: `Anti HCV Report`,
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${
                            pancreasDetails?.viralMarkerStatus?.antiHcvReport?.name
                              ? 'ATTACHED'
                              : '-'
                          }`,
                          colSpan: 2,
                        },
                        {},
                      ],

                      [
                        {
                          text: 'HCV RNA Quantitative',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${pancreasDetails?.viralMarkerStatus?.hcvRnaQuantitativeRemark}`,
                          colSpan: 2,
                        },
                        {},
                      ],

                      [
                        {
                          text: `HCV RNA Quantitative Report Date: `,
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },
                        pancreasDetails?.viralMarkerStatus?.hcvRnaQuantitativeRemark === 'NOTDONE'
                          ? {
                              text: `-`,
                              colSpan: 2,
                            }
                          : {
                              text: `${
                                pancreasDetails?.viralMarkerStatus?.hcvRnaQuantitativeReportDate
                                  ? moment(
                                      pancreasDetails?.viralMarkerStatus
                                        ?.hcvRnaQuantitativeReportDate,
                                      FORMAT_DATE_UNIXTS
                                    ).format('DD/MM/YYYY')
                                  : '-'
                              }`,
                              colSpan: 2,
                            },
                        {},
                      ],

                      [
                        {
                          text: `HCV RNA Quantitative Report`,
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        pancreasDetails?.viralMarkerStatus?.hcvRnaQuantitativeRemark === 'NOTDONE'
                          ? {
                              text: `-`,
                              colSpan: 2,
                            }
                          : {
                              text: `${
                                pancreasDetails?.viralMarkerStatus?.hcvRnaQuantitativeReport?.name
                                  ? 'ATTACHED'
                                  : '-'
                              }`,
                              colSpan: 2,
                            },
                        {},
                      ],

                      [
                        {
                          text: 'HCV DNA',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${pancreasDetails?.viralMarkerStatus?.hcvDnaRemark}`,
                          colSpan: 2,
                        },
                        {},
                      ],

                      [
                        {
                          text: `HCV DNA Report Date: `,
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },
                        pancreasDetails?.viralMarkerStatus?.hcvDnaRemark === 'NOTDONE'
                          ? {
                              text: `-`,
                              colSpan: 2,
                            }
                          : {
                              text: `${
                                pancreasDetails?.viralMarkerStatus?.hcvDnaReportDate
                                  ? moment(
                                      pancreasDetails?.viralMarkerStatus?.hcvDnaReportDate,
                                      FORMAT_DATE_UNIXTS
                                    ).format('DD/MM/YYYY')
                                  : '-'
                              }`,
                              colSpan: 2,
                            },
                        {},
                      ],

                      [
                        {
                          text: `HCV DNA Report`,
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },
                        pancreasDetails?.viralMarkerStatus?.hcvDnaRemark === 'NOTDONE'
                          ? {
                              text: `-`,
                              colSpan: 2,
                            }
                          : {
                              text: `${
                                pancreasDetails?.viralMarkerStatus?.hcvDnaReport?.name
                                  ? 'ATTACHED'
                                  : '-'
                              }`,
                              colSpan: 2,
                            },
                        {},
                      ],

                      [
                        {
                          text: 'HIV',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${pancreasDetails?.viralMarkerStatus?.hivRemark}`,
                          colSpan: 2,
                        },
                        {},
                      ],

                      [
                        {
                          text: `HIV Report Date: `,
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${
                            pancreasDetails?.viralMarkerStatus?.hivReportDate
                              ? moment(
                                  pancreasDetails?.viralMarkerStatus?.hivReportDate,
                                  FORMAT_DATE_UNIXTS
                                ).format('DD/MM/YYYY')
                              : '-'
                          }`,
                          colSpan: 2,
                        },
                        {},
                      ],

                      [
                        {
                          text: `HIV Report`,
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${
                            pancreasDetails?.viralMarkerStatus?.hivReport?.name ? 'ATTACHED' : '-'
                          }`,
                          colSpan: 2,
                        },
                        {},
                      ],
                      // [
                      //   {
                      //     text: `Etiology `,
                      //     style: 'tableHeader',
                      //     alignment: 'left',
                      //     colSpan: 1,
                      //   },

                      //   pancreasDetails.etiologies.map((e) => [
                      //     { text: `${e.etiology ? e.etiology : '-'}` },
                      //   ]),

                      //   {},
                      // ],
                    ],
                  },
                },
                {
                  style: 'table',
                  table: {
                    headerRows: 1,
                    widths: ['*', '*'],
                    body: [
                      [
                        { text: 'Etiology', style: 'tableHeader', alignment: 'left' },
                        { text: 'Etiology Report', style: 'tableHeader', alignment: 'left' },
                      ],
                      ...pancreasDetails?.etiologies?.map((e) => [
                        {
                          text: `${e.etiology ? e.etiology : '-'}`,
                          style: 'tableCell',
                          alignment: 'left',
                        },
                        {
                          text: `${e.attachment?.name ? 'ATTACHED' : '-'}`,
                          style: 'tableCell',
                          alignment: 'left',
                        },
                      ]),
                    ],
                  },
                },
                {
                  style: 'table',
                  table: {
                    widths: [250, 100, 150, 'auto', 'auto'],
                    heights: ['auto', 'auto', 'auto'],
                    body: [
                      [
                        {
                          text: 'Etiology Details ',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${
                            pancreasDetails?.etiologyDetails
                              ? pancreasDetails?.etiologyDetails
                              : '-'
                          }`,
                          colSpan: 2,
                        },
                        {},
                      ],
                    ],
                  },
                },
                {
                  pageBreak: 'before',
                  text: 'Any Other Attachments',
                  style: 'subHeader',
                },
                {
                  style: 'table',
                  table: {
                    headerRows: 1,
                    widths: ['*', '*'],
                    body: [
                      [
                        { text: 'Attachment Name', style: 'tableHeader', alignment: 'left' },
                        { text: 'Report', style: 'tableHeader', alignment: 'left' },
                      ],
                      ...pancreasDetails?.otherAttachments?.map((e) => [
                        {
                          text: `${e.attachmentName ? e.attachmentName : '-'}`,
                          style: 'tableCell',
                          alignment: 'left',
                        },
                        {
                          text: `${e.attachment?.name ? 'ATTACHED' : '-'}`,
                          style: 'tableCell',
                          alignment: 'left',
                        },
                      ]),
                    ],
                  },
                },
                {
                  style: 'table',
                  table: {
                    headerRows: 1,
                    widths: ['*', '*'],
                    body: [
                      [
                        { text: 'Endocrinologist Team', style: 'tableHeader', alignment: 'left' },

                        { text: 'Transplant Surgeon', style: 'tableHeader', alignment: 'left' },
                      ],
                      [
                        (pancreasDetails?.endocrinologistTeam || [])?.map((e) => [
                          {
                            text: `${e.user.name ? e.user.name : '-'}`,
                            style: 'tableCell',
                            alignment: 'left',
                          },
                        ]),
                        (pancreasDetails?.transplantSurgeon || [])?.map((e) => [
                          {
                            text: `${e.user.name ? e.user.name : '-'}`,
                            style: 'tableCell',
                            alignment: 'left',
                          },
                        ]),
                      ],
                    ],
                  },
                },
              ],
            }
          : null,

        // organs.includes('LUNGS') && basicDetails.waitingStatus === 'ROUTINE'
        organs.some((organ) => organ.organType === 'LUNGS' && organ.category === 'ROUTINE')
          ? {
              stack: [
                {
                  style: 'table',
                  table: {
                    widths: [250, 100, 150, 'auto', 'auto'],
                    heights: [
                      'auto',
                      'auto',
                      'auto',
                      'auto',
                      'auto',
                      'auto',
                      'auto',
                      'auto',
                      'auto',
                      'auto',
                      'auto',
                      'auto',
                      'auto',
                      'auto',
                      'auto',
                    ],
                    body: [
                      [
                        {
                          text: 'State Organ and Tissue Transplant Organisation (SOTTO),\n  Maharashtra',
                          style: 'tableHeader',
                          colSpan: 3,
                          alignment: 'center',
                          bold: true,
                        },
                        {},
                        {},
                      ],
                      [
                        {
                          text: 'State Appropriate Authority of Maharashtra',
                          style: 'textStyle',
                          alignment: 'center',
                          colSpan: 3,
                        },
                        {},
                        {},
                      ],
                      [
                        {
                          text: 'ROUTINE LUNGS REGISTRATION',
                          style: 'textStyle',
                          alignment: 'center',
                          colSpan: 3,
                        },
                        {},
                        {},
                      ],
                      [
                        {
                          text: '2D Echo Report',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${lungDetails?.echo2dReport?.name ? 'ATTACHED' : '-'}`,
                          colSpan: 2,
                        },
                        {},
                      ],

                      [
                        {
                          text: 'ABG Report',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${lungDetails?.abgReport?.name ? 'ATTACHED' : '-'}`,
                          colSpan: 2,
                        },
                        {},
                      ],
                      [
                        {
                          text: 'PFT Report',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${lungDetails?.pftReport?.name ? 'ATTACHED' : '-'}`,
                          colSpan: 2,
                        },
                        {},
                      ],
                      [
                        {
                          text: 'HRCT Report',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${lungDetails?.hrctReport?.name ? 'ATTACHED' : '-'}`,
                          colSpan: 2,
                        },
                        {},
                      ],

                      [
                        {
                          text: 'Does the patient statisfy the ISHLT Criteria?',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${_yesNoAndEmptyFormatter(lungDetails?.ishltCriteria)}`,
                          colSpan: 2,
                        },
                        {},
                      ],

                      [
                        {
                          text: 'Viral Marker Status : ',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 3,
                          bold: true,
                        },
                        {},
                        {},
                      ],
                      [
                        {
                          text: `HBs AG Report Date \n (Positive/Negative) `,
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${_viralMrakerRemarkAndDateFormatter(
                            lungDetails?.viralMarkerStatus?.hbsagRemark,
                            lungDetails?.viralMarkerStatus?.hbsagReportDate
                          )}`,
                          colSpan: 2,
                        },
                        {},
                      ],

                      [
                        {
                          text: `Anti HCV Report Date \n (Positive/Negative) `,
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${_viralMrakerRemarkAndDateFormatter(
                            lungDetails?.viralMarkerStatus?.antiHcvRemark,
                            lungDetails?.viralMarkerStatus?.antiHcvReportDate
                          )}`,
                          colSpan: 2,
                        },
                        {},
                      ],
                      [
                        {
                          text: `HCV RNA Quantitative Report Date \n (Positive/Negative/Not Done) `,
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },
                        lungDetails?.viralMarkerStatus?.hcvRnaQuantitativeRemark === 'NOTDONE'
                          ? {
                              text: `${_viralMrakerRemarkAndDateFormatter(
                                lungDetails?.viralMarkerStatus?.hcvRnaQuantitativeRemark
                              )}`,
                              colSpan: 2,
                            }
                          : {
                              text: `${_viralMrakerRemarkAndDateFormatter(
                                lungDetails?.viralMarkerStatus?.hcvRnaQuantitativeRemark,
                                lungDetails?.viralMarkerStatus?.hcvRnaQuantitativeReportDate
                              )}`,
                              colSpan: 2,
                            },
                        {},
                      ],
                      [
                        {
                          text: `HCV DNA Report Date \n (Positive/Negative/Not Done) `,
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },
                        lungDetails?.viralMarkerStatus?.hcvDnaRemark === 'NOTDONE'
                          ? {
                              text: `${_viralMrakerRemarkAndDateFormatter(
                                lungDetails?.viralMarkerStatus?.hcvDnaRemark
                              )}`,
                              colSpan: 2,
                            }
                          : {
                              text: `${_viralMrakerRemarkAndDateFormatter(
                                lungDetails?.viralMarkerStatus?.hcvDnaRemark,
                                lungDetails?.viralMarkerStatus?.hcvDnaReportDate
                              )}`,
                              colSpan: 2,
                            },
                        {},
                      ],
                      [
                        {
                          text: `HIV Report Date \n (Positive/Negative) `,
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${_viralMrakerRemarkAndDateFormatter(
                            lungDetails?.viralMarkerStatus?.hivRemark,
                            lungDetails?.viralMarkerStatus?.hivReportDate
                          )}`,
                          colSpan: 2,
                        },
                        {},
                      ],

                      [
                        {
                          text: 'Any Other Complications',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${
                            lungDetails?.otherComplications ? lungDetails?.otherComplications : '-'
                          }`,
                          colSpan: 2,
                        },
                        {},
                      ],
                    ],
                  },
                },
                {
                  style: 'table',
                  table: {
                    headerRows: 1,
                    widths: ['*', '*'],
                    body: [
                      [
                        {
                          text: 'Etiology of Lung Failure',
                          style: 'tableHeader',
                          alignment: 'left',
                        },
                        { text: 'Etiology Report', style: 'tableHeader', alignment: 'left' },
                      ],
                      ...lungDetails.etiologies.map((e) => [
                        {
                          text: `${e.etiology ? e.etiology : '-'}`,
                          style: 'tableCell',
                          alignment: 'left',
                        },
                        {
                          text: `${e.attachment?.name ? 'ATTACHED' : '-'}`,
                          style: 'tableCell',
                          alignment: 'left',
                        },
                      ]),
                    ],
                  },
                },

                {
                  style: 'table',
                  table: {
                    widths: [250, 100, 150, 'auto', 'auto'],
                    heights: ['auto', 'auto', 'auto', 'auto'],
                    body: [
                      [
                        {
                          text: 'Etiology Details ',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${
                            lungDetails?.etiologyDetails ? lungDetails?.etiologyDetails : '-'
                          }`,
                          colSpan: 2,
                        },
                        {},
                      ],
                    ],
                  },
                },
                {
                  text: 'Any Other Attachments',
                  style: 'subHeader',
                },
                {
                  style: 'table',
                  table: {
                    headerRows: 1,
                    widths: ['*', '*'],
                    body: [
                      [
                        { text: 'Attachment Name', style: 'tableHeader', alignment: 'left' },
                        { text: 'Report', style: 'tableHeader', alignment: 'left' },
                      ],
                      ...lungDetails?.otherAttachments?.map((e) => [
                        {
                          text: `${e.attachmentName ? e.attachmentName : '-'}`,
                          style: 'tableCell',
                          alignment: 'left',
                        },
                        {
                          text: `${e.attachment?.name ? 'ATTACHED' : '-'}`,
                          style: 'tableCell',
                          alignment: 'left',
                        },
                      ]),
                    ],
                  },
                },

                {
                  style: 'table',
                  table: {
                    headerRows: 1,
                    widths: ['*', '*', '*'],
                    body: [
                      [
                        { text: 'Intensivist Team', style: 'tableHeader', alignment: 'left' },

                        { text: 'Pulmonologist Team', style: 'tableHeader', alignment: 'left' },

                        { text: 'Transplant Surgeon', style: 'tableHeader', alignment: 'left' },
                      ],
                      [
                        (lungDetails?.intensivistTeam || [])?.map((e) => [
                          {
                            text: `${e.user.name ? e.user.name : '-'}`,
                            style: 'tableCell',
                            alignment: 'left',
                          },
                        ]),
                        (lungDetails?.pulmonologistTeam || [])?.map((e) => [
                          {
                            text: `${e.user.name ? e.user.name : '-'}`,
                            style: 'tableCell',
                            alignment: 'left',
                          },
                        ]),
                        (lungDetails?.transplantSurgeon || [])?.map((e) => [
                          {
                            text: `${e.user.name ? e.user.name : '-'}`,
                            style: 'tableCell',
                            alignment: 'left',
                          },
                        ]),
                      ],
                    ],
                  },
                },
              ],
            }
          : null,
        // organs.includes('LUNGS') && basicDetails.waitingStatus === 'SUPERURGENT'
        organs.some((organ) => organ.organType === 'LUNGS' && organ.category === 'SUPERURGENT')
          ? {
              stack: [
                {
                  style: 'table',
                  table: {
                    widths: [250, 100, 150, 'auto', 'auto'],
                    heights: [
                      'auto',
                      20,
                      'auto',
                      'auto',
                      'auto',
                      'auto',
                      'auto',
                      'auto',
                      'auto',
                      20,
                      30,
                      30,
                      'auto',
                      'auto',
                      40,
                    ],
                    body: [
                      [
                        {
                          text: 'State Organ and Tissue Transplant Organisation (SOTTO),\n  Maharashtra',
                          style: 'tableHeader',
                          colSpan: 3,
                          alignment: 'center',
                          bold: true,
                        },
                        {},
                        {},
                      ],
                      [
                        {
                          text: 'State Appropriate Authority of Maharashtra',
                          style: 'textStyle',
                          alignment: 'center',
                          colSpan: 3,
                        },
                        {},
                        {},
                      ],
                      [
                        {
                          text: 'SUPERURGENT LUNGS REGISTRATION',
                          style: 'textStyle',
                          alignment: 'center',
                          colSpan: 3,
                        },
                        {},
                        {},
                      ],
                      [
                        {
                          text: 'Patient Admission File',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${lungDetails?.patientAdmissionfile?.name ? 'ATTACHED' : '-'}`,
                          colSpan: 2,
                        },
                        {},
                      ],
                      [
                        {
                          text: 'Treating Doctor Clinical Summary report',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${
                            lungDetails?.treatingDoctorClinicalSummaryReport?.name
                              ? 'ATTACHED'
                              : '-'
                          }`,
                          colSpan: 2,
                        },
                        {},
                      ],
                      [
                        {
                          text: '2D Echo Report',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${lungDetails?.echo2dReport?.name ? 'ATTACHED' : '-'}`,
                          colSpan: 2,
                        },
                        {},
                      ],

                      [
                        {
                          text: 'ABG Report',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${lungDetails?.abgReport?.name ? 'ATTACHED' : '-'}`,
                          colSpan: 2,
                        },
                        {},
                      ],
                      [
                        {
                          text: 'PFT Report',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${lungDetails?.pftReport?.name ? 'ATTACHED' : '-'}`,
                          colSpan: 2,
                        },
                        {},
                      ],
                      [
                        {
                          text: 'HRCT Report',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${lungDetails?.hrctReport?.name ? 'ATTACHED' : '-'}`,
                          colSpan: 2,
                        },
                        {},
                      ],

                      [
                        {
                          text: 'Does the patient have the End Stage Lung Disease? ',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `
                      ${_yesNoAndEmptyFormatter(lungDetails?.endStageLungDisease)}`,

                          colSpan: 2,
                        },
                        {},
                      ],
                      [
                        {
                          text: 'Inotrope Details ',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: ` ${
                            lungDetails?.inotropeDetails ? lungDetails?.inotropeDetails : '-'
                          }`,

                          colSpan: 2,
                        },
                        {},
                      ],
                      [
                        {
                          text: 'If positive, provide details ',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${
                            lungDetails?.inotropePositiveDetails
                              ? lungDetails?.inotropePositiveDetails
                              : '-'
                          }`,

                          colSpan: 2,
                        },
                        {},
                      ],

                      [
                        {
                          text: 'Does the patient have the ECMO Support? ',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${_yesNoAndEmptyFormatter(lungDetails?.ecmoSupport)}`,

                          colSpan: 2,
                        },
                        {},
                      ],
                      [
                        {
                          text: 'Types of ECMO ',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: ` ${lungDetails?.typesOfEcmo ? lungDetails?.typesOfEcmo : '-'}`,

                          colSpan: 2,
                        },
                        {},
                      ],
                      [
                        {
                          text: 'Date of ECMO Initiated ',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${
                            lungDetails?.dateOfEcmo
                              ? moment(lungDetails?.dateOfEcmo, FORMAT_DATE_UNIXTS).format(
                                  'DD MMMM YYYY'
                                )
                              : '-'
                          }`,

                          colSpan: 2,
                        },
                        {},
                      ],
                      [
                        {
                          text: 'ECMO Blood Flow Rate(L/Min) ',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: ` ${lungDetails?.irv ? lungDetails?.irv : '-'}`,

                          colSpan: 2,
                        },
                        {},
                      ],
                      [
                        {
                          text: 'ECMO RPM ',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${lungDetails?.ecmoRpm ? lungDetails?.ecmoRpm : '-'}`,

                          colSpan: 2,
                        },
                        {},
                      ],
                      [
                        {
                          text: 'ECMO Cannulation Site ',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${
                            lungDetails?.ecmoCannulation ? lungDetails?.ecmoCannulation : '-'
                          }`,

                          colSpan: 2,
                        },
                        {},
                      ],
                      [
                        {
                          text: 'ECMO Weaning ',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${_yesNoAndEmptyFormatter(lungDetails?.ecmoWeaning)}`,

                          colSpan: 2,
                        },
                        {},
                      ],
                      [
                        {
                          text: 'ECMO Complication ',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${
                            lungDetails?.ecmoComplication ? lungDetails?.ecmoComplication : '-'
                          }`,

                          colSpan: 2,
                        },
                        {},
                      ],

                      [
                        {
                          text: ' Does the patient with respiratory failure dependent on Mechanical Ventilation (not weanable)',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `
                      ${_yesNoAndEmptyBooleanFormatter(
                        lungDetails?.respiratoryFialureDependent?.dependentOnMechanicalVentilation
                      )}
                      
                      `,
                          colSpan: 2,
                        },
                        {},
                      ],
                      [
                        {
                          text: ' Duration on Ventilators(in Days)',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${
                            lungDetails?.respiratoryFialureDependent?.durationOnVentilator
                              ? lungDetails?.respiratoryFialureDependent?.durationOnVentilator
                              : '-'
                          }`,
                          colSpan: 2,
                        },
                        {},
                      ],
                      [
                        {
                          text: ' Is a patient ready for weaning off ventilation support?',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `
                      ${_yesNoAndEmptyFormatter(
                        lungDetails?.respiratoryFialureDependent?.ventilationSupport
                      )}`,

                          colSpan: 2,
                        },
                        {},
                      ],
                      [
                        {
                          text: ' Ventilator Setting',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${
                            lungDetails?.respiratoryFialureDependent?.ventilatorSetting
                              ? lungDetails?.respiratoryFialureDependent?.ventilatorSetting
                              : '-'
                          }`,
                          colSpan: 2,
                        },
                        {},
                      ],
                      [
                        {
                          text: ' Lung Complication Details',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${
                            lungDetails?.respiratoryFialureDependent?.lungComplicationDetails
                              ? lungDetails?.respiratoryFialureDependent?.lungComplicationDetails
                              : '-'
                          }`,
                          colSpan: 2,
                        },
                        {},
                      ],

                      [
                        {
                          text: 'Viral Marker Status',
                          style: 'tableHeader',
                          alignment: 'center',
                          colSpan: 3,
                          bold: true,
                        },
                        {},
                        {},
                      ],

                      [
                        {
                          text: 'HBsAg',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${lungDetails?.viralMarkerStatus?.hbsagRemark}`,
                          colSpan: 2,
                        },
                        {},
                      ],

                      [
                        {
                          text: `HBsAg Report Date: `,
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${
                            lungDetails?.viralMarkerStatus?.hbsagReportDate
                              ? moment(
                                  lungDetails?.viralMarkerStatus?.hbsagReportDate,
                                  FORMAT_DATE_UNIXTS
                                ).format('DD/MM/YYYY')
                              : '-'
                          }`,
                          colSpan: 2,
                        },
                        {},
                      ],

                      [
                        {
                          text: `HBsAg Report`,
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${
                            lungDetails?.viralMarkerStatus?.hbsagReport?.name ? 'ATTACHED' : '-'
                          }`,
                          colSpan: 2,
                        },
                        {},
                      ],

                      [
                        {
                          text: 'Anti HCV',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${lungDetails?.viralMarkerStatus?.antiHcvRemark}`,
                          colSpan: 2,
                        },
                        {},
                      ],

                      [
                        {
                          text: `Anti HCV Report Date `,
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${
                            lungDetails?.viralMarkerStatus?.antiHcvReportDate
                              ? moment(
                                  lungDetails?.viralMarkerStatus?.antiHcvReportDate,
                                  FORMAT_DATE_UNIXTS
                                ).format('DD/MM/YYYY')
                              : '-'
                          }`,
                          colSpan: 2,
                        },
                        {},
                      ],

                      [
                        {
                          text: `Anti HCV Report`,
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${
                            lungDetails?.viralMarkerStatus?.antiHcvReport?.name ? 'ATTACHED' : '-'
                          }`,
                          colSpan: 2,
                        },
                        {},
                      ],

                      [
                        {
                          text: 'HCV RNA Quantitative',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${lungDetails?.viralMarkerStatus?.hcvRnaQuantitativeRemark}`,
                          colSpan: 2,
                        },
                        {},
                      ],

                      [
                        {
                          text: `HCV RNA Quantitative Report Date: `,
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },
                        lungDetails?.viralMarkerStatus?.hcvRnaQuantitativeRemark === 'NOTDONE'
                          ? {
                              text: `-`,
                              colSpan: 2,
                            }
                          : {
                              text: `${
                                lungDetails?.viralMarkerStatus?.hcvRnaQuantitativeReportDate
                                  ? moment(
                                      lungDetails?.viralMarkerStatus?.hcvRnaQuantitativeReportDate,
                                      FORMAT_DATE_UNIXTS
                                    ).format('DD/MM/YYYY')
                                  : '-'
                              }`,
                              colSpan: 2,
                            },
                        {},
                      ],

                      [
                        {
                          text: `HCV RNA Quantitative Report`,
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        lungDetails?.viralMarkerStatus?.hcvRnaQuantitativeRemark === 'NOTDONE'
                          ? {
                              text: `-`,
                              colSpan: 2,
                            }
                          : {
                              text: `${
                                lungDetails?.viralMarkerStatus?.hcvRnaQuantitativeReport?.name
                                  ? 'ATTACHED'
                                  : '-'
                              }`,
                              colSpan: 2,
                            },
                        {},
                      ],

                      [
                        {
                          text: 'HCV DNA',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${lungDetails?.viralMarkerStatus?.hcvDnaRemark}`,
                          colSpan: 2,
                        },
                        {},
                      ],

                      [
                        {
                          text: `HCV DNA Report Date: `,
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },
                        lungDetails?.viralMarkerStatus?.hcvDnaRemark === 'NOTDONE'
                          ? {
                              text: `-`,
                              colSpan: 2,
                            }
                          : {
                              text: `${
                                lungDetails?.viralMarkerStatus?.hcvDnaReportDate
                                  ? moment(
                                      lungDetails?.viralMarkerStatus?.hcvDnaReportDate,
                                      FORMAT_DATE_UNIXTS
                                    ).format('DD/MM/YYYY')
                                  : '-'
                              }`,
                              colSpan: 2,
                            },
                        {},
                      ],

                      [
                        {
                          text: `HCV DNA Report`,
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },
                        lungDetails?.viralMarkerStatus?.hcvDnaRemark === 'NOTDONE'
                          ? {
                              text: `-`,
                              colSpan: 2,
                            }
                          : {
                              text: `${
                                lungDetails?.viralMarkerStatus?.hcvDnaReport?.name
                                  ? 'ATTACHED'
                                  : '-'
                              }`,
                              colSpan: 2,
                            },
                        {},
                      ],

                      [
                        {
                          text: 'HIV',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${lungDetails?.viralMarkerStatus?.hivRemark}`,
                          colSpan: 2,
                        },
                        {},
                      ],
                      [
                        {
                          text: `HIV Report Date: `,
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${
                            lungDetails?.viralMarkerStatus?.hivReportDate
                              ? moment(
                                  lungDetails?.viralMarkerStatus?.hivReportDate,
                                  FORMAT_DATE_UNIXTS
                                ).format('DD/MM/YYYY')
                              : '-'
                          }`,
                          colSpan: 2,
                        },
                        {},
                      ],

                      [
                        {
                          text: `HIV Report`,
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${
                            lungDetails?.viralMarkerStatus?.hivReport?.name ? 'ATTACHED' : '-'
                          }`,
                          colSpan: 2,
                        },
                        {},
                      ],

                      [
                        {
                          text: 'Any Other Complications',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${
                            lungDetails?.otherComplications ? lungDetails?.otherComplications : '-'
                          }`,
                          colSpan: 2,
                        },
                        {},
                      ],
                    ],
                  },
                },
                {
                  text: 'Any Other Attachments',
                  style: 'subHeader',
                },
                {
                  style: 'table',
                  table: {
                    headerRows: 1,
                    widths: ['*', '*'],
                    body: [
                      [
                        { text: 'Attachment Name', style: 'tableHeader', alignment: 'left' },
                        { text: 'Report', style: 'tableHeader', alignment: 'left' },
                      ],
                      ...lungDetails?.otherAttachments?.map((e) => [
                        {
                          text: `${e.attachmentName ? e.attachmentName : '-'}`,
                          style: 'tableCell',
                          alignment: 'left',
                        },
                        {
                          text: `${e.attachment?.name ? 'ATTACHED' : '-'}`,
                          style: 'tableCell',
                          alignment: 'left',
                        },
                      ]),
                    ],
                  },
                },

                {
                  style: 'table',
                  table: {
                    headerRows: 1,
                    widths: ['*', '*'],
                    body: [
                      [
                        {
                          text: 'Etiology of Lung Failure',
                          style: 'tableHeader',
                          alignment: 'left',
                        },
                        { text: 'Etiology Report', style: 'tableHeader', alignment: 'left' },
                      ],
                      ...lungDetails.etiologies.map((e) => [
                        {
                          text: `${e.etiology ? e.etiology : '-'}`,
                          style: 'tableCell',
                          alignment: 'left',
                        },
                        {
                          text: `${e.attachment?.name ? 'ATTACHED' : '-'}`,
                          style: 'tableCell',
                          alignment: 'left',
                        },
                      ]),
                    ],
                  },
                },

                {
                  style: 'table',
                  table: {
                    widths: [250, 100, 150, 'auto', 'auto'],
                    heights: ['auto', 'auto', 'auto', 'auto'],
                    body: [
                      [
                        {
                          text: 'Etiology Details ',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${
                            lungDetails?.etiologyDetails ? lungDetails?.etiologyDetails : '-'
                          }`,
                          colSpan: 2,
                        },
                        {},
                      ],
                    ],
                  },
                },
                {
                  style: 'table',
                  table: {
                    headerRows: 1,
                    widths: ['*', '*', '*'],
                    body: [
                      [
                        { text: 'intensivist Team', style: 'tableHeader', alignment: 'left' },

                        { text: 'Pulmonologist Team', style: 'tableHeader', alignment: 'left' },

                        { text: 'Transplant Surgeon', style: 'tableHeader', alignment: 'left' },
                      ],
                      [
                        (lungDetails?.intensivistTeam || [])?.map((e) => [
                          {
                            text: `${e.user.name ? e.user.name : '-'}`,
                            style: 'tableCell',
                            alignment: 'left',
                          },
                        ]),
                        (lungDetails?.pulmonologistTeam || [])?.map((e) => [
                          {
                            text: `${e.user.name ? e.user.name : '-'}`,
                            style: 'tableCell',
                            alignment: 'left',
                          },
                        ]),
                        (lungDetails?.transplantSurgeon || [])?.map((e) => [
                          {
                            text: `${e.user.name ? e.user.name : '-'}`,
                            style: 'tableCell',
                            alignment: 'left',
                          },
                        ]),
                      ],
                    ],
                  },
                },
              ],
            }
          : null,
        // organs.includes('PANCREAS')
        // organs.some((organ) => organ.organType === 'PANCREAS')
        //   ? {
        //       style: 'table',
        //       table: {
        //         widths: [250, 100, 150, 'auto', 'auto'],
        //         heights: [
        //           'auto',
        //           20,
        //           'auto',
        //           'auto',
        //           'auto',
        //           'auto',
        //           'auto',
        //           'auto',
        //           'auto',
        //           20,
        //           40,
        //           40,
        //           'auto',
        //           'auto',
        //           40,
        //         ],
        //         body: [
        //           [
        //             {
        //               text: 'Serum Creatinine(mg/dL)',
        //               style: 'tableHeader',
        //               alignment: 'left',
        //               colSpan: 1,
        //             },

        //             {
        //               text: `${
        //                 pancreasDetails?.serumCreatinine ? pancreasDetails?.serumCreatinine : '-'
        //               }`,
        //               colSpan: 2,
        //             },
        //             {},
        //           ],
        //           [
        //             {
        //               text: ' C-Peptide(ng/mL)',
        //               style: 'tableHeader',
        //               alignment: 'left',
        //               colSpan: 1,
        //             },

        //             {
        //               text: `${pancreasDetails?.peptide ? pancreasDetails?.peptide : '-'}`,
        //               colSpan: 2,
        //             },
        //             {},
        //           ],

        //           [
        //             {
        //               text: 'Does the patient have Frequent episodes of Hypoglycemia unawareness?',
        //               style: 'tableHeader',
        //               alignment: 'left',
        //               colSpan: 1,
        //             },

        //             {
        //               text: `${
        //                 pancreasDetails?.hypoglycemia ? pancreasDetails?.hypoglycemia : '-'
        //               }`,
        //               colSpan: 2,
        //             },
        //             {},
        //           ],
        //           [
        //             {
        //               text: ' Does the patient have Brittle Diabetes? ',
        //               style: 'tableHeader',
        //               alignment: 'left',
        //               colSpan: 1,
        //             },

        //             {
        //               text: `${
        //                 pancreasDetails?.brittleDiabetes ? pancreasDetails?.brittleDiabetes : '-'
        //               }`,
        //               colSpan: 2,
        //             },
        //             {},
        //           ],
        //           [
        //             {
        //               text: ' Does the patient have the pancreatic exocrine insufficiency?',
        //               style: 'tableHeader',
        //               alignment: 'left',
        //               colSpan: 1,
        //             },

        //             {
        //               text: `${
        //                 pancreasDetails?.pancreaticExocrineInsuff
        //                   ? pancreasDetails?.pancreaticExocrineInsuff
        //                   : '-'
        //               }`,
        //               colSpan: 2,
        //             },
        //             {},
        //           ],

        //           [
        //             {
        //               text: 'Does the patient require the procurement or transplantation of a pancreas as part of a multiple organ transplant for technical reasons',
        //               style: 'tableHeader',
        //               alignment: 'left',
        //               colSpan: 1,
        //             },

        //             {
        //               text: `${
        //                 pancreasDetails?.pancreasAsMultipleOrgan
        //                   ? pancreasDetails?.pancreasAsMultipleOrgan
        //                   : '-'
        //               }`,
        //               colSpan: 2,
        //             },
        //             {},
        //           ],
        //           [
        //             {
        //               text: 'Does the patient have diagnosed with diabetes for Type I DM? \n a)Secondary Complications of diabetes \n b) Poor quality of life with Insulin',
        //               style: 'tableHeader',
        //               alignment: 'left',
        //               colSpan: 1,
        //             },

        //             {
        //               text: `${
        //                 pancreasDetails?.diabetes?.secondaryComplications
        //                   ? pancreasDetails?.diabetes?.secondaryComplications
        //                   : '-'
        //               } -
        //             ${
        //               pancreasDetails?.diabetes?.pancreasDetails?.diabetes
        //                 ?.pooerQualityOfLifeWithInsulin
        //                 ? pancreasDetails?.diabetes?.pancreasDetails?.diabetes
        //                     ?.pooerQualityOfLifeWithInsulin
        //                 : '-'
        //             }`,
        //               colSpan: 2,
        //             },
        //             {},
        //           ],
        //           [
        //             {
        //               text: 'Treatment Details',
        //               style: 'tableHeader',
        //               alignment: 'left',
        //               colSpan: 1,
        //             },

        //             {
        //               text: `${
        //                 pancreasDetails?.diabetes?.pancreasDetails?.diabetes?.treatmentDetails
        //                   ? pancreasDetails?.diabetes?.pancreasDetails?.diabetes?.treatmentDetails
        //                   : '-'
        //               }`,
        //               colSpan: 2,
        //             },
        //             {},
        //           ],
        //           [
        //             {
        //               text: 'Does the patient have diagnosed with diabetes for Type II DM? \n a)Does the patient have Insulin dependency? \n b) Does the patient have any minimal coronary risk or with corrected coronary disease and therefore low cardiac risk?',
        //               style: 'tableHeader',
        //               alignment: 'left',
        //               colSpan: 1,
        //             },

        //             {
        //               text: `${
        //                 pancreasDetails?.diabetes?.pancreasDetails?.diabetes?.insulinDependency
        //                   ? pancreasDetails?.diabetes?.pancreasDetails?.diabetes?.insulinDependency
        //                   : '-'
        //               } -
        //             ${
        //               pancreasDetails?.diabetes?.pancreasDetails?.diabetes?.minimalCoronaryRisk
        //                 ? pancreasDetails?.diabetes?.pancreasDetails?.diabetes?.minimalCoronaryRisk
        //                 : '-'
        //             }`,
        //               colSpan: 2,
        //             },
        //             {},
        //           ],
        //           [
        //             {
        //               text: 'Treatment Details',
        //               style: 'tableHeader',
        //               alignment: 'left',
        //               colSpan: 1,
        //             },

        //             {
        //               text: `${
        //                 pancreasDetails?.diabetes?.pancreasDetails?.diabetes?.treatmentDetails
        //                   ? pancreasDetails?.diabetes?.pancreasDetails?.diabetes?.treatmentDetails
        //                   : '-'
        //               }`,
        //               colSpan: 2,
        //             },
        //             {},
        //           ],

        //           ...(basicDetails.waitingStatus === 'SPK' ? [] : []),
        //         ],
        //       },
        //     }
        //   : null,
        // organs.includes('INTESTINE')
        organs.some((organ) => organ.organType === 'INTESTINE')
          ? {
              stack: [
                {
                  style: 'table',
                  table: {
                    widths: [250, 100, 150, 'auto', 'auto'],
                    heights: [
                      'auto',
                      'auto',
                      'auto',
                      'auto',
                      'auto',
                      'auto',
                      'auto',
                      'auto',
                      'auto',
                      'auto',
                      'auto',
                      'auto',
                      'auto',
                      'auto',
                    ],
                    body: [
                      [
                        {
                          text: 'State Organ and Tissue Transplant Organisation (SOTTO),\n  Maharashtra',
                          style: 'tableHeader',
                          colSpan: 3,
                          alignment: 'center',
                          bold: true,
                        },
                        {},
                        {},
                      ],
                      [
                        {
                          text: 'State Appropriate Authority of Maharashtra',
                          style: 'textStyle',
                          alignment: 'center',
                          colSpan: 3,
                        },
                        {},
                        {},
                      ],
                      [
                        {
                          text: 'INTESTINE REGISTRATION',
                          style: 'textStyle',
                          alignment: 'center',
                          colSpan: 3,
                        },
                        {},
                        {},
                      ],
                      // [
                      //   {
                      //     text: 'Does the patient have Primary Intestinal failure? \n \n Massive bowel resection \n Crohn’s disease \n Necrotizing enterocolitis \n Malabsorption syndromes \n Tumors such as massive mesenteric desmoids \n Visceral myopathy \n Pseudo obstruction',
                      //     style: 'tableHeader',
                      //     alignment: 'left',
                      //     colSpan: 1,
                      //   },

                      //   {
                      //     text: `${
                      //       primaryIntestinalFailure?.massiveBowelResection
                      //         ? primaryIntestinalFailure?.massiveBowelResection
                      //         : '-'
                      //     } -
                      //    ${
                      //      primaryIntestinalFailure?.cronhsDisease
                      //        ? primaryIntestinalFailure?.cronhsDisease
                      //        : '-'
                      //    } -
                      //    ${
                      //      primaryIntestinalFailure?.necrotizingEnterocolitis
                      //        ? primaryIntestinalFailure?.necrotizingEnterocolitis
                      //        : '-'
                      //    } -
                      //    ${
                      //      primaryIntestinalFailure?.malabsorptionSyndromes
                      //        ? primaryIntestinalFailure?.malabsorptionSyndromes
                      //        : '-'
                      //    } -
                      //    ${primaryIntestinalFailure?.tumors ? primaryIntestinalFailure?.tumors : '-'} -
                      //    ${
                      //      primaryIntestinalFailure?.visceralMyopathy
                      //        ? primaryIntestinalFailure?.visceralMyopathy
                      //        : '-'
                      //    } -
                      //    ${
                      //      primaryIntestinalFailure?.pseudoObstruction
                      //        ? primaryIntestinalFailure?.pseudoObstruction
                      //        : '-'
                      //    }`,
                      //     colSpan: 2,
                      //   },
                      //   {},
                      // ],
                      // [
                      //   {
                      //     text: ' Does the patient have the Failure of Parenteral Nutrition? \n \n Impending or overt liver failure due to TPN-induced liver injury \n Thrombosis of two or more central veins \n Two or more episodes per year of catheter-related systemic sepsis that requires hospitalization \n A single episode of line-related fungemia, septic shock, or acute respiratory distress syndrome \n Frequent episodes of severe dehydration despite intravenous fluid supplementation in addition to TPN',
                      //     style: 'tableHeader',
                      //     alignment: 'left',
                      //     colSpan: 1,
                      //   },

                      //   {
                      //     text: `${
                      //       failureOfParenteralNutrition?.impendingOrOvertLiverFailure
                      //         ? failureOfParenteralNutrition?.impendingOrOvertLiverFailure
                      //         : '-'
                      //     } -
                      //    ${
                      //      failureOfParenteralNutrition?.thrombosisOfTwoOrMoreCentralVeins
                      //        ? failureOfParenteralNutrition?.thrombosisOfTwoOrMoreCentralVeins
                      //        : '-'
                      //    } -
                      //    ${
                      //      failureOfParenteralNutrition?.twoOrMoreEpisodesPerYearOfCatheterRelatedSystemicSepsisThatRequiresHospitalization
                      //        ? failureOfParenteralNutrition?.twoOrMoreEpisodesPerYearOfCatheterRelatedSystemicSepsisThatRequiresHospitalization
                      //        : '-'
                      //    } -
                      //    ${
                      //      failureOfParenteralNutrition?.singleEpisodeOfLineRelatedFungemiaSepticShockOrAcuteRespiratoryDistressSyndrome
                      //        ? failureOfParenteralNutrition?.singleEpisodeOfLineRelatedFungemiaSepticShockOrAcuteRespiratoryDistressSyndrome
                      //        : '-'
                      //    } -
                      //    ${
                      //      failureOfParenteralNutrition?.frequentEpisodesOfSevereDehydrationDespiteIntravenousFluidSupplementationInAdditionToTPN
                      //        ? failureOfParenteralNutrition?.frequentEpisodesOfSevereDehydrationDespiteIntravenousFluidSupplementationInAdditionToTPN
                      //        : '-'
                      //    }`,
                      //     colSpan: 2,
                      //   },
                      //   {},
                      // ],
                      // [
                      //   {
                      //     text: 'Does the patient have the additional liver dysfunction or failure due to long term total parenteral nutrition?',
                      //     style: 'tableHeader',
                      //     alignment: 'left',
                      //     colSpan: 1,
                      //   },

                      //   {
                      //     text: `${
                      //       intestineDetails?.liverDysfucntion
                      //         ? intestineDetails?.liverDysfucntion
                      //         : '-'
                      //     }`,
                      //     colSpan: 2,
                      //   },
                      //   {},
                      // ],
                      // [
                      //   {
                      //     text: 'Does the patient have the Extensive thrombosis of porto-mesenteric axis with or without liver failure?',
                      //     style: 'tableHeader',
                      //     alignment: 'left',
                      //     colSpan: 1,
                      //   },

                      //   {
                      //     text: `${
                      //       intestineDetails?.extensiveThrombosis
                      //         ? intestineDetails?.extensiveThrombosis
                      //         : '-'
                      //     }`,
                      //     colSpan: 2,
                      //   },
                      //   {},
                      // ],

                      [
                        {
                          text: `Does the patient have Primary Intestinal failure? `,
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${_yesNoAndEmptyFormatter(
                            intestineDetails?.primaryIntestinalFailure?.primaryIntestinalFailure
                          )}`,
                          colSpan: 2,
                        },
                        {},
                      ],

                      [
                        {
                          text: `Does the patient required TPN? `,
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${_yesNoAndEmptyFormatter(
                            intestineDetails?.primaryIntestinalFailure?.tpn?.tpn
                          )}`,
                          colSpan: 2,
                        },
                        {},
                      ],

                      [
                        {
                          text: `Does the patient have the Failure of Parenteral Nutrition? `,
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${_yesNoAndEmptyFormatter(
                            intestineDetails?.primaryIntestinalFailure?.tpn
                              ?.failureOfParenteralNutrition?.failureOfParenteralNutrition
                          )}`,
                          colSpan: 2,
                        },
                        {},
                      ],

                      [
                        {
                          text: `Impending or overt liver failure due to TPN-induced liver injury `,
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${_yesNoAndEmptyFormatter(
                            intestineDetails?.primaryIntestinalFailure?.tpn
                              ?.failureOfParenteralNutrition?.impendingOrOvertLiverFailure
                          )}`,
                          colSpan: 2,
                        },
                        {},
                      ],
                      [
                        {
                          text: `Attachment `,
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${
                            intestineDetails?.primaryIntestinalFailure?.tpn
                              ?.failureOfParenteralNutrition
                              ?.impendingOrOvertLiverFailureLftAttachment?.name
                              ? 'ATTACHED'
                              : '-'
                          }`,
                          colSpan: 2,
                        },
                        {},
                      ],
                      [
                        {
                          text: ` Date `,
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${
                            intestineDetails?.primaryIntestinalFailure?.tpn
                              ?.failureOfParenteralNutrition?.impendingOrOvertLiverFailureLftDate
                              ? moment(
                                  intestineDetails?.primaryIntestinalFailure?.tpn
                                    ?.failureOfParenteralNutrition
                                    ?.impendingOrOvertLiverFailureLftDate,
                                  FORMAT_DATE_UNIXTS
                                ).format('DD/MM/YYYY')
                              : '-'
                          }`,
                          colSpan: 2,
                        },
                        {},
                      ],

                      [
                        {
                          text: `Thrombosis of two or more central veins `,
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${_yesNoAndEmptyFormatter(
                            intestineDetails?.primaryIntestinalFailure?.tpn
                              ?.failureOfParenteralNutrition?.thrombosisOfTwoOrMoreCentralVeins
                          )}`,
                          colSpan: 2,
                        },
                        {},
                      ],
                      [
                        {
                          text: `Attachment `,
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${
                            intestineDetails?.primaryIntestinalFailure?.tpn
                              ?.failureOfParenteralNutrition
                              ?.thrombosisOfTwoOrMoreCentralVeinsAttachment?.name
                              ? 'ATTACHED'
                              : '-'
                          }`,
                          colSpan: 2,
                        },
                        {},
                      ],
                      [
                        {
                          text: ` Date `,
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${
                            intestineDetails?.primaryIntestinalFailure?.tpn
                              ?.failureOfParenteralNutrition
                              ?.thrombosisOfTwoOrMoreCentralVeinsAttachmentDate
                              ? moment(
                                  intestineDetails?.primaryIntestinalFailure?.tpn
                                    ?.failureOfParenteralNutrition
                                    ?.thrombosisOfTwoOrMoreCentralVeinsAttachmentDate,
                                  FORMAT_DATE_UNIXTS
                                ).format('DD/MM/YYYY')
                              : '-'
                          }`,
                          colSpan: 2,
                        },
                        {},
                      ],

                      [
                        {
                          text: `Two or more episodes per year of catheter-related systemic sepsis that requires hospitalization `,
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${_yesNoAndEmptyFormatter(
                            intestineDetails?.primaryIntestinalFailure?.tpn
                              ?.failureOfParenteralNutrition?.catheterRealtedSystemicSepsis
                          )}`,
                          colSpan: 2,
                        },
                        {},
                      ],
                      [
                        {
                          text: `Attachment `,
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${
                            intestineDetails?.primaryIntestinalFailure?.tpn
                              ?.failureOfParenteralNutrition
                              ?.catheterRealtedSystemicSepsisAttachment?.name
                              ? 'ATTACHED'
                              : '-'
                          }`,
                          colSpan: 2,
                        },
                        {},
                      ],
                      [
                        {
                          text: ` Date `,
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${
                            intestineDetails?.primaryIntestinalFailure?.tpn
                              ?.failureOfParenteralNutrition
                              ?.catheterRealtedSystemicSepsisAttachmentDate
                              ? moment(
                                  intestineDetails?.primaryIntestinalFailure?.tpn
                                    ?.failureOfParenteralNutrition
                                    ?.catheterRealtedSystemicSepsisAttachmentDate,
                                  FORMAT_DATE_UNIXTS
                                ).format('DD/MM/YYYY')
                              : '-'
                          }`,
                          colSpan: 2,
                        },
                        {},
                      ],

                      [
                        {
                          text: `A single episode of line-related fungemia, septic shock, or acute respiratory distress syndrome `,
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${_yesNoAndEmptyFormatter(
                            intestineDetails?.primaryIntestinalFailure?.tpn
                              ?.failureOfParenteralNutrition?.lineRelatedFungemia
                          )}`,
                          colSpan: 2,
                        },
                        {},
                      ],
                      [
                        {
                          text: `Attachment `,
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${
                            intestineDetails?.primaryIntestinalFailure?.tpn
                              ?.failureOfParenteralNutrition?.lineRelatedFungemiaAttachment?.name
                              ? 'ATTACHED'
                              : '-'
                          }`,
                          colSpan: 2,
                        },
                        {},
                      ],
                      [
                        {
                          text: ` Date `,
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${
                            intestineDetails?.primaryIntestinalFailure?.tpn
                              ?.failureOfParenteralNutrition?.lineRelatedFungemiaAttachmentDate
                              ? moment(
                                  intestineDetails?.primaryIntestinalFailure?.tpn
                                    ?.failureOfParenteralNutrition
                                    ?.lineRelatedFungemiaAttachmentDate,
                                  FORMAT_DATE_UNIXTS
                                ).format('DD/MM/YYYY')
                              : '-'
                          }`,
                          colSpan: 2,
                        },
                        {},
                      ],

                      [
                        {
                          text: `Frequent episodes of severe dehydration despite intravenous fluid supplementation in addition to TPN `,
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${_yesNoAndEmptyFormatter(
                            intestineDetails?.primaryIntestinalFailure?.tpn
                              ?.failureOfParenteralNutrition?.severeDehydration
                          )}`,
                          colSpan: 2,
                        },
                        {},
                      ],
                      [
                        {
                          text: `Attachment `,
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${
                            intestineDetails?.primaryIntestinalFailure?.tpn
                              ?.failureOfParenteralNutrition?.severeDehydrationAttachment?.name
                              ? 'ATTACHED'
                              : '-'
                          }`,
                          colSpan: 2,
                        },
                        {},
                      ],
                      [
                        {
                          text: ` Date `,
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${
                            intestineDetails?.primaryIntestinalFailure?.tpn
                              ?.failureOfParenteralNutrition?.severeDehydrationAttachmentDate
                              ? moment(
                                  intestineDetails?.primaryIntestinalFailure?.tpn
                                    ?.failureOfParenteralNutrition?.severeDehydrationAttachmentDate,
                                  FORMAT_DATE_UNIXTS
                                ).format('DD/MM/YYYY')
                              : '-'
                          }`,
                          colSpan: 2,
                        },
                        {},
                      ],

                      [
                        {
                          text: `Does the Patient required Abdominal Wall? `,
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${_yesNoAndEmptyFormatter(
                            intestineDetails?.patientRequiresAbdominalWall
                          )}`,
                          colSpan: 2,
                        },
                        {},
                      ],

                      [
                        {
                          text: `Massive bowel resection `,
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${_yesNoAndEmptyFormatter(
                            intestineDetails?.massiveBowelResection?.massiveBowelResection
                          )}`,
                          colSpan: 2,
                        },
                        {},
                      ],
                      [
                        {
                          text: `Length of Bowl Remaining (in cm) `,
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${
                            intestineDetails?.massiveBowelResection?.lengthOfBowel
                              ? intestineDetails?.massiveBowelResection?.lengthOfBowel
                              : '-'
                          }`,
                          colSpan: 2,
                        },
                        {},
                      ],

                      [
                        {
                          text: `Massive bowel resection Attachment `,
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${
                            intestineDetails?.massiveBowelResection?.massiveBowelResectionAttachment
                              ?.name
                              ? 'ATTACHED'
                              : '-'
                          }`,
                          colSpan: 2,
                        },
                        {},
                      ],

                      [
                        {
                          text: `Massive bowel resection Date `,
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${
                            intestineDetails?.massiveBowelResection?.massiveBowelResectionDate
                              ? moment(
                                  intestineDetails?.massiveBowelResection
                                    ?.massiveBowelResectionDate,
                                  FORMAT_DATE_UNIXTS
                                ).format('DD/MM/YYYY')
                              : '-'
                          }`,
                          colSpan: 2,
                        },
                        {},
                      ],

                      [
                        {
                          text: 'Viral Marker Status : ',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 3,
                          bold: true,
                        },
                        {},
                        {},
                      ],
                      [
                        {
                          text: `HBs AG Report Date \n (Positive/Negative) `,
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${_viralMrakerRemarkAndDateFormatter(
                            intestineDetails?.viralMarkerStatus?.hbsagRemark,
                            intestineDetails?.viralMarkerStatus?.hbsagReportDate
                          )}`,
                          colSpan: 2,
                        },
                        {},
                      ],

                      [
                        {
                          text: `Anti HCV Report Date \n (Positive/Negative) `,
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${_viralMrakerRemarkAndDateFormatter(
                            intestineDetails?.viralMarkerStatus?.antiHcvRemark,
                            intestineDetails?.viralMarkerStatus?.antiHcvReportDate
                          )}`,
                          colSpan: 2,
                        },
                        {},
                      ],
                      [
                        {
                          text: `HCV RNA Quantitative Report Date \n (Positive/Negative/Not Done) `,
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        intestineDetails?.viralMarkerStatus?.hcvRnaQuantitativeRemark === 'NOTDONE'
                          ? {
                              text: `${_viralMrakerRemarkAndDateFormatter(
                                intestineDetails?.viralMarkerStatus?.hcvRnaQuantitativeRemark
                              )}`,
                              colSpan: 2,
                            }
                          : {
                              text: `${_viralMrakerRemarkAndDateFormatter(
                                intestineDetails?.viralMarkerStatus?.hcvRnaQuantitativeRemark,
                                intestineDetails?.viralMarkerStatus?.hcvRnaQuantitativeReportDate
                              )}`,
                              colSpan: 2,
                            },

                        {},
                      ],
                      [
                        {
                          text: `HCV DNA Report Date \n (Positive/Negative/Not Done) `,
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        intestineDetails?.viralMarkerStatus?.hcvDnaRemark === 'NOTDONE'
                          ? {
                              text: `${_viralMrakerRemarkAndDateFormatter(
                                intestineDetails?.viralMarkerStatus?.hcvDnaRemark
                              )}`,
                              colSpan: 2,
                            }
                          : {
                              text: `${_viralMrakerRemarkAndDateFormatter(
                                intestineDetails?.viralMarkerStatus?.hcvDnaRemark,
                                intestineDetails?.viralMarkerStatus?.hcvDnaReportDate
                              )}`,
                              colSpan: 2,
                            },

                        {},
                      ],
                      [
                        {
                          text: `HIV Report Date \n (Positive/Negative) `,
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${_viralMrakerRemarkAndDateFormatter(
                            intestineDetails?.viralMarkerStatus?.hivRemark,
                            intestineDetails?.viralMarkerStatus?.hivReportDate
                          )}`,
                          colSpan: 2,
                        },
                        {},
                      ],
                    ],
                  },
                },
                {
                  text: 'Any Other Attachments',
                  style: 'subHeader',
                },
                {
                  style: 'table',
                  table: {
                    headerRows: 1,
                    widths: ['*', '*'],
                    body: [
                      [
                        { text: 'Attachment Name', style: 'tableHeader', alignment: 'left' },
                        { text: 'Report', style: 'tableHeader', alignment: 'left' },
                      ],
                      ...intestineDetails?.otherAttachments?.map((e) => [
                        {
                          text: `${e.attachmentName ? e.attachmentName : '-'}`,
                          style: 'tableCell',
                          alignment: 'left',
                        },
                        {
                          text: `${e.attachment?.name ? 'ATTACHED' : '-'}`,
                          style: 'tableCell',
                          alignment: 'left',
                        },
                      ]),
                    ],
                  },
                },

                {
                  style: 'table',
                  table: {
                    headerRows: 1,
                    widths: ['*', '*'],
                    body: [
                      [
                        { text: 'Etiology ', style: 'tableHeader', alignment: 'left' },
                        { text: 'Etiology Report', style: 'tableHeader', alignment: 'left' },
                      ],
                      ...intestineDetails.etiologies.map((e) => [
                        {
                          text: `${e.reportType ? e.reportType : '-'}`,
                          style: 'tableCell',
                          alignment: 'left',
                        },
                        {
                          text: `${e.attachment?.name ? 'ATTACHED' : '-'}`,
                          style: 'tableCell',
                          alignment: 'left',
                        },
                      ]),
                    ],
                  },
                },

                {
                  style: 'table',
                  table: {
                    widths: [250, 100, 150, 'auto', 'auto'],
                    heights: [
                      'auto',
                      'auto',
                      'auto',
                      'auto',
                      'auto',
                      'auto',
                      'auto',
                      'auto',
                      'auto',
                      'auto',
                      'auto',
                      'auto',
                      // 20,
                      // 20,
                      // 10,
                      'auto',
                      'auto',
                      'auto',
                      'auto',
                      'auto',
                      'auto',
                      'auto',
                      'auto',
                      'auto',
                      'auto',
                      'auto',
                    ],
                    body: [
                      [
                        {
                          text: 'Etiology Details ',
                          style: 'tableHeader',
                          alignment: 'left',
                          colSpan: 1,
                        },

                        {
                          text: `${
                            intestineDetails?.etiologyDetails
                              ? intestineDetails?.etiologyDetails
                              : '-'
                          }`,
                          colSpan: 2,
                        },
                        {},
                      ],
                    ],
                  },
                },
                {
                  style: 'table',
                  table: {
                    headerRows: 1,
                    widths: ['*', '*'],
                    body: [
                      [
                        {
                          text: 'Gastroenterologist Team',
                          style: 'tableHeader',
                          alignment: 'left',
                        },

                        { text: 'Transplant Surgeon', style: 'tableHeader', alignment: 'left' },
                      ],
                      [
                        (intestineDetails?.gastroenterologistTeam || [])?.map((e) => [
                          {
                            text: `${e.user.name ? e.user.name : '-'}`,
                            style: 'tableCell',
                            alignment: 'left',
                          },
                        ]),
                        (intestineDetails?.transplantSurgeon || [])?.map((e) => [
                          {
                            text: `${e.user.name ? e.user.name : '-'}`,
                            style: 'tableCell',
                            alignment: 'left',
                          },
                        ]),
                      ],
                    ],
                  },
                },
              ],
            }
          : null,

        {
          style: 'table',
          table: {
            widths: [250, 100, 150],
            heights: [0, 50, 50, 50, 50, 50],
            body: [
              [
                {
                  text: 'All verification of the clinical details / reports / criteria eligibility to justify above criteria is the responsibility of the transplant team signing this form. \n1. All the reports (Lab reports + imaging report) should be within 1 month at the time of registration. The patients with older reports should not be considered for registration.\n2. All lab reports should be within 1 month and imaging should be within 3 months for allocation, patients with older reports should not be considered for allocation.',
                  style: 'tableHeader',
                  colSpan: 3,
                  // alignment: 'center',
                  // bold: true,
                  fontSize: 11,
                },
                {},
                {},
              ],
              [
                {
                  text: 'Patient Signature:',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                { text: '', colSpan: 2 },
              ],
              [
                {
                  text: 'Surgeon Signature:',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                { text: '', colSpan: 2 },
              ],
              [
                {
                  text: 'Physician Signature:',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                { text: '', colSpan: 2 },
              ],
              [
                {
                  text: 'Transplant Coordinator Signature:',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                { text: '', colSpan: 2 },
              ],
              [
                {
                  text: 'Signature of Hospital Head:',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                { text: '', colSpan: 2 },
              ],
            ],
          },
        },
      ],
      defaultStyle: {
        fontSize: 10,
      },
      styles: {
        header: {
          fontSize: 16,
          // bold: true,
          margin: [0, 30, 0, 10],
          alignment: 'center',
        },
        table: {
          margin: [0, 5, 0, 15],
          // fontSize: 16,
        },
        textStyle: {
          alignment: 'center',
        },
        subHeader: {
          fontSize: 10,
          bold: true,
          margin: [0, 20, 0, 5],
        },
      },
    };

    this.reportDocDefinition = reportDocDefinition;
  }

  docDefinition() {
    const documentDefinition = this.reportDocDefinition;
    return pdfMake.createPdf(documentDefinition);
  }

  download(file) {
    const fileName = file || `Allocation Recipient Record`;
    const documentDefinition = this.reportDocDefinition;
    pdfMake.createPdf(documentDefinition).download(fileName);
  }

  getBuffer(cb = () => {}) {
    const documentDefinition = this.reportDocDefinition;
    return pdfMake.createPdf(documentDefinition).getBuffer(cb);
  }
}

export default AllocationRecipientRecordFormPdf;
