import React, { useState, useEffect, memo } from 'react';
import { useSelector } from 'react-redux';
import { compose } from 'redux';
import Joi from 'joi';
import {
  makeStyles,
  Paper,
  Box,
  Button,
  Typography,
  Grid,
  List,
  ListItem,
  FormControlLabel,
  Radio,
  RadioGroup,
  FormControl,
  FormLabel,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import util from 'util';
import { useSnackbar } from 'notistack';
import { withRouter, useRouteMatch, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Document, Page } from 'react-pdf';
import moment from 'moment';
import CloseIcon from 'mdi-react/CloseIcon';
import LoadableButton from '../../Custom/LoadableButton';
import { withValidation } from '../../../hoc';
import StyledDialogTitle from '../../common/StyledDialogTitle';
import { useCancellableApiCall } from '../../../hooks';
import CustomOrganSelector from '../../common/CustomOrganSelector';
import {
  ValidatedInput,
  ValidatedAttachment,
  ValidatedDate,
  DownloadAttachments,
  ValidatedSelect,
  ValidatedRadio,
} from '../../Custom';
import { DonorFormPdf, AllocationDonorEmptyFormPdf } from '../../Reports/DocDefinition';
import {
  VALIDATION,
  FILESUFFIX_FORMAT_DATE,
  ALLOCATIONDONOR_FILEPREFIX,
  FORMAT_DATE_UNIXTS,
  BLOODGROUP,
  SEX,
  REGEXP_MOBILE,
  isRottoUserUserType,
} from '../../../constants';
import {
  PATH_EXP_SUBMITALLOCATIONDONOR,
  PATH_EXP_GETALLOCATIONDONOR,
  PATH_EXP_SUBMITDRAFTALLOCATIONDONOR,
} from '../../../appConstants';
import MedicalReportsBasicInfo from './MedicalReportsBasicInfo';
import MedicalReportsHemodynamics from './MedicalReportHemodynamics';
import MedicalReportsHeartFunction from './MedicalReportHeartFunction';
import MedicalReportsImaging from './MedicalReportImaging';
import MedicalReportsLiverFunction from './MedicalReportLiverFunction';
import MedicalReportsKidneyFunction from './MedicalReportKidneyFunction';
import MedicalReportsLatestInvestigations from './MedicalReportLatestInvestigations';
import MedicalReportsViralMarkers from './MedicalReportsViralMarkers';
import MedicalReportsHandAssessment from './MedicalReportHandAssessment';
import { uploadDocument } from '../../../utils';
import { DONOR } from '../allocationEmptyModels';
import { OPTIONS_NATIONALITY } from '../../../nationalities';
import { FILETYPES } from '../../Custom/ValidatedAttachment';
import { STATE_IN } from '../../../statesIndia';
import { districts } from '../../../districts/districts';
import AllocationDonorAttachment from './AllocationDonorAttachment';

const MemoizedDocument = memo(function MemoizedDocument({ documentBuffer }) {
  const [numPages, setNumPages] = useState(1);
  if (!documentBuffer) {
    return null;
  }
  return (
    <Grid
      container
      item
      sm={12}
      justify="center"
      style={{ height: 500, overflowY: 'scroll', background: 'grey' }}
    >
      <Document
        file={{
          data: documentBuffer,
        }}
        onLoadSuccess={({ numPages: pdfNumPages }) => {
          if (numPages !== pdfNumPages) {
            setNumPages(pdfNumPages);
          }
        }}
      >
        {Array.from(new Array(numPages), (el, index) => (
          <Page key={`page_${index + 1}`} pageNumber={index + 1} />
        ))}
      </Document>
    </Grid>
  );
});
MemoizedDocument.propTypes = {
  documentBuffer: PropTypes.objectOf(PropTypes.any),
};
MemoizedDocument.defaultProps = {
  documentBuffer: '',
};

// eslint-disable-next-line react/prop-types
const MemoizedDocumentForView = memo(function MemoizedDocumentForView({ documentBuffer }) {
  const [numPages, setNumPages] = useState(1);
  if (!documentBuffer) {
    return null;
  }

  const handlePrint = () => {
    const pdfBlob = new Blob([documentBuffer], { type: 'application/pdf' });
    const pdfUrl = URL.createObjectURL(pdfBlob);
    const tempFrame = document.createElement('iframe');
    tempFrame.style.display = 'none';
    tempFrame.src = pdfUrl;
    tempFrame.onload = () => {
      tempFrame.contentWindow.print();
      URL.revokeObjectURL(pdfUrl);
    };
    document.body.appendChild(tempFrame);
  };
  return (
    <Grid
      container
      item
      sm={12}
      xs={12}
      justify="center"
      style={{
        height: 1500,
        width: 1000,
      }}
    >
      <div
        style={{
          position: 'relative',
          width: '100%',
          height: '100%',
          margin: 'auto',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Button
          variant="contained"
          color="primary"
          style={{
            backgroundColor: '#728FCE',
            marginTop: 20,
            width: 200,
            marginLeft: 'auto',
            marginRight: 'auto',
            display: 'block',
          }}
          onClick={handlePrint}
        >
          PRINT FORM
        </Button>
        <div
          style={{
            height: 'calc(100% - 70px)',
            overflow: 'auto',
          }}
        >
          <Document
            file={{
              data: documentBuffer,
            }}
            onLoadSuccess={({ numPages: pdfNumPages }) => {
              if (numPages !== pdfNumPages) {
                setNumPages(pdfNumPages);
              }
            }}
          >
            {Array.from(new Array(numPages), (el, index) => (
              <Page key={`page_${index + 1}`} pageNumber={index + 1} />
            ))}
          </Document>
        </div>
      </div>
    </Grid>
  );
});
MemoizedDocument.propTypes = {
  documentBuffer: PropTypes.objectOf(PropTypes.any),
};
MemoizedDocument.defaultProps = {
  documentBuffer: '',
};

function AllocationDonor({ validate, validation, onValidation }) {
  const [redirectToDashboard, setRedirectToDashboard] = useState(false);
  const [donorFormStatus, setDonorFormStatus] = useState('NEW');
  const [donorCurrentSelectedNav, setDonorCurrentSelectedNav] = useState('BASICINFO');
  const [donorOrganRetrievalDetails, setDonorOrganRetrievalDetails] = useState(
    DONOR.organRetrievalInformation
  );
  const [donorBasicInfoDetails, setDonorBasicInfoDetails] = useState(DONOR.basicInfo);
  const [donorMedicalReportsDetails, setDonorMedicalReportsDetails] = useState(
    DONOR.medicalReportsAndStatus
  );
  const [donorAttachmentsDetails, setDonorAttachmentsDetails] = useState(DONOR.attachments);
  const [ecdInfoDetails, setEcdInfoDetails] = useState(DONOR.ecdInformation);

  const [donorMedicalReportCurrentSelectedTab, setDonorMedicalReportCurrentSelectedTab] =
    useState('BASICMEDINFO');

  const [donorPages, setDonorPages] = useState([
    'BASICINFO',
    'MEDICALREPORTS',
    'ATTACHMENTS',
    'ORGANRETRVLINFO',
  ]);

  const [tumorInformation, setTumorInformation] = useState(DONOR.tumorInformation);
  const [disableDateOfBirth, setDisableDateOfBirth] = useState(false);

  const [redirectToSaved, setRedirectToSaved] = useState(false);
  const [draftDonorId, setDraftDonorId] = useState();
  const [currentRecordSection, setCurrentRecordSection] = useState('DETAILS');
  const [donorFormPreviewDialog, setDonorFormPreviewDialog] = useState(false);
  const [donorFormPreviewBuffer, setDonorFormPreviewBuffer] = useState();
  const [donorRecordViewBuffer, setDonorRecordViewBuffer] = useState();
  const [documentListDialogOpenStatus, setDocumentListDialogOpenStatus] = useState(false);
  const app = useSelector((state) => state);
  const {
    user: { userType },
    hospital: { name: hospitalName, ztccZone },
  } = app;

  const { apiPost, apiGet, apiCallStatuses } = useCancellableApiCall();

  const { enqueueSnackbar } = useSnackbar();

  const _getDonorOrgans = (donorRecord) => {
    const organs = [];
    if (donorRecord.organRetrievalInformation.retrievedOrgans) {
      donorRecord.organRetrievalInformation.retrievedOrgans.forEach((o) => {
        organs.push(o.organType);
      });
      return organs;
    }
    return [];
  };

  const calculateCurrentAge = () => {
    if (donorBasicInfoDetails.dateOfBirth) {
      const duration = moment.duration(
        moment().diff(moment(donorBasicInfoDetails.dateOfBirth, FORMAT_DATE_UNIXTS))
      );
      return `${duration?._data?.years} Years  ${duration?._data?.months} Months ${duration?._data?.days} Days`;
    }
    if (donorBasicInfoDetails.currentAge) {
      return donorBasicInfoDetails.currentAge;
    }
  };

  const donorRecordRoute = useRouteMatch('/allocationDonor/:_id');
  const updateRecordMatch = useRouteMatch('/updateAllocationDonorListing/:_id');

  let extractedRecordId;
  useEffect(() => {
    if (isRottoUserUserType(userType)) {
      setIsAddedByRottoUser(true);
    }
    if (donorRecordRoute) {
      const {
        params: { _id: id },
      } = donorRecordRoute;
      extractedRecordId = id;
    }
    if (donorRecordRoute) {
      apiGet(util.format(PATH_EXP_GETALLOCATIONDONOR, extractedRecordId), {})
        .then((donorRecord) => {
          setDonorBasicInfoDetails(donorRecord?.basicInformation);
          setDonorMedicalReportsDetails(donorRecord?.medicalReportsAndStatus);
          setDonorOrganRetrievalDetails(donorRecord?.organRetrievalInformation);
          setDonorAttachmentsDetails(donorRecord?.attachments);
          setDonorFormStatus(donorRecord?.formStatus);
          setEcdInfoDetails(donorRecord?.ecdInformation);
          setIsAddedByRottoUser(true);
          new DonorFormPdf({
            donorBasicInfoDetails: donorRecord?.basicInformation,
            donorMedicalReportsDetails: donorRecord?.medicalReportsAndStatus,
            donorAttachmentsDetails: donorRecord?.attachments,
            donorOrganRetrievalDetails: donorRecord?.organRetrievalInformation,
            retrievedOrgans: _getDonorOrgans(donorRecord),
            anyEcdOrgans: donorOrganRetrievalDetails.anyEcdOrgans,
            ecdOrgans: donorOrganRetrievalDetails.ecdOrgans,
            ecdInformation: donorRecord?.ecdInformation,
            hospitalName,
            ztccZone,
          }).getBuffer((buffer) => {
            setDonorRecordViewBuffer(buffer);
          });
        })
        .catch(() => {});
    }
  }, [apiGet, donorMedicalReportCurrentSelectedTab, setDonorMedicalReportCurrentSelectedTab]);

  const useStyles = makeStyles(() => ({
    paperTransplantInfo: {
      background: '#F0FEF9',
      border: '1px solid #B1E7D3',
      borderRadius: '4px',
      marginTop: 20,
      marginBottom: 10,
    },
    gridTransplantDetails: {
      paddingLeft: 24,
      paddingTop: 30,
      paddingRight: 24,
      paddingBottom: 20,
    },
    paperTransplantSections: {
      background: '#F0FEF9',
      border: '1px solid #B1E7D3',
      borderRadius: '4px 0px 0px 4px',
      height: '100%',
    },
    transplantSectionsStyle: {
      fontWeight: 300,
      lineHeight: '15px',
      marginTop: 20,
      marginLeft: 20,
    },
    transplantSectionsHeadingStyle: {
      fontWeight: 600,
      fontSize: '14px',
      marginLeft: 20,
    },
    transplantDetailsHeading: {
      fontWeight: 600,
      fontSize: '16px',
      color: '#34495E',
      marginTop: '5px',
    },
    form12BoxStyle: {
      direction: 'row',
      ...(donorRecordRoute ? { marginTop: 120 } : {}),
    },
  }));

  const classes = useStyles();

  const _renderDocumentListDialog = () => {
    return (
      <Dialog
        open={documentListDialogOpenStatus}
        onClose={() => {
          setDocumentListDialogOpenStatus(false);
        }}
        maxWidth="sm"
        fullWidth
      >
        <StyledDialogTitle>
          <Grid container justify="space-between" alignItems="center">
            Document List
            <IconButton
              edge="start"
              color="#F0FEF9"
              onClick={() => {
                setDocumentListDialogOpenStatus(false);
              }}
              aria-label="close"
            >
              <CloseIcon
                style={{
                  color: '#F0FEF9',
                }}
              />
            </IconButton>
          </Grid>
        </StyledDialogTitle>{' '}
        <DialogContent style={{ margin: 20 }}>
          <Grid container>
            <Grid item sm={12}>
              <List>
                <ListItem>1. First Apnea Baseline Report</ListItem>
                <ListItem>2. First Apnea after 10 minutes Report</ListItem>
                <ListItem>3. Second Apnea Baseline Report</ListItem>
                <ListItem>4. Second Apnea after 10 minutes Report</ListItem>
                <ListItem>5. Abdominal or Chest Injuries Report</ListItem>
                <ListItem>6. Operation on Donor Report</ListItem>
                <ListItem>7. Covid Vaccination Certificates</ListItem>
                <ListItem>8. Police NOC </ListItem>
                <ListItem>9. Latest RTPCR Report</ListItem>
                <ListItem>10. Latest Medication Report</ListItem>
                <ListItem>11. Infections Report</ListItem>
                <ListItem>12. Live Vaccine Report</ListItem>
                <ListItem>13. HBsAg Report</ListItem>
                <ListItem>14. HBcAb Report</ListItem>
                <ListItem>15. Anti HCV Report</ListItem>
                <ListItem>16. HIV Report</ListItem>
                <ListItem>17. CBC Report</ListItem>
                <ListItem>18. ABG Report</ListItem>
                <ListItem>19. HbA1c Report</ListItem>
                <ListItem>20. Urine Routine Report</ListItem>
                <ListItem>21. Liver UltraSound Report</ListItem>
                <ListItem>22. 2D Echo Report</ListItem>
                <ListItem>23. Chest X-Ray Report</ListItem>
                <ListItem>24. Angiography Report</ListItem>
                <ListItem>25. Blood sample of Heart Cross Matching Report</ListItem>
                <ListItem>26. CT Thorax for Lung Report</ListItem>
                <ListItem>27. Trop T Report</ListItem>
                <ListItem>28. Hand X-Ray Report</ListItem>
                <ListItem>29. CT Head Report</ListItem>
                <ListItem>30. MRI Head Report</ListItem>
                <ListItem>31. Chest X-Ray Report</ListItem>
                <ListItem>32. Abdomen X-Ray Report</ListItem>
                <ListItem>33. CT Chest Report</ListItem>
                <ListItem>34. USG Abdomen and Pelvis Report</ListItem>
                <ListItem>35. Form 8 - Declaration cum consent</ListItem>
                <ListItem>36. Form 10 - for Certification of Brain Strain Donor</ListItem>
                <ListItem>37. Admission Notes</ListItem>
                <ListItem>38. Clinical History and Medical Summary Report</ListItem>
                <ListItem>39. ICU Chart Report</ListItem>
                <ListItem>40. Investigation Chart Report</ListItem>
                <ListItem>41. Histopathology and Other Reports Relevant to the case</ListItem>
                <ListItem>42. Bronchoscopy Report</ListItem>
                <ListItem>43. Culture Report</ListItem>
                <ListItem>44. Any Other Attachments </ListItem>
              </List>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              setDocumentListDialogOpenStatus(false);
            }}
          >
            Okay
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const uploadAllocationDonorDocuments = async () => {
    const {
      basicMedicalInformation: {
        medicoLegalCaseNoc,
        abdominalOrChestInjuryReport,
        operationOnDonorReport,
        firstApneaBaselineReport,
        firstApneaAfterTenMinutesReport,
        secondApneaBaselineReport,
        secondApneaAfterTenMinutesReport,
        latestRtpct,
        anyOtherMedications,
      },
      hemoDynamicParameters: {
        diabeticReport,
        htnReport,
        ihdReport,
        asthmaReport,
        lungDiseaseReport,
      },
      viralMarkerStatus: {
        hbSagReport,
        antiHcvReport,
        hivReport,
        hcvRnaQuantitativeReport,
        hepatetisCReport,
        hbcabReport,
        hbvdnaReport,
        hcvrnaReport,
      },
      latestInvestigations: { cbcReport, abgReport, hba1cReport, urineRoutineReport },
      renalFunction: { reports: renalFunctionReports },
      liverFunction: { reports: liverFunctionReports, liverUltraSoundReport },
      heartFunction: {
        twoDEchoReport,
        angiographyReport: heartFunctionAngiographyReport,
        chestXRayReport,
        bloodSampleForCrossMatchReport,
        bloodSampleForHeartCrossMatchingReport,
        ctThoraxReport,
        tropTReport,
      },
      medicalHistory: {
        infectionsReport,
        liveVaccinesReport,
        occupationalExposureReport,
        bloodTransfusionReport,
        contactWithTransmissibleDiseasesReport,
        illicitDrugsReport,
        tattoEarOrBodyPiercingReport,
        unsafeSexPraticesReport,
      },
      handAssessment: { handXRay },
      imaging: {
        ctHead: { ctHeadReport },
        mriHead: { mriHeadReport },
        xRayChest: { xRayChestReport },
        xRayAbdomen: { xRayAbdomenReport },
        ctChest: { ctChestReport },
        usgAbdomenAndPelvis: { usgAbdomenAndPelvisReport },
        reports: imagingReports,
      },
    } = donorMedicalReportsDetails;

    const {
      anyReports,
      form8Report,
      form10Report,
      admissionReport,
      clinicalHistoryReport,
      icuChartReport,
      investigationChartReport,
      imagingHistopathologyEndoscopiesReport,
      angiographyReport,
      cultureReport,
      procalcitoninReport,
    } = donorAttachmentsDetails;

    const {
      firstDose: { firstDoseAttachment },
      bothDose: { bothDoseAttachment },
      boosterDose: { doseAttachment },
    } = donorMedicalReportsDetails?.basicMedicalInformation?.covidVaccination;

    const { deathCertificate } = donorOrganRetrievalDetails;

    const fileSuffix = moment().format(FILESUFFIX_FORMAT_DATE);

    const uploadPromises = [];

    uploadPromises.push(
      uploadDocument(
        firstApneaBaselineReport,
        ALLOCATIONDONOR_FILEPREFIX.FIRSTAPNEABASELINEREPORT,
        hospitalName,
        fileSuffix
      )
    );

    uploadPromises.push(
      uploadDocument(
        firstApneaAfterTenMinutesReport,
        ALLOCATIONDONOR_FILEPREFIX.FIRSTAPNEAAFTERTENMINUTESREPORT,
        hospitalName,
        fileSuffix
      )
    );
    uploadPromises.push(
      uploadDocument(
        secondApneaBaselineReport,
        ALLOCATIONDONOR_FILEPREFIX.SECONDAPNEABASELINEREPORT,
        hospitalName,
        fileSuffix
      )
    );

    uploadPromises.push(
      uploadDocument(
        secondApneaAfterTenMinutesReport,
        ALLOCATIONDONOR_FILEPREFIX.SECONDAPNEAAFTERTENMINUTESREPORT,
        hospitalName,
        fileSuffix
      )
    );

    uploadPromises.push(
      uploadDocument(latestRtpct, ALLOCATIONDONOR_FILEPREFIX.LATESTRTPCR, hospitalName, fileSuffix)
    );

    uploadPromises.push(
      uploadDocument(
        anyOtherMedications,
        ALLOCATIONDONOR_FILEPREFIX.ANYOTHERMEDICATIONS,
        hospitalName,
        fileSuffix
      )
    );
    uploadPromises.push(
      uploadDocument(
        liverUltraSoundReport,
        ALLOCATIONDONOR_FILEPREFIX.LIVERULTRASOUNDREPORT,
        hospitalName,
        fileSuffix
      )
    );
    uploadPromises.push(
      uploadDocument(handXRay, ALLOCATIONDONOR_FILEPREFIX.HANDXRAY, hospitalName, fileSuffix)
    );
    uploadPromises.push(
      uploadDocument(
        ctHeadReport,
        ALLOCATIONDONOR_FILEPREFIX.CTHEADREPORT,
        hospitalName,
        fileSuffix
      )
    );
    uploadPromises.push(
      uploadDocument(
        mriHeadReport,
        ALLOCATIONDONOR_FILEPREFIX.MRIHEADREPORT,
        hospitalName,
        fileSuffix
      )
    );
    uploadPromises.push(
      uploadDocument(
        xRayChestReport,
        ALLOCATIONDONOR_FILEPREFIX.XRAYCHESTREPORT,
        hospitalName,
        fileSuffix
      )
    );
    uploadPromises.push(
      uploadDocument(
        xRayAbdomenReport,
        ALLOCATIONDONOR_FILEPREFIX.XRAYABDOMENREPORT,
        hospitalName,
        fileSuffix
      )
    );
    uploadPromises.push(
      uploadDocument(
        ctChestReport,
        ALLOCATIONDONOR_FILEPREFIX.CTCHESTREPORT,
        hospitalName,
        fileSuffix
      )
    );
    uploadPromises.push(
      uploadDocument(
        usgAbdomenAndPelvisReport,
        ALLOCATIONDONOR_FILEPREFIX.USGABDOMENANDPELVISREPORT,
        hospitalName,
        fileSuffix
      )
    );

    uploadPromises.push(
      uploadDocument(
        abdominalOrChestInjuryReport,
        ALLOCATIONDONOR_FILEPREFIX.ABDOMINALCHESTINJURIESREPORT,
        hospitalName,
        fileSuffix
      )
    );

    uploadPromises.push(
      uploadDocument(
        operationOnDonorReport,
        ALLOCATIONDONOR_FILEPREFIX.OPERATIONONDONORREPORT,
        hospitalName,
        fileSuffix
      )
    );

    uploadPromises.push(
      uploadDocument(
        medicoLegalCaseNoc,
        ALLOCATIONDONOR_FILEPREFIX.MEDICOLEGALCASENOC,
        hospitalName,
        fileSuffix
      )
    );

    uploadPromises.push(
      uploadDocument(
        doseAttachment,
        ALLOCATIONDONOR_FILEPREFIX.DOSEATTACHMENT,
        hospitalName,
        fileSuffix
      )
    );

    uploadPromises.push(
      uploadDocument(
        firstDoseAttachment,
        ALLOCATIONDONOR_FILEPREFIX.FIRSTDOSEATTACHMENT,
        hospitalName,
        fileSuffix
      )
    );

    uploadPromises.push(
      uploadDocument(
        bothDoseAttachment,
        ALLOCATIONDONOR_FILEPREFIX.BOTHDOSE,
        hospitalName,
        fileSuffix
      )
    );

    for (let j = 0; j < liverFunctionReports.length; j += 1) {
      uploadPromises.push(
        uploadDocument(
          liverFunctionReports[j],
          ALLOCATIONDONOR_FILEPREFIX.REPORTS,
          hospitalName,
          fileSuffix
        )
      );
    }

    for (let j = 0; j < renalFunctionReports.length; j += 1) {
      uploadPromises.push(
        uploadDocument(
          renalFunctionReports[j],
          ALLOCATIONDONOR_FILEPREFIX.REPORTS,
          hospitalName,
          fileSuffix
        )
      );
    }

    uploadPromises.push(
      uploadDocument(
        diabeticReport,
        ALLOCATIONDONOR_FILEPREFIX.DIABETICREPORT,
        hospitalName,
        fileSuffix
      )
    );

    uploadPromises.push(
      uploadDocument(htnReport, ALLOCATIONDONOR_FILEPREFIX.HTNREPORT, hospitalName, fileSuffix)
    );

    uploadPromises.push(
      uploadDocument(ihdReport, ALLOCATIONDONOR_FILEPREFIX.IHDREPORT, hospitalName, fileSuffix)
    );

    uploadPromises.push(
      uploadDocument(
        asthmaReport,
        ALLOCATIONDONOR_FILEPREFIX.ASTHMAREPORT,
        hospitalName,
        fileSuffix
      )
    );

    uploadPromises.push(
      uploadDocument(
        lungDiseaseReport,
        ALLOCATIONDONOR_FILEPREFIX.LUNGDISEASEREPORT,
        hospitalName,
        fileSuffix
      )
    );

    uploadPromises.push(
      uploadDocument(hbcabReport, ALLOCATIONDONOR_FILEPREFIX.HBCABREPORT, hospitalName, fileSuffix)
    );

    uploadPromises.push(
      uploadDocument(hbSagReport, ALLOCATIONDONOR_FILEPREFIX.HBSAGREPORT, hospitalName, fileSuffix)
    );

    uploadPromises.push(
      uploadDocument(
        antiHcvReport,
        ALLOCATIONDONOR_FILEPREFIX.ANTIHCVREPORT,
        hospitalName,
        fileSuffix
      )
    );

    uploadPromises.push(
      uploadDocument(
        hcvRnaQuantitativeReport,
        ALLOCATIONDONOR_FILEPREFIX.HCVRNAQUANTITATIVEREPORT,
        hospitalName,
        fileSuffix
      )
    );

    uploadPromises.push(
      uploadDocument(
        hepatetisCReport,
        ALLOCATIONDONOR_FILEPREFIX.HEPATETISCREPORT,
        hospitalName,
        fileSuffix
      )
    );

    uploadPromises.push(
      uploadDocument(hivReport, ALLOCATIONDONOR_FILEPREFIX.HIVREPORT, hospitalName, fileSuffix)
    );

    uploadPromises.push(
      uploadDocument(
        hbvdnaReport,
        ALLOCATIONDONOR_FILEPREFIX.HBVDNAREPORT,
        hospitalName,
        fileSuffix
      )
    );

    uploadPromises.push(
      uploadDocument(
        hcvrnaReport,
        ALLOCATIONDONOR_FILEPREFIX.HCVRNAREPORT,
        hospitalName,
        fileSuffix
      )
    );

    uploadPromises.push(
      uploadDocument(cbcReport, ALLOCATIONDONOR_FILEPREFIX.CBCREPORT, hospitalName, fileSuffix)
    );

    uploadPromises.push(
      uploadDocument(abgReport, ALLOCATIONDONOR_FILEPREFIX.ABGREPORT, hospitalName, fileSuffix)
    );

    uploadPromises.push(
      uploadDocument(hba1cReport, ALLOCATIONDONOR_FILEPREFIX.HBA1CREPORT, hospitalName, fileSuffix)
    );

    uploadPromises.push(
      uploadDocument(
        urineRoutineReport,
        ALLOCATIONDONOR_FILEPREFIX.URINEROUTINEREPORT,
        hospitalName,
        fileSuffix
      )
    );

    uploadPromises.push(
      uploadDocument(
        renalFunctionReports,
        ALLOCATIONDONOR_FILEPREFIX.REPORTS,
        hospitalName,
        fileSuffix
      )
    );

    for (let j = 0; j < imagingReports.length; j += 1) {
      uploadPromises.push(
        uploadDocument(
          imagingReports[j],
          ALLOCATIONDONOR_FILEPREFIX.REPORTS,
          hospitalName,
          fileSuffix
        )
      );
    }
    uploadPromises.push(
      uploadDocument(
        liverFunctionReports,
        ALLOCATIONDONOR_FILEPREFIX.REPORTS,
        hospitalName,
        fileSuffix
      )
    );

    uploadPromises.push(
      uploadDocument(
        angiographyReport,
        ALLOCATIONDONOR_FILEPREFIX.ANGIOGRAPHYREPORT,
        hospitalName,
        fileSuffix
      )
    );

    uploadPromises.push(
      uploadDocument(
        bloodSampleForCrossMatchReport,
        ALLOCATIONDONOR_FILEPREFIX.BLOODSAMPLEFORCROSSMATCHREPORT,
        hospitalName,
        fileSuffix
      )
    );

    uploadPromises.push(
      uploadDocument(
        bloodSampleForHeartCrossMatchingReport,
        ALLOCATIONDONOR_FILEPREFIX.BLOODSAMPLEFORHEARTCROSSMATCHINGREPORT,
        hospitalName,
        fileSuffix
      )
    );

    uploadPromises.push(
      uploadDocument(
        chestXRayReport,
        ALLOCATIONDONOR_FILEPREFIX.CHESTXRAYREPORT,
        hospitalName,
        fileSuffix
      )
    );

    uploadPromises.push(
      uploadDocument(
        ctThoraxReport,
        ALLOCATIONDONOR_FILEPREFIX.CTTHORAXREPORT,
        hospitalName,
        fileSuffix
      )
    );

    uploadPromises.push(
      uploadDocument(tropTReport, ALLOCATIONDONOR_FILEPREFIX.TROPTREPORT, hospitalName, fileSuffix)
    );

    uploadPromises.push(
      uploadDocument(
        twoDEchoReport,
        ALLOCATIONDONOR_FILEPREFIX.TWODECHOREPORT,
        hospitalName,
        fileSuffix
      )
    );

    uploadPromises.push(
      uploadDocument(
        heartFunctionAngiographyReport,
        ALLOCATIONDONOR_FILEPREFIX.ANGIOGRAPHYREPORT,
        hospitalName,
        fileSuffix
      )
    );

    uploadPromises.push(
      uploadDocument(
        bloodTransfusionReport,
        ALLOCATIONDONOR_FILEPREFIX.BLOODTRANSFUSIONREPORT,
        hospitalName,
        fileSuffix
      )
    );

    uploadPromises.push(
      uploadDocument(
        contactWithTransmissibleDiseasesReport,
        ALLOCATIONDONOR_FILEPREFIX.CONTACTWITHTRANSMISSIBLEDISEASESREPORT,
        hospitalName,
        fileSuffix
      )
    );

    uploadPromises.push(
      uploadDocument(
        illicitDrugsReport,
        ALLOCATIONDONOR_FILEPREFIX.ILLICITDRUGSREPORT,
        hospitalName,
        fileSuffix
      )
    );

    uploadPromises.push(
      uploadDocument(
        infectionsReport,
        ALLOCATIONDONOR_FILEPREFIX.INFECTIONSREPORT,
        hospitalName,
        fileSuffix
      )
    );

    uploadPromises.push(
      uploadDocument(
        liveVaccinesReport,
        ALLOCATIONDONOR_FILEPREFIX.LIVEVACCINESREPORT,
        hospitalName,
        fileSuffix
      )
    );

    uploadPromises.push(
      uploadDocument(
        occupationalExposureReport,
        ALLOCATIONDONOR_FILEPREFIX.OCCUPATIONALEXPOSUREREPORT,
        hospitalName,
        fileSuffix
      )
    );

    uploadPromises.push(
      uploadDocument(
        tattoEarOrBodyPiercingReport,
        ALLOCATIONDONOR_FILEPREFIX.TATTOEARORBODYPIERCINGREPORT,
        hospitalName,
        fileSuffix
      )
    );

    uploadPromises.push(
      uploadDocument(
        unsafeSexPraticesReport,
        ALLOCATIONDONOR_FILEPREFIX.UNSAFESEXPRACTICESREPORT,
        hospitalName,
        fileSuffix
      )
    );

    uploadPromises.push(
      uploadDocument(
        admissionReport,
        ALLOCATIONDONOR_FILEPREFIX.ADMISSIONREPORT,
        hospitalName,
        fileSuffix
      )
    );

    for (let j = 0; j < anyReports.length; j += 1) {
      const { attachment } = anyReports[j];
      uploadPromises.push(
        uploadDocument(attachment, ALLOCATIONDONOR_FILEPREFIX.ANYREPORT, hospitalName, fileSuffix)
      );
    }

    uploadPromises.push(
      uploadDocument(
        clinicalHistoryReport,
        ALLOCATIONDONOR_FILEPREFIX.CLINICALHISTORYREPORT,
        hospitalName,
        fileSuffix
      )
    );

    uploadPromises.push(
      uploadDocument(
        cultureReport,
        ALLOCATIONDONOR_FILEPREFIX.CULTUREREPORT,
        hospitalName,
        fileSuffix
      )
    );

    uploadPromises.push(
      uploadDocument(form8Report, ALLOCATIONDONOR_FILEPREFIX.FORM8REPORT, hospitalName, fileSuffix)
    );

    uploadPromises.push(
      uploadDocument(
        form10Report,
        ALLOCATIONDONOR_FILEPREFIX.FORM10REPORT,
        hospitalName,
        fileSuffix
      )
    );

    uploadPromises.push(
      uploadDocument(
        icuChartReport,
        ALLOCATIONDONOR_FILEPREFIX.ICUCHARTREPORT,
        hospitalName,
        fileSuffix
      )
    );

    uploadPromises.push(
      uploadDocument(
        imagingHistopathologyEndoscopiesReport,
        ALLOCATIONDONOR_FILEPREFIX.IMAGINGHISTOPATHOLOGYENDOSCOPIESREPORT,
        hospitalName,
        fileSuffix
      )
    );

    uploadPromises.push(
      uploadDocument(
        investigationChartReport,
        ALLOCATIONDONOR_FILEPREFIX.INVESTIGATIONCHARTREPORT,
        hospitalName,
        fileSuffix
      )
    );

    uploadPromises.push(
      uploadDocument(
        procalcitoninReport,
        ALLOCATIONDONOR_FILEPREFIX.PROCALCITONINREPORT,
        hospitalName,
        fileSuffix
      )
    );

    uploadPromises.push(
      uploadDocument(
        deathCertificate,
        ALLOCATIONDONOR_FILEPREFIX.DEATHCERTIFICATE,
        hospitalName,
        fileSuffix
      )
    );

    return Promise.all(uploadPromises);
  };

  const _getDocuments = () => {
    const {
      basicMedicalInformation: {
        medicoLegalCaseNoc,
        abdominalOrChestInjuryReport,
        operationOnDonorReport,
        firstApneaBaselineReport,
        firstApneaAfterTenMinutesReport,
        secondApneaBaselineReport,
        secondApneaAfterTenMinutesReport,
        latestRtpct,
        anyOtherMedications,
      },
      hemoDynamicParameters: {
        diabeticReport,
        htnReport,
        ihdReport,
        asthmaReport,
        lungDiseaseReport,
      },
      viralMarkerStatus: {
        hbSagReport,
        antiHcvReport,
        hivReport,
        hcvRnaQuantitativeReport,
        hepatetisCReport,
        hbcabReport,
      },
      latestInvestigations: { cbcReport, abgReport, hba1cReport, urineRoutineReport },
      renalFunction: { reports: renalFunctionReports },
      liverFunction: { reports: liverFunctionReports, liverUltraSoundReport },
      heartFunction: {
        twoDEchoReport,
        angiographyReport: heartFunctionAngiographyReport,
        chestXRayReport,
        bloodSampleForCrossMatchReport,
        bloodSampleForHeartCrossMatchingReport,
        ctThoraxReport,
        tropTReport,
      },
      medicalHistory: {
        infectionsReport,
        liveVaccinesReport,
        occupationalExposureReport,
        bloodTransfusionReport,
        contactWithTransmissibleDiseasesReport,
        illicitDrugsReport,
        tattoEarOrBodyPiercingReport,
        unsafeSexPraticesReport,
      },
      handAssessment: { handXRay },
      imaging: {
        ctHead: { ctHeadReport },
        mriHead: { mriHeadReport },
        xRayChest: { xRayChestReport },
        xRayAbdomen: { xRayAbdomenReport },
        ctChest: { ctChestReport },
        usgAbdomenAndPelvis: { usgAbdomenAndPelvisReport },
        reports: imagingReports,
      },
    } = donorMedicalReportsDetails;

    const {
      anyReports,
      form8Report,
      form10Report,
      admissionReport,
      clinicalHistoryReport,
      icuChartReport,
      investigationChartReport,
      imagingHistopathologyEndoscopiesReport,
      angiographyReport,
      cultureReport,
      procalcitoninReport,
    } = donorAttachmentsDetails;
    const { deathCertificate } = donorOrganRetrievalDetails;
    return [
      medicoLegalCaseNoc,
      abdominalOrChestInjuryReport,
      operationOnDonorReport,
      firstApneaBaselineReport,
      firstApneaAfterTenMinutesReport,
      secondApneaBaselineReport,
      secondApneaAfterTenMinutesReport,
      latestRtpct,
      anyOtherMedications,
      diabeticReport,
      htnReport,
      ihdReport,
      asthmaReport,
      lungDiseaseReport,
      hbSagReport,
      hbcabReport,
      antiHcvReport,
      hivReport,
      hcvRnaQuantitativeReport,
      hepatetisCReport,
      cbcReport,
      abgReport,
      hba1cReport,
      urineRoutineReport,
      renalFunctionReports,
      liverFunctionReports,
      imagingReports,
      twoDEchoReport,
      heartFunctionAngiographyReport,
      chestXRayReport,
      bloodSampleForCrossMatchReport,
      bloodSampleForHeartCrossMatchingReport,
      ctThoraxReport,
      tropTReport,
      infectionsReport,
      liveVaccinesReport,
      occupationalExposureReport,
      bloodTransfusionReport,
      contactWithTransmissibleDiseasesReport,
      illicitDrugsReport,
      tattoEarOrBodyPiercingReport,
      unsafeSexPraticesReport,
      anyReports,
      form8Report,
      form10Report,
      admissionReport,
      clinicalHistoryReport,
      icuChartReport,
      investigationChartReport,
      imagingHistopathologyEndoscopiesReport,
      angiographyReport,
      cultureReport,
      procalcitoninReport,
      deathCertificate,
      liverUltraSoundReport,
      liverFunctionReports,
      renalFunctionReports,
      imagingReports,
      handXRay,
      ctHeadReport,
      mriHeadReport,
      xRayChestReport,
      xRayAbdomenReport,
      ctChestReport,
      usgAbdomenAndPelvisReport,
    ]
      .flat()
      .filter((d) => !!d && !!d.key);
  };

  const [isAddedByRottoUser, setIsAddedByRottoUser] = useState(DONOR.isAddedByRotto);

  const [submitDonorApiId, setSubmitDonorApiId] = useState('APIID');

  const _submitDonor = async () => {
    if (isRottoUserUserType(userType)) {
      setIsAddedByRottoUser(true);
    }
    await uploadAllocationDonorDocuments();
    validate(VALIDATION.ALL | VALIDATION.INVALIDATE_EMPTY, (valid) => {
      if (!valid) {
        // Display an error snackbar if validation fails
        enqueueSnackbar('Please check the red fields', { variant: 'error' });
        return; // Prevent the API call from executing
      }
      if (donorOrganRetrievalDetails.retrievedOrgans.length === 0) {
        setSelectOrganDialogStatus(true);
      }
      // else if (donorOrganRetrievalDetails.retrievedOrgans.includes('KIDNEY')) {
      //   if (donorOrganRetrievalDetails.sideOfKidneyCompatibleForDonation === '') {
      //     setSelectKidenyDialogStatus(true);
      //   }
      // }
      else {
        donorBasicInfoDetails.dateOfRegistration = Math.floor(Date.now() / 1000);
        apiPost(
          PATH_EXP_SUBMITALLOCATIONDONOR,
          {
            donorDetails: {
              basicInformation: donorBasicInfoDetails,
              medicalReportsAndStatus: donorMedicalReportsDetails,
              organRetrievalInformation: donorOrganRetrievalDetails,
              attachments: donorAttachmentsDetails,
              ecdInformation: ecdInfoDetails,
              isAddedByRotto: isAddedByRottoUser,
            },
          },
          {
            apiIdCb: (apiId) => {
              setSubmitDonorApiId(apiId);
            },
          }
        )
          .then(() => {
            setRedirectToDashboard(true);
          })
          .catch(() => {});
      }
    });
  };

  const submitAllocationDonorEdits = async () => {};

  const [selectOrganDialogStatus, setSelectOrganDialogStatus] = useState(false);

  const _renderNoOrganAddedDialog = () => {
    return (
      <Dialog
        open={selectOrganDialogStatus}
        onClose={() => {
          setSelectOrganDialogStatus(false);
        }}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle style={{ backgroundColor: 'red', color: '#F0FEF9' }}>
          <Grid container justify="space-between" alignItems="center">
            Organ Not Selected
            <IconButton
              edge="start"
              color="#F0FEF9"
              onClick={() => {
                setSelectOrganDialogStatus(false);
              }}
              aria-label="close"
            >
              <CloseIcon
                style={{
                  color: '#F0FEF9',
                }}
              />
            </IconButton>
          </Grid>
        </DialogTitle>
        <DialogContent style={{ margin: 10 }}>
          <Grid container spacing={2}>
            <Grid item sm={12}>
              <Typography variant="body1" className={classes.transplantDetailsHeading}>
                Please select organ to proceed further.
              </Typography>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <LoadableButton
            variant="contained"
            color="primary"
            onClick={() => {
              setSelectOrganDialogStatus(false);
              setDonorFormPreviewDialog(false);
            }}
            disabledButton
          >
            Okay
          </LoadableButton>
        </DialogActions>
      </Dialog>
    );
  };

  // const [selectKidneyDialogStatus, setSelectKidenyDialogStatus] = useState(false);

  // const _renderNoKidneySideSelectedDialog = () => {
  //   return (
  //     <Dialog
  //       open={selectKidneyDialogStatus}
  //       onClose={() => {
  //         setSelectKidenyDialogStatus(false);
  //       }}
  //       maxWidth="sm"
  //       fullWidth
  //     >
  //       <DialogTitle style={{ backgroundColor: 'red', color: '#F0FEF9' }}>
  //         <Grid container justify="space-between" alignItems="center">
  //           Kideny side Not Selected
  //           <IconButton
  //             edge="start"
  //             color="#F0FEF9"
  //             onClick={() => {
  //               setSelectKidenyDialogStatus(false);
  //             }}
  //             aria-label="close"
  //           >
  //             <CloseIcon
  //               style={{
  //                 color: '#F0FEF9',
  //               }}
  //             />
  //           </IconButton>
  //         </Grid>
  //       </DialogTitle>
  //       <DialogContent style={{ margin: 10 }}>
  //         <Grid container spacing={2}>
  //           <Grid item sm={12}>
  //             <Typography variant="body1" className={classes.transplantDetailsHeading}>
  //               Please select Kidney side to proceed further.
  //             </Typography>
  //           </Grid>
  //         </Grid>
  //       </DialogContent>
  //       <DialogActions>
  //         <LoadableButton
  //           variant="contained"
  //           color="primary"
  //           onClick={() => {
  //             setSelectKidenyDialogStatus(false);
  //             setDonorFormPreviewDialog(false);
  //           }}
  //           disabledButton
  //         >
  //           Okay
  //         </LoadableButton>
  //       </DialogActions>
  //     </Dialog>
  //   );
  // };

  const submitDraftAllocationDonor = async () => {
    await uploadAllocationDonorDocuments();
    // validate(VALIDATION.ALL | VALIDATION.INVALIDATE_EMPTY, (valid) => {
    //   if (!valid) {
    //     return;
    //   }
    if (donorOrganRetrievalDetails.retrievedOrgans.length === 0) {
      setSelectOrganDialogStatus(true);
    } else {
      donorBasicInfoDetails.dateOfRegistration = Math.floor(Date.now() / 1000);
      apiPost(PATH_EXP_SUBMITDRAFTALLOCATIONDONOR, {
        donorDetails: {
          basicInformation: donorBasicInfoDetails,
          medicalReportsAndStatus: donorMedicalReportsDetails,
          organRetrievalInformation: donorOrganRetrievalDetails,
          attachments: donorAttachmentsDetails,
          ecdInformation: ecdInfoDetails,
        },
      })
        .then((donorDetails) => {
          setDraftDonorId(donorDetails?._id);
          setRedirectToSaved(true);
        })
        .catch(() => {});
      // });
    }
  };

  const _renderAnyOtherAttachmentDetails = (d, index) => {
    const { attachmentName, attachment } = d;
    return (
      <>
        <Grid container spacing={2} alignContent="center" justify="space-between">
          <Grid item sm={4} xs={12}>
            <ValidatedInput
              label="Attachment Name"
              fullWidth
              value={attachmentName}
              onChange={(e) => {
                const newAnyOtherAttachments = [];
                donorAttachmentsDetails.anyReports.forEach((member, idx) => {
                  if (idx === index) {
                    newAnyOtherAttachments.push({
                      ...member,
                      attachmentName: e.target.value,
                    });
                  } else {
                    newAnyOtherAttachments.push(member);
                  }
                });
                setDonorAttachmentsDetails({
                  ...donorAttachmentsDetails,
                  anyReports: newAnyOtherAttachments,
                });
              }}
            />
          </Grid>
          <Grid sm={4} xs={12}>
            <ValidatedAttachment
              required
              id="Attachment"
              name="Attachment"
              label="Attachment"
              acceptFileTypes={[FILETYPES.PNG, FILETYPES.JPEG, FILETYPES.PDF]}
              value={attachment}
              onChange={(e) => {
                const file = e.target.files[0];
                const newAnyOtherAttachments = [];
                donorAttachmentsDetails.anyReports.forEach((member, idx) => {
                  if (idx === index) {
                    newAnyOtherAttachments.push({
                      ...member,
                      attachment: {
                        name: file.name,
                        file,
                      },
                    });
                  } else {
                    newAnyOtherAttachments.push(member);
                  }
                });
                setDonorAttachmentsDetails({
                  ...donorAttachmentsDetails,
                  anyReports: newAnyOtherAttachments,
                });
              }}
              // schema={Joi.string()}
            />
          </Grid>
          {/* {index !== 0 ? ( */}
          <Grid item sm={2} xs={2}>
            <Button
              variant="contained"
              color="secondary"
              startIcon={<DeleteIcon />}
              onClick={() => {
                const newAnyOtherAttachments = donorAttachmentsDetails.anyReports.filter(
                  (nr, nrIndex) => nrIndex !== index
                );
                setDonorAttachmentsDetails({
                  ...donorAttachmentsDetails,
                  anyReports: newAnyOtherAttachments,
                });
              }}
            >
              Delete
            </Button>
          </Grid>
          {/* ) : null} */}
        </Grid>
        <Grid container item sm={12}>
          <hr style={{ width: '100%', marginTop: 30, marginBottom: 30 }} />
        </Grid>
      </>
    );
  };

  const _downloadEmptyAllocationDonorForm = () => {
    new AllocationDonorEmptyFormPdf().download();
  };

  const _renderDonorFormPreviewDialog = () => {
    return (
      <Dialog
        open={donorFormPreviewDialog}
        fullWidth={true}
        maxWidth="md"
        onClose={() => {
          setDonorFormPreviewDialog(false);
        }}
        aria-labelledby="form-dialog-title"
      >
        {/* <DialogTitle id="form-dialog-title"></DialogTitle> */}
        <StyledDialogTitle>
          <Grid container justify="space-between" alignItems="center">
            Donor Form
            <IconButton
              edge="start"
              color="#F0FEF9"
              onClick={() => {
                setDonorFormPreviewDialog(false);
              }}
              aria-label="close"
            >
              <CloseIcon
                style={{
                  color: '#F0FEF9',
                }}
              />
            </IconButton>
          </Grid>
        </StyledDialogTitle>
        <DialogContent>
          <Grid container item xs={12}>
            <Typography variant="body1">
              Please check that the following form is correct. If yes, click on SUBMIT or else click
              on Edit Form below
            </Typography>
          </Grid>
          <Grid container item xs={12} justify="center" style={{ backgroundColor: '#D3D3D3' }}>
            {donorFormPreviewBuffer ? (
              <>
                <Grid item container justify="center" spacing={2}>
                  <Grid item sm={3}>
                    <DownloadAttachments
                      documents={_getDocuments().filter((d) => !!d.key)}
                      name={donorBasicInfoDetails?.name}
                    />
                  </Grid>
                </Grid>
                <MemoizedDocument documentBuffer={donorFormPreviewBuffer} />
              </>
            ) : (
              <Typography variant="body1">LOADING</Typography>
            )}
          </Grid>
        </DialogContent>

        <DialogActions style={{ marginTop: 40 }}>
          <LoadableButton
            color="primary"
            variant="contained"
            apiCallId={submitDonorApiId}
            apiCallStatuses={apiCallStatuses}
            loadingText="Submitting..."
            onClick={() => _submitDonor()}
            disabledButton
          >
            SUBMIT FORM
          </LoadableButton>
          <LoadableButton
            // apiCallId={submitDonorApiId}
            // apiCallStatuses={apiCallStatuses}
            onClick={() => setDonorFormPreviewDialog(false)}
            disabledButton
          >
            Edit Form
          </LoadableButton>
          <Button
            // color="primary"
            // variant="contained"
            onClick={() => {
              new DonorFormPdf({
                donorBasicInfoDetails,
                donorMedicalReportsDetails,
                donorAttachmentsDetails,
                donorOrganRetrievalDetails,
                retrievedOrgans: donorOrganRetrievalDetails.retrievedOrgans,
                anyEcdOrgans: donorOrganRetrievalDetails.anyEcdOrgans,
                ecdOrgans: donorOrganRetrievalDetails.ecdOrgans,
                ecdInformation: ecdInfoDetails,
              }).download();
            }}
          >
            Download Pdf
          </Button>

          {/* <LoadableButton
            // color="primary"
            // variant="contained"
            onClick={() =>
              donorFormStatus === 'NEW'
                ? submitDraftAllocationDonor()
                : submitAllocationDonorEdits()
            }
            disabledButton
          >
            SAVE FORM
          </LoadableButton> */}
        </DialogActions>
      </Dialog>
    );
  };

  const openDonorFormPreviewDialog = () => {
    validate(VALIDATION.ALL | VALIDATION.INVALIDATE_EMPTY, (valid) => {
      if (!valid) {
        enqueueSnackbar('Please check the red fields', { variant: 'error' });
        return;
      }
      new DonorFormPdf({
        ztccZone,
        hospitalName,
        donorBasicInfoDetails,
        donorMedicalReportsDetails,
        donorAttachmentsDetails,
        donorOrganRetrievalDetails,
        retrievedOrgans: donorOrganRetrievalDetails.retrievedOrgans,
        anyEcdOrgans: donorOrganRetrievalDetails.anyEcdOrgans,
        ecdOrgans: donorOrganRetrievalDetails.ecdOrgans,
        ecdInformation: ecdInfoDetails,
      }).getBuffer((buffer) => {
        setDonorFormPreviewBuffer(buffer);
      });
      setDonorFormPreviewDialog(true);
    });
  };

  const _isDisabled = () => {
    return donorFormStatus === 'SUBMITTED';
  };

  const _getDonorCurrentPageMinusOne = () => {
    if (donorPages[donorPages.indexOf(donorCurrentSelectedNav) - 1] === 0) {
      return donorPages[donorPages.length];
    }
    return donorPages[donorPages.indexOf(donorCurrentSelectedNav) - 1];
  };

  const _getDonorCurrentPagePlusOne = () => {
    if (donorPages.indexOf(donorCurrentSelectedNav) + 1 === donorPages.length) {
      return donorPages[0];
    }
    return donorPages[donorPages.indexOf(donorCurrentSelectedNav) + 1];
  };

  const _renderDonorFooterButtons = () => {
    if (_isDisabled()) {
      return null;
    }

    return (
      // <Box justifyContent="center">
      <Grid container justify="center" style={{ paddingBottom: 20 }} spacing={1}>
        {donorCurrentSelectedNav !== donorPages[0] ? (
          <Grid item>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                setDonorCurrentSelectedNav(_getDonorCurrentPageMinusOne());
              }}
            >
              Previous
            </Button>
          </Grid>
        ) : null}

        {donorCurrentSelectedNav !== donorPages[donorPages.length - 1] ? (
          <Grid item>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                setDonorCurrentSelectedNav(_getDonorCurrentPagePlusOne());
              }}
            >
              Next
            </Button>
          </Grid>
        ) : null}
        {donorCurrentSelectedNav === donorPages[donorPages.length - 1] ? (
          <Grid item>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                openDonorFormPreviewDialog();
              }}
            >
              Preview and Submit
            </Button>
          </Grid>
        ) : null}
      </Grid>
      // </Box>
    );
  };

  const _getDonorListingtType = (duration) => {
    if (
      duration?._data?.years < 15 ||
      (duration?._data?.years === 15 && duration?._data?.months === 0)
    ) {
      if (donorBasicInfoDetails?.weightKg && donorBasicInfoDetails?.weightKg > 40) {
        return 'ADULT';
      }
      return 'PEDIATRIC';
    }
    if (
      duration?._data?.years > 15 ||
      (duration?._data?.years === 15 && duration?._data?.months > 0) ||
      (donorBasicInfoDetails?.weightKg && donorBasicInfoDetails?.weightKg > 40)
    ) {
      return 'ADULT';
    }
    return '-';
  };

  const _getDonorListingtTypeByWeight = (duration, weight) => {
    if (
      duration?._data?.years < 15 ||
      (duration?._data?.years === 15 && duration?._data?.months === 0)
    ) {
      if (weight && weight > 40) {
        return 'ADULT';
      }
      return 'PEDIATRIC';
    }
    if (
      duration?._data?.years > 15 ||
      (duration?._data?.years === 15 && duration?._data?.months > 0) ||
      (weight && weight > 40)
    ) {
      return 'ADULT';
    }
    return '-';
  };

  const _renderDonorListingBasicInfo = () => {
    return (
      <>
        <Paper className={classes.paperSpecialistInfo}>
          <Grid container justify="space-between" alignContent="center" style={{ padding: 20 }}>
            <Grid item sm={4} xs={12}>
              <Button
                variant="contained"
                color="primary"
                style={{
                  fontWeight: 600,
                  width: '250px',
                  height: '44px',
                  marginRight: '10px',
                  fontSize: '16px',
                  lineHeight: '20px',
                }}
                onClick={() => {
                  setDocumentListDialogOpenStatus(true);
                }}
              >
                Document List
              </Button>
            </Grid>
            <Grid item sm={4} xs={12}>
              <Button
                variant="contained"
                color="primary"
                style={{
                  fontWeight: 600,
                  width: '250px',
                  height: '44px',
                  marginRight: '10px',
                  fontSize: '16px',
                  lineHeight: '20px',
                }}
                onClick={() => {
                  _downloadEmptyAllocationDonorForm();
                }}
              >
                Download Form
              </Button>
            </Grid>
          </Grid>
        </Paper>
        <Grid container spacing={3} style={{ marginTop: 3, marginBottom: 10, padding: 10 }}>
          <Grid item sm={4} xs={12}>
            <ValidatedRadio
              label="Has the Donor NOTTOID been generated?"
              name="isNottoId"
              value={donorBasicInfoDetails.isNottoId}
              onChange={(e) => {
                setDonorBasicInfoDetails({
                  ...donorBasicInfoDetails,
                  isNottoId: e.target.value === 'true',
                });
              }}
              options={[
                { value: true, label: 'YES' },
                { value: false, label: 'NO' },
              ]}
              // validation={validation}
              // onValidation={onValidation}
            />
          </Grid>
          {donorBasicInfoDetails.isNottoId === true ? (
            <Grid item sm={4} xs={12}>
              <Typography variant="body1">NOTTO ID</Typography>
              <ValidatedInput
                required
                fullWidth
                value={donorBasicInfoDetails.nottoId}
                label=""
                type="text"
                placeholder=""
                onChange={(e) => {
                  setDonorBasicInfoDetails({
                    ...donorBasicInfoDetails,
                    nottoId: e.target.value,
                  });
                }}
              />
            </Grid>
          ) : null}
          {donorBasicInfoDetails.isNottoId === false ? (
            <Grid item sm={4} xs={12}>
              <Typography variant="body1">If No, Specify Reason</Typography>
              <ValidatedInput
                required
                fullWidth
                value={donorBasicInfoDetails.reason}
                label=""
                type="text"
                placeholder=""
                onChange={(e) => {
                  setDonorBasicInfoDetails({
                    ...donorBasicInfoDetails,
                    reason: e.target.value,
                  });
                }}
              />
            </Grid>
          ) : null}

          <Grid item sm={4} xs={12}>
            <Typography variant="body1">Donor Full Name</Typography>
            <ValidatedInput
              required
              fullWidth
              value={donorBasicInfoDetails.name}
              label=""
              type="text"
              placeholder=""
              onChange={(e) => {
                setDonorBasicInfoDetails({
                  ...donorBasicInfoDetails,
                  name: e.target.value,
                });
              }}
              schema={Joi.string()}
              validation={validation}
              onValidation={onValidation}
            />
          </Grid>
          {/* {!disableDateOfBirth ? (
            <Grid item sm={4} xs={12}>
              <Typography variant="body1">Date of Birth</Typography>
              <ValidatedDate
                required
                fullWidth
                label=""
                value={donorBasicInfoDetails.dateOfBirth}
                onChange={(v) => {
                  const duration = moment.duration(moment().diff(moment(v, FORMAT_DATE_UNIXTS)));

                  setDonorBasicInfoDetails({
                    ...donorBasicInfoDetails,
                    dateOfBirth: v,
                    currentAge: `${duration?._data?.years} Years  ${duration?._data?.months} Months ${duration?._data?.days} Days`,
                    listingType: _getDonorListingtType(duration),
                  });
                }}
              />
            </Grid>
          ) : null}

          <Grid item sm={4} xs={12}>
            <Typography variant="body1">Current Age</Typography>
            <ValidatedInput
              required
              // disabled={true}
              fullWidth
              value={donorBasicInfoDetails.currentAge}
              label=""
              type="text"
              placeholder=""
              onChange={(e) => {
                if (e.target.value) {
                  setDonorBasicInfoDetails({
                    ...donorBasicInfoDetails,
                    currentAge: e.target.value,
                  });
                  setDisableDateOfBirth(true);
                } else {
                  setDonorBasicInfoDetails({
                    ...donorBasicInfoDetails,
                    currentAge: e.target.value,
                  });
                  setDisableDateOfBirth(false);
                }
              }}
            />
          </Grid> */}
          {donorBasicInfoDetails.currentAge < 18 || donorBasicInfoDetails.dateOfBirth ? (
            <Grid item sm={4} xs={12}>
              <Typography variant="body1">Date of Birth</Typography>
              <ValidatedDate
                required
                label=""
                value={donorBasicInfoDetails?.dateOfBirth}
                // disabled={isDisabled}
                onChange={(e) => {
                  const duration = moment.duration(moment().diff(moment(e, FORMAT_DATE_UNIXTS)));
                  setDonorBasicInfoDetails({
                    ...donorBasicInfoDetails,
                    dateOfBirth: e,
                    currentAge: `${duration?._data?.years} Years  ${duration?._data?.months} Months ${duration?._data?.days} Days`,
                    listingType: _getDonorListingtType(duration),
                  });
                }}
                schema={Joi.date().max('now')}
                validation={validation}
                onValidation={onValidation}
                fullWidth
              />
            </Grid>
          ) : null}
          <Grid item sm={4} xs={12}>
            <Typography variant="body1">Current Age</Typography>
            <ValidatedInput
              required
              label=""
              value={calculateCurrentAge()}
              // disabled={isDisabled}
              onChange={(e) => {
                const age = e.target.value;
                if (age >= 18) {
                  setDonorBasicInfoDetails({
                    ...donorBasicInfoDetails,
                    currentAge: age,
                    dateOfBirth: 0,
                  });
                } else {
                  setDonorBasicInfoDetails({
                    ...donorBasicInfoDetails,
                    currentAge: age,
                    dateOfBirth: 0,
                  });
                }
              }}
              validation={validation}
              onValidation={onValidation}
              fullWidth
            />
          </Grid>
          <Grid item sm={4} xs={12}>
            <Typography variant="body1">Blood Group</Typography>
            <ValidatedSelect
              required
              label=""
              value={donorBasicInfoDetails.bloodGroup}
              options={Object.values(BLOODGROUP)}
              onChange={(v) => {
                setDonorBasicInfoDetails({
                  ...donorBasicInfoDetails,
                  bloodGroup: v.value,
                });
              }}
              validation={validation}
              onValidation={onValidation}
            />
          </Grid>
          <Grid item sm={4} xs={12}>
            <Typography variant="body1">Gender</Typography>
            <ValidatedSelect
              required
              label=""
              value={donorBasicInfoDetails.sex}
              options={Object.values(SEX)}
              onChange={(v) => {
                setDonorBasicInfoDetails({
                  ...donorBasicInfoDetails,
                  sex: v.value,
                });
              }}
              validation={validation}
              onValidation={onValidation}
            />
          </Grid>
          <Grid item sm={4} xs={12}>
            <Typography variant="body1">Height (in cm)</Typography>
            <ValidatedInput
              required
              fullWidth
              value={donorBasicInfoDetails.heightCm}
              label=""
              type="text"
              placeholder=""
              onChange={(e) => {
                setDonorBasicInfoDetails({
                  ...donorBasicInfoDetails,
                  heightCm: e.target.value,
                  heightIn: (e.target.value * 0.39).toFixed(2),
                });
              }}
              schema={Joi.string()}
              validation={validation}
              onValidation={onValidation}
            />
          </Grid>
          <Grid item sm={4} xs={12}>
            <Typography variant="body1">Height (in inch)</Typography>
            <ValidatedInput
              required
              fullWidth
              value={donorBasicInfoDetails.heightIn}
              label=""
              type="text"
              placeholder=""
              onChange={(e) => {
                setDonorBasicInfoDetails({
                  ...donorBasicInfoDetails,
                  heightIn: e.target.value,
                  heightCm: (e.target.value * 2.54).toFixed(2),
                });
              }}
              schema={Joi.string()}
              validation={validation}
              onValidation={onValidation}
            />
          </Grid>
          <Grid item sm={4} xs={12}>
            <Typography variant="body1">Weight (in kg)</Typography>
            <ValidatedInput
              required
              fullWidth
              value={donorBasicInfoDetails.weightKg}
              label=""
              type="number"
              placeholder=""
              onChange={(e) => {
                const duration = moment.duration(
                  moment().diff(moment(donorBasicInfoDetails.dateOfBirth, FORMAT_DATE_UNIXTS))
                );

                setDonorBasicInfoDetails({
                  ...donorBasicInfoDetails,
                  weightKg: e.target.value,
                  listingType: _getDonorListingtTypeByWeight(duration, e.target.value),
                });
              }}
              schema={Joi.string()}
              validation={validation}
              onValidation={onValidation}
            />
          </Grid>
          <Grid item sm={4} xs={12}>
            <Typography variant="body1">Date of Admission</Typography>
            <ValidatedDate
              required
              fullWidth
              label=""
              value={donorBasicInfoDetails.dateOfAdmission}
              onChange={(v) => {
                setDonorBasicInfoDetails({
                  ...donorBasicInfoDetails,
                  dateOfAdmission: v,
                });
              }}
              // schema={Joi.date().max('now')}
              // validation={validation}
              // onValidation={onValidation}
            />
          </Grid>
          <Grid item sm={4} xs={12}>
            <Typography variant="body1">Date of Death</Typography>
            <ValidatedDate
              required
              fullWidth
              label=""
              value={donorBasicInfoDetails.dateOfDeath}
              onChange={(v) => {
                setDonorBasicInfoDetails({
                  ...donorBasicInfoDetails,
                  dateOfDeath: v,
                });
              }}
              // schema={Joi.date().max('now')}
              // validation={validation}
              // onValidation={onValidation}
            />
          </Grid>
          <Grid item sm={4} xs={12}>
            <Typography variant="body1">Nationality</Typography>
            <ValidatedSelect
              required
              label=""
              value={donorBasicInfoDetails.nationality}
              options={[
                { label: 'Indian', value: 'INDIAN_NATIONAL' },
                { label: 'Overseas Citizen of India (OCI) card holder', value: 'OCI_CARDHOLDER' },
                { label: 'Foreign national', value: 'FOREIGN_NATIONAL' },
              ]}
              onChange={(v) => {
                setDonorBasicInfoDetails({
                  ...donorBasicInfoDetails,
                  nationality: v.value,
                });
              }}
            />
          </Grid>
          <Grid item sm={4} xs={12}>
            <Typography variant="body1">Country</Typography>
            <ValidatedSelect
              required
              label=""
              value={donorBasicInfoDetails.countryOfResidence}
              options={OPTIONS_NATIONALITY}
              onChange={(v) => {
                setDonorBasicInfoDetails({
                  ...donorBasicInfoDetails,
                  countryOfResidence: v.value,
                });
              }}
            />
          </Grid>
          <Grid item sm={4} xs={12}>
            <Typography variant="body1">State</Typography>
            <ValidatedSelect
              required
              fullWidth
              value={donorBasicInfoDetails.state}
              options={Object.values(STATE_IN)}
              label=""
              type="text"
              placeholder=""
              onChange={(e) => {
                setDonorBasicInfoDetails({
                  ...donorBasicInfoDetails,
                  state: e.value,
                });
              }}
            />
          </Grid>
          <Grid item sm={4} xs={12}>
            <Typography variant="body1">District</Typography>
            <ValidatedSelect
              required
              fullWidth
              value={donorBasicInfoDetails.district}
              options={Object.values(districts(donorBasicInfoDetails.state))}
              label=""
              type="text"
              placeholder=""
              onChange={(e) => {
                setDonorBasicInfoDetails({
                  ...donorBasicInfoDetails,
                  district: e.value,
                });
              }}
            />
          </Grid>
          <Grid item sm={4} xs={12}>
            <Typography variant="body1">Religion</Typography>
            <ValidatedSelect
              required
              label=""
              value={donorBasicInfoDetails.religion}
              options={[
                {
                  label: 'Buddhism',
                  value: 'BUDDHISM',
                },
                {
                  label: 'Christianity',
                  value: 'CHRISTIANITY',
                },
                {
                  label: 'Hinduism',
                  value: 'HINDUISM',
                },
                {
                  label: 'Islam',
                  value: 'ISLAM',
                },
                {
                  label: 'Jainism',
                  value: 'JAINISM',
                },

                {
                  label: 'Judaism',
                  value: 'JUDAISM',
                },
                {
                  label: 'Sikhism',
                  value: 'SIKHISM',
                },
                {
                  label: 'Other',
                  value: 'OTHER',
                },
              ]}
              onChange={(v) => {
                setDonorBasicInfoDetails({
                  ...donorBasicInfoDetails,
                  religion: v.value,
                });
              }}
            />
          </Grid>
          <Grid item sm={4} xs={12}>
            <Typography variant="body1">Treating Doctor Name</Typography>
            <ValidatedInput
              required
              fullWidth
              value={donorBasicInfoDetails.treatingDoctorName}
              label=""
              type="text"
              placeholder=""
              onChange={(e) => {
                setDonorBasicInfoDetails({
                  ...donorBasicInfoDetails,
                  treatingDoctorName: e.target.value,
                });
              }}
              schema={Joi.string()}
              validation={validation}
              onValidation={onValidation}
            />
          </Grid>
          <Grid item sm={4} xs={12}>
            <Typography variant="body1">Treating Doctor Email</Typography>
            <ValidatedInput
              required
              fullWidth
              value={donorBasicInfoDetails.treatingDoctorEmail}
              label=""
              type="text"
              placeholder=""
              onChange={(e) => {
                setDonorBasicInfoDetails({
                  ...donorBasicInfoDetails,
                  treatingDoctorEmail: e.target.value,
                });
              }}
              schema={Joi.string().email({ tlds: { allow: false } })}
              validation={validation}
              onValidation={onValidation}
            />
          </Grid>
          <Grid item sm={4} xs={12}>
            <Typography variant="body1">Treating Doctor Contact Number</Typography>
            <ValidatedInput
              required
              fullWidth
              value={donorBasicInfoDetails.treatingDoctorContact}
              label=""
              type="text"
              placeholder=""
              onChange={(e) => {
                setDonorBasicInfoDetails({
                  ...donorBasicInfoDetails,
                  treatingDoctorContact: e.target.value,
                });
              }}
              schema={Joi.string()
                .pattern(REGEXP_MOBILE)
                .message({ 'string.pattern.base': 'Should be a 10 digit number' })}
              validation={validation}
              onValidation={onValidation}
            />
          </Grid>
          <Grid item sm={4} xs={12}>
            <Typography variant="body1">ICU Doctor Name</Typography>
            <ValidatedInput
              required
              fullWidth
              value={donorBasicInfoDetails.icuDoctorName}
              label=""
              type="text"
              placeholder=""
              onChange={(e) => {
                setDonorBasicInfoDetails({
                  ...donorBasicInfoDetails,
                  icuDoctorName: e.target.value,
                });
              }}
              schema={Joi.string()}
              validation={validation}
              onValidation={onValidation}
            />
          </Grid>
          <Grid item sm={4} xs={12}>
            <Typography variant="body1">ICU Doctor Mobile Number</Typography>
            <ValidatedInput
              required
              fullWidth
              value={donorBasicInfoDetails.icuDoctorContact}
              label=""
              type="text"
              placeholder=""
              onChange={(e) => {
                setDonorBasicInfoDetails({
                  ...donorBasicInfoDetails,
                  icuDoctorContact: e.target.value,
                });
              }}
              schema={Joi.string()
                .pattern(REGEXP_MOBILE)
                .message({ 'string.pattern.base': 'Should be a 10 digit number' })}
              validation={validation}
              onValidation={onValidation}
            />
          </Grid>
          <Grid item sm={4} xs={12}>
            <Typography variant="body1">Donor Listing Type</Typography>
            <ValidatedInput
              required
              fullWidth
              disabled={true}
              value={donorBasicInfoDetails.listingType}
              label=""
              type="text"
              placeholder=""
              onChange={(e) => {
                setDonorBasicInfoDetails({
                  ...donorBasicInfoDetails,
                  listingType: e.target.value,
                });
              }}
            />
          </Grid>
        </Grid>
        {_renderDonorFooterButtons()}
      </>
    );
  };

  const _getSelectedTabPlusOne = () => {
    let nextPage;
    switch (donorMedicalReportCurrentSelectedTab) {
      case 'BASICMEDINFO':
        nextPage = 'HEMOPARA';
        break;
      case 'HEMOPARA':
        nextPage = 'VIRALMARKERS';
        break;
      case 'VIRALMARKERS':
        nextPage = 'LATESTINVESTG';
        break;

      case 'LATESTINVESTG':
        nextPage = 'LIVERFUNC';
        break;
      // case 'RENALFUNC':
      //   nextPage = 'LIVERFUNC';
      //   break;

      case 'LIVERFUNC':
        nextPage = 'HEARTFUNC';
        break;

      case 'HEARTFUNC':
        nextPage = 'KIDNEYFUNC';
        break;

      case 'KIDNEYFUNC':
        nextPage = 'HANDASSESSMENT';
        break;

      case 'HANDASSESSMENT':
        nextPage = 'IMAGING';
        break;

      case 'IMAGING':
        nextPage = 'BASICMEDINFO';
        break;

      default:
        break;
    }
    return nextPage;
  };

  const _getSelectedTabMinusOne = () => {
    let previousPage;
    switch (donorMedicalReportCurrentSelectedTab) {
      case 'BASICMEDINFO':
        previousPage = 'IMAGING';
        break;
      case 'HEMOPARA':
        previousPage = 'BASICMEDINFO';
        break;
      case 'VIRALMARKERS':
        previousPage = 'HEMOPARA';
        break;

      case 'LATESTINVESTG':
        previousPage = 'VIRALMARKERS';
        break;
      // case 'RENALFUNC':
      //   previousPage = 'LATESTINVESTG';
      //   break;

      case 'LIVERFUNC':
        previousPage = 'LATESTINVESTG';
        break;

      case 'HEARTFUNC':
        previousPage = 'LIVERFUNC';
        break;

      case 'KIDNEYFUNC':
        previousPage = 'HEARTFUNC';
        break;

      case 'HANDASSESSMENT':
        previousPage = 'KIDNEYFUNC';
        break;

      case 'IMAGING':
        previousPage = 'HANDASSESSMENT';
        break;

      default:
        break;
    }
    return previousPage;
  };

  const _renderLiverRegistrationFooterButtons = () => {
    return (
      <Grid container justify="center" style={{ paddingBottom: 20 }} spacing={1}>
        <Grid item>
          <Button
            onClick={() => {
              setDonorMedicalReportCurrentSelectedTab(_getSelectedTabMinusOne());
            }}
            color="primary"
            variant="contained"
          >
            Previous Medical Reports and Status Section
          </Button>
        </Grid>
        <Grid item>
          <Button
            onClick={() => {
              setDonorMedicalReportCurrentSelectedTab(_getSelectedTabPlusOne());
            }}
            color="primary"
            variant="contained"
          >
            Next Medical Reports and Status Section
          </Button>
        </Grid>
      </Grid>
    );
  };

  const _renderDonorMedicalReportsAndStatusCurrentPage = () => {
    let page;
    switch (donorMedicalReportCurrentSelectedTab) {
      case 'BASICMEDINFO':
        page = (
          <>
            <MedicalReportsBasicInfo
              donorMedicalReportsDetails={donorMedicalReportsDetails}
              setDonorMedicalReportsDetails={setDonorMedicalReportsDetails}
              disabled={_isDisabled()}
              validation={validation}
              onValidation={onValidation}
            />
            {_renderLiverRegistrationFooterButtons()}
          </>
        );
        break;
      case 'HEMOPARA':
        page = (
          <>
            <MedicalReportsHemodynamics
              donorMedicalReportsDetails={donorMedicalReportsDetails}
              setDonorMedicalReportsDetails={setDonorMedicalReportsDetails}
              disabled={_isDisabled()}
              validation={validation}
              onValidation={onValidation}
            />
            {_renderLiverRegistrationFooterButtons()}
          </>
        );
        break;
      case 'VIRALMARKERS':
        page = (
          <>
            <MedicalReportsViralMarkers
              donorMedicalReportsDetails={donorMedicalReportsDetails}
              setDonorMedicalReportsDetails={setDonorMedicalReportsDetails}
              disabled={_isDisabled()}
              validation={validation}
              onValidation={onValidation}
            />
            {_renderLiverRegistrationFooterButtons()}
          </>
        );
        break;

      case 'LATESTINVESTG':
        page = (
          <>
            <MedicalReportsLatestInvestigations
              donorMedicalReportsDetails={donorMedicalReportsDetails}
              setDonorMedicalReportsDetails={setDonorMedicalReportsDetails}
              disabled={_isDisabled()}
              validation={validation}
              onValidation={onValidation}
            />
            {_renderLiverRegistrationFooterButtons()}
          </>
        );
        break;
      // case 'RENALFUNC':
      //   page = (
      //     <>
      //       <MedicalReportsRenalFunction
      //         donorMedicalReportsDetails={donorMedicalReportsDetails}
      //         setDonorMedicalReportsDetails={setDonorMedicalReportsDetails}
      //         disabled={_isDisabled()}
      //       />
      //       {_renderLiverRegistrationFooterButtons()}
      //     </>
      //   );
      //   break;
      case 'LIVERFUNC':
        page = (
          <>
            <MedicalReportsLiverFunction
              donorMedicalReportsDetails={donorMedicalReportsDetails}
              setDonorMedicalReportsDetails={setDonorMedicalReportsDetails}
              disabled={_isDisabled()}
              validation={validation}
              onValidation={onValidation}
            />
            {_renderLiverRegistrationFooterButtons()}
          </>
        );
        break;
      case 'HEARTFUNC':
        page = (
          <>
            <MedicalReportsHeartFunction
              donorMedicalReportsDetails={donorMedicalReportsDetails}
              setDonorMedicalReportsDetails={setDonorMedicalReportsDetails}
              disabled={_isDisabled()}
              validation={validation}
              onValidation={onValidation}
            />
            {_renderLiverRegistrationFooterButtons()}
          </>
        );
        break;

      case 'KIDNEYFUNC':
        page = (
          <>
            <MedicalReportsKidneyFunction
              donorMedicalReportsDetails={donorMedicalReportsDetails}
              setDonorMedicalReportsDetails={setDonorMedicalReportsDetails}
              disabled={_isDisabled()}
              validation={validation}
              onValidation={onValidation}
            />
            {_renderLiverRegistrationFooterButtons()}
          </>
        );
        break;

      case 'HANDASSESSMENT':
        page = (
          <>
            <MedicalReportsHandAssessment
              donorMedicalReportsDetails={donorMedicalReportsDetails}
              setDonorMedicalReportsDetails={setDonorMedicalReportsDetails}
              disabled={_isDisabled()}
              validation={validation}
              onValidation={onValidation}
            />
            {_renderLiverRegistrationFooterButtons()}
          </>
        );
        break;
      // case 'MEDHISTORY':
      //   page = (
      //     <>
      //       <MedicalReportsMedicalHistory
      //         donorMedicalReportsDetails={donorMedicalReportsDetails}
      //         setDonorMedicalReportsDetails={setDonorMedicalReportsDetails}
      //         donorPages={donorPages}
      //         setDonorPages={setDonorPages}
      //         disabled={_isDisabled()}
      //       />
      //       {_renderLiverRegistrationFooterButtons()}
      //     </>
      //   );
      //   break;
      case 'IMAGING':
        page = (
          <>
            <MedicalReportsImaging
              donorMedicalReportsDetails={donorMedicalReportsDetails}
              setDonorMedicalReportsDetails={setDonorMedicalReportsDetails}
              donorPages={donorPages}
              setDonorPages={setDonorPages}
              disabled={_isDisabled()}
              validation={validation}
              onValidation={onValidation}
            />
            {_renderLiverRegistrationFooterButtons()}
          </>
        );
        break;
      default:
        break;
    }
    return page;
  };

  const _renderDonorMedicalReportsAndStatus = () => {
    return (
      <>
        <Box justifyContent="space-between" display="flex">
          <Grid item sm={12}>
            <Grid container spacing={1} style={{ padding: 10 }}>
              <Grid item sm={4} xs={12}>
                <Button
                  variant={
                    donorMedicalReportCurrentSelectedTab === 'BASICMEDINFO'
                      ? 'contained'
                      : 'outlined'
                  }
                  color="primary"
                  style={{
                    height: '36px',
                    backgroundColor:
                      donorMedicalReportCurrentSelectedTab !== 'BASICMEDINFO' ? '#F0FEF9' : '',
                  }}
                  onClick={() => {
                    setDonorMedicalReportCurrentSelectedTab('BASICMEDINFO');
                  }}
                  fullWidth
                >
                  Basic Medical Information
                </Button>
              </Grid>
              <Grid item sm={4} xs={12}>
                <Button
                  variant={
                    donorMedicalReportCurrentSelectedTab === 'HEMOPARA' ? 'contained' : 'outlined'
                  }
                  color="primary"
                  style={{
                    height: '36px',
                    backgroundColor:
                      donorMedicalReportCurrentSelectedTab !== 'HEMOPARA' ? '#F0FEF9' : '',
                  }}
                  onClick={() => {
                    setDonorMedicalReportCurrentSelectedTab('HEMOPARA');
                  }}
                  fullWidth
                >
                  Hemodynamic Parameters
                </Button>
              </Grid>
              <Grid item sm={4} xs={12}>
                <Button
                  variant={
                    donorMedicalReportCurrentSelectedTab === 'VIRALMARKERS'
                      ? 'contained'
                      : 'outlined'
                  }
                  color="primary"
                  style={{
                    height: '36px',
                    backgroundColor:
                      donorMedicalReportCurrentSelectedTab !== 'VIRALMARKERS' ? '#F0FEF9' : '',
                  }}
                  onClick={() => {
                    setDonorMedicalReportCurrentSelectedTab('VIRALMARKERS');
                  }}
                  fullWidth
                >
                  Microbiology
                </Button>
              </Grid>
              <Grid item sm={4} xs={12}>
                <Button
                  variant={
                    donorMedicalReportCurrentSelectedTab === 'LATESTINVESTG'
                      ? 'contained'
                      : 'outlined'
                  }
                  color="primary"
                  style={{
                    height: '36px',
                    backgroundColor:
                      donorMedicalReportCurrentSelectedTab !== 'LATESTINVESTG' ? '#F0FEF9' : '',
                  }}
                  onClick={() => {
                    setDonorMedicalReportCurrentSelectedTab('LATESTINVESTG');
                  }}
                  fullWidth
                >
                  Latest Investigation
                </Button>
              </Grid>
              {/* <Grid item sm={4} xs={12}>
                <Button
                  variant={
                    donorMedicalReportCurrentSelectedTab === 'RENALFUNC' ? 'contained' : 'outlined'
                  }
                  color="primary"
                  style={{
                    height: '36px',
                    backgroundColor:
                      donorMedicalReportCurrentSelectedTab !== 'RENALFUNC' ? '#F0FEF9' : '',
                  }}
                  onClick={() => {
                    setDonorMedicalReportCurrentSelectedTab('RENALFUNC');
                  }}
                  fullWidth
                >
                  Renal Function
                </Button>
              </Grid> */}
              <Grid item sm={4} xs={12}>
                <Button
                  variant={
                    donorMedicalReportCurrentSelectedTab === 'LIVERFUNC' ? 'contained' : 'outlined'
                  }
                  color="primary"
                  style={{
                    height: '36px',
                    backgroundColor:
                      donorMedicalReportCurrentSelectedTab !== 'LIVERFUNC' ? '#F0FEF9' : '',
                  }}
                  onClick={() => {
                    setDonorMedicalReportCurrentSelectedTab('LIVERFUNC');
                  }}
                  fullWidth
                >
                  Liver Function
                </Button>
              </Grid>
              <Grid item sm={4} xs={12}>
                <Button
                  variant={
                    donorMedicalReportCurrentSelectedTab === 'HEARTFUNC' ? 'contained' : 'outlined'
                  }
                  color="primary"
                  style={{
                    height: '36px',
                    backgroundColor:
                      donorMedicalReportCurrentSelectedTab !== 'HEARTFUNC' ? '#F0FEF9' : '',
                  }}
                  onClick={() => {
                    setDonorMedicalReportCurrentSelectedTab('HEARTFUNC');
                  }}
                  fullWidth
                >
                  Heart Function
                </Button>
              </Grid>
              <Grid item sm={4} xs={12}>
                <Button
                  variant={
                    donorMedicalReportCurrentSelectedTab === 'KIDNEYFUNC' ? 'contained' : 'outlined'
                  }
                  color="primary"
                  style={{
                    height: '36px',
                    backgroundColor:
                      donorMedicalReportCurrentSelectedTab !== 'KIDNEYFUNC' ? '#F0FEF9' : '',
                  }}
                  onClick={() => {
                    setDonorMedicalReportCurrentSelectedTab('KIDNEYFUNC');
                  }}
                  fullWidth
                >
                  Kidney Function
                </Button>
              </Grid>
              <Grid item sm={4} xs={12}>
                <Button
                  variant={
                    donorMedicalReportCurrentSelectedTab === 'HANDASSESSMENT'
                      ? 'contained'
                      : 'outlined'
                  }
                  color="primary"
                  style={{
                    height: '36px',
                    backgroundColor:
                      donorMedicalReportCurrentSelectedTab !== 'HANDASSESSMENT' ? '#F0FEF9' : '',
                  }}
                  onClick={() => {
                    setDonorMedicalReportCurrentSelectedTab('HANDASSESSMENT');
                  }}
                  fullWidth
                >
                  Hand Assessment
                </Button>
              </Grid>
              {/* <Grid item sm={4} xs={12}>
                <Button
                  variant={
                    donorMedicalReportCurrentSelectedTab === 'MEDHISTORY' ? 'contained' : 'outlined'
                  }
                  color="primary"
                  style={{
                    height: '36px',
                    backgroundColor:
                      donorMedicalReportCurrentSelectedTab !== 'MEDHISTORY' ? '#F0FEF9' : '',
                  }}
                  onClick={() => {
                    setDonorMedicalReportCurrentSelectedTab('MEDHISTORY');
                  }}
                  fullWidth
                >
                  Medical History
                </Button>
              </Grid> */}
              <Grid item sm={4} xs={12}>
                <Button
                  variant={
                    donorMedicalReportCurrentSelectedTab === 'IMAGING' ? 'contained' : 'outlined'
                  }
                  color="primary"
                  style={{
                    height: '36px',
                    backgroundColor:
                      donorMedicalReportCurrentSelectedTab !== 'IMAGING' ? '#F0FEF9' : '',
                  }}
                  onClick={() => {
                    setDonorMedicalReportCurrentSelectedTab('IMAGING');
                  }}
                  fullWidth
                >
                  Imaging
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Box>
        {_renderDonorMedicalReportsAndStatusCurrentPage()}
        {_renderDonorFooterButtons()}
      </>
    );
  };

  const _renderDonorReportAttachments = () => {
    return (
      <>
        <Grid container spacing={3} style={{ marginTop: 10, marginBottom: 10, padding: 10 }}>
          <Grid item sm={6} xs={12}>
            {/* <Typography variant="body1">Form 8 - Declaration cum Consent</Typography> */}
            <ValidatedAttachment
              label="Form 8 - Declaration cum Consent"
              acceptFileTypes={[FILETYPES.PNG, FILETYPES.JPEG, FILETYPES.PDF]}
              value={donorAttachmentsDetails.form8Report}
              onChange={(e) =>
                setDonorAttachmentsDetails({
                  ...donorAttachmentsDetails,
                  form8Report: {
                    key: '',
                    name: e.target.files[0]?.name,
                    file: e.target.files[0],
                  },
                })
              }
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            {/* <Typography variant="body1">Form 10 - For Certification of Brain Stem Death</Typography> */}
            <ValidatedAttachment
              label="Form 10 - For Certification of Brain Stem Death"
              acceptFileTypes={[FILETYPES.PNG, FILETYPES.JPEG, FILETYPES.PDF]}
              value={donorAttachmentsDetails.form10Report}
              onChange={(e) =>
                setDonorAttachmentsDetails({
                  ...donorAttachmentsDetails,
                  form10Report: {
                    key: '',
                    name: e.target.files[0]?.name,
                    file: e.target.files[0],
                  },
                })
              }
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            {/* <Typography variant="body1">Admission Notes</Typography> */}
            <ValidatedAttachment
              label="Admission Notes"
              acceptFileTypes={[FILETYPES.PNG, FILETYPES.JPEG, FILETYPES.PDF]}
              value={donorAttachmentsDetails.admissionReport}
              onChange={(e) =>
                setDonorAttachmentsDetails({
                  ...donorAttachmentsDetails,
                  admissionReport: {
                    key: '',
                    name: e.target.files[0]?.name,
                    file: e.target.files[0],
                  },
                })
              }
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            {/* <Typography variant="body1">Clinical History and Medical Summary</Typography> */}
            <ValidatedAttachment
              label="Clinical History and Medical Summary"
              acceptFileTypes={[FILETYPES.PNG, FILETYPES.JPEG, FILETYPES.PDF]}
              value={donorAttachmentsDetails.clinicalHistoryReport}
              onChange={(e) =>
                setDonorAttachmentsDetails({
                  ...donorAttachmentsDetails,
                  clinicalHistoryReport: {
                    key: '',
                    name: e.target.files[0]?.name,
                    file: e.target.files[0],
                  },
                })
              }
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            {/* <Typography variant="body1">
              ICU Chart (Images Clicked / Copies for all days)
            </Typography> */}
            <ValidatedAttachment
              label="ICU Chart (Images Clicked / Copies for all days)"
              acceptFileTypes={[FILETYPES.PNG, FILETYPES.JPEG, FILETYPES.PDF]}
              value={donorAttachmentsDetails.icuChartReport}
              onChange={(e) =>
                setDonorAttachmentsDetails({
                  ...donorAttachmentsDetails,
                  icuChartReport: {
                    key: '',
                    name: e.target.files[0]?.name,
                    file: e.target.files[0],
                  },
                })
              }
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            {/* <Typography variant="body1">Investigation Chart / Table</Typography> */}
            <ValidatedAttachment
              label="Investigation Chart / Table"
              acceptFileTypes={[FILETYPES.PNG, FILETYPES.JPEG, FILETYPES.PDF]}
              value={donorAttachmentsDetails.investigationChartReport}
              onChange={(e) =>
                setDonorAttachmentsDetails({
                  ...donorAttachmentsDetails,
                  investigationChartReport: {
                    key: '',
                    name: e.target.files[0]?.name,
                    file: e.target.files[0],
                  },
                })
              }
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            {/* <Typography variant="body1">
              Histopathology and other reports relevant to the case
            </Typography> */}
            <ValidatedAttachment
              label="Histopathology and other reports relevant to the case"
              acceptFileTypes={[FILETYPES.PNG, FILETYPES.JPEG, FILETYPES.PDF]}
              value={donorAttachmentsDetails.imagingHistopathologyEndoscopiesReport}
              onChange={(e) =>
                setDonorAttachmentsDetails({
                  ...donorAttachmentsDetails,
                  imagingHistopathologyEndoscopiesReport: {
                    key: '',
                    name: e.target.files[0]?.name,
                    file: e.target.files[0],
                  },
                })
              }
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            {/* <Typography variant="body1">Bronchoscopy Report</Typography> */}
            <ValidatedAttachment
              label="Bronchoscopy Report"
              acceptFileTypes={[FILETYPES.PNG, FILETYPES.JPEG, FILETYPES.PDF]}
              value={donorAttachmentsDetails.angiographyReport}
              onChange={(e) =>
                setDonorAttachmentsDetails({
                  ...donorAttachmentsDetails,
                  angiographyReport: {
                    key: '',
                    name: e.target.files[0]?.name,
                    file: e.target.files[0],
                  },
                })
              }
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <ValidatedRadio
              label="Culture Reports"
              name="cultureReports"
              value={donorAttachmentsDetails.cultureReportYesNo}
              onChange={(e) => {
                const isYes = e.target.value === 'true';
                setDonorAttachmentsDetails({
                  ...donorAttachmentsDetails,
                  cultureReportYesNo: isYes,
                  cultureReportType: isYes ? donorAttachmentsDetails.cultureReportType : [],
                  cultureReportStatus: isYes ? donorAttachmentsDetails.cultureReportStatus : '',
                  cultureReport: isYes
                    ? donorAttachmentsDetails.cultureReport
                    : { key: '', name: '', file: null },
                });
              }}
              options={[
                { value: true, label: 'YES' },
                { value: false, label: 'NO' },
              ]}
            />
          </Grid>
          {donorAttachmentsDetails?.cultureReportYesNo ? (
            <Grid container spacing={3} style={{ marginBottom: 10, padding: 10 }}>
              <Grid item sm={6} xs={12}>
                <Typography variant="body1">Types of Culture Report</Typography>
                <ValidatedSelect
                  required
                  label=""
                  value={donorAttachmentsDetails?.cultureReportType}
                  multiple={true}
                  options={[
                    {
                      label: 'Urine',
                      value: 'URINE',
                    },
                    {
                      label: 'Blood',
                      value: 'BLOOD',
                    },
                    {
                      label: 'Trachia',
                      value: 'TRACHIA',
                    },
                    // {
                    //   label: 'Other',
                    //   value: 'OTHER',
                    // },
                  ]}
                  onChange={(e) =>
                    setDonorAttachmentsDetails({
                      ...donorAttachmentsDetails,
                      cultureReportType: e.map((opt) => opt.value),
                    })
                  }
                />
              </Grid>

              <Grid item sm={6} xs={12}>
                <ValidatedRadio
                  label="Culture Report Status"
                  name="controlled-radio-buttons-group"
                  value={donorAttachmentsDetails.cultureReportStatus}
                  onChange={(e) =>
                    setDonorAttachmentsDetails({
                      ...donorAttachmentsDetails,
                      cultureReportStatus: e.target.value,
                    })
                  }
                  options={[
                    { value: 'POSITIVE', label: 'Positive' },
                    { value: 'NEGATIVE', label: 'Negative' },
                  ]}
                  // validation={validation}
                  // onValidation={onValidation}
                />
              </Grid>

              <Grid item sm={6} xs={12}>
                {/* <Typography variant="body1">Culture Reports</Typography> */}

                <ValidatedAttachment
                  label="Culture Reports"
                  acceptFileTypes={[FILETYPES.PNG, FILETYPES.JPEG, FILETYPES.PDF]}
                  value={donorAttachmentsDetails.cultureReport}
                  onChange={(e) =>
                    setDonorAttachmentsDetails({
                      ...donorAttachmentsDetails,
                      cultureReport: {
                        key: '',
                        name: e.target.files[0]?.name,
                        file: e.target.files[0],
                      },
                    })
                  }
                />
              </Grid>
            </Grid>
          ) : null}

          {/* <Grid item sm={6} xs={12}>
            <Typography variant="body1">Procalcitonin(if relevant)</Typography>
            <ValidatedAttachment
              label="Attachment"
              acceptFileTypes={[FILETYPES.PNG, FILETYPES.JPEG, FILETYPES.PDF]}
              value={donorAttachmentsDetails.procalcitoninReport}
              onChange={(e) =>
                setDonorAttachmentsDetails({
                  ...donorAttachmentsDetails,
                  procalcitoninReport: {
                    key: '',
                    name: e.target.files[0]?.name,
                    file: e.target.files[0],
                  },
                })
              }
            />
          </Grid> */}
          <Grid container style={{ padding: 10 }}>
            <Grid item sm={6} xs={12}>
              <Typography variant="body1">Add Any Attachment Report</Typography>

              <Grid item sm={6} style={{ marginTop: 10, marginBottom: 20 }}>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => {
                    setDonorAttachmentsDetails({
                      ...donorAttachmentsDetails,
                      anyReports: [
                        ...donorAttachmentsDetails.anyReports,
                        {
                          key: '',
                          name: '',
                          file: '',
                        },
                      ],
                    });
                  }}
                >
                  + New Attachment
                </Button>
              </Grid>
            </Grid>
            {donorAttachmentsDetails?.anyReports?.map((a, index) =>
              _renderAnyOtherAttachmentDetails(a, index)
            )}
          </Grid>
        </Grid>
        {_renderDonorFooterButtons()}
      </>
    );
  };

  const _renderDonorOrganRetrievalInformation = () => {
    return (
      <>
        <Grid container spacing={3} style={{ marginTop: 10, marginBottom: 10, padding: 10 }}>
          {/* <Grid item sm={12}>
            <FormControl component="fieldset">
              <FormLabel component="legend">
                <Typography style={{ color: 'black' }}>
                  Is Donor eligible for Organ Donation?
                </Typography>
              </FormLabel>
              <RadioGroup
                row
                aria-label="Is Donor eligible for Organ Donation?"
                name="controlled-radio-buttons-group"
                value={donorOrganRetrievalDetails.donorEligibleForDonation}
                onChange={(e) => {
                  setDonorOrganRetrievalDetails({
                    ...donorOrganRetrievalDetails,
                    donorEligibleForDonation: e.target.value === 'true',
                  });
                }}
              >
                <FormControlLabel value={true} control={<Radio />} label="Yes" />
                <FormControlLabel value={false} control={<Radio />} label="No" />
              </RadioGroup>
            </FormControl>
          </Grid>
          {donorOrganRetrievalDetails.donorEligibleForDonation === false ? (
            <Grid item sm={12}>
              <Typography variant="body1">If donor is not eligble, State Reason</Typography>
              <ValidatedInput
                required
                fullWidth
                value={donorOrganRetrievalDetails.notEligibleReason}
                label=""
                type="text"
                placeholder=""
                onChange={(e) => {
                  setDonorOrganRetrievalDetails({
                    ...donorOrganRetrievalDetails,
                    notEligibleReason: e.target.value,
                  });
                }}
              />
            </Grid>
          ) : null} */}
        </Grid>
        <Grid container style={{ padding: 10 }} spacing={2}>
          <Grid item style={{ padding: 10 }}>
            <Typography>List of Organs or Tissues Consented for Retrieval</Typography>
            <CustomOrganSelector
              required
              organButtonSize={3}
              value={donorOrganRetrievalDetails.retrievedOrgans}
              multi
              organsList={[
                'LIVER',
                'HEART',
                'KIDNEY',
                // 'KIDNEY ONE',
                // 'KIDNEY TWO',
                'LUNGS',
                'PANCREAS',
                'RIGHT HAND',
                'LEFT HAND',
                'INTESTINE',
                'CORNEAS',
                'BONES',
                'HEART VALVES',
                'BLOOD VESSELS',
                'FACE',
                'ABDOMINAL WALL',
                'TRACHEA',
                'OTHER COMPOSITE TISSUE',
              ]}
              onChange={(selectedOrgans) => {
                setDonorOrganRetrievalDetails({
                  ...donorOrganRetrievalDetails,
                  retrievedOrgans: selectedOrgans,
                });
              }}
              disabled={_isDisabled()}
            />
          </Grid>
          <Grid item sm={12}>
            <ValidatedInput
              label="Any other Organs or tissues retrieved"
              value={donorOrganRetrievalDetails.retrievedOtherOrgans}
              onChange={(e) => {
                setDonorOrganRetrievalDetails({
                  ...donorOrganRetrievalDetails,
                  retrievedOtherOrgans: e.target.value,
                });
              }}
              fullWidth
              disabled={_isDisabled()}
            />
          </Grid>
          {donorOrganRetrievalDetails.retrievedOrgans.includes('INTESTINE') ? (
            <Grid item sm={12}>
              <ValidatedRadio
                label="Does the Patient Abdominal Wall Harvested?"
                name="controlled-radio-buttons-group"
                value={donorOrganRetrievalDetails?.abdominalWallHarvested}
                onChange={(e) => {
                  setDonorOrganRetrievalDetails({
                    ...donorOrganRetrievalDetails,
                    abdominalWallHarvested: e.target.value,
                  });
                }}
                options={[
                  { value: 'YES', label: 'YES' },
                  { value: 'NO', label: 'NO' },
                ]}
                validation={validation}
                onValidation={onValidation}
              />
            </Grid>
          ) : null}
          {donorOrganRetrievalDetails.retrievedOrgans.includes('LIVER') ? (
            <>
              <Grid item sm={12}>
                <ValidatedRadio
                  label="Does the donor meet the liver splitting criteria?"
                  name="controlled-radio-buttons-group"
                  value={donorOrganRetrievalDetails?.liverSplittingCriteria?.meetSplittingCriteria}
                  onChange={(e) => {
                    setDonorOrganRetrievalDetails({
                      ...donorOrganRetrievalDetails,
                      liverSplittingCriteria: {
                        ...donorOrganRetrievalDetails.liverSplittingCriteria,
                        meetSplittingCriteria: e.target.value,
                        ...(e.target.value === 'NO' ? { icu: '', sgot: '', vasopresser: '' } : {}),
                      },
                    });
                  }}
                  options={[
                    { value: 'YES', label: 'YES' },
                    { value: 'NO', label: 'NO' },
                  ]}
                  validation={validation}
                  onValidation={onValidation}
                />
              </Grid>
              {donorOrganRetrievalDetails.liverSplittingCriteria.meetSplittingCriteria === 'YES' ? (
                <Grid container style={{ marginLeft: 20 }}>
                  <Grid item sm={4}>
                    <ValidatedRadio
                      label="ICU stay ≤5 days"
                      name="controlled-radio-buttons-group"
                      value={donorOrganRetrievalDetails?.liverSplittingCriteria?.icu}
                      onChange={(e) => {
                        setDonorOrganRetrievalDetails({
                          ...donorOrganRetrievalDetails,
                          liverSplittingCriteria: {
                            ...donorOrganRetrievalDetails.liverSplittingCriteria,
                            icu: e.target.value,
                          },
                        });
                      }}
                      options={[
                        { value: 'YES', label: 'YES' },
                        { value: 'NO', label: 'NO' },
                      ]}
                      validation={validation}
                      onValidation={onValidation}
                    />
                  </Grid>
                  <Grid item sm={4}>
                    <ValidatedRadio
                      label="SGOT (AST) / SGPT (ALT) ≤ 3x ULN (upper limit of normal)"
                      name="controlled-radio-buttons-group"
                      value={donorOrganRetrievalDetails?.liverSplittingCriteria?.sgot}
                      onChange={(e) => {
                        setDonorOrganRetrievalDetails({
                          ...donorOrganRetrievalDetails,
                          liverSplittingCriteria: {
                            ...donorOrganRetrievalDetails.liverSplittingCriteria,
                            sgot: e.target.value,
                          },
                        });
                      }}
                      options={[
                        { value: 'YES', label: 'YES' },
                        { value: 'NO', label: 'NO' },
                      ]}
                      validation={validation}
                      onValidation={onValidation}
                    />
                  </Grid>
                  <Grid item sm={4}>
                    <ValidatedRadio
                      label="On a single or no vasopressor"
                      name="controlled-radio-buttons-group"
                      value={donorOrganRetrievalDetails?.liverSplittingCriteria?.vasopresser}
                      onChange={(e) => {
                        setDonorOrganRetrievalDetails({
                          ...donorOrganRetrievalDetails,
                          liverSplittingCriteria: {
                            ...donorOrganRetrievalDetails.liverSplittingCriteria,
                            vasopresser: e.target.value,
                          },
                        });
                      }}
                      options={[
                        { value: 'YES', label: 'YES' },
                        { value: 'NO', label: 'NO' },
                      ]}
                      validation={validation}
                      onValidation={onValidation}
                    />
                  </Grid>
                </Grid>
              ) : null}
            </>
          ) : null}
          {donorOrganRetrievalDetails?.retrievedOrgans.includes('RIGHT HAND') ? (
            <>
              <Grid item sm={6} style={{ padding: 10 }}>
                <ValidatedRadio
                  label="Level of Retrieval from Right Hand"
                  name="controlled-radio-buttons-group"
                  value={donorOrganRetrievalDetails?.levelOfRetrivalFromRightHand}
                  onChange={(e) => {
                    setDonorOrganRetrievalDetails({
                      ...donorOrganRetrievalDetails,
                      levelOfRetrivalFromRightHand: e.target.value,
                    });
                  }}
                  options={[
                    { value: 'WRIST', label: 'At level of Wrist' },
                    { value: 'MIDFOREARM', label: 'At Level of Mid Fore Arm' },
                    { value: 'ELBOW', label: 'At level of Elbow' },
                    { value: 'MIDARM', label: 'At Level of Mid Arm' },
                    { value: 'SHOULDER', label: 'At Level of Shoulder' },
                  ]}
                  validation={validation}
                  onValidation={onValidation}
                />
              </Grid>
            </>
          ) : null}
          {donorOrganRetrievalDetails?.retrievedOrgans.includes('LEFT HAND') ? (
            <Grid item sm={6} style={{ padding: 10 }}>
              <ValidatedRadio
                label="Level of Retrieval from Left Hand"
                name="controlled-radio-buttons-group"
                value={donorOrganRetrievalDetails?.levelOfRetrivalFromLeftHand}
                onChange={(e) => {
                  setDonorOrganRetrievalDetails({
                    ...donorOrganRetrievalDetails,
                    levelOfRetrivalFromLeftHand: e.target.value,
                  });
                }}
                options={[
                  { value: 'WRIST', label: 'At level of Wrist' },
                  { value: 'MIDFOREARM', label: 'At Level of Mid Fore Arm' },
                  { value: 'ELBOW', label: 'At level of Elbow' },
                  { value: 'MIDARM', label: 'At Level of Mid Arm' },
                  { value: 'SHOULDER', label: 'At Level of Shoulder' },
                ]}
                validation={validation}
                onValidation={onValidation}
              />
            </Grid>
          ) : null}
          {donorOrganRetrievalDetails.retrievedOrgans.includes('KIDNEY') ? (
            <Grid item sm={12}>
              <ValidatedRadio
                label="Side of kidney compatible for donation"
                name="controlled-radio-buttons-group"
                value={donorOrganRetrievalDetails?.sideOfKidneyCompatibleForDonation}
                onChange={(e) => {
                  setDonorOrganRetrievalDetails({
                    ...donorOrganRetrievalDetails,
                    sideOfKidneyCompatibleForDonation: e.target.value,
                  });
                }}
                options={[
                  { value: 'LEFTKIDNEY', label: 'Left Kidney' },
                  { value: 'RIGHTKIDNEY', label: 'Right Kidney' },
                  { value: 'BOTH', label: 'Both Kidneys' },
                ]}
                validation={validation}
                onValidation={onValidation}
              />
            </Grid>
          ) : null}
          {/* <Grid item sm={12}>
            <FormControl component="fieldset">
              <FormLabel component="legend">
                <Typography style={{ color: 'black' }}>
                  Is any donated organs under ECD (Extended Criteria Donor) ?
                </Typography>
              </FormLabel>
              <RadioGroup
                row
                aria-label="Is any donated organs under ECD (Extended Criteria Donor) ?"
                name="controlled-radio-buttons-group"
                value={donorOrganRetrievalDetails.anyEcdOrgans}
                onChange={(e) => {
                  setDonorOrganRetrievalDetails({
                    ...donorOrganRetrievalDetails,
                    anyEcdOrgans: e.target.value === 'true',
                  });
                }}
              >
                <FormControlLabel value={true} control={<Radio />} label="Yes" />
                <FormControlLabel value={false} control={<Radio />} label="No" />
              </RadioGroup>
            </FormControl>
          </Grid>
          {donorOrganRetrievalDetails.anyEcdOrgans ? (
            <Grid item style={{ padding: 10 }} sm={12}>
              <Typography>List of Organs under ECD (Extended Criteria Donor)</Typography>
              <CustomOrganSelector
                required
                value={donorOrganRetrievalDetails.ecdOrgans}
                multi
                organsList={donorOrganRetrievalDetails.retrievedOrgans}
                onChange={(selectedOrgans) => {
                  setDonorOrganRetrievalDetails({
                    ...donorOrganRetrievalDetails,
                    ecdOrgans: selectedOrgans,
                  });
                  if (selectedOrgans.length) {
                    donorPages.splice(donorPages.length, 0, 'ECDINFO');
                    setDonorPages([...donorPages]);
                  } else {
                    donorPages.splice(-1, 1);
                    setDonorPages([...donorPages]);
                  }
                }}
                disabled={_isDisabled()}
              />
            </Grid>
          ) : null} */}
          {/* <Grid item sm={12}>
            <FormControl component="fieldset">
              <FormLabel component="legend">
                {' '}
                <Typography style={{ color: 'black' }}>
                  Is Donor under DCD (Organ Donation after Circulatory Death) ?
                </Typography>
              </FormLabel>
              <RadioGroup
                row
                aria-label="Is Donor under DCD (Organ Donation after Circulatory Death) ?"
                name="controlled-radio-buttons-group"
                value={donorOrganRetrievalDetails.anyDcdOrgans}
                onChange={(e) => {
                  setDonorOrganRetrievalDetails({
                    ...donorOrganRetrievalDetails,
                    anyDcdOrgans: e.target.value === 'true',
                  });
                }}
              >
                <FormControlLabel value={true} control={<Radio />} label="Yes" />
                <FormControlLabel value={false} control={<Radio />} label="No" />
              </RadioGroup>
            </FormControl>
          </Grid> */}
          {/* {donorOrganRetrievalDetails.anyDcdOrgans ? (
            <Grid item style={{ padding: 10 }} sm={12}> */}
          {/* <Typography>List of Organs under DCD (Organ Donation after Circulatory Death)</Typography> */}
          {/* <CustomOrganSelector
                required
                value={dcdOrgans}
                multi
                organsList={[...retrievedOrgans]}
                onChange={(selectedOrgans) => {
                  setDcdOrgans(selectedOrgans);
                }}
              /> */}
          {/* <Typography>Donor Death Certificate</Typography> */}
          {/* <ValidatedAttachment
                id="Donor Death Certificate"
                name="Donor Death Certificate"
                label="Donor Death Certificate"
                acceptFileTypes={[FILETYPES.PNG, FILETYPES.JPEG, FILETYPES.PDF]}
                value={donorOrganRetrievalDetails.deathCertificate}
                onChange={(e) => {
                  setDonorOrganRetrievalDetails({
                    ...donorOrganRetrievalDetails,
                    deathCertificate: {
                      key: '',
                      name: e.target.files[0]?.name,
                      file: e.target.files[0],
                    },
                  });
                }} 
          // validation={validation}
          // onValidation={onValidation}
          /> */}
          {/* </Grid>
          ) : null} */}
          <Grid container style={{ marginLeft: 20 }}>
            <Grid item sm={4} xs={12}>
              <Typography variant="body1">Retrieval Organ Date and Time</Typography>
              <ValidatedDate
                required
                fullWidth
                value={donorOrganRetrievalDetails?.retrievalOrganTime}
                label=""
                type="datetime-local"
                placeholder=""
                onChange={(e) => {
                  setDonorOrganRetrievalDetails({
                    ...donorOrganRetrievalDetails,
                    retrievalOrganTime: e,
                  });
                }}
                schema={Joi.date()}
                validation={validation}
                onValidation={onValidation}
              />
            </Grid>
            <Grid item sm={4} xs={12} style={{ marginLeft: 10 }}>
              <Typography variant="body1">Body Handover Date and Time</Typography>
              <ValidatedDate
                required
                fullWidth
                value={donorOrganRetrievalDetails?.bodyHandoverTime}
                label=""
                type="datetime-local"
                placeholder=""
                onChange={(e) => {
                  setDonorOrganRetrievalDetails({
                    ...donorOrganRetrievalDetails,
                    bodyHandoverTime: e,
                  });
                }}
                schema={Joi.date()}
                validation={validation}
                onValidation={onValidation}
              />
            </Grid>
          </Grid>
        </Grid>
        {_renderDonorFooterButtons()}
      </>
    );
  };

  const _renderDonorTumorInformation = () => {
    return (
      <Grid container style={{ padding: 20 }} spacing={2}>
        <Grid item sm={12}>
          <Grid item style={{ borderLeft: '5px solid #4EA95E' }}>
            <Typography variant="body1" className={classes.transplantDetailsHeading}>
              Absolute Contraindication
            </Typography>
          </Grid>
          <FormControl component="fieldset">
            <RadioGroup
              row
              aria-label="Absolute Contraindication"
              name="controlled-radio-buttons-group"
              value={tumorInformation?.absoluteContraindication?.absoluteContraindication}
              onChange={(e) => {
                if (e.target.value === 'true') {
                  setTumorInformation({
                    ...tumorInformation,
                    highRisk: { highRisk: false },
                    intermediateRisk: {
                      intermediateRisk: false,
                    },
                    lowRisk: { lowRisk: false },
                    minimalRisk: { minimalRisk: false },
                    absoluteContraindication: {
                      ...tumorInformation.absoluteContraindication,
                      absoluteContraindication: true,
                    },
                  });
                } else {
                  setTumorInformation({
                    ...tumorInformation,
                    absoluteContraindication: {
                      ...tumorInformation.absoluteContraindication,
                      absoluteContraindication: false,
                    },
                  });
                }
              }}
            >
              <FormControlLabel value={true} control={<Radio />} label="Yes" />
              <FormControlLabel value={false} control={<Radio />} label="No" />
            </RadioGroup>
          </FormControl>
        </Grid>
        {tumorInformation?.absoluteContraindication?.absoluteContraindication ? (
          <Grid
            container
            spacing={3}
            style={{ marginTop: 10, marginBottom: 10, padding: 10, marginLeft: 10 }}
          >
            <Grid item sm={4} xs={12}>
              <Typography variant="body1">Primary Cerebral Lymphoma</Typography>
              <RadioGroup
                row={true}
                aria-label="Primary Cerebral Lymphoma"
                name="primaryCerebralLymphoma"
                value={tumorInformation?.absoluteContraindication?.primaryCerebralLymphoma}
                onChange={(e) => {
                  setTumorInformation({
                    ...tumorInformation,
                    absoluteContraindication: {
                      ...tumorInformation.absoluteContraindication,
                      primaryCerebralLymphoma: e.target.value === 'true',
                    },
                  });
                }}
              >
                <FormControlLabel value={true} control={<Radio />} label="Yes" />
                <FormControlLabel value={false} control={<Radio />} label="No" />
              </RadioGroup>
            </Grid>
            <Grid item sm={4} xs={12}>
              <Typography variant="body1">All secondary intracranial Tumors</Typography>
              <RadioGroup
                row={true}
                aria-label="All secondary intracranial Tumors"
                name="secondaryIntracranialTumors"
                value={tumorInformation?.absoluteContraindication?.secondaryIntracranialTumors}
                onChange={(e) => {
                  setTumorInformation({
                    ...tumorInformation,
                    absoluteContraindication: {
                      ...tumorInformation.absoluteContraindication,
                      secondaryIntracranialTumors: e.target.value === 'true',
                    },
                  });
                }}
              >
                <FormControlLabel value={true} control={<Radio />} label="Yes" />
                <FormControlLabel value={false} control={<Radio />} label="No" />
              </RadioGroup>
            </Grid>
            <Grid item sm={4} xs={12}>
              <Typography variant="body1">
                Active Cancer with spread outside the organ of origin
              </Typography>
              <RadioGroup
                row={true}
                aria-label="Active Cancer with spread outside the organ of origin"
                name="activeCancerSpread"
                value={tumorInformation?.absoluteContraindication?.activeCancerSpread}
                onChange={(e) => {
                  setTumorInformation({
                    ...tumorInformation,
                    absoluteContraindication: {
                      ...tumorInformation.absoluteContraindication,
                      activeCancerSpread: e.target.value === 'true',
                    },
                  });
                }}
              >
                <FormControlLabel value={true} control={<Radio />} label="Yes" />
                <FormControlLabel value={false} control={<Radio />} label="No" />
              </RadioGroup>
            </Grid>
            <Grid item sm={4} xs={12}>
              <Typography variant="body1">Active Haematological Malignancy</Typography>
              <RadioGroup
                row={true}
                aria-label="Active Haematological Malignancy"
                name="activeHaematologicalMalignancy"
                value={tumorInformation?.absoluteContraindication?.activeHaematologicalMalignancy}
                onChange={(e) => {
                  setTumorInformation({
                    ...tumorInformation,
                    absoluteContraindication: {
                      ...tumorInformation.absoluteContraindication,
                      activeHaematologicalMalignancy: e.target.value === 'true',
                    },
                  });
                }}
              >
                <FormControlLabel value={true} control={<Radio />} label="Yes" />
                <FormControlLabel value={false} control={<Radio />} label="No" />
              </RadioGroup>
            </Grid>
          </Grid>
        ) : null}
        <Grid item sm={12}>
          <Grid item style={{ borderLeft: '5px solid #4EA95E' }}>
            <Typography variant="body1" className={classes.transplantDetailsHeading}>
              High Risk ( {'>'} 10% risk of Transmission)
            </Typography>
          </Grid>
          <FormControl component="fieldset">
            <RadioGroup
              row
              aria-label="High Risk  ( > 10% risk of Transmission)"
              name="controlled-radio-buttons-group"
              value={tumorInformation?.highRisk?.highRisk}
              onChange={(e) => {
                if (e.target.value === 'true') {
                  setTumorInformation({
                    ...tumorInformation,
                    highRisk: {
                      ...tumorInformation.highRisk,
                      highRisk: true,
                    },
                    intermediateRisk: {
                      intermediateRisk: false,
                    },
                    lowRisk: { lowRisk: false },
                    minimalRisk: { minimalRisk: false },
                    absoluteContraindication: {
                      absoluteContraindication: false,
                    },
                  });
                } else {
                  setTumorInformation({
                    ...tumorInformation,
                    highRisk: {
                      ...tumorInformation.highRisk,
                      highRisk: false,
                    },
                  });
                }
              }}
            >
              <FormControlLabel value={true} control={<Radio />} label="Yes" />
              <FormControlLabel value={false} control={<Radio />} label="No" />
            </RadioGroup>
          </FormControl>
        </Grid>
        {tumorInformation?.highRisk?.highRisk ? (
          <Grid
            container
            spacing={3}
            style={{ marginTop: 10, marginBottom: 10, padding: 10, marginLeft: 10 }}
          >
            <Grid item sm={4} xs={12}>
              <Typography variant="body1">Malignant melanoma</Typography>
              <RadioGroup
                row={true}
                aria-label="Malignant melanoma"
                name="malignantMelanoma"
                value={tumorInformation?.highRisk?.malignantMelanoma}
                onChange={(e) => {
                  setTumorInformation({
                    ...tumorInformation,
                    highRisk: {
                      ...tumorInformation.highRisk,
                      malignantMelanoma: e.target.value === 'true',
                    },
                  });
                }}
              >
                <FormControlLabel value={true} control={<Radio />} label="Yes" />
                <FormControlLabel value={false} control={<Radio />} label="No" />
              </RadioGroup>
            </Grid>
            <Grid item sm={4} xs={12}>
              <Typography variant="body1">Breast Carcinoma {'>'} Stage 0 Active</Typography>
              <RadioGroup
                row={true}
                aria-label="Breast Carcinoma > Stage 0 Active"
                name="breatCarcinoma"
                value={tumorInformation?.highRisk?.breatCarcinoma}
                onChange={(e) => {
                  setTumorInformation({
                    ...tumorInformation,
                    highRisk: {
                      ...tumorInformation.highRisk,
                      breatCarcinoma: e.target.value === 'true',
                    },
                  });
                }}
              >
                <FormControlLabel value={true} control={<Radio />} label="Yes" />
                <FormControlLabel value={false} control={<Radio />} label="No" />
              </RadioGroup>
            </Grid>
            <Grid item sm={4} xs={12}>
              <Typography variant="body1">Colon Carcinoma {'>'} Stage 0 Active</Typography>
              <RadioGroup
                row={true}
                aria-label="Colon Carcinoma > Stage 0 Active"
                name="colonCarcinoma"
                value={tumorInformation?.highRisk?.colonCarcinoma}
                onChange={(e) => {
                  setTumorInformation({
                    ...tumorInformation,
                    highRisk: {
                      ...tumorInformation.highRisk,
                      colonCarcinoma: e.target.value === 'true',
                    },
                  });
                }}
              >
                <FormControlLabel value={true} control={<Radio />} label="Yes" />
                <FormControlLabel value={false} control={<Radio />} label="No" />
              </RadioGroup>
            </Grid>
            <Grid item sm={4} xs={12}>
              <Typography variant="body1">Choriocarinoma</Typography>
              <RadioGroup
                row={true}
                aria-label="Choriocarinoma"
                name="choriocarinoma"
                value={tumorInformation?.highRisk?.choriocarinoma}
                onChange={(e) => {
                  setTumorInformation({
                    ...tumorInformation,
                    highRisk: {
                      ...tumorInformation.highRisk,
                      choriocarinoma: e.target.value === 'true',
                    },
                  });
                }}
              >
                <FormControlLabel value={true} control={<Radio />} label="Yes" />
                <FormControlLabel value={false} control={<Radio />} label="No" />
              </RadioGroup>
            </Grid>
            <Grid item sm={4} xs={12}>
              <Typography variant="body1">
                CNS Tumor (any) with ventriculoperitoneal or ventriculoatrial shnt, surgery(other
                thatn uncomplicated biopsy), irradiation or extra CNS metastasis
              </Typography>
              <RadioGroup
                row={true}
                aria-label="CNS Tumor (any) with ventriculoperitoneal or ventriculoatrial shnt, surgery(other thatn uncomplicated biopsy), irradiation or extra CNS metastasis"
                name="cnsTumorWithVentriculoperitoneal"
                value={tumorInformation?.highRisk?.cnsTumorWithVentriculoperitoneal}
                onChange={(e) => {
                  setTumorInformation({
                    ...tumorInformation,
                    highRisk: {
                      ...tumorInformation.highRisk,
                      cnsTumorWithVentriculoperitoneal: e.target.value === 'true',
                    },
                  });
                }}
              >
                <FormControlLabel value={true} control={<Radio />} label="Yes" />
                <FormControlLabel value={false} control={<Radio />} label="No" />
              </RadioGroup>
            </Grid>
            <Grid item sm={4} xs={12}>
              <Typography variant="body1">CNS Tumor WHO grade III or IV</Typography>
              <RadioGroup
                row={true}
                aria-label="CNS Tumor WHO grade III or IV"
                name="cnsTumorWhoGrade"
                value={tumorInformation?.highRisk?.cnsTumorWhoGrade}
                onChange={(e) => {
                  setTumorInformation({
                    ...tumorInformation,
                    highRisk: {
                      ...tumorInformation.highRisk,
                      cnsTumorWhoGrade: e.target.value === 'true',
                    },
                  });
                }}
              >
                <FormControlLabel value={true} control={<Radio />} label="Yes" />
                <FormControlLabel value={false} control={<Radio />} label="No" />
              </RadioGroup>
            </Grid>
          </Grid>
        ) : null}
        <Grid item sm={12}>
          <Grid item style={{ borderLeft: '5px solid #4EA95E' }}>
            <Typography variant="body1" className={classes.transplantDetailsHeading}>
              Intermediate Risk ( 1-10% risk of Transmission)
            </Typography>
          </Grid>
          <FormControl component="fieldset">
            <RadioGroup
              row
              aria-label="Intermediate Risk ( 1-10% risk of Transmission) "
              name="controlled-radio-buttons-group"
              value={tumorInformation?.intermediateRisk?.intermediateRisk}
              onChange={(e) => {
                if (e.target.value === 'true') {
                  setTumorInformation({
                    ...tumorInformation,
                    highRisk: {
                      highRisk: false,
                    },
                    intermediateRisk: {
                      ...tumorInformation.intermediateRisk,
                      intermediateRisk: true,
                    },
                    lowRisk: { lowRisk: false },
                    minimalRisk: { minimalRisk: false },
                    absoluteContraindication: {
                      absoluteContraindication: false,
                    },
                  });
                } else {
                  setTumorInformation({
                    ...tumorInformation,
                    intermediateRisk: {
                      ...tumorInformation.intermediateRisk,
                      intermediateRisk: false,
                    },
                  });
                }
              }}
            >
              <FormControlLabel value={true} control={<Radio />} label="Yes" />
              <FormControlLabel value={false} control={<Radio />} label="No" />
            </RadioGroup>
          </FormControl>
        </Grid>
        {tumorInformation?.intermediateRisk?.intermediateRisk ? (
          <Grid
            container
            spacing={3}
            style={{ marginTop: 10, marginBottom: 10, padding: 10, marginLeft: 10 }}
          >
            <Grid item sm={4} xs={12}>
              <Typography variant="body1">
                Breast Carcinoma( Stage 0 i.e., Carcinoma in situ)
              </Typography>
              <RadioGroup
                row={true}
                aria-label="Breast Carcinoma( Stage 0 i.e., Carcinoma in situ)"
                name="colonCarcinoma"
                value={tumorInformation?.intermediateRisk?.colonCarcinoma}
                onChange={(e) => {
                  setTumorInformation({
                    ...tumorInformation,
                    intermediateRisk: {
                      ...tumorInformation.intermediateRisk,
                      colonCarcinoma: e.target.value === 'true',
                    },
                  });
                }}
              >
                <FormControlLabel value={true} control={<Radio />} label="Yes" />
                <FormControlLabel value={false} control={<Radio />} label="No" />
              </RadioGroup>
            </Grid>
            <Grid item sm={4} xs={12}>
              <Typography variant="body1">
                Colon Carcinoma (Stage 0 i.e., Carcinoma in situ)
              </Typography>
              <RadioGroup
                row={true}
                aria-label="Colon Carcinoma (Stage 0 i.e., Carcinoma in situ)"
                name="choriocarinoma"
                value={tumorInformation?.intermediateRisk?.choriocarinoma}
                onChange={(e) => {
                  setTumorInformation({
                    ...tumorInformation,
                    intermediateRisk: {
                      ...tumorInformation.intermediateRisk,
                      choriocarinoma: e.target.value === 'true',
                    },
                  });
                }}
              >
                <FormControlLabel value={true} control={<Radio />} label="Yes" />
                <FormControlLabel value={false} control={<Radio />} label="No" />
              </RadioGroup>
            </Grid>
            <Grid item sm={4} xs={12}>
              <Typography variant="body1">
                (Resected) Solitary renal cell carcinoma T1b (4-7 cm) well differentiate(Fuhrman
                1-2) Stage I
              </Typography>
              <RadioGroup
                row={true}
                aria-label="(Resected) Solitary renal cell carcinoma T1b (4-7 cm) well differentiate(Fuhrman 1-2) Stage I"
                name="cnsTumorWithVentriculoperitoneal"
                value={tumorInformation?.intermediateRisk?.cnsTumorWithVentriculoperitoneal}
                onChange={(e) => {
                  setTumorInformation({
                    ...tumorInformation,
                    intermediateRisk: {
                      ...tumorInformation.intermediateRisk,
                      cnsTumorWithVentriculoperitoneal: e.target.value === 'true',
                    },
                  });
                }}
              >
                <FormControlLabel value={true} control={<Radio />} label="Yes" />
                <FormControlLabel value={false} control={<Radio />} label="No" />
              </RadioGroup>
            </Grid>
            <Grid item sm={4} xs={12}>
              <Typography variant="body1">
                History of treated non-CNS malignancy({'>='} 5 years prior) with probability of cure
                between 90-99%
              </Typography>
              <RadioGroup
                row={true}
                aria-label="History of treated non-CNS malignancy(>= 5 years prior) with probability of cure between 90-99%"
                name="cnsTumorWhoGrade"
                value={tumorInformation?.intermediateRisk?.cnsTumorWhoGrade}
                onChange={(e) => {
                  setTumorInformation({
                    ...tumorInformation,
                    intermediateRisk: {
                      ...tumorInformation.intermediateRisk,
                      cnsTumorWhoGrade: e.target.value === 'true',
                    },
                  });
                }}
              >
                <FormControlLabel value={true} control={<Radio />} label="Yes" />
                <FormControlLabel value={false} control={<Radio />} label="No" />
              </RadioGroup>
            </Grid>
          </Grid>
        ) : null}
        <Grid item sm={12}>
          <Grid item style={{ borderLeft: '5px solid #4EA95E' }}>
            <Typography variant="body1" className={classes.transplantDetailsHeading}>
              Low Risk ( 0.1-1% risk of Transmission)
            </Typography>
          </Grid>
          <FormControl component="fieldset">
            <RadioGroup
              row
              aria-label="Low Risk ( 0.1-1% risk of Transmission) "
              name="controlled-radio-buttons-group"
              value={tumorInformation?.lowRisk?.lowRisk}
              onChange={(e) => {
                if (e.target.value === 'true') {
                  setTumorInformation({
                    ...tumorInformation,
                    highRisk: {
                      highRisk: false,
                    },
                    intermediateRisk: {
                      intermediateRisk: false,
                    },
                    lowRisk: {
                      ...tumorInformation.lowRisk,
                      lowRisk: true,
                    },
                    minimalRisk: { minimalRisk: false },
                    absoluteContraindication: {
                      absoluteContraindication: false,
                    },
                  });
                } else {
                  setTumorInformation({
                    ...tumorInformation,
                    lowRisk: {
                      ...tumorInformation.lowRisk,
                      lowRisk: false,
                    },
                  });
                }
              }}
            >
              <FormControlLabel value={true} control={<Radio />} label="Yes" />
              <FormControlLabel value={false} control={<Radio />} label="No" />
            </RadioGroup>
          </FormControl>
        </Grid>
        {tumorInformation?.lowRisk?.lowRisk ? (
          <Grid
            container
            spacing={3}
            style={{ marginTop: 10, marginBottom: 10, padding: 10, marginLeft: 10 }}
          >
            <Grid item sm={4} xs={12}>
              <Typography variant="body1">
                (Resected) solitary renal cell carcinoma, {'>'} 1.0 cm {'<='} 2.5cm, well
                differentialed (Fuhrman 1-2)
              </Typography>
              <RadioGroup
                row={true}
                aria-label="(Resected) solitary renal cell carcinoma, > 1.0 cm <= 2.5cm, well differentialed (Fuhrman 1-2)"
                name="solitaryRenalCellCarcinoma"
                value={tumorInformation?.lowRisk?.solitaryRenalCellCarcinoma}
                onChange={(e) => {
                  setTumorInformation({
                    ...tumorInformation,
                    lowRisk: {
                      ...tumorInformation.lowRisk,
                      solitaryRenalCellCarcinoma: e.target.value === 'true',
                    },
                  });
                }}
              >
                <FormControlLabel value={true} control={<Radio />} label="Yes" />
                <FormControlLabel value={false} control={<Radio />} label="No" />
              </RadioGroup>
            </Grid>
            <Grid item sm={4} xs={12}>
              <Typography variant="body1">Low grade CNS Tumor (WHO grade I or II)</Typography>
              <RadioGroup
                row={true}
                aria-label="Low grade CNS Tumor (WHO grade I or II)"
                name="lowGradeCns"
                value={tumorInformation?.lowRisk?.lowGradeCns}
                onChange={(e) => {
                  setTumorInformation({
                    ...tumorInformation,
                    lowRisk: {
                      ...tumorInformation.lowRisk,
                      lowGradeCns: e.target.value === 'true',
                    },
                  });
                }}
              >
                <FormControlLabel value={true} control={<Radio />} label="Yes" />
                <FormControlLabel value={false} control={<Radio />} label="No" />
              </RadioGroup>
            </Grid>
            <Grid item sm={4} xs={12}>
              <Typography variant="body1">Primary CNS Mature Teratoma</Typography>
              <RadioGroup
                row={true}
                aria-label="Primary CNS Mature Teratoma"
                name="primaryCnsmatureTeratoma"
                value={tumorInformation?.lowRisk?.primaryCnsmatureTeratoma}
                onChange={(e) => {
                  setTumorInformation({
                    ...tumorInformation,
                    lowRisk: {
                      ...tumorInformation.lowRisk,
                      primaryCnsmatureTeratoma: e.target.value === 'true',
                    },
                  });
                }}
              >
                <FormControlLabel value={true} control={<Radio />} label="Yes" />
                <FormControlLabel value={false} control={<Radio />} label="No" />
              </RadioGroup>
            </Grid>
            <Grid item sm={4} xs={12}>
              <Typography variant="body1">
                Solitary Papillary Thyroid Carcinoma, 0.5-2.0 cm
              </Typography>
              <RadioGroup
                row={true}
                aria-label="Solitary Papillary Thyroid Carcinoma, 0.5-2.0 cm"
                name="solitaryPapillaryThyroidCarcinoma"
                value={tumorInformation?.lowRisk?.solitaryPapillaryThyroidCarcinoma}
                onChange={(e) => {
                  setTumorInformation({
                    ...tumorInformation,
                    lowRisk: {
                      ...tumorInformation.lowRisk,
                      solitaryPapillaryThyroidCarcinoma: e.target.value === 'true',
                    },
                  });
                }}
              >
                <FormControlLabel value={true} control={<Radio />} label="Yes" />
                <FormControlLabel value={false} control={<Radio />} label="No" />
              </RadioGroup>
            </Grid>
            <Grid item sm={4} xs={12}>
              <Typography variant="body1">
                Minimally invasive follicular carcinoma, thyroid, 1-0-2.0 cm
              </Typography>
              <RadioGroup
                row={true}
                aria-label="Minimally invasive follicular carcinoma, thyroid, 1-0-2.0 cm"
                name="minimallyInvasiveFollicularCarcinoma"
                value={tumorInformation?.lowRisk?.minimallyInvasiveFollicularCarcinoma}
                onChange={(e) => {
                  setTumorInformation({
                    ...tumorInformation,
                    lowRisk: {
                      ...tumorInformation.lowRisk,
                      minimallyInvasiveFollicularCarcinoma: e.target.value === 'true',
                    },
                  });
                }}
              >
                <FormControlLabel value={true} control={<Radio />} label="Yes" />
                <FormControlLabel value={false} control={<Radio />} label="No" />
              </RadioGroup>
            </Grid>
            <Grid item sm={4} xs={12}>
              <Typography variant="body1">
                History of treated non-CNS malignancy ({'>='} 5 years prior) with {'>'} 99%
                probability of cure
              </Typography>
              <RadioGroup
                row={true}
                aria-label="History of treated non-CNS malignancy (>= 5 years prior) with > 99% probability of cure"
                name="historyOfTreatedNonCnsMalignancy"
                value={tumorInformation?.lowRisk?.historyOfTreatedNonCnsMalignancy}
                onChange={(e) => {
                  setTumorInformation({
                    ...tumorInformation,
                    lowRisk: {
                      ...tumorInformation.lowRisk,
                      historyOfTreatedNonCnsMalignancy: e.target.value === 'true',
                    },
                  });
                }}
              >
                <FormControlLabel value={true} control={<Radio />} label="Yes" />
                <FormControlLabel value={false} control={<Radio />} label="No" />
              </RadioGroup>
            </Grid>
          </Grid>
        ) : null}
        <Grid item sm={12}>
          <Grid item style={{ borderLeft: '5px solid #4EA95E' }}>
            <Typography variant="body1" className={classes.transplantDetailsHeading}>
              Minimal Risk
            </Typography>
          </Grid>
          <FormControl component="fieldset">
            <RadioGroup
              row
              aria-label="Absolute Contraindication"
              name="controlled-radio-buttons-group"
              value={tumorInformation?.minimalRisk?.minimalRisk}
              onChange={(e) => {
                if (e.target.value === 'true') {
                  setTumorInformation({
                    ...tumorInformation,
                    highRisk: {
                      highRisk: false,
                    },
                    intermediateRisk: {
                      intermediateRisk: false,
                    },
                    lowRisk: {
                      lowRisk: false,
                    },
                    minimalRisk: {
                      ...tumorInformation.minimalRisk,
                      minimalRisk: true,
                    },
                    absoluteContraindication: {
                      absoluteContraindication: false,
                    },
                  });
                } else {
                  setTumorInformation({
                    ...tumorInformation,
                    minimalRisk: {
                      ...tumorInformation.minimalRisk,
                      minimalRisk: false,
                    },
                  });
                }
              }}
            >
              <FormControlLabel value={true} control={<Radio />} label="Yes" />
              <FormControlLabel value={false} control={<Radio />} label="No" />
            </RadioGroup>
          </FormControl>
        </Grid>
        {tumorInformation?.minimalRisk?.minimalRisk ? (
          <Grid
            container
            spacing={3}
            style={{ marginTop: 10, marginBottom: 10, padding: 10, marginLeft: 10 }}
          >
            <Grid item sm={4} xs={12}>
              <Typography variant="body1">
                (Resected) solitary renal cell carcinoma, {'>'} 1.0 cm {'<='} 2.5cm, well
                differentialed (Fuhrman 1-2)
              </Typography>
              <RadioGroup
                row={true}
                aria-label="(Resected) solitary renal cell carcinoma, > 1.0 cm <= 2.5cm, well differentialed (Fuhrman 1-2)"
                name="solitaryRenalCellCarcinoma"
                value={tumorInformation?.minimalRisk?.solitaryRenalCellCarcinoma}
                onChange={(e) => {
                  setTumorInformation({
                    ...tumorInformation,
                    minimalRisk: {
                      ...tumorInformation.minimalRisk,
                      solitaryRenalCellCarcinoma: e.target.value === 'true',
                    },
                  });
                }}
              >
                <FormControlLabel value={true} control={<Radio />} label="Yes" />
                <FormControlLabel value={false} control={<Radio />} label="No" />
              </RadioGroup>
            </Grid>
            <Grid item sm={4} xs={12}>
              <Typography variant="body1">Low grade CNS Tumor (WHO grade I or II)</Typography>
              <RadioGroup
                row={true}
                aria-label="Low grade CNS Tumor (WHO grade I or II)"
                name="lowGradeCns"
                value={tumorInformation?.minimalRisk?.lowGradeCns}
                onChange={(e) => {
                  setTumorInformation({
                    ...tumorInformation,
                    minimalRisk: {
                      ...tumorInformation.minimalRisk,
                      lowGradeCns: e.target.value === 'true',
                    },
                  });
                }}
              >
                <FormControlLabel value={true} control={<Radio />} label="Yes" />
                <FormControlLabel value={false} control={<Radio />} label="No" />
              </RadioGroup>
            </Grid>
            <Grid item sm={4} xs={12}>
              <Typography variant="body1">Primary CNS Mature Teratoma</Typography>
              <RadioGroup
                row={true}
                aria-label="Primary CNS Mature Teratoma"
                name="primaryCnsmatureTeratoma"
                value={tumorInformation?.minimalRisk?.primaryCnsmatureTeratoma}
                onChange={(e) => {
                  setTumorInformation({
                    ...tumorInformation,
                    minimalRisk: {
                      ...tumorInformation.minimalRisk,
                      primaryCnsmatureTeratoma: e.target.value === 'true',
                    },
                  });
                }}
              >
                <FormControlLabel value={true} control={<Radio />} label="Yes" />
                <FormControlLabel value={false} control={<Radio />} label="No" />
              </RadioGroup>
            </Grid>
            <Grid item sm={4} xs={12}>
              <Typography variant="body1">
                Solitary Papillary Thyroid Carcinoma, 0.5-2.0 cm
              </Typography>
              <RadioGroup
                row={true}
                aria-label="Solitary Papillary Thyroid Carcinoma, 0.5-2.0 cm"
                name="solitaryPapillaryThyroidCarcinoma"
                value={tumorInformation?.minimalRisk?.solitaryPapillaryThyroidCarcinoma}
                onChange={(e) => {
                  setTumorInformation({
                    ...tumorInformation,
                    minimalRisk: {
                      ...tumorInformation.minimalRisk,
                      solitaryPapillaryThyroidCarcinoma: e.target.value === 'true',
                    },
                  });
                }}
              >
                <FormControlLabel value={true} control={<Radio />} label="Yes" />
                <FormControlLabel value={false} control={<Radio />} label="No" />
              </RadioGroup>
            </Grid>
            <Grid item sm={4} xs={12}>
              <Typography variant="body1">
                Minimally invasive follicular carcinoma, thyroid, 1-0-2.0 cm
              </Typography>
              <RadioGroup
                row={true}
                aria-label="Minimally invasive follicular carcinoma, thyroid, 1-0-2.0 cm"
                name="minimallyInvasiveFollicularCarcinoma"
                value={tumorInformation?.minimalRisk?.minimallyInvasiveFollicularCarcinoma}
                onChange={(e) => {
                  setTumorInformation({
                    ...tumorInformation,
                    minimalRisk: {
                      ...tumorInformation.minimalRisk,
                      minimallyInvasiveFollicularCarcinoma: e.target.value === 'true',
                    },
                  });
                }}
              >
                <FormControlLabel value={true} control={<Radio />} label="Yes" />
                <FormControlLabel value={false} control={<Radio />} label="No" />
              </RadioGroup>
            </Grid>
            <Grid item sm={4} xs={12}>
              <Typography variant="body1">
                History of treated non-CNS malignancy ({'>='} 5 years prior) with {'>'} 99%
                probability of cure
              </Typography>
              <RadioGroup
                row={true}
                aria-label="History of treated non-CNS malignancy (>= 5 years prior) with > 99% probability of cure"
                name="historyOfTreatedNonCnsMalignancy"
                value={tumorInformation?.minimalRisk?.historyOfTreatedNonCnsMalignancy}
                onChange={(e) => {
                  setTumorInformation({
                    ...tumorInformation,
                    minimalRisk: {
                      ...tumorInformation.minimalRisk,
                      historyOfTreatedNonCnsMalignancy: e.target.value === 'true',
                    },
                  });
                }}
              >
                <FormControlLabel value={true} control={<Radio />} label="Yes" />
                <FormControlLabel value={false} control={<Radio />} label="No" />
              </RadioGroup>
            </Grid>
          </Grid>
        ) : null}
        {_renderDonorFooterButtons()}
      </Grid>
    );
  };

  const _renderHeartEcdInformation = () => {
    return (
      <Grid>
        <Grid item style={{ borderLeft: '5px solid #4EA95E' }}>
          <Typography variant="body1" className={classes.transplantDetailsHeading}>
            Heart ECD (Extended Criteria Donor) Information
          </Typography>
        </Grid>
        <Grid
          container
          spacing={3}
          style={{ marginTop: 10, marginBottom: 10, padding: 10, marginLeft: 10 }}
        >
          <Grid item sm={4} xs={12}>
            <Typography variant="body1">
              Does the patient have the Significant cardiac anomalies?
            </Typography>
            <RadioGroup
              row={true}
              aria-label="Does the patient have the Significant cardiac anomalies?"
              name="significantCardiacAnomalies"
              value={ecdInfoDetails?.heartEcd?.significantCardiacAnomalies}
              onChange={(e) => {
                setEcdInfoDetails({
                  ...ecdInfoDetails,
                  heartEcd: {
                    ...ecdInfoDetails.heartEcd,
                    significantCardiacAnomalies: e.target.value === 'true',
                  },
                });
              }}
            >
              <FormControlLabel value={true} control={<Radio />} label="Yes" />
              <FormControlLabel value={false} control={<Radio />} label="No" />
            </RadioGroup>
          </Grid>
          <Grid item sm={4} xs={12}>
            <Typography variant="body1">
              Does the patient have the Significant CAD or History of MI (Myocardial Infarction)?
            </Typography>
            <RadioGroup
              row={true}
              aria-label="Does the patient have the Significant CAD or History of MI (Myocardial Infarction)?"
              name="myocardialInfarction"
              value={ecdInfoDetails?.heartEcd?.myocardialInfarction}
              onChange={(e) => {
                setEcdInfoDetails({
                  ...ecdInfoDetails,
                  heartEcd: {
                    ...ecdInfoDetails.heartEcd,
                    myocardialInfarction: e.target.value === 'true',
                  },
                });
              }}
            >
              <FormControlLabel value={true} control={<Radio />} label="Yes" />
              <FormControlLabel value={false} control={<Radio />} label="No" />
            </RadioGroup>
          </Grid>
          <Grid item sm={4} xs={12}>
            <Typography variant="body1">
              Does the patient have the Significant valvular abnormalities?
            </Typography>
            <RadioGroup
              row={true}
              aria-label="Does the patient have the Significant valvular abnormalities?"
              name="significantValvularAbnormalities"
              value={ecdInfoDetails?.heartEcd?.significantValvularAbnormalities}
              onChange={(e) => {
                setEcdInfoDetails({
                  ...ecdInfoDetails,
                  heartEcd: {
                    ...ecdInfoDetails.heartEcd,
                    significantValvularAbnormalities: e.target.value === 'true',
                  },
                });
              }}
            >
              <FormControlLabel value={true} control={<Radio />} label="Yes" />
              <FormControlLabel value={false} control={<Radio />} label="No" />
            </RadioGroup>
          </Grid>
          <Grid item sm={4} xs={12}>
            <Typography variant="body1">
              Does the patient have the Refractory ventricular arrhythmias?
            </Typography>
            <RadioGroup
              row={true}
              aria-label="Does the patient have the Refractory ventricular arrhythmias?"
              name="refractoryVentricularArrhythmias"
              value={ecdInfoDetails?.heartEcd?.refractoryVentricularArrhythmias}
              onChange={(e) => {
                setEcdInfoDetails({
                  ...ecdInfoDetails,
                  heartEcd: {
                    ...ecdInfoDetails.heartEcd,
                    refractoryVentricularArrhythmias: e.target.value === 'true',
                  },
                });
              }}
            >
              <FormControlLabel value={true} control={<Radio />} label="Yes" />
              <FormControlLabel value={false} control={<Radio />} label="No" />
            </RadioGroup>
          </Grid>
          <Grid item sm={4} xs={12}>
            <Typography variant="body1">
              Does the patient have the Ejection fraction {'< 45%'} by Echo?
            </Typography>
            <RadioGroup
              row={true}
              aria-label="Does the patient have the Ejection fraction < 45% by Echo?"
              name="ejectionFractionLessThanFortyFivePercentByEcho"
              value={ecdInfoDetails?.heartEcd?.ejectionFractionLessThanFortyFivePercentByEcho}
              onChange={(e) => {
                setEcdInfoDetails({
                  ...ecdInfoDetails,
                  heartEcd: {
                    ...ecdInfoDetails.heartEcd,
                    ejectionFractionLessThanFortyFivePercentByEcho: e.target.value === 'true',
                  },
                });
              }}
            >
              <FormControlLabel value={true} control={<Radio />} label="Yes" />
              <FormControlLabel value={false} control={<Radio />} label="No" />
            </RadioGroup>
          </Grid>
          <Grid item sm={4} xs={12}>
            <Typography variant="body1">
              Does the patient have the Size/ Weight matching?
            </Typography>
            <RadioGroup
              row={true}
              aria-label="Does the patient have the Size/ Weight matching?"
              name="sizeWeightMatching"
              value={ecdInfoDetails?.heartEcd?.sizeWeightMatching}
              onChange={(e) => {
                setEcdInfoDetails({
                  ...ecdInfoDetails,
                  heartEcd: {
                    ...ecdInfoDetails.heartEcd,
                    sizeWeightMatching: e.target.value === 'true',
                  },
                });
              }}
            >
              <FormControlLabel value={true} control={<Radio />} label="Yes" />
              <FormControlLabel value={false} control={<Radio />} label="No" />
            </RadioGroup>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const _renderKidneyEcdInformation = () => {
    return (
      <Grid>
        <Grid item style={{ borderLeft: '5px solid #4EA95E' }}>
          <Typography variant="body1" className={classes.transplantDetailsHeading}>
            Kidney ECD (Extended Criteria Donor) Information
          </Typography>
        </Grid>
        <Grid
          container
          spacing={3}
          style={{ marginTop: 10, marginBottom: 10, padding: 10, marginLeft: 10 }}
        >
          <Grid item sm={4} xs={12}>
            <Typography variant="body1">
              Does the patient have any history of CKD( chronic kidney disease)?
            </Typography>
            <RadioGroup
              row={true}
              aria-label="Does the patient have any history of CKD( chronic kidney disease)?"
              name="chronicKidneyDisease"
              value={ecdInfoDetails?.kidneyEcd?.chronicKidneyDisease}
              onChange={(e) => {
                setEcdInfoDetails({
                  ...ecdInfoDetails,
                  kidneyEcd: {
                    ...ecdInfoDetails.kidneyEcd,
                    chronicKidneyDisease: e.target.value === 'true',
                  },
                });
              }}
            >
              <FormControlLabel value={true} control={<Radio />} label="Yes" />
              <FormControlLabel value={false} control={<Radio />} label="No" />
            </RadioGroup>
          </Grid>
          <Grid item sm={4} xs={12}>
            <Typography variant="body1">Does the patient have any combodities?</Typography>
            <RadioGroup
              row={true}
              aria-label="Does the patient have any combodities?"
              name="combodities"
              value={ecdInfoDetails?.kidneyEcd?.combodities}
              onChange={(e) => {
                setEcdInfoDetails({
                  ...ecdInfoDetails,
                  kidneyEcd: {
                    ...ecdInfoDetails.kidneyEcd,
                    combodities: e.target.value === 'true',
                  },
                });
              }}
            >
              <FormControlLabel value={true} control={<Radio />} label="Yes" />
              <FormControlLabel value={false} control={<Radio />} label="No" />
            </RadioGroup>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const _renderLiverEcdInformation = () => {
    return (
      <Grid>
        <Grid item style={{ borderLeft: '5px solid #4EA95E' }}>
          <Typography variant="body1" className={classes.transplantDetailsHeading}>
            Liver ECD (Extended Criteria Donor) Information
          </Typography>
        </Grid>
        <Grid
          container
          spacing={3}
          style={{ marginTop: 10, marginBottom: 10, padding: 10, marginLeft: 10 }}
        >
          <Grid item sm={4}>
            <ValidatedInput
              label="Macrosteatosis (in %)"
              value={ecdInfoDetails?.liverEcd?.macrosteatosis}
              onChange={(e) => {
                setEcdInfoDetails({
                  ...ecdInfoDetails,
                  liverEcd: {
                    ...ecdInfoDetails.liverEcd,
                    macrosteatosis: e.target.value,
                  },
                });
              }}
              fullWidth
            />
          </Grid>
          <Grid item sm={12}>
            <FormControl component="fieldset">
              <FormLabel component="legend">
                <Typography style={{ color: 'black' }}>
                  High Ionotropes (Single ionotrope at doses as below or 3 or more ionotropes at any
                  doses)
                </Typography>
              </FormLabel>
              <RadioGroup
                row
                aria-label="High Ionotropes (Single ionotrope at doses as below or 3 or more ionotropes at any doses)"
                name="controlled-radio-buttons-group"
                value={ecdInfoDetails?.liverEcd?.highInotrope}
                onChange={(e) => {
                  setEcdInfoDetails({
                    ...ecdInfoDetails,
                    liverEcd: {
                      ...ecdInfoDetails.liverEcd,
                      highInotrope: e.target.value === 'true',
                      dopamine: '',
                      noradrenaline: '',
                      adrenaline: '',
                      vasopressin: '',
                    },
                  });
                }}
              >
                <FormControlLabel value={true} control={<Radio />} label="Yes" />
                <FormControlLabel value={false} control={<Radio />} label="No" />
              </RadioGroup>
            </FormControl>
          </Grid>
          {ecdInfoDetails?.liverEcd?.highInotrope ? (
            <Grid container spacing={2} style={{ marginLeft: 20 }}>
              <Grid item sm={4}>
                <ValidatedInput
                  label="Dopamine(micrograms/kg/min)"
                  value={ecdInfoDetails?.liverEcd?.dopamine}
                  onChange={(e) => {
                    setEcdInfoDetails({
                      ...ecdInfoDetails,
                      liverEcd: {
                        ...ecdInfoDetails.liverEcd,
                        dopamine: e.target.value,
                      },
                    });
                  }}
                  fullWidth
                />
              </Grid>
              <Grid item sm={4}>
                <ValidatedInput
                  label="Noradrenaline (micrograms/kg/min)"
                  value={ecdInfoDetails?.liverEcd?.noradrenaline}
                  onChange={(e) => {
                    setEcdInfoDetails({
                      ...ecdInfoDetails,
                      liverEcd: {
                        ...ecdInfoDetails.liverEcd,
                        noradrenaline: e.target.value,
                      },
                    });
                  }}
                  fullWidth
                />
              </Grid>
              <Grid item sm={4}>
                <ValidatedInput
                  label="Adrenaline (micrograms/kg/min)"
                  value={ecdInfoDetails?.liverEcd?.adrenaline}
                  onChange={(e) => {
                    setEcdInfoDetails({
                      ...ecdInfoDetails,
                      liverEcd: {
                        ...ecdInfoDetails.liverEcd,
                        adrenaline: e.target.value,
                      },
                    });
                  }}
                  fullWidth
                />
              </Grid>
              <Grid item sm={4}>
                <ValidatedInput
                  label="Vasopressin (units/hour)"
                  value={ecdInfoDetails?.liverEcd?.vasopressin}
                  onChange={(e) => {
                    setEcdInfoDetails({
                      ...ecdInfoDetails,
                      liverEcd: {
                        ...ecdInfoDetails.liverEcd,
                        vasopressin: e.target.value,
                      },
                    });
                  }}
                  fullWidth
                />
              </Grid>
            </Grid>
          ) : null}
          <Grid item sm={12}>
            <FormControl component="fieldset">
              <FormLabel component="legend">
                <Typography style={{ color: 'black' }}>Transaminitis (raised AST / ALT)</Typography>
              </FormLabel>
              <RadioGroup
                row
                aria-label="Transaminitis (raised AST / ALT)"
                name="controlled-radio-buttons-group"
                value={ecdInfoDetails?.liverEcd?.transaminitis}
                onChange={(e) => {
                  setEcdInfoDetails({
                    ...ecdInfoDetails,
                    liverEcd: {
                      ...ecdInfoDetails.liverEcd,
                      transaminitis: e.target.value === 'true',
                      transaminitisSelect: '',
                    },
                  });
                }}
              >
                <FormControlLabel value={true} control={<Radio />} label="Yes" />
                <FormControlLabel value={false} control={<Radio />} label="No" />
              </RadioGroup>
            </FormControl>
          </Grid>
          {ecdInfoDetails?.liverEcd?.transaminitis ? (
            <Grid item sm={12} style={{ marginLeft: 20 }}>
              <FormControl component="fieldset">
                <RadioGroup
                  row
                  aria-label=""
                  name="controlled-radio-buttons-group"
                  value={ecdInfoDetails?.liverEcd?.transaminitisSelect}
                  onChange={(e) => {
                    setEcdInfoDetails({
                      ...ecdInfoDetails,
                      liverEcd: {
                        ...ecdInfoDetails.liverEcd,
                        transaminitisSelect: e.target.value,
                      },
                    });
                  }}
                >
                  <FormControlLabel value="10" control={<Radio />} label="10 times ULN" />
                  <FormControlLabel
                    value="5"
                    control={<Radio />}
                    label="5 times ULN and rising trend"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
          ) : null}
          <Grid item sm={12}>
            <ValidatedInput
              label="Cholestatic Liver: Bilirubin (mg/dL)"
              value={ecdInfoDetails?.liverEcd?.bilirubin}
              onChange={(e) => {
                setEcdInfoDetails({
                  ...ecdInfoDetails,
                  liverEcd: {
                    ...ecdInfoDetails.liverEcd,
                    bilirubin: e.target.value,
                  },
                });
              }}
              fullWidth
            />
          </Grid>
          <Grid item sm={12}>
            <FormControl component="fieldset">
              <FormLabel component="legend">
                {' '}
                <Typography style={{ color: 'black' }}>
                  Positive blood culture within last 5 days
                </Typography>
              </FormLabel>
              <RadioGroup
                row
                aria-label="Positive blood culture within last 5 days"
                name="controlled-radio-buttons-group"
                value={ecdInfoDetails?.liverEcd?.positiveBloodCulture}
                onChange={(e) => {
                  setEcdInfoDetails({
                    ...ecdInfoDetails,
                    liverEcd: {
                      ...ecdInfoDetails.liverEcd,
                      positiveBloodCulture: e.target.value === 'true',
                    },
                  });
                }}
              >
                <FormControlLabel value={true} control={<Radio />} label="Yes" />
                <FormControlLabel value={false} control={<Radio />} label="No" />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item sm={12}>
            <ValidatedInput
              label="Expected Cold Ischemia Time (CIT) (in Hours)"
              value={ecdInfoDetails?.liverEcd?.cit}
              onChange={(e) => {
                setEcdInfoDetails({
                  ...ecdInfoDetails,
                  liverEcd: {
                    ...ecdInfoDetails.liverEcd,
                    cit: e.target.value,
                  },
                });
              }}
              fullWidth
            />
          </Grid>
          <Grid item sm={12}>
            <FormControl component="fieldset">
              <FormLabel component="legend">
                {' '}
                <Typography style={{ color: 'black' }}>Partial Graft</Typography>
              </FormLabel>
              <RadioGroup
                row
                aria-label="Partial Graft"
                name="controlled-radio-buttons-group"
                value={ecdInfoDetails?.liverEcd?.partialGraft}
                onChange={(e) => {
                  setEcdInfoDetails({
                    ...ecdInfoDetails,
                    liverEcd: {
                      ...ecdInfoDetails.liverEcd,
                      partialGraft: e.target.value === 'true',
                    },
                  });
                }}
              >
                <FormControlLabel value={true} control={<Radio />} label="Yes" />
                <FormControlLabel value={false} control={<Radio />} label="No" />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item sm={12}>
            <FormControl component="fieldset">
              <FormLabel component="legend">
                {' '}
                <Typography style={{ color: 'black' }}>Split Graft</Typography>
              </FormLabel>
              <RadioGroup
                row
                aria-label="Split Graft"
                name="controlled-radio-buttons-group"
                value={ecdInfoDetails?.liverEcd?.splitGraft}
                onChange={(e) => {
                  setEcdInfoDetails({
                    ...ecdInfoDetails,
                    liverEcd: {
                      ...ecdInfoDetails.liverEcd,
                      splitGraft: e.target.value === 'true',
                    },
                  });
                }}
              >
                <FormControlLabel value={true} control={<Radio />} label="Yes" />
                <FormControlLabel value={false} control={<Radio />} label="No" />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item sm={12}>
            <ValidatedInput
              label="Last pre-retrieval serum Sodium (mEq/L)"
              value={ecdInfoDetails?.liverEcd?.sodium}
              onChange={(e) => {
                setEcdInfoDetails({
                  ...ecdInfoDetails,
                  liverEcd: {
                    ...ecdInfoDetails.liverEcd,
                    sodium: e.target.value,
                  },
                });
              }}
              fullWidth
            />
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const _renderDonorEDCInformation = () => {
    return (
      <Grid container style={{ padding: 10 }}>
        {donorOrganRetrievalDetails.ecdOrgans.includes('HEART')
          ? _renderHeartEcdInformation()
          : null}
        {donorOrganRetrievalDetails.ecdOrgans.includes('KIDNEY')
          ? _renderKidneyEcdInformation()
          : null}
        {donorOrganRetrievalDetails.ecdOrgans.includes('LIVER')
          ? _renderLiverEcdInformation()
          : null}
        {_renderDonorFooterButtons()}
      </Grid>
    );
  };

  const _renderCurrentViewDocuments = () => {
    return (
      <>
        <Paper
          style={{
            // background: '#F0FEF9',
            border: '1px solid #B1E7D3',
            borderRadius: '4px',
            marginTop: 10,
            marginBottom: 10,
          }}
        >
          <Grid container spacing={2} style={{ padding: 20 }}>
            <AllocationDonorAttachment
              donorMedicalReportsDetails={donorMedicalReportsDetails}
              setDonorMedicalReportsDetails={setDonorMedicalReportsDetails}
              donorAttachmentsDetails={donorAttachmentsDetails}
              setDonorAttachmentsDetails={setDonorAttachmentsDetails}
            />
          </Grid>
        </Paper>
      </>
    );
  };

  const _renderDonorCurrentForm = () => {
    if (donorCurrentSelectedNav === 'BASICINFO') {
      return _renderDonorListingBasicInfo();
    }
    if (donorCurrentSelectedNav === 'MEDICALREPORTS') {
      return _renderDonorMedicalReportsAndStatus();
      // return (
      //   <AllocationDonorMedicalReports
      //     donorMedicalReportsDetails={donorMedicalReportsDetails}
      //     setDonorMedicalReportsDetails={setDonorMedicalReportsDetails}
      //     donorMedicalReportCurrentSelectedTab={donorMedicalReportCurrentSelectedTab}
      //     setDonorMedicalReportCurrentSelectedTab={setDonorMedicalReportCurrentSelectedTab}
      //     medicoLegalCase={medicoLegalCase}
      //     setMedicoLegalCase={setMedicoLegalCase}
      //   />
      // );
    }
    if (donorCurrentSelectedNav === 'ATTACHMENTS') {
      return _renderDonorReportAttachments();
    }
    if (donorCurrentSelectedNav === 'ORGANRETRVLINFO') {
      return _renderDonorOrganRetrievalInformation();
    }
    if (donorCurrentSelectedNav === 'ECDINFO') {
      return _renderDonorEDCInformation();
    }
    if (donorCurrentSelectedNav === 'TUMOR') {
      return _renderDonorTumorInformation();
    }
    return null;
  };

  return (
    <Box className={classes.form12BoxStyle}>
      {donorFormStatus !== 'SUBMITTED' && !donorRecordRoute ? (
        <>
          {/* {redirectToSaved ? <Redirect to={`/allocationDonor/saved/${draftDonorId}`} /> : null} */}
          <Box display="flex" flexDirection="row" marginTop="20">
            <Grid container justify="center" spacing={2}>
              <Grid item sm={2}>
                <Paper className={classes.paperTransplantSections}>
                  <Grid
                    container
                    style={{ padding: 20 }}
                    justify="center"
                    alignContent="center"
                    alignItems="center"
                    spacing={2}
                  >
                    <Grid
                      item
                      xs={12}
                      style={{ justify: 'center', alignContent: 'center', alignItems: 'center' }}
                    >
                      <Typography
                        variant="body1"
                        color="primary"
                        className={classes.transplantSectionsHeadingStyle}
                      >
                        New Donor Registration
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      style={{ justify: 'center', alignContent: 'center', alignItems: 'center' }}
                    >
                      <Button
                        onClick={() => {
                          setDonorCurrentSelectedNav('BASICINFO');
                        }}
                        color="primary"
                        fullWidth
                        variant={donorCurrentSelectedNav === 'BASICINFO' ? 'contained' : 'outlined'}
                      >
                        Basic and Demographic Information
                      </Button>
                    </Grid>
                    <Grid item xs={12}>
                      <Button
                        onClick={() => {
                          setDonorCurrentSelectedNav('MEDICALREPORTS');
                        }}
                        color="primary"
                        fullWidth
                        variant={
                          donorCurrentSelectedNav === 'MEDICALREPORTS' ? 'contained' : 'outlined'
                        }
                      >
                        Medical Reports and Status
                      </Button>
                    </Grid>
                    <Grid item xs={12}>
                      <Button
                        onClick={() => {
                          setDonorCurrentSelectedNav('ATTACHMENTS');
                        }}
                        color="primary"
                        fullWidth
                        variant={
                          donorCurrentSelectedNav === 'ATTACHMENTS' ? 'contained' : 'outlined'
                        }
                      >
                        Attachments
                      </Button>
                    </Grid>
                    {donorPages.includes('TUMOR') ? (
                      <Grid item xs={12}>
                        <Button
                          onClick={() => {
                            setDonorCurrentSelectedNav('TUMOR');
                          }}
                          color="primary"
                          fullWidth
                          variant={donorCurrentSelectedNav === 'TUMOR' ? 'contained' : 'outlined'}
                        >
                          Tumor Information
                        </Button>
                      </Grid>
                    ) : null}
                    <Grid item xs={12}>
                      <Button
                        onClick={() => {
                          setDonorCurrentSelectedNav('ORGANRETRVLINFO');
                        }}
                        color="primary"
                        fullWidth
                        variant={
                          donorCurrentSelectedNav === 'ORGANRETRVLINFO' ? 'contained' : 'outlined'
                        }
                      >
                        Organ Retrieval Information
                      </Button>
                    </Grid>
                    {donorPages.includes('ECDINFO') ? (
                      <Grid item xs={12}>
                        <Button
                          onClick={() => {
                            setDonorCurrentSelectedNav('ECDINFO');
                          }}
                          color="primary"
                          fullWidth
                          variant={donorCurrentSelectedNav === 'ECDINFO' ? 'contained' : 'outlined'}
                        >
                          Extended Criteria Donor Information
                        </Button>
                      </Grid>
                    ) : null}
                  </Grid>
                </Paper>
              </Grid>
              <Grid item sm={10}>
                <Paper>{_renderDonorCurrentForm()}</Paper>
              </Grid>
            </Grid>
          </Box>
        </>
      ) : (
        <Box>
          <Grid container spacing={2} style={{ padding: 20 }}>
            <Grid item sm={3} xs={12} style={{ marginLeft: 180 }}>
              <Button
                variant={currentRecordSection === 'DETAILS' ? 'contained' : 'outlined'}
                color="primary"
                style={{
                  marginLeft: 10,
                  marginRight: 10,
                  border: '1px solid #25B67D',
                  borderBottom: 'none',
                  borderRadius: '2px',
                  fontSize: '16px',
                  height: '52px',
                  backgroundColor: currentRecordSection !== 'DETAILS' ? '#FFFFFF' : '',
                }}
                onClick={() => {
                  setCurrentRecordSection('DETAILS');
                }}
              >
                Donor Form Details
              </Button>
            </Grid>
            <Grid item sm={3} xs={12}>
              <Button
                variant={currentRecordSection === 'DOCUMENTS' ? 'contained' : 'outlined'}
                color="primary"
                style={{
                  marginLeft: 10,
                  marginRight: 10,
                  border: '1px solid #25B67D',
                  borderBottom: 'none',
                  borderRadius: '2px',
                  fontSize: '16px',
                  height: '52px',
                  minWidth: '130px',
                  backgroundColor: currentRecordSection !== 'DOCUMENTS' ? '#FFFFFF' : '',
                }}
                onClick={() => {
                  setCurrentRecordSection('DOCUMENTS');
                }}
              >
                Donor Attached Documents
              </Button>
            </Grid>
          </Grid>
        </Box>
      )}
      {currentRecordSection === 'DETAILS' ? (
        <>
          <MemoizedDocumentForView documentBuffer={donorRecordViewBuffer} />
        </>
      ) : null}
      {currentRecordSection === 'DOCUMENTS' ? _renderCurrentViewDocuments() : null}
      {redirectToDashboard ? <Redirect to="/dashboard" /> : null}

      {_renderDonorFormPreviewDialog()}
      {_renderDocumentListDialog()}
      {_renderNoOrganAddedDialog()}
      {/* {_renderNoKidneySideSelectedDialog()} */}
    </Box>
  );
}

AllocationDonor.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      draftId: PropTypes.string,
    }),
  }),
  validate: PropTypes.func.isRequired,
  validation: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]).isRequired,
  onValidation: PropTypes.func.isRequired,
};
AllocationDonor.defaultProps = {
  match: {
    params: {
      draftId: undefined,
    },
  },
};

export default compose(withRouter, withValidation)(AllocationDonor);
