import React from 'react';
import PropTypes from 'prop-types';
import LoadingScreen from './LoadingScreen';
import ErrorArticle from './ErrorArticle';

const LoadingComponent = ({ error, timedOut, pastDelay, retry }) => {
  if (error) {
    return (
      <ErrorArticle mailSubj="MahaAyudaan ModuleLoad Error">
        <React.Fragment>
          Please {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a href="" onClick={retry}>
            <b>Retry</b>
          </a>
        </React.Fragment>
      </ErrorArticle>
    );
  }
  if (timedOut) {
    return (
      <LoadingScreen
        loadingText={
          <React.Fragment>
            Taking a long time... {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a href="" onClick={retry}>
              Refresh
            </a>
          </React.Fragment>
        }
      />
    );
  }
  if (pastDelay) {
    return <LoadingScreen loadingText="Loading Module..." />;
  }
  return null;
};
LoadingComponent.propTypes = {
  error: PropTypes.bool,
  timedOut: PropTypes.bool.isRequired,
  pastDelay: PropTypes.bool.isRequired,
  retry: PropTypes.func.isRequired
};
LoadingComponent.defaultProps = {
  error: null
};

export default LoadingComponent;
